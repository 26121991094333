import Box from 'assets/components/box/Box';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales, Language } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Theme from 'assets/themes/Theme.Common';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { compact, differenceBy, flatten, get, intersectionBy } from 'lodash';
import Application from 'models/core/application/Model.Application';
import Permission from 'models/core/permission/Model.Permission';
import Role from 'models/core/role/Model.Role';
import User from 'models/core/user/Model.User';
import userSaveApi from 'models/core/user/save/Api.User.Save';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Distributor from 'models/sales/distributor/Model.Distributor';
import { Fragment } from 'react';

export default function UserEdit(
  props: Template.ModalForm.MinimalImport<
    Model.IUser,
    Api.Core.User.Save.IRequest,
    Api.Res<Api.Core.User.Save.IResponse>
  >
) {
  const { permissions: currentUserPermissions, user, isDealer, isDistributor } = useAuthStore();
  const { lang } = getLocales();
  const save = useApi({ action: userSaveApi, callback: props.onSave, wait: true });

  const permissionsApi = useApi({ action: Permission.list });
  const permissions = permissionsApi.payload?.data;
  const applicationsApi = useApi({ action: Application.list });
  const applications = applicationsApi.payload?.data;
  const rolesApi = useApi({ action: Role.list });
  const roles = rolesApi.payload?.data;

  const { payload: dealersPayload } = useApi({
    action: Dealer.list,
    body: {
      id: compact(user?.dealers?.map((d) => d.id)),
    },
  });
  const { payload: distributorsPayload } = useApi(
    {
      action: Distributor.list,
      body: {
        id: compact([user?.distributor?.id ?? user?.distributorId]),
      },
      wait: !user,
    },
    [user]
  );

  return (
    <>
      <ModalForm<Api.Core.User.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[props.data]}
        title={lang.user}
        default={props.data}
        onCancel={props.onCancel}
        onSubmit={(data) => save.execute(data)}
        validation={(data, errors) => {
          if (!data.firstName) errors.firstName = lang.mustNotBeEmpty;
          if (!data.lastName) errors.lastName = lang.mustNotBeEmpty;
          if (!data.email) errors.email = lang.mustNotBeEmpty;
          if (!data.languageIsoCode) errors.languageIsoCode = lang.mustNotBeEmpty;
          if (!data?.id && !User.checkPasswordValidity(data.password)) errors.password = lang.passwordValidityMessage;
          if (!data?.roles?.length) errors.roles = { _objectError: lang.mustSelectAtleastOneOption };
          if ((isDistributor || isDealer) && !data.distributorId) errors.distributorId = lang.mustNotBeEmpty;
          if ((isDistributor || isDealer) && !data.dealers?.length)
            errors.dealers = { _objectError: lang.mustSelectAtleastOneOption };
        }}
        beforeUpdate={(data, updates) => {
          if (updates.roles) {
            const currentPermissions = flatten(
              data?.roles.map((it) => roles?.find((r) => r.id == it.id)?.permissions)
            ).filter((it) => it);
            data.additionalPermissions = data.additionalPermissions?.filter(
              (it) => !currentPermissions?.find((p) => p.id == it.permission?.id)
            );
          }
          return data;
        }}
        tabs={[
          { id: 'info', icon: 'fas-user', text: lang.info },
          {
            id: 'role',
            icon: 'fas-user-shield',
            text: lang.roles,
            errors: ({ form }) => form.errors?.roles?._objectError,
          },
          { id: 'additionalPermissions', icon: 'fas-shield-alt', text: lang.additionalPermissions },
        ]}
        render={({ form, activeTab }) => {
          const selectableDistributors =
            distributorsPayload?.data?.filter((it) => (user?.distributorId ? user?.distributorId === it.id : true)) ??
            [];
          const selectableDealers =
            dealersPayload?.data?.filter((d) => d.representativeId === form.data.distributorId) || [];
          return (
            <>
              {activeTab?.id == 'info' && (
                <FormWrapper>
                  <InputWrapper>
                    <TextInput
                      name="firstName"
                      label={lang.firstName}
                      value={form.data.firstName}
                      error={form.errors.firstName}
                      onChange={form.update}
                      icon={<Icon class="if-quill-pen" />}
                    />
                    <TextInput
                      name="lastName"
                      label={lang.lastName}
                      value={form.data.lastName}
                      error={form.errors.lastName}
                      onChange={form.update}
                      icon={<Icon class="if-quill-pen" />}
                    />
                  </InputWrapper>
                  <TextInput
                    name="inpute"
                    label={lang.email}
                    value={form.data.email}
                    error={form.errors.email}
                    onChange={({ inpute: email }) => form.update({ email })}
                    icon={<Icon class="if-envelope" />}
                  />
                  {!props.data?.id && (
                    <TextInput
                      name="inputp"
                      label={lang.password}
                      value={form.data.password}
                      error={form.errors.password}
                      onChange={({ inputp: password }) => form.update({ password })}
                      type="password"
                      autoComplete="new-password"
                      icon={<Icon class="if-key" />}
                    />
                  )}
                  <SelectInput
                    name="languageIsoCode"
                    label={lang.language}
                    data={Object.values(Language).map((id) => ({ id, title: lang[id] }))}
                    value={form.data.languageIsoCode}
                    error={form.errors.languageIsoCode}
                    onChange={form.update}
                    icon={<Icon class="fas-globe" />}
                  />

                  <SelectInput
                    name="distributorId"
                    disabled={isDealer}
                    label={lang.distributor}
                    data={selectableDistributors.map((it) => it.displayInfo)}
                    value={form.data.distributorId}
                    error={form.errors.distributorId}
                    onChange={({ distributorId }) => {
                      const distributor = selectableDistributors?.find((d) => d.id === distributorId);
                      form.update({
                        distributorId: distributor?.id,
                        distributor,
                        dealers: intersectionBy(distributor?.dealers || [], form.data.dealers || [], (d) => d.id),
                      });
                    }}
                    icon={<Icon class="fas-globe" />}
                  />

                  <SelectInput
                    name="dealers"
                    label={lang.dealers}
                    data={selectableDealers?.map((it) => it.displayInfo)}
                    value={form.data.dealers?.map((it) => it.id)}
                    error={form.errors.dealers?._objectError}
                    onChange={(selectData) =>
                      form.update({
                        dealers: selectData.dealers
                          .map((it) => selectableDealers?.find((d) => d.id === it))
                          ?.filter((it) => (it ? true : false)),
                      })
                    }
                    isMultiselect
                    icon={<Icon class="fas-globe" />}
                  />
                </FormWrapper>
              )}
              {activeTab?.id === 'role' && (
                <>
                  <Collection class={ThemeCollectionTable}>
                    <DataMap
                      data={applications}
                      render={({ data: app }) => {
                        //we are filtering by current logged in permissions to avoid issues when user updates himself
                        const roleList = Role.getPermissionUnion(roles, app).filter((it) =>
                          isDistributor || isDealer
                            ? it.unionPermissions.every((p) => get(currentUserPermissions, p.key))
                            : true
                        );
                        return (
                          roleList?.length > 0 && (
                            <Fragment key={app.id}>
                              <Box
                                active
                                children={app.name}
                                style={{ paddingLeft: Theme.Size.L, paddingRight: Theme.Size.L }}
                              />
                              <DataMap
                                data={roleList}
                                render={({ data: { role, unionPermissions } }) => {
                                  const currentRole = form.data.roles?.find((it) => it.id == role.id);
                                  return (
                                    <Card
                                      key={role.id}
                                      class={ThemeCardRow}
                                      clickable
                                      title={role.name}
                                      subtitle={unionPermissions?.map((p) => p.displayInfo?.title)?.join(', ')}
                                      htmlElementProps={{
                                        card: {
                                          data: {
                                            role: role.id,
                                            application: app.id,
                                          },
                                          onClick: () => {
                                            if (currentRole)
                                              form.update({
                                                roles: form.data.roles?.filter((it) => it.id !== currentRole?.id),
                                              });
                                            else form.update({ roles: [...(form.data.roles || []), { id: role.id }] });
                                          },
                                          title: currentRole ? lang.unselect : lang.select,
                                        },
                                      }}
                                      actions={<Icon class={currentRole ? 'fas-check-square' : 'far-square'} />}
                                    />
                                  );
                                }}
                              />
                            </Fragment>
                          )
                        );
                      }}
                    />
                  </Collection>
                </>
              )}
              {activeTab?.id == 'additionalPermissions' && (
                <DataMap
                  data={applications}
                  render={({ data: app }) => {
                    //we are filtering by current logged in permissions to avoid issues when user updates himself
                    const permissionList = differenceBy(
                      permissions?.filter(
                        (it) =>
                          it.application?.id == app.id &&
                          (isDistributor || isDealer ? get(currentUserPermissions, it.key) : true)
                      ),
                      flatten(form.data?.roles.map((it) => roles?.find((r) => r.id == it.id)?.permissions)).filter(
                        (it) => it
                      ),
                      (permission) => permission.id
                    );
                    return (
                      permissionList?.length > 0 && (
                        <Collection class={ThemeCollectionTable} key={app.id}>
                          <Box
                            active
                            children={app.name}
                            style={{ paddingLeft: Theme.Size.L, paddingRight: Theme.Size.L }}
                          />
                          <DataMap
                            data={permissionList}
                            render={({ data: permission }) => {
                              const currentPermission = form.data.additionalPermissions?.find(
                                (it) => it.permission?.id === permission.id
                              );
                              return (
                                <Card
                                  key={permission.id}
                                  class={ThemeCardRow}
                                  info={permission?.category?.name}
                                  title={permission.name}
                                  subtitle={permission.description}
                                  actions={
                                    <>
                                      <DateInput
                                        dateTimeFormat={defaultInputDateTimeFormat}
                                        onChange={({ expiresAtUtc }) => {
                                          if (currentPermission) {
                                            currentPermission.expiresAtUtc = expiresAtUtc;
                                            form.update({ additionalPermissions: form.data.additionalPermissions });
                                          }
                                        }}
                                        value={currentPermission?.expiresAtUtc}
                                        name="expiresAtUtc"
                                        placeholder={!currentPermission?.expiresAtUtc ? lang.unlimited : ''}
                                        disabled={!currentPermission}
                                        icon={<Icon class="fas-calendar-alt" />}
                                      />
                                      &nbsp; &nbsp;
                                      <Icon
                                        class={currentPermission ? 'fas-check-square' : 'far-square'}
                                        style={{ cursor: 'pointer' }}
                                        htmlElementProps={{
                                          onClick: () => {
                                            if (currentPermission)
                                              form.update({
                                                additionalPermissions: form.data.additionalPermissions?.filter(
                                                  (it) => it.permission?.id !== currentPermission?.permission?.id
                                                ),
                                              });
                                            else
                                              form.update({
                                                additionalPermissions: [
                                                  ...(form.data.additionalPermissions || []),
                                                  { permission: { id: permission.id } },
                                                ],
                                              });
                                          },
                                          title: currentPermission ? lang.unselect : lang.select,
                                        }}
                                      />
                                    </>
                                  }
                                />
                              );
                            }}
                          />
                        </Collection>
                      )
                    );
                  }}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
}
