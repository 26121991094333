import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import ColorInput from 'assets/components/inputs/color/ColorInput';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { CSS_COLOR_VARIABLES } from 'config/Ui.Config';
import { compact, first, get, kebabCase } from 'lodash';
import React from 'react';
import { DealerGroupForm } from '../DealerGroup.Edit';
import { getLocales } from 'assets/locales/Locale';

const DealerGroupUiSettingsForm: React.FC = () => {
  const { form } = useModalForm<DealerGroupForm>();
  const { lang } = getLocales();
  function updateCssVariables(cssVariables: React.CSSProperties) {
    form.merge({ uiSettings: { cssVariables } });
  }
  return (
    <FormWrapper>
      <h2>Primary</h2>
      <InputWrapper>
        <DataMap
          data={CSS_COLOR_VARIABLES.primary}
          render={({ data: varName }) => (
            <ColorInput
              name={varName}
              label={kebabCase(varName).replace(/-/g, ' ').trim()}
              value={get(form.data.uiSettings?.cssVariables, varName)}
              error={form.errors.name}
              onChange={updateCssVariables}
            />
          )}
        />
      </InputWrapper>
      <h2>Secondary</h2>
      <InputWrapper>
        <DataMap
          data={CSS_COLOR_VARIABLES.secondary}
          render={({ data: varName }) => (
            <ColorInput
              name={varName}
              label={kebabCase(varName).replace(/-/g, ' ').trim()}
              value={get(form.data.uiSettings?.cssVariables, varName)}
              error={form.errors.name}
              onChange={updateCssVariables}
            />
          )}
        />
      </InputWrapper>
      <FileInput
        name="logo"
        label={lang.logo}
        value={compact([form.data.logo])}
        fileLimit={1}
        onChange={({ logo }) => {
          form.update({ logo: first(logo) });
        }}
        class={ThemeFileInputDropzone}
      />
    </FormWrapper>
  );
};
export default DealerGroupUiSettingsForm;
