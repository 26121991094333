import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import React, { useState } from 'react';
import ClaimTypeDocumentEdit from '../ClaimType.DocumentEdit';

export default function ClaimTypeEditDocuments() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();

  const [currentDocument, setDocument] = useState<Partial<Model.IClaimTypeDocument>>();

  return (
    <>
      <Collection class={ThemeCollectionTable}>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            class={ThemeButtonCircle}
            render={lang.addNew}
            active
            data={{
              id: -1 * (form.data.claimTypeDocuments?.length || 0),
              allowMultiple: false,
              required: false,
              allowNotes: false,
              approvalRequired: false,
            }}
            onClick={setDocument}
          />
          {currentDocument && (
            <ClaimTypeDocumentEdit
              data={currentDocument}
              onCancel={() => setDocument(null)}
              onSave={(newDocument) => {
                const filteredDocuments = form.data.claimTypeDocuments?.filter((it) => it?.id !== newDocument.id);
                form.update({ claimTypeDocuments: [...filteredDocuments, newDocument] });
                setDocument(null);
              }}
            />
          )}
        </Box>
        <DataMap
          data={form.data?.claimTypeDocuments}
          render={({ data: document }) => (
            <Card
              class={ThemeCardRow}
              clickable
              media={<Icon class="far-file-alt" />}
              key={document.id}
              info={document.source}
              title={document.name}
              subtitle={document.description}
              htmlElementProps={{
                card: {
                  onClick: () => setDocument(document),
                },
                title: lang.edit,
              }}
            />
          )}
        />
      </Collection>
    </>
  );
}
