import Model from 'models/Model';
import lenderListApi from './list/Api.Lender.List';
import lenderSaveApi from './save/Api.Lender.Save';
import lenderDeleteApi from './delete/Api.Lender.Delete';
import { combineStrings } from 'assets/utils/data/String';
import lenderFindApi from './find/Api.Lender.Find';
import Distributor from '../distributor/Model.Distributor';

export default class Lender
  extends Model<Model.ILender>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ILender>
{
  id: number;
  name: string;
  email?: string;
  phoneNumber?: string;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  provinceCode?: string;
  countryCode?: string;
  distributors?: Distributor[];

  constructor(data: Partial<Model.ILender> | Utils.FormData<Model.ILender>, language?: Locale.Languages) {
    super({ distributors: [Distributor] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      subtitle: combineStrings(
        ', ',
        this.streetAddress,
        this.postalCode,
        this.city,
        this.provinceCode,
        this.countryCode
      ),
    };
  }

  static async list(body?: Api.Core.Lender.List.IRequest) {
    const { payload, ...rest } = await lenderListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Lender(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: { id: number }) {
    const { payload, ...rest } = await lenderFindApi(params);
    return {
      ...rest,
      payload: payload.id ? new Lender(payload) : null,
    };
  }
  async save() {
    return await lenderSaveApi(this);
  }
  async delete() {
    return await lenderDeleteApi(this.id);
  }
}
