import React from 'react';

const EmailWrapper: React.CSSProperties = {
  width: '100%',
  backgroundColor: '#f2f2f2',
  color: '#6c7378',
  padding: '30px 0',
};
const Email: React.CSSProperties = {
  width: '100%',
  backgroundColor: '#fff',
  maxWidth: '650px',
  margin: '0px auto',
  borderRadius: '3px',
  overflow: 'hidden',
  boxShadow: '0px 0px 20px -10px rgba(0,0,0,0.05)',
  borderCollapse: 'collapse',
};
const EmailHeader: React.CSSProperties = {
  fontSize: '20px',
  fontWeight: 'bold',
  padding: '30px 50px',
  textAlign: 'center',
};
const EmailBody: React.CSSProperties = {
  padding: '30px 50px',
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  textAlign: 'left',
};
const EmailFooter: React.CSSProperties = {
  backgroundColor: '#f7f7f7',
  fontSize: '11px',
  padding: '30px 50px',
  textAlign: 'center',
};
const EmailSender: React.CSSProperties = {
  padding: '30px 50px',
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  verticalAlign: 'baseline',
};
const EmailRecipient: React.CSSProperties = {
  padding: '30px 50px',
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  verticalAlign: 'baseline',
};

export default function EmailHtml(props: Component.HtmlTemplate.Email.Import) {
  function RenderVar(varName: string) {
    return `{{${varName}}}`;
  }
  return (
    <>
      <div
        style={EmailWrapper}
        children={
          <>
            <table style={Email}>
              <thead>
                <tr>
                  <th
                    style={EmailHeader}
                    colSpan={2}
                    children={
                      <img
                        height="100"
                        width="300"
                        alt="Autoshield canada"
                        src={`${props.origin}/images/logo-asc.png`}
                      />
                    }
                  />
                </tr>
              </thead>
              <tbody>
                <tr>
                  {typeof props.body === 'string' ? (
                    <td
                      style={EmailBody}
                      colSpan={2}
                      dangerouslySetInnerHTML={{
                        __html: props.body.replace(/\n/g, '<br/>'),
                      }}
                    />
                  ) : (
                    <td style={EmailBody} colSpan={2} children={props.body} />
                  )}
                </tr>
              </tbody>
              <tfoot>
                <tr style={EmailFooter}>
                  <td
                    colSpan={1}
                    style={EmailSender}
                    children={
                      <>
                        <b>{RenderVar('EmailTemplate_SenderName')}</b>
                      </>
                    }
                  />
                  <td
                    colSpan={1}
                    style={EmailRecipient}
                    children={
                      <>
                        <b>{RenderVar('EmailTemplate_SenderEmail')}</b>
                      </>
                    }
                  />
                </tr>
              </tfoot>
            </table>
          </>
        }
      />
    </>
  );
}
