import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import ThemeWrapperBreadcrumbs from 'assets/themes/wrappers/Theme.Wrapper.Breadcrumbs';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import { Fragment, useState } from 'react';
import { salesContractIndexRoute } from '../contract/Sales.Contract.Index';
import useSalesNewContract, { SalesNewContractAction } from './Sales.NewContract.Hooks';
import SalesNewContractClient from './sections/Sales.NewContract.Client';
import SalesNewContractContract from './sections/Sales.NewContract.Contract';
import SalesNewContractFinish from './sections/Sales.NewContract.Finish';
import SalesNewContractProducts from './sections/Sales.NewContract.Products';
import SalesNewContractReview from './sections/Sales.NewContract.Review';
import SalesNewContractVehicle from './sections/Sales.NewContract.Vehicle';
import FormatValue from 'assets/components/formatValue/FormatValue';
import { salesPayAndSignContractIndexRoute } from '../payAndSignContract/Sales.PayAndSignContract.Index';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { compact } from 'lodash';

export const salesNewContractIndexRoute = salesHomeRoute + '/new-contract/:action/:id?';
export default function SalesNewContractIndex() {
  const { lang } = getLocales();
  const newContractHook = useSalesNewContract();
  const { redirect: redirectToContract } = usePageRouter<Module.Sales.Contract.Params, Module.Sales.Contract.Query>({
    route: salesContractIndexRoute,
  });
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [isTryingToPassPrice, setIsTryingToPassPrice] = useState(false);

  if (!newContractHook.isAllowed) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={newContractHook}
        render={({
          isAllowed,
          activeTab,
          nextTab,
          saveDraft,
          deleteDraft,
          next,
          prev,
          reset,
          openTab,
          prevTab,
          permissions,
          form,
          pageRouter,
          tabs,
          contractStatuses,
          isSavingDone,
        }) => (
          <Page
            class={ThemePageStatic2}
            style={{
              page: {
                overflow: 'auto',
                height: '100vh',
              },
            }}
            header={
              <Card
                class={ThemeCardRow}
                media={<Icon class="fas-file-contract" />}
                info={lang.addNew}
                title={lang.contract}
              />
            }
            subHeader={
              <div className={ThemeWrapperBreadcrumbs}>
                <DataMap
                  data={tabs}
                  render={({ data: tab }) => (
                    <div
                      id={tab.id}
                      data-active={tab.id === activeTab.id ? true : undefined}
                      style={{ cursor: form.data.id ? 'pointer' : undefined }}
                      onClick={
                        form.data.id && !tab.hasErrors && tab.id !== SalesNewContractAction.FINISH
                          ? () => openTab(tab.id)
                          : null
                      }
                    >
                      {tab.label}
                    </div>
                  )}
                />
              </div>
            }
            render={
              isAllowed ? (
                <>
                  {activeTab.id === SalesNewContractAction.VEHICLE && <SalesNewContractVehicle />}
                  {activeTab.id === SalesNewContractAction.PRODUCTS && <SalesNewContractProducts />}
                  {activeTab.id === SalesNewContractAction.CONTRACT && <SalesNewContractContract />}
                  {activeTab.id === SalesNewContractAction.CLIENT && <SalesNewContractClient />}
                  {activeTab.id === SalesNewContractAction.REVIEW && <SalesNewContractReview />}
                  {activeTab.id === SalesNewContractAction.FINISH && <SalesNewContractFinish />}
                </>
              ) : (
                <ErrorPage status={HttpStatus.unauthorized} />
              )
            }
            footer={
              activeTab.id === SalesNewContractAction.FINISH ? (
                <>
                  <Button
                    class={ThemeButtonCircle}
                    render={lang.viewAllContracts}
                    onClick={redirectToContract}
                    disabled={!isSavingDone}
                  />
                  <Button
                    class={ThemeButtonCircle}
                    active
                    render={lang.addNew}
                    onClick={reset}
                    disabled={!isSavingDone || !permissions.CREATE_CONTRACT}
                  />
                  {isSavingDone && contractStatuses.some((s) => s.isPaymentRequired) && (
                    <Button
                      active
                      class={ThemeButtonCircle}
                      onClick={() => {
                        document.location.assign(
                          createPathFromRoute(
                            salesPayAndSignContractIndexRoute,
                            {},
                            {
                              contractId: compact(
                                contractStatuses
                                  .filter((s) => s.isPaymentRequired || s.isSignRequired)
                                  .map((s) => s.contract?.id)
                              ),
                            }
                          )
                        );
                      }}
                    >
                      {contractStatuses.some((s) => s.isPaymentRequired) && 'Go to payment'}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!!pageRouter.query.draftId && (
                    <Button
                      onClick={deleteDraft}
                      media="fas-trash-alt"
                      render={lang.deleteDraft}
                      class={ThemeButtonCircle}
                      disabled={!!form.data.id || !pageRouter.query.draftId || form.isSubmitted}
                    />
                  )}
                  <Button
                    onClick={saveDraft}
                    media="far-save"
                    render={pageRouter.query.draftId ? lang.updateDraft : lang.saveDraft}
                    class={ThemeButtonCircle}
                    style={{ marginRight: 'auto' }}
                    disabled={!!form.data.id || form.isSubmitted}
                  />
                  <Button
                    onClick={prev}
                    render={lang.prev}
                    class={ThemeButtonCircle}
                    active={activeTab.id !== prevTab.id}
                    disabled={activeTab.id === prevTab.id || form.isSubmitted}
                  />
                  <Button
                    onClick={
                      nextTab.id === SalesNewContractAction.FINISH
                        ? () => setIsTryingToSubmit(true)
                        : nextTab.id === SalesNewContractAction.CLIENT &&
                          form.data.products?.some((it) => it.salePriceCAD < it.discountPriceCAD)
                        ? () => setIsTryingToPassPrice(true)
                        : next
                    }
                    render={nextTab.id === SalesNewContractAction.FINISH ? lang.submit : lang.next}
                    class={ThemeButtonCircle}
                    active={activeTab.id !== nextTab.id}
                    disabled={activeTab.id === nextTab.id || activeTab.hasErrors}
                    isLoading={form.isSubmitted}
                  />
                  {isTryingToSubmit && (
                    <Confirm
                      onConfirm={() => {
                        setIsTryingToSubmit(false);
                        next();
                      }}
                      onClose={() => setIsTryingToSubmit(false)}
                      actionName={lang.contractSubmitMessage}
                      confirmationType="nextButton"
                      subcomponents={ThemeConfirmDefault}
                    />
                  )}
                  {isTryingToPassPrice && (
                    <Confirm
                      onConfirm={() => {
                        setIsTryingToPassPrice(false);
                        next();
                      }}
                      onClose={() => setIsTryingToPassPrice(false)}
                      actionName={
                        <>
                          Sell{' '}
                          <DataMap
                            data={form.data.products?.filter((it) => it.salePriceCAD < it.discountPriceCAD)}
                            render={({ data: product, isLast, isFirst }) => (
                              <Fragment key={product.id}>
                                {isLast && !isFirst ? ' & ' : !isFirst ? ', ' : null}
                                {product.name} for <FormatValue value={`$${product.salePriceCAD.toFixed(2)}`} />
                              </Fragment>
                            )}
                          />
                        </>
                      }
                      confirmationType="nextButton"
                      subcomponents={ThemeConfirmDefault}
                    />
                  )}
                </>
              )
            }
          />
        )}
      />
    </>
  );
}
