import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ClaimEmail.SendEmail.IRequest;
type Response = Api.Core.ClaimEmail.SendEmail.IResponse;
type Params = Api.Core.ClaimEmail.SendEmail.IParams;
type Headers = Api.Core.ClaimEmail.SendEmail.IHeaders;
const url = '/api/Claims/ClaimEmail/SendEmail/:claimId';
export default async function claimEmailSendEmailApi({
  body,
  params,
}: {
  body: Request;
  params: Params;
}): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { claimId: params.claimId }),
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
