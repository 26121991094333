import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardSidebarInfo from 'assets/components/card/themes/Theme.Card.SidebarInfo';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';

export default function Sidebar(props: Template.Sidebar.Import) {
  const { lang } = getLocales();
  return (
    <>
      <Card
        class={ThemeCardSidebarInfo}
        media={props.image ? <img src={props.image} /> : props.icon ? RenderProps(props, 'icon') : null}
        title={props.title}
        subtitle={props.subtitle}
        info={props.info}
        actions={
          props.onClose && (
            <Button
              onClick={props.onClose}
              class={ThemeButtonCircle}
              media={<Icon class={iconStyles.common.close} />}
              htmlElementProps={{ title: lang.close }}
            />
          )
        }
      />
      <Box
        style={{ overflow: 'auto', padding: `${Theme.Size.M} ${Theme.Size.XL}` }}
        render={RenderProps(props, 'content')}
      />
      {props.footer && (
        <Box
          //class={ThemeBoxFooter}
          render={RenderProps(props, 'footer')}
        />
      )}
    </>
  );
}
