import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import pricingLimitTemplateDeleteApi from 'models/productManagement/pricingLimitTemplate/delete/Api.PricingLimitTemplate.Delete';
import PricingLimitTemplate from 'models/productManagement/pricingLimitTemplate/Model.PricingLimitTemplate';
import pricingLimitTemplateSaveApi from 'models/productManagement/pricingLimitTemplate/save/Api.PricingLimitTemplate.Save';
import { useMemo } from 'react';
import { pricingLimitTemplateIndexRoute } from './PricingLimitTemplate.Index';

export enum PricingLimitTemplateAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyPricingLimitTemplateAction = Utils.VerifyExtends<
  Module.ProductManagement.PricingLimitTemplate.Actions,
  Utils.ValueOf<typeof PricingLimitTemplateAction>
>;
export default function usePricingLimitTemplate() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.PricingLimitTemplate.Params,
    Module.ProductManagement.PricingLimitTemplate.Query
  >({ route: pricingLimitTemplateIndexRoute });

  const canView =
    permissions.CREATE_PRICINGLIMITTEMPLATE ||
    permissions.EDIT_PRICINGLIMITTEMPLATE ||
    permissions.DELETE_PRICINGLIMITTEMPLATE;
  const listApi = useApi({
    action: PricingLimitTemplate.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: pricingLimitTemplateDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: pricingLimitTemplateSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [PricingLimitTemplateAction.DELETE]:
        pageRouter.params.action === PricingLimitTemplateAction.DELETE &&
        selected &&
        permissions.DELETE_PRICINGLIMITTEMPLATE,
      [PricingLimitTemplateAction.EDIT]:
        pageRouter.params.action === PricingLimitTemplateAction.EDIT &&
        ((permissions.CREATE_PRICINGLIMITTEMPLATE && !selected) || (permissions.EDIT_PRICINGLIMITTEMPLATE && selected)),
    },
  };
}

export function usePricingLimitTemplateProvider() {
  return useDataProvider<ReturnType<typeof usePricingLimitTemplate>>();
}
