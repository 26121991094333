import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';
import { useSalesNewContractProvider } from '../Sales.NewContract.Hooks';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';

export default function SalesNewContractClient() {
  const { lang } = getLocales();
  const { form } = useSalesNewContractProvider();
  const client = form?.data?.client;
  const errors = form?.errors?.client;
  function updateClient(client: Partial<Model.IClient>) {
    if (client.taxExempt === false) client.taxExemptionBand = null;
    form.merge({ client });
  }

  return (
    <>
      <FormWrapper style={{ padding: `${Theme.Size.L} ${Theme.Size.XL} ${Theme.Size.M} ${Theme.Size.XL}` }}>
        <InputWrapper>
          <TextInput
            name="firstName"
            label={lang.firstName}
            value={client?.firstName}
            error={errors?.firstName}
            onChange={updateClient}
            icon={<Icon class="if-quill-pen" />}
          />
          <TextInput
            name="lastName"
            label={lang.lastName}
            value={client?.lastName}
            error={errors?.lastName}
            onChange={updateClient}
            icon={<Icon class="if-quill-pen" />}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="inpute"
            label={lang.email}
            value={client?.email}
            error={errors?.email}
            onChange={({ inpute: email }) => updateClient({ email })}
            icon={<Icon class="if-email" />}
          />
          <TextInput
            name="phone"
            label={lang.phoneNumber}
            value={client?.phone}
            error={errors?.phone}
            onChange={({ phone }) => updateClient({ phone: formatFormPhone(client?.phone, phone) })}
            icon={<Icon class="if-phone" />}
          />
        </InputWrapper>
        <LocationSearch
          onSelect={(location) => {
            updateClient({
              streetAddress: location?.Line1 || null,
              provinceCode: location?.ProvinceCode || null,
              city: location?.City || null,
              countryCode: location?.CountryIso3 || null,
              postalCode: location?.PostalCode || null,
            });
          }}
        />
        <InputWrapper>
          <TextInput
            name="streetAddress"
            label={lang.streetAddress}
            value={client?.streetAddress}
            error={errors?.streetAddress}
            onChange={updateClient}
            icon={<Icon class="if-map-pin" />}
            readOnly
          />
          <TextInput
            name="postalCode"
            label={lang.postalCode}
            value={client?.postalCode}
            error={errors?.postalCode}
            onChange={updateClient}
            icon={<Icon class="if-map-pin" />}
            readOnly
          />
          <TextInput
            name="city"
            label={lang.city}
            value={client?.city}
            error={errors?.city}
            onChange={updateClient}
            icon={<Icon class="if-map-pin" />}
            readOnly
          />
          <TextInput
            name="provinceCode"
            label={lang.provinceCode}
            value={client?.provinceCode}
            error={errors?.provinceCode}
            onChange={updateClient}
            icon={<Icon class="if-map-pin" />}
            readOnly
            style={{ width: Theme.Size.XXL }}
          />
          <TextInput
            name="countryCode"
            label={lang.countryCode}
            value={client?.countryCode}
            error={errors?.countryCode}
            onChange={updateClient}
            icon={<Icon class="if-map-pin" />}
            readOnly
            style={{ width: Theme.Size.XXL }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="coApplicantFirstName"
            label={lang.coApplicantFirstName}
            value={form.data?.coApplicantFirstName}
            error={form?.errors?.coApplicantFirstName}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
          />
          <TextInput
            name="coApplicantLastName"
            label={lang.coApplicantLastName}
            value={form.data?.coApplicantLastName}
            error={form?.errors?.coApplicantLastName}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
          />
        </InputWrapper>
        <CheckInput
          name="taxExempt"
          class={inputStyles.check.switch}
          label={lang.taxExempt}
          value={client?.taxExempt}
          error={errors?.taxExempt}
          onChange={updateClient}
          htmlElementProps={{ title: client?.taxExempt ? lang.disable : lang.enable }}
        />
        {client?.taxExempt && (
          <TextInput
            name="taxExemptionBand"
            label={lang.taxExemptionBand}
            value={client?.taxExemptionBand}
            error={errors?.taxExemptionBand}
            onChange={updateClient}
          />
        )}
      </FormWrapper>
    </>
  );
}
