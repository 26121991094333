import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { set } from 'lodash';
import Client from 'models/claims/client/Model.Client';
import { useClientProvider } from '../Client.Hooks';
import { useEffect } from 'react';
import { HttpStatus } from 'config/Api.Config';
import { addLog } from 'assets/components/feedback/Feedback';

export default function ClientEdit() {
  const { lang } = getLocales();
  const { pageRouter, syncWithVertaforeApi } = useClientProvider();

  const unsyncedClients = useApi({ action: Client.list, body: { hasVeraforteCustomerNumber: false }, wait: false });

  useEffect(() => {
    if (!syncWithVertaforeApi.isExecuting && syncWithVertaforeApi.status === HttpStatus.ok) {
      addLog({ success: lang.saveSuccess });
      pageRouter.updateParams();
    }
  }, [syncWithVertaforeApi.isExecuting, syncWithVertaforeApi.message, syncWithVertaforeApi.status]);

  return (
    <ModalForm<Api.Core.Client.SyncWithVertafore.IParams>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[syncWithVertaforeApi.body]}
      title={lang.syncWithVertafore}
      default={syncWithVertaforeApi.body}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) => syncWithVertaforeApi.execute(data)}
      submitButtonText={lang.submit}
      validation={(data, errors) => {
        if (!data?.clientId) set(errors, 'clientId', lang.mustNotBeEmpty);
      }}
      render={({ form }) => (
        <FormWrapper>
          <SelectInput
            name="clientId"
            label={lang.client}
            error={form.errors?.clientId}
            data={unsyncedClients.payload?.data?.map((item) => item.displayInfo)}
            value={form.data?.clientId}
            onChange={form.update}
            icon={<Icon class="fas-user-cog" />}
          />
        </FormWrapper>
      )}
    />
  );
}
