import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import { addLog } from 'assets/components/feedback/Feedback';
import Icon from 'assets/components/icon/Icon';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { combineStrings } from 'assets/utils/data/String';
import { ApiHeaderApplication, HttpStatus } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import Claim from 'models/claims/claim/Model.Claim';
import EmailTemplate from 'models/core/emailTemplate/Model.EmailTemplate';
import Permission from 'models/core/permission/Model.Permission';
import User from 'models/core/user/Model.User';
import { getNextStatus } from 'module/claims/hooks/useClaimsGlobalStore';
import { useState } from 'react';

type Props = {
  emailTemplates: EmailTemplate[];
  statusInfo: Model.ClaimStatusInfo;
  claim: Model.IClaim;
  currentPermissions: Auth.Permission[];
  reloadClaim: () => void;
  hasPermission: boolean;
};

export default function ClaimChangeOwnership({
  emailTemplates,
  statusInfo,
  currentPermissions,
  claim,
  reloadClaim,
  hasPermission,
}: Props) {
  const { lang } = getLocales();
  const [selectedUser, setSelectedUser] = useState<Model.IUser>(undefined);
  const { nextStatusList } = getNextStatus(emailTemplates, statusInfo, currentPermissions, claim?.statusCode);

  const permissionListApi = useApi({
    action: Permission.list,
    body: {
      applicationId: ApiHeaderApplication.claims,
    },
    wait: hasPermission,
  });

  const permissionIds = permissionListApi.payload?.data
    ?.filter((it) => it.name === AuthPermission.claimAdmin || it.name === AuthPermission.editClaim)
    ?.map((it) => it.id);

  const userListApi = useApi({
    action: User.list,
    body: {
      applicationId: [ApiHeaderApplication.claims],
      permissionId: permissionIds,
    },
    wait: hasPermission,
  });

  async function onConfirmChangeOwnership() {
    if (selectedUser && nextStatusList?.length > 0) {
      const { status } = await Claim.changeOwnership({
        claimId: claim?.id,
        ownerId: selectedUser.id,
      });
      if (status !== HttpStatus.ok) addLog({ error: lang.statusError });
      else reloadClaim();
      setSelectedUser(undefined);
    }
  }

  return (
    <>
      <DropdownButton
        title={lang.transferOwnershipTo}
        buttonBody={lang.changeOwner}
        buttonMedia={<Icon class="fas-user" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        constrainment="unconstrained"
        disableWidthConstraint
        disabled={hasPermission}
        htmlElementProps={{ title: lang.changeOwner }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <DataMap
            data={userListApi?.payload?.data}
            render={({ data: user }) => (
              <Button
                key={user?.id}
                class={ThemeButtonMenuItem}
                render={user?.displayInfo?.title}
                onClick={setSelectedUser}
                data={user}
              />
            )}
          />
        }
      />
      {selectedUser && (
        <Confirm
          data={claim}
          onConfirm={onConfirmChangeOwnership}
          onClose={() => setSelectedUser(undefined)}
          actionName={lang.transferOwnershipTo}
          itemName={combineStrings(' ', selectedUser.firstName, selectedUser.lastName)}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
    </>
  );
}
