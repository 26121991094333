import { useEffect, useRef } from 'react';
import { date, defaultDateFormat, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { ascLogo } from 'assets/themes/assets/Images';
import '../style/print.scss';
import { useStatementSummaryProvider } from '../StatementSummary.Hooks';
import { getLocales } from 'assets/locales/Locale';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Contract from 'models/sales/contract/Model.Contract';

export default function StatementSummaryPrint() {
  const { lang } = getLocales();
  const { selected } = useStatementSummaryProvider();
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement | null>(null);

  const dateNow = new Date();

  const _OLDER_THEN_90_DAYS = 'Older than 90 days';
  const _61_TO_90_DAYS = '61 to 90 days';
  const _31_TO_60_DAYS = '31 to 60 days';
  const _0_TO_30_DAYS = '0 to 30 days';

  const contractsTimeRanges = [
    { label: _OLDER_THEN_90_DAYS, minDays: 90, maxDays: undefined },
    { label: _61_TO_90_DAYS, minDays: 61, maxDays: 90 },
    { label: _31_TO_60_DAYS, minDays: 31, maxDays: 60 },
    { label: _0_TO_30_DAYS, minDays: 0, maxDays: 30 },
  ];

  const dealers: Dealer[] = selected?.dealerGroup?.dealers;
  const contracts: Contract[] = selected?.contracts.map(
    (x) =>
      ({
        ...x,
        paymentTaxCAD: x.client?.taxExempt ? 0 : x.paymentTaxCAD,
        paymentAmountWithTaxCAD: x.client?.taxExempt ? x.paymentAmountCAD : x.paymentAmountWithTaxCAD,
      } as Contract)
  );

  // Calculate difference in days beetwen contact creation day and current time
  const differenceInDays = (contract: Contract) => {
    const contractDate = new Date(
      date(contract?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)
    );
    return Math.floor((dateNow.getTime() - contractDate.getTime()) / (1000 * 60 * 60 * 24));
  };

  // Filter contracts by time ranges defined in contractsTimeRanges
  const filterContractsByDays = (contracts: Contract[], minDays: number, maxDays: number) => {
    return contracts.filter((contract) => {
      const difference = differenceInDays(contract);
      return difference >= minDays && (maxDays === undefined || difference < maxDays);
    });
  };

  // Sort and group contracts by dealerId into arrays inside object allContractsSorted
  function sortAndGroupContracts(contracts: Contract[] = []) {
    return contracts
      .sort((a, b) => a.dealerId.toString().localeCompare(b.dealerId.toString()))
      .reduce((groupedContracts: Contract[][], contract) => {
        const currentGroup = groupedContracts[groupedContracts.length - 1];
        if (!currentGroup || currentGroup[0].dealerId !== contract.dealerId) {
          groupedContracts.push([contract]);
        } else {
          currentGroup.push(contract);
        }
        return groupedContracts;
      }, []);
  }

  const sortedContractsByDealer = dealers.map((dealer) => {
    let sortedContracts = contracts.filter((contract) => contract.dealerId === dealer.id);
    return {
      dealer: dealer,
      dealerTotal: sortedContracts.reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0),
      sortedContracts: contractsTimeRanges.map((range) => {
        const { label, minDays, maxDays } = range;
        const filtered = filterContractsByDays(sortedContracts, minDays, maxDays);
        sortedContracts = sortedContracts.filter((contract) => !filtered.includes(contract));
        return {
          label,
          allContractsSorted: sortAndGroupContracts(filtered),
          length: filtered.length,
          paymentAmountWithTaxCAD: filtered.reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0),
        };
      }),
    };
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    documentTitle: `Statment#${selected?.id}`,
  });

  useEffect(() => {
    handlePrint();
    history.goBack();
  }),
    [];

  return (
    selected && (
      <div id="printStatementSummary" ref={componentRef}>
        <style>{`@page {size: a4 portret; margin: 1cm;}`}</style>
        <h1 style={{ fontSize: '3rem', textAlign: 'end' }}>{`${lang.statementSummary.toUpperCase()} #${
          selected.statementNumber
        }`}</h1>
        <div className="print_details">
          <div className="print_details_company">
            <img src={ascLogo} />
            <p>
              <b>Auto Shield Canada,</b>
              <br />
              6601 Goreway Dr.,
              <br />
              Mississauga, ON, L4V 1V6
            </p>
          </div>
          <div className="print_details_dealer">
            <p>
              <b>{lang.date}:</b> {date(selected.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateFormat)}
            </p>
            <p>
              <b>{lang.fromDate}:</b>{' '}
              {date(selected.fromDate, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
            </p>
            <p>
              <b>{lang.toDate}:</b> {date(selected.toDate, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
            </p>
          </div>
        </div>
        <div className="print_table_header">
          {sortedContractsByDealer.map((data) => (
            <>
              <table>
                <tr className="print_background_color print_never_break">
                  <th className="print_table_header_dealer">
                    <b>{data.dealer.name}</b>
                  </th>
                </tr>
              </table>
              <table key={data.dealer.id} className="print_table_header_payments">
                <tr className="print_never_break">
                  <th>Balance Due:</th>
                  <th>Over 90 Days:</th>
                  <th>61 - 90 Days</th>
                  <th>31 - 60 Days</th>
                  <th>0 - 30 Days</th>
                </tr>
                <tr className="print_never_break">
                  <td>${data.dealerTotal.toFixed(2)}</td>
                  <td>
                    $
                    {data.sortedContracts
                      .find((contracts) => contracts.label.includes(_OLDER_THEN_90_DAYS))
                      .paymentAmountWithTaxCAD.toFixed(2)}
                  </td>
                  <td>
                    $
                    {data.sortedContracts
                      .find((contracts) => contracts.label.includes(_61_TO_90_DAYS))
                      .paymentAmountWithTaxCAD.toFixed(2)}
                  </td>
                  <td>
                    $
                    {data.sortedContracts
                      .find((contracts) => contracts.label.includes(_31_TO_60_DAYS))
                      .paymentAmountWithTaxCAD.toFixed(2)}
                  </td>
                  <td>
                    $
                    {data.sortedContracts
                      .find((contracts) => contracts.label.includes(_0_TO_30_DAYS))
                      .paymentAmountWithTaxCAD.toFixed(2)}
                  </td>
                </tr>
              </table>
              <br />
              <br />
            </>
          ))}
          <>
            <table>
              <tr className="print_background_color print_never_break">
                <th className="print_table_header_total">
                  <b>{lang.total.toUpperCase()}</b>
                </th>
              </tr>
            </table>
            <table className="print_table_header_payments">
              <tr className="print_never_break">
                <th>Balance Due:</th>
                <th>Over 90 Days:</th>
                <th>61 - 90 Days</th>
                <th>31 - 60 Days</th>
                <th>0 - 30 Days</th>
              </tr>
              <tr className="print_never_break">
                <td>${sortedContractsByDealer.reduce((acc, current) => acc + current.dealerTotal, 0).toFixed(2)}</td>
                <td>
                  $
                  {sortedContractsByDealer
                    .reduce(
                      (acc, current) =>
                        acc +
                        current.sortedContracts.find((contract) => contract.label.includes(_OLDER_THEN_90_DAYS))
                          .paymentAmountWithTaxCAD,
                      0
                    )
                    .toFixed(2)}
                </td>
                <td>
                  $
                  {sortedContractsByDealer
                    .reduce(
                      (acc, current) =>
                        acc +
                        current.sortedContracts.find((contract) => contract.label.includes(_61_TO_90_DAYS))
                          .paymentAmountWithTaxCAD,
                      0
                    )
                    .toFixed(2)}
                </td>
                <td>
                  $
                  {sortedContractsByDealer
                    .reduce(
                      (acc, current) =>
                        acc +
                        current.sortedContracts.find((contract) => contract.label.includes(_31_TO_60_DAYS))
                          .paymentAmountWithTaxCAD,
                      0
                    )
                    .toFixed(2)}
                </td>
                <td>
                  $
                  {sortedContractsByDealer
                    .reduce(
                      (acc, current) =>
                        acc +
                        current.sortedContracts.find((contract) => contract.label.includes(_0_TO_30_DAYS))
                          .paymentAmountWithTaxCAD,
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </table>
            <br />
            <br />
          </>
        </div>
      </div>
    )
  );
}
