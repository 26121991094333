import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import insurerDeleteApi from 'models/productManagement/insurer/delete/Api.Insurer.Delete';
import Insurer from 'models/productManagement/insurer/Model.Insurer';
import insurerSaveApi from 'models/productManagement/insurer/save/Api.Insurer.Save';
import { useMemo } from 'react';
import { insurerIndexRoute } from './Insurer.Index';

export enum InsurerAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyInsurerAction = Utils.VerifyExtends<
  Module.ProductManagement.Insurer.Actions,
  Utils.ValueOf<typeof InsurerAction>
>;
export default function useInsurer() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.ProductManagement.Insurer.Params, Module.ProductManagement.Insurer.Query>({
    route: insurerIndexRoute,
  });

  const canView = permissions.CREATE_INSURER || permissions.EDIT_INSURER || permissions.DELETE_INSURER;
  const listApi = useApi({
    action: Insurer.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: insurerDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: insurerSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [InsurerAction.DELETE]:
        pageRouter.params.action === InsurerAction.DELETE && selected && permissions.DELETE_INSURER,
      [InsurerAction.EDIT]:
        pageRouter.params.action === InsurerAction.EDIT &&
        ((permissions.CREATE_INSURER && !selected) || (permissions.EDIT_INSURER && selected)),
    },
  };
}

export function useInsurerProvider() {
  return useDataProvider<ReturnType<typeof useInsurer>>();
}
