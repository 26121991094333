import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useUIEditor from 'assets/hooks/uiEditor/UIEditor';
import { getLocales } from 'assets/locales/Locale';
import { UICommand } from 'assets/utils/dom/Commands';
import React from 'react';

export default function UICommandButton(props: Component.Button.DefaultImport & Component.Button.OnlyData<UICommand>) {
  const { lang } = getLocales();
  const uiEditor = useUIEditor();

  return (
    uiEditor && (
      <Button
        class={ThemeButtonCircle}
        htmlElementProps={{
          button: { title: lang[props.data] },
        }}
        {...props}
        onClick={uiEditor.applyUiCommand}
      />
    )
  );
}
