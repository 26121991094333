import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import claimJobDocumentDeleteApi from 'models/claims/claimJobDocument/delete/Api.ClaimJobDocument.Delete';
import ClaimDocumentPreview from 'module/claims/views/claim/other/Claim.DocumentPreview';
import { useEffect, useMemo, useState } from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import claimJobDocumentSaveApi from 'models/claims/claimJobDocument/save/Api.ClaimJobDocument.Save';

type DeleteJobDocumentProps = { documentId: number; claimJobId: number };
export default function ClaimEditJobDocuments() {
  const { lang } = getLocales();
  const { reload, uploadedDocuments, uploadableDocuments, claimJob, isClaimJobEditable, isClaimJobEditableByStatus } =
    useClaimJobEdit();
  const { execute: deleteClaimJobDocument } = useApi({
    action: claimJobDocumentDeleteApi,
    wait: true,
    callback: reload,
  });

  const onJobDocumentDelete = ({ documentId, claimJobId }: DeleteJobDocumentProps) =>
    deleteClaimJobDocument({ claimJobId, documentId });
  const [document, selectDocument] = useState<Model.IClaimTypeDocument>();
  useEffect(() => {
    selectDocument(uploadableDocuments[0]);
  }, [uploadableDocuments]);
  const docForm = useForm<Model.IClaimJobDocument>(
    {
      default: !document
        ? {}
        : {
            name: document.name,
            claimTypeDocumentId: document.id,
            approved: document.approvalRequired ? false : true,
          },
      validation: (data, errors) => {
        if (!data.formFile) errors.formFile = lang.required;
        if (!data.claimTypeDocumentId) errors.claimTypeDocumentId = lang.required;
      },
      onSubmit: async (doc) => {
        await claimJobDocumentSaveApi({ ...doc, claimJobId: claimJob.id });
        docForm.reload();
        reload();
      },
    },
    [document]
  );
  const formFile = useMemo(
    () =>
      docForm.data.formFile && {
        original: docForm.data.formFile,
        type: docForm.data.formFile.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(docForm.data.formFile),
      },
    [docForm.data.formFile]
  );

  return !uploadableDocuments.length ? null : (
    <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
        {lang.documents}
        <span>
          <SelectInput
            name="docId"
            style={{
              formInput: {
                maxWidth: Theme.Size.pixel(240),
              },
            }}
            placeholder={lang.documents + '...'}
            data={uploadableDocuments?.map((document) => ({
              id: document.id,
              title: combineStrings(' - ', document.name, document.source),
            }))}
            value={document?.id}
            onChange={({ docId }) => selectDocument(uploadableDocuments.find((it) => it.id === docId))}
            icon={<Icon class="fas-file" />}
          />
        </span>
      </h2>
      <ClaimDocumentPreview
        documents={uploadedDocuments.map((it) => ({
          id: it.id,
          url: it.mediaFile?.url,
          name: it.name,
          extension: it.mediaFile?.extension,
          description: it.notes,
          approved: it.approved,
          onDelete: () => onJobDocumentDelete({ documentId: it.id, claimJobId: it.claimJobId }),
        }))}
      />
      <br />
      <FileInput
        name="file"
        fileLimit={1}
        value={formFile ? [formFile] : []}
        onChange={({ file }) => {
          const formFile = file && file[0]?.original;
          docForm.update({ formFile });
        }}
        class={ThemeFileInputDropzone}
        disabled={!document || isClaimJobEditableByStatus || !isClaimJobEditable}
        error={docForm.errors.formFile?._objectError}
        label={
          !document ? (
            lang.youNeedToSelectOneDocument
          ) : (
            <>
              <b>
                {combineStrings(' - ', document.name, document.source)}
                {document.example && (
                  <>
                    <LinkButton
                      class={ThemeButtonCircle}
                      style={{ fontSize: Theme.FontSize.M, margin: 0 }}
                      url={document.example}
                      media={<Icon class="fas-external-link-alt" />}
                      render={lang.example}
                      target="_blank"
                    />
                  </>
                )}
              </b>
              <p>{document.description}</p>
            </>
          )
        }
        renderAfter={
          <>
            <TextInput
              name="name"
              label={lang.name}
              value={docForm.data.name}
              onChange={docForm.update}
              error={docForm.errors.name}
              disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            />
            {document?.allowNotes && (
              <MultilineTextInput
                name="notes"
                label={lang.notes}
                value={docForm.data.notes}
                onChange={docForm.update}
                error={docForm.errors.notes}
                disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
              />
            )}
          </>
        }
      />
      <br />
      <Button
        render={lang.add}
        media={<Icon class="fas-upload" />}
        onClick={docForm.submit}
        disabled={docForm.hasErrors || !document || isClaimJobEditableByStatus || !isClaimJobEditable}
        active={!docForm.hasErrors}
        class={ThemeButtonCircle}
        style={{ marginLeft: 'auto' }}
      />
      <br />
    </div>
  );
}
