import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import VehicleModel from '../vehicleModel/Model.VehicleModel';
import vehicleClassDeleteApi from './delete/Api.VehicleClass.Delete';
import vehicleClassListApi from './list/Api.VehicleClass.List';
import vehicleClassSaveApi from './save/Api.VehicleClass.Save';
import vehicleClassCopyApi from './copy/Api.VehicleClass.Copy';

export default class VehicleClass
  extends Model<Model.IVehicleClass>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVehicleClass>
{
  id: number;
  primaryCode: string; //A1, A2, B1...
  secondaryCode: string; //A1, A2, B1...
  description?: string;
  vehicleModels?: VehicleModel[];
  fromClassId: number;
  toClassId: number;

  constructor(data: Partial<Model.IVehicleClass> | Utils.FormData<Model.IVehicleClass>, language?: Locale.Languages) {
    super({ vehicleModels: [VehicleModel] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' - ', this.primaryCode, this.secondaryCode) || this.id.toString(),
      subtitle: this.description,
    };
  }

  static async list(body?: Api.Core.VehicleClass.List.IRequest) {
    const { payload, ...rest } = await vehicleClassListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new VehicleClass(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await vehicleClassSaveApi(this);
  }
  async delete() {
    return await vehicleClassDeleteApi(this.id);
  }
  async copy(body: Api.Core.VehicleClass.Copy.IRequest) {
    return await vehicleClassCopyApi(body);
  }
}
