import React, { useState, useEffect } from 'react';
import { usePagination } from './hooks/Pagination';
import DataMap from '../dataMap/DataMap';
import { useTheme } from '../../hooks/theme/useTheme';
import Icon, { iconStyles } from '../icon/Icon';
import { getLocales } from 'assets/locales/Locale';

export enum PaginationStructure {
  pagination = 'pagination',
  limitSelect = 'limitSelect',
  limitOptions = 'limitOptions',
  limitOption = 'limitOption',
  offsetButton = 'offsetButton',
}
export type Verify = Utils.VerifyExtends<Component.Pagination.Structure, typeof PaginationStructure>;
export default function Pagination(props: Component.Pagination.Import) {
  const extend = useTheme(PaginationStructure, props);
  const pagination = usePagination(props);
  const { lang } = getLocales();
  const [customPage, setCustomPage] = useState<number | undefined>((props.offset || 0) + 1);

  function changeCustomOffset() {
    if (!customPage || isNaN(customPage) || !(customPage > 0 && customPage <= (pagination.pages || 0))) {
      setCustomPage((props.offset || 0) + 1);
    } else if (props.onOffsetChange) {
      props.onOffsetChange(customPage - 1);
    }
  }
  function updateLimit(n: number) {
    setCustomPage(1);
    if (props.onLimitChange) props.onLimitChange(n);
  }
  function updateOffset(n: number) {
    setCustomPage(n + 1);
    if (props.onOffsetChange) props.onOffsetChange(n);
  }

  useEffect(() => {
    if (props.offset !== undefined && props.offset !== customPage) setCustomPage(props.offset + 1);
  }, [props.offset]);

  const currentOffsetMin = (props.offset || 0) * (props.limit || 0) + 1;
  const currentOffsetMax = (props.offset || 0) * (props.limit || 0) + (props.limit || 0);
  return (
    <div {...extend(PaginationStructure.pagination, true)}>
      <div {...extend(PaginationStructure.limitOptions)}>
        {props.limit &&
          props.count &&
          `${currentOffsetMin} - ${currentOffsetMax > props.count ? props.count : currentOffsetMax}`}{' '}
        {lang.itemsShown}
        <span style={{ opacity: 0.6 }}>
          {props.limit && props.count && props.count > currentOffsetMax && `${lang.totalItems} ${props.count}`}
        </span>
        {props.onLimitChange && (
          <div {...extend(PaginationStructure.limitSelect)}>
            <div
              {...extend(PaginationStructure.limitOption)}
              data-active={pagination.limit === 1 ? true : null}
              onClick={() => updateLimit(1)}
              children={1}
            />
            <div
              {...extend(PaginationStructure.limitOption)}
              data-active={pagination.limit === 10 ? true : null}
              onClick={() => updateLimit(10)}
              children={10}
            />
            <div
              {...extend(PaginationStructure.limitOption)}
              data-active={pagination.limit === 20 ? true : null}
              onClick={() => updateLimit(20)}
              children={20}
            />
            <div
              {...extend(PaginationStructure.limitOption)}
              data-active={pagination.limit === 50 ? true : null}
              onClick={() => updateLimit(50)}
              children={50}
            />
            <div
              {...extend(PaginationStructure.limitOption)}
              data-active={pagination.limit === 100 ? true : null}
              onClick={() => updateLimit(100)}
              children={100}
            />
          </div>
        )}
      </div>
      <DataMap
        data={pagination.pageList}
        render={({ key, data: page }) => (
          <div
            key={key}
            onClick={page.isActive ? undefined : () => updateOffset(page.index)}
            data-active={page.isActive ? true : null}
            {...extend(PaginationStructure.offsetButton)}
            children={
              page.isActive ? (
                <>
                  <input
                    type="number"
                    value={customPage}
                    onChange={(event) => setCustomPage(parseInt(event.currentTarget.value))}
                    onKeyUp={(event) => event.key === 'Enter' && changeCustomOffset()}
                  />
                  {page.title !== customPage && (
                    <Icon class={iconStyles.common.reload} htmlElementProps={{ onClick: changeCustomOffset }} />
                  )}
                </>
              ) : (
                page.title
              )
            }
          />
        )}
      />
    </div>
  );
}
