import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Subfee.DeleteFile.IRequest;
type Response = Api.Core.Subfee.DeleteFile.IResponse;
type Headers = Api.Core.Subfee.DeleteFile.IHeaders;
const apiSubfeeDeleteFilePath = '/api/ProductManagement/Subfee/:id/Documents/:documentId';
export default async function subfeeDeleteFileApi(id: number, request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiSubfeeDeleteFilePath, { id, documentId: request.documentId }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
