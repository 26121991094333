import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Claim.UpdateCustomerInfo.IRequest;
type Response = Api.Core.Claim.UpdateCustomerInfo.IResponse;
type Headers = Api.Core.Claim.UpdateCustomerInfo.IHeaders;
const url = '/api/Claims/Claim/UpdateCustomerInfo';
export default async function claimUpdateCustomerInfoApi(body?: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
