import { getLocales } from 'assets/locales/Locale';
import Model from 'models/Model';
import nonWarrantyPricePointDeleteApi from './delete/Api.NonWarrantyPricePoint.Delete';
import nonWarrantyPricePointListApi from './list/Api.NonWarrantyPricePoint.List';
import nonWarrantyPricePointSaveApi from './save/Api.NonWarrantyPricePoint.Save';

export default class NonWarrantyPricePoint
  extends Model<Model.INonWarrantyPricePoint>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.INonWarrantyPricePoint>
{
  id: number;
  termInMonths: number;
  maxVehiclePriceCAD: number;
  minVehiclePriceCAD: number;
  priceCAD: number;
  productId: number;
  pricingDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  productProvinceGroupId?: number;

  constructor(
    data: Partial<Model.INonWarrantyPricePoint> | Utils.FormData<Model.INonWarrantyPricePoint>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: `${this.termInMonths}${lang.month} | ${this.minVehiclePriceCAD}$ - ${this.maxVehiclePriceCAD}$`,
      info: `${this.priceCAD}$`,
    };
  }
  static async list(body?: Api.Core.NonWarrantyPricePoint.List.IRequest) {
    const { payload, ...rest } = await nonWarrantyPricePointListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new NonWarrantyPricePoint(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await nonWarrantyPricePointSaveApi(this);
  }
  async delete() {
    return await nonWarrantyPricePointDeleteApi(this.id);
  }
}
