import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { cloneDeep } from 'lodash';
import {
  ClaimTypeReportInput,
  ReportInputType,
} from 'models/claims/claimTypeReportSettings/Model.ClaimTypeReportSettings';

type Props = {
  input: Utils.FormData<Model.IClaimTypeReportInput>;
  inputsForMapping: string[];
  onSave: (data: Model.IClaimTypeReportRow) => void;
};
export default function ClaimTypeEditInfoInputForm({ input, inputsForMapping, onSave }: Props) {
  const { lang, translate } = getLocales();

  const form = useForm<Utils.FormData<Model.IClaimTypeReportInput>>({
    default: cloneDeep(input),
    isUpdatedByDefault: true,
    onSubmit: onSave,
    validation: (data, errors) => {
      if (data.key !== input.key && inputsForMapping?.some((it) => it === data.key)) errors.key = lang.alreadyExists;
    },
  });
  return (
    <FormWrapper>
      <InputWrapper>
        <TextInput name="key" label={lang.key} value={form.data.key} onChange={form.update} error={form.errors?.key} />
        <TextInput
          name="name"
          label={lang.name}
          value={form.data.name?.en}
          onChange={({ name: en }) => form.merge({ name: { en } })}
        />
        <SelectInput
          name="type"
          label={lang.type}
          value={form.data.type}
          onChange={form.update}
          data={Object.keys(ReportInputType).map((it) => ({
            id: ReportInputType[it],
            title: translate(it),
            icon: ClaimTypeReportInput.getIcon(ReportInputType[it]),
          }))}
        />
      </InputWrapper>
      <Button
        class={{ button: ThemeButtonCircle }}
        style={{
          marginLeft: 'auto',
        }}
        media="fas-save"
        children={lang.save}
        onClick={form.submit}
        active={!form.hasErrors}
        disabled={form.hasErrors}
      />
    </FormWrapper>
  );
}
