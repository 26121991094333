import { addLog } from 'assets/components/feedback/Feedback';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { HttpStatus } from 'config/Api.Config';
import claimJobDeleteApi from 'models/claims/claimJob/delete/Api.ClaimJob.Delete';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';

export default function ClaimEditJobDelete() {
  const { lang } = getLocales();
  const { claimJob, product, claim, reloadClaim, setAction } = useClaimJobEdit();

  return (
    <Confirm
      data={claimJob}
      onConfirm={async ({ id }) => {
        const res = await claimJobDeleteApi({ id });
        if (res.status === HttpStatus.ok) {
          addLog({ success: 'Claim job successfully deleted.' });
        } else {
          addLog({ error: res.message });
        }
        reloadClaim();
      }}
      onClose={() => setAction(undefined)}
      actionName={lang.delete}
      itemName={combineStrings('-', product?.category?.code, claim?.id?.toString(), `J${claimJob?.jobNumber}`)}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
