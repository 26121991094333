import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useSubfee, { SubfeeAction } from './Subfee.Hooks';
import SubfeeDelete from './sections/Subfee.Delete';
import SubfeeEdit from './sections/Subfee.Edit';
import SubfeeFilters from './sections/Subfee.Filters';
import SubfeeList from './sections/Subfee.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type Verify = Utils.VerifyExtends<Module.ProductManagement.Subfee.Actions, Utils.ValueOf<typeof Actions>>;
export const subfeeIndexRoute = productManagementHomeRoute + '/subfee/:action?/:id?';
export default function SubfeeIndex() {
  const { lang } = getLocales();
  const subfeeHook = useSubfee();

  if (!subfeeHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={subfeeHook}
        render={({ listApi, views, pageRouter, permissions }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.surcharges} />
                <SubfeeFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_TIER}
                  data={{ action: SubfeeAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <SubfeeEdit />}
                {views.delete && <SubfeeDelete />}
                <SubfeeList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
