import Model from 'models/Model';
import PayeeType from '../payeeType/Model.PayeeType';
import payeeListApi from './list/Api.Payee.List';
import payeeSaveApi from './save/Api.Payee.Save';
import payeeDeleteApi from './delete/Api.Payee.Delete';
import { combineStrings } from 'assets/utils/data/String';

export default class Payee extends Model<Model.IPayee>({ icon: 'fas-cloud' }) implements Model.Instance<Model.IPayee> {
  id: number;
  name: string;
  email: string;
  phoneNumber?: string;
  payeeTypeId?: number;
  payeeType?: PayeeType;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  provinceCode?: string;
  countryCode?: string;
  labourRate?: number;
  paymentMethodCode?: string;

  constructor(data: Partial<Model.IPayee> | Utils.FormData<Model.IPayee>, language?: Locale.Languages) {
    super({ payeeType: PayeeType }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id?.toString(),
      info: this.payeeType?.name,
      subtitle: combineStrings(
        ', ',
        this.streetAddress,
        this.postalCode,
        this.city,
        this.provinceCode,
        this.countryCode
      ),
    };
  }
  get fullAddress() {
    return combineStrings(', ', this.streetAddress, combineStrings(' ', this.postalCode, this.provinceCode));
  }

  static async list(body?: Api.Core.Payee.List.IRequest) {
    const { payload, ...rest } = await payeeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Payee(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await payeeSaveApi(this);
  }
  async delete() {
    return await payeeDeleteApi(this.id);
  }
}
