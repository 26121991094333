import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import ProductPricingButton from 'module/productManagement/components/productPricingButton/ProductPricingButton';
import { ProductAction, useProductProvider } from '../Product.Hooks';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import ProductFilters from './Product.Filters';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import { isMobile } from 'react-device-detect';

export default function ProductList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, permissions } = useProductProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.products}
      render={lang.noInfo}
    />
  ) : (
    <Page
      class={ThemePageList}
      isLoading={listApi.isExecuting}
      header={
        <>
          <b style={{ marginRight: 'auto' }} children={lang.products} />
          <ProductFilters />
          &nbsp;
          <Button
            class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
            active
            media={<Icon class="fas-plus" />}
            render={!isMobile && lang.addNew}
            onClick={pageRouter.updateParams}
            disabled={!permissions.CREATE_PRODUCT}
            data={{ action: ProductAction.EDIT }}
          />
        </>
      }
      render={
        <>
          <Collection class={ThemeCollectionList}>
            <DataMap
              data={listApi.payload?.data}
              render={({ data: product }) => (
                <Row
                  key={product.id}
                  children={
                    <>
                      <RowImage render={<Icon class="fas-user-shield" />} />
                      <RowData {...product.displayInfo} />
                      <RowActions isVertical>
                        <Button
                          onClick={pageRouter.updateParams}
                          data={{
                            id: product.id.toString(),
                            action: ProductAction.EDIT,
                          }}
                          disabled={!permissions.EDIT_PRODUCT}
                          class={ThemeButtonCircle}
                          media={<Icon class="fas-edit" />}
                          htmlElementProps={{ title: lang.edit }}
                        />
                        <Button
                          onClick={pageRouter.updateParams}
                          data={{
                            id: product.id.toString(),
                            action: ProductAction.DELETE,
                          }}
                          disabled={!permissions.DELETE_PRODUCT}
                          class={ThemeButtonCircle}
                          media={<Icon class="fas-trash" />}
                          htmlElementProps={{ title: lang.delete }}
                        />
                        <ProductPricingButton product={product} />
                      </RowActions>
                    </>
                  }
                />
              )}
            />
          </Collection>
        </>
      }
      footer={
        <Pagination
          class={ThemePaginationRounded}
          count={listApi.payload?.count}
          offset={listApi.body?.offset}
          limit={listApi.body?.limit}
          onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
          onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
        />
      }
    />
  );
}
