import { ProgressInfo } from 'assets/utils/data/Iterations';
import './PriceProgressWindow.scss';
import { getLocales } from 'assets/locales/Locale';

export type PriceProgressKey = 'priceDistribution' | 'distributorPriceDistribution' | 'dealerLimit' | 'dealerDiscount';
const PRICE_PROGRESS_WINDOW_DOM_ID = 'priceProgressWindow';
export default function PriceProgressWindow() {
  return <div id={PRICE_PROGRESS_WINDOW_DOM_ID} className="PriceProgressWindow" />;
}

export function addPriceProgressToWindow(progressKey: PriceProgressKey, progressInfo: ProgressInfo) {
  const { lang } = getLocales();
  const progressWindow = document.getElementById(PRICE_PROGRESS_WINDOW_DOM_ID);
  if (progressWindow) {
    let progressSection = progressWindow.querySelector(`#${progressKey}`);
    if (!progressSection) {
      progressSection = document.createElement('div');
      progressSection.id = progressKey;
      progressWindow.appendChild(progressSection);
    }
    progressSection.setAttribute('progress', progressInfo.percentCompleted.toFixed(2));
    progressSection.innerHTML = `${
      progressKey === 'priceDistribution'
        ? lang.prices
        : progressKey === 'distributorPriceDistribution'
        ? lang.distributorDistributions
        : progressKey === 'dealerLimit'
        ? lang.limits
        : lang.discounts
    }: ${progressInfo.currentItem}/${progressInfo.totalItems} | ${progressInfo.percentCompleted.toFixed(
      2
    )}% | Estimated ${(progressInfo.timeEstimateMs / 1000).toFixed(0)}s`;

    if (
      Array.from(progressWindow.getElementsByTagName('*')).every(
        (it) => it.getAttribute('progress')?.toString() === '100.00'
      )
    ) {
      progressWindow.innerHTML = '';
    }
  }
}
