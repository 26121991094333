import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import { useStatementProvider } from '../Statement.Hooks';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import { date, defaultInputDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';

export default function StatementCreate() {
  const { lang } = getLocales();
  const { createApi, pageRouter } = useStatementProvider();
  const { payload: dealersPayload } = useApi({
    action: Dealer.list,
  });

  return (
    <ModalForm<Api.Core.Statement.CreateForDealer.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      default={{}}
      onCancel={pageRouter.updateParams}
      onSubmit={createApi.execute}
      validation={(data, errors) => {
        if (!data.dealerId) errors.dealerId = lang.mustNotBeEmpty;
        if (!data.from) errors.from = lang.mustNotBeEmpty;
        if (!data.to) errors.to = lang.mustNotBeEmpty;
      }}
      render={({ form }) => (
        <>
          <FormWrapper>
            <SelectInput
              name="dealerId"
              label={lang.dealer}
              data={dealersPayload?.data?.map((it) => ({
                ...it.displayInfo,
                id: it.id,
              }))}
              value={form.data?.dealerId}
              error={form.errors.dealerId}
              onChange={({ dealerId }) => {
                form.update({
                  dealerId,
                });
              }}
              icon={<Icon class="if-cloud" />}
            />
            <DateInput
              dateTimeFormat={defaultInputDateTimeFormat}
              name="from"
              value={form.data?.from}
              error={form.errors.from}
              label={lang.date}
              max={date().endOf('day').add(-1, 'second').tz('UTC').format(defaultServerDateTimeFormat)}
              onChange={({ from }) => {
                form.update({
                  from,
                });
              }}
              icon={<Icon class="fas-calendar-alt" />}
            />
            <DateInput
              dateTimeFormat={defaultInputDateTimeFormat}
              name="to"
              value={form.data?.to}
              error={form.errors.to}
              label={lang.date}
              min={form.data.from}
              max={date().endOf('day').add(-1, 'second').tz('UTC').format(defaultServerDateTimeFormat)}
              onChange={({ to }) => {
                form.update({
                  to,
                });
              }}
              icon={<Icon class="fas-calendar-alt" />}
            />
          </FormWrapper>
        </>
      )}
    />
  );
}
