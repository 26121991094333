import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import {
  defaultDateTimeFormat,
  defaultServerDateTimeFormat,
  defaultInputDateTimeFormat,
  date,
} from 'assets/utils/data/Date';
import { defaultLimit, defaultOffset, HttpStatus, managementHomeRoute, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import callApiAccessLogList from 'models/audit/accessLog/list/Api.AccessLog.List';
import Application from 'models/core/application/Model.Application';
import React from 'react';
import { isMobile } from 'react-device-detect';

export const accessLogIndexRoute = managementHomeRoute + '/access-log';
export default function AccessLogIndex() {
  const { lang } = getLocales();
  const { hasAccess } = useAuthStore();
  const canView = hasAccess(AuthPermission.viewAccessLogs);
  const { query, updateQuery } = usePageRouter<Module.Management.AccessLog.Params, Module.Management.AccessLog.Query>({
    route: accessLogIndexRoute,
  });

  const {
    body: filters,
    payload,
    execute: reload,
    reset,
    isExecuting,
  } = useApi({
    action: callApiAccessLogList,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: query.list,
    callback: (req) => updateQuery({ ...query, list: req }),
    wait: !canView,
  });
  const appApi = useApi({ action: Application.list });
  const applications = appApi.payload?.data;

  if (!canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <Page
        class={ThemePageList}
        isLoading={isExecuting}
        header={
          !canView ? null : (
            <>
              <b style={{ marginRight: 'auto' }} children={lang.accessLogs} />
              {!isMobile && (
                <SearchInput
                  value={filters?.search}
                  onSearch={(search) => reload((body) => ({ ...body, search }))}
                  threshold={2}
                  placeholder={`${lang.search}...`}
                />
              )}
              &nbsp;
              <DropdownButton
                buttonMedia={<Icon class="fas-sort" />}
                horizontalPosition="auto_fixed"
                verticalPosition="auto_fixed"
                htmlElementProps={{ title: lang.sort }}
                subcomponents={ThemeDropdownButtonPopup}
                dropdownOptions={
                  <>
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.ascendingByDate}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'accessedAtUtc',
                        orderType: OrderType.asc,
                        thenBy: undefined,
                        thenType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.descendingByDate}
                      media={<Icon class="fas-caret-down" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'accessedAtUtc',
                        orderType: OrderType.desc,
                        thenBy: undefined,
                        thenType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.ascendingByUserName}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'user.FirstName',
                        orderType: OrderType.asc,
                        thenBy: 'user.LastName',
                        thenType: OrderType.asc,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.descendingByUserName}
                      media={<Icon class="fas-caret-down" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'user.FirstName',
                        orderType: OrderType.desc,
                        thenBy: 'user.lastName',
                        thenType: OrderType.desc,
                      })}
                    />
                  </>
                }
              />
              &nbsp;
              <FilterForm
                subcomponents={ThemeFilterFormDrawer}
                filters={filters}
                onFilter={(newFilers) => reload(newFilers)}
                onReset={reset}
                render={({ tempFilters, setFilters }) => (
                  <>
                    <SelectInput
                      name="applicationId"
                      label={lang.applications}
                      data={applications?.map((item) => ({
                        id: item.id,
                        title: item.name,
                        icon: <Icon class="fas-cloud" />,
                      }))}
                      isMultiselect
                      value={tempFilters?.applicationId}
                      onChange={({ applicationId }) => setFilters({ ...tempFilters, applicationId })}
                      icon={<Icon class="if-cloud" />}
                    />
                    <DateInput
                      name="startDateUtc"
                      label={lang.startDate}
                      dateTimeFormat={defaultInputDateTimeFormat}
                      value={tempFilters?.startDateUtc}
                      onChange={({ startDateUtc }) => setFilters({ ...tempFilters, startDateUtc })}
                      icon={<Icon class="fas-calendar-alt" />}
                    />
                    <DateInput
                      name="endDateUtc"
                      label={lang.endDate}
                      dateTimeFormat={defaultInputDateTimeFormat}
                      value={tempFilters?.endDateUtc}
                      onChange={({ endDateUtc }) => setFilters({ ...tempFilters, endDateUtc })}
                      icon={<Icon class="fas-calendar-alt" />}
                    />
                  </>
                )}
              />
            </>
          )
        }
        render={
          !canView ? (
            <ErrorPage status={HttpStatus.unauthorized} />
          ) : (
            <>
              <Collection class={ThemeCollectionList}>
                <DataMap
                  data={payload?.data}
                  render={({ data: accessLog }) => (
                    <Row
                      key={accessLog.id}
                      children={
                        <>
                          <RowImage render={<Icon class="if-login" />} />
                          <RowData
                            info={accessLog.application?.name}
                            title={
                              <>
                                {accessLog.user?.firstName}&nbsp;
                                {accessLog.user?.lastName}
                              </>
                            }
                            subtitle={
                              <>
                                {lang.accessedAt}{' '}
                                <b>
                                  {date(accessLog?.accessedAtUtc, defaultServerDateTimeFormat).format(
                                    defaultDateTimeFormat
                                  )}
                                </b>
                              </>
                            }
                          />
                        </>
                      }
                    />
                  )}
                />
              </Collection>
            </>
          )
        }
        footer={
          !canView ? null : (
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          )
        }
      />
    </>
  );
}
