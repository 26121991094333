import TextBox, { textBoxStyles } from 'assets/components/textBox/TextBox';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { getClaimCreatedByApp } from 'assets/utils/other/Utils.Other.ClaimEdit';
import payeeListApi from 'models/claims/payee/list/Api.Payee.List';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import dealerFindApi from 'models/dealerManagement/dealer/find/Api.Dealer.Find';
import { paragraphStyles } from '../ClaimEdit.Info';
import { ClaimCreationType } from 'models/enums/ClaimCreation';

type Props = {
  claim: Model.IClaim;
};

export default function ClaimEditInfoContactInfo({ claim }: Props) {
  const { lang } = getLocales();

  const { payload: payess } = useApi({ action: payeeListApi, body: { payeeTypeId: [PayeeType.REPAIR_FACILITY_ID] } });
  const payee = payess?.data?.find((p) => p.id === claim?.createdByPayeeId);

  const { payload: dealer } = useApi({ action: dealerFindApi, body: { id: claim?.dealerId }, wait: !claim?.dealerId });

  return (
    <>
      <h2>Contact info</h2>
      <div style={paragraphStyles}>
        <TextBox class={textBoxStyles.block.flexBlock} title="Created by:" subtitle={getClaimCreatedByApp(claim)} />
        {getClaimCreatedByApp(claim) === ClaimCreationType.PUBLIC ? (
          <>
            <TextBox
              class={textBoxStyles.block.flexBlock}
              title="Contact name:"
              subtitle={claim?.publicContactName ?? 'N/A'}
            />
            <TextBox
              class={textBoxStyles.block.flexBlock}
              title="Phone number:"
              subtitle={claim?.publicContactPhone ?? 'N/A'}
            />
            <TextBox
              class={textBoxStyles.block.flexBlock}
              title="Email:"
              subtitle={claim?.publicContactEmail ?? 'N/A'}
            />
            {payee && (
              <>
                <TextBox class={textBoxStyles.block.flexBlock} title="Created as:" subtitle={lang.repairFacility} />
                <TextBox class={textBoxStyles.block.flexBlock} title="Payee:" subtitle={payee?.name} />
              </>
            )}
            {dealer && (
              <>
                <TextBox class={textBoxStyles.block.flexBlock} title="Created as:" subtitle={lang.dealer} />
                <TextBox class={textBoxStyles.block.flexBlock} title="Dealership:" subtitle={dealer?.name} />
              </>
            )}
          </>
        ) : (
          <>
            <TextBox
              class={textBoxStyles.block.flexBlock}
              title="User:"
              subtitle={combineStrings(' ', claim?.owner?.firstName, claim?.owner?.lastName) ?? 'N/A'}
            />
            {dealer && (
              <>
                <TextBox class={textBoxStyles.block.flexBlock} title="Created as:" subtitle={lang.dealer} />
                <TextBox class={textBoxStyles.block.flexBlock} title="Dealer:" subtitle={dealer?.name} />
              </>
            )}
            <TextBox class={textBoxStyles.block.flexBlock} title="Email:" subtitle={claim?.owner?.email ?? 'N/A'} />
          </>
        )}
      </div>
    </>
  );
}
