import React from 'react';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { StatementAction, useStatementProvider } from '../Statement.Hooks';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import HeaderData from 'assets/components/data/rows/HeaderData';
import FormatValue from 'assets/components/formatValue/FormatValue';
import { combineStrings } from 'assets/utils/data/String';
import StatementStatusCode from 'models/enums/StatementStatusCode';
import Statement from 'models/sales/statement/Model.Statement';
import statementRequestPaymentUrlApi from 'models/sales/statement/requestPaymentUrl/Api.Statment.RequestPaymentUrl';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { statementIndexRoute } from '../Statement.Index';
import Box from 'assets/components/box/Box';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import Theme from 'assets/themes/Theme.Common';
import Contract from 'models/sales/contract/Model.Contract';

export default function StatementList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useStatementProvider();
  const { permissions } = useAuthStore();

  async function pay(statement: Statement) {
    if (!statement.dealer) return;
    if (statement.isPayed || statement.status === StatementStatusCode.RECONCILED) return;

    const dealer = statement.dealer;
    const entityIds = [statement.id];
    const paymentUrl = await statementRequestPaymentUrlApi({
      entityIds,
      amount: statement.contracts.reduce((p, c) => {
        const taxExemptPrice = new Contract(c).taxExemptPrices;
        return p + (taxExemptPrice.paymentAmountTotalCAD ?? 0);
      }, 0),
      redirectUrl: `${document.location.origin}${createPathFromRoute(statementIndexRoute)}`,
      client: {
        firstName: '',
        lastName: '',
        address: dealer.streetAddress,
        city: dealer.city,
        province: dealer.provinceCode,
        zip: dealer.postalCode,
        email: dealer.primaryEmailAddress,
        phone: dealer.primaryPhoneNumber,
      },
    });
    if (paymentUrl.payload) {
      window.open(paymentUrl.payload, '_blank', 'noopener, noreferrer');
    }
  }

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.statements}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <HeaderRow>
        <HeaderData>
          {lang.dealer}/{lang.contracts}
        </HeaderData>
        <HeaderData>{lang.representative}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.amount}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.tax}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.totalAmount}</HeaderData>
        <HeaderData numOfActions={1} />
      </HeaderRow>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: statement }) => (
          <Row
            key={statement.id}
            children={
              <>
                <RowImage render={<Icon class="fas-user-shield" />} />
                <RowData
                  {...statement.displayInfo}
                  subtitle={
                    <>
                      {statement.isPayed && (
                        <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                          <Icon class="fas-check" />
                          &nbsp; Paid
                        </Box>
                      )}
                    </>
                  }
                />
                <RowData>
                  {combineStrings(' ', statement.representative?.firstName, statement.representative?.lastName)}
                </RowData>
                <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                  <b>
                    <FormatValue
                      value={`${(statement.contracts.reduce((sum, obj) => sum + obj.paymentAmountCAD, 0) || 0).toFixed(
                        2
                      )}$`}
                    />
                  </b>
                </RowData>
                <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                  <b>
                    <FormatValue
                      value={`${(
                        statement.contracts.reduce(
                          (sum, obj) => sum + (obj.client.taxExempt ? 0 : obj.paymentTaxCAD),
                          0
                        ) || 0
                      ).toFixed(2)}$`}
                    />
                  </b>
                </RowData>
                <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                  <b>
                    <FormatValue value={`${(statement.totalAmountDueCAD || 0).toFixed(2)}$`} />
                  </b>
                </RowData>
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statement.id.toString(),
                      action: StatementAction.CHANGE_STATUS,
                    }}
                    disabled={
                      !permissions.UPDATE_STATEMENT_STATUS ||
                      statement.status === StatementStatusCode.CANCELED ||
                      statement.status === StatementStatusCode.RECONCILED
                    }
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-check-double" />}
                    htmlElementProps={{ title: lang.changeStatus }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statement.id.toString(),
                      action: StatementAction.UPLOAD_DOCUMENT,
                      router: pageRouter,
                    }}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-upload" />}
                    htmlElementProps={{ title: lang.upload }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statement.id.toString(),
                      action: StatementAction.PRINT,
                      router: pageRouter,
                    }}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-print" />}
                    htmlElementProps={{ title: lang.print }}
                  />
                  <Button
                    onClick={() => pay(statement)}
                    disabled={statement.isPayed || statement.status === StatementStatusCode.RECONCILED}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-receipt" />}
                    htmlElementProps={{ title: lang.pay }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statement.id.toString(),
                      action: StatementAction.DELETE,
                    }}
                    disabled={
                      !permissions.DELETE_STATEMENT ||
                      (statement.status !== StatementStatusCode.CANCELED &&
                        statement.status !== StatementStatusCode.OPEN)
                    }
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{ title: lang.delete }}
                  />
                  <Button />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
