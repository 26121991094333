import Model from 'models/Model';

export default class CategoryTranslation
  extends Model<Model.ICategoryTranslation>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ICategoryTranslation>
{
  id: number;
  languageIsoCode?: Locale.Languages;
  name?: string;
  description?: string;

  constructor(
    data: Partial<Model.ICategoryTranslation> | Utils.FormData<Model.ICategoryTranslation>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      subtitle: this.description,
    };
  }
}
