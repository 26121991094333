import useApi from 'assets/hooks/api/useApi';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { compact, differenceBy, first, get, keys, reduce } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import DealerDistributionDiscount, {
  dealerDistributionDiscountDeleteApi,
  dealerDistributionDiscountSaveApi,
} from 'models/dealerManagement/dealerDistributionDiscount/Model.DealerDistributionDiscount';
import DealerDistributionLimitation, {
  dealerDistributionLimitationDeleteApi,
  dealerDistributionLimitationSaveApi,
} from 'models/dealerManagement/dealerDistributionLimitation/Model.DealerDistributionLimitation';

import { useEffect, useMemo, useState } from 'react';
import { PriceProgressKey } from '../components/priceProgressWindow/PriceProgressWindow';

export type DealerDiscountSettings = Partial<Model.IDealerDistributionDiscount> & {
  isUpdated?: boolean;
};
export type DealerLimitsSettings = Partial<Model.IDealerDistributionLimitation> & {
  isUpdated?: boolean;
};
type Props = {
  productId: number;
};
export default function useDealerPricingSettings({ productId }: Props) {
  const { user, isDealer, isDealerOrDistributor, permissions } = useAuthStore();
  const dealerIds = user?.dealers?.filter((d) => d.products?.some((p) => p.id === productId))?.map((d) => d.id);
  const [dealerId, setDealerId] = useState<number | null>(first(dealerIds));

  const dealerApi = useApi({
    action: Dealer.list,
    body: {
      id: dealerIds ? dealerIds : undefined,
    },
  });
  const discountsApi = useApi(
    {
      action: DealerDistributionDiscount.list,
      body: {
        productId: [productId],
        dealerId,
      },
      wait: !productId || !dealerId,
    },
    [productId, dealerId]
  );
  const limitsApi = useApi(
    {
      action: DealerDistributionLimitation.list,
      body: {
        productId,
        dealerId,
      },
      wait: !productId || !dealerId,
    },
    [productId, dealerId]
  );

  const dealers = dealerApi?.payload?.data?.filter((d) => d.products?.some((p) => p.id === productId));

  useEffect(() => {
    setDiscounts([]);
    setLimits([]);
  }, [dealerId, isDealer]);
  //#region DISTRIBUTOR
  const dealer = dealerApi?.payload?.data?.find((d) => d.id === dealerId);

  const [discounts, setDiscounts] = useState<DealerDiscountSettings[]>([]);
  useEffect(() => {
    if (discountsApi.payload?.data) {
      setDiscounts(discountsApi.payload?.data ?? []);
    }
  }, [discountsApi.payload?.data]);
  const [limits, setLimits] = useState<DealerLimitsSettings[]>([]);
  useEffect(() => {
    if (limitsApi.payload?.data) {
      setLimits(limitsApi.payload?.data ?? []);
    }
  }, [limitsApi.payload?.data]);

  const hasErrors = useMemo(
    () =>
      limits?.some(
        (it) =>
          (it.limitations.minAmount != null &&
            it.limitations.maxAmount != null &&
            it.limitations.maxAmount < it.limitations.minAmount) ||
          (it.limitations.minAmount != null && it.limitations.minAmount <= 0) ||
          (it.limitations.maxAmount != null && it.limitations.maxAmount <= 0)
      ),
    [limits]
  );

  function getDiscountPrice(pricingDistributions: Utils.PricingDistribution) {
    let discountPrice = 0;
    const suggestedPrice = reduce(pricingDistributions, (p, c) => p + (c.amount ?? 0), 0);
    const discounts = discountsApi.payload?.data?.find((it) => it.productId === productId);
    const allKeys = compact(keys(discounts?.distributionDiscount ?? {}));
    for (const key of allKeys) {
      const distributionPrice = get(pricingDistributions, key);
      const discount = get(discounts.distributionDiscount, key);
      const hasDiscount = !!discount?.amount && !!discount?.unit;
      if (isValidValue(distributionPrice?.amount) && hasDiscount) {
        const discountAmount =
          discount.unit === 'percent' ? distributionPrice.amount * (discount.amount / 100) : discount.amount;

        discountPrice += discountAmount;
      }
    }
    return suggestedPrice - discountPrice;
  }
  function getDistributionLimitation(pricePointId: number, pricePointType: Utils.PricePointType) {
    return limits?.find((it) => it.pricePointId === pricePointId && it.pricePointType === pricePointType) ?? null;
  }
  function updateDistributionLimitation(
    limitations: Utils.SingleOrArray<Partial<Model.IDealerDistributionLimitation>>
  ) {
    const finalLimitations = (Array.isArray(limitations) ? limitations : [limitations]).map((it) => {
      const currentDistribution = getDistributionLimitation(it.pricePointId, it.pricePointType);
      return { ...currentDistribution, ...it };
    });
    setLimits((old) =>
      [
        ...differenceBy(old, finalLimitations, (it) => it.pricePointId),
        ...finalLimitations.map((it) => ({ ...it, isUpdated: true })),
      ].filter((it) => it && it.pricePointId && it.pricePointType)
    );
  }
  //#endregion

  async function saveAll(onSaveProgress?: (key: PriceProgressKey, progressInfo: ProgressInfo) => void) {
    const updatedLimits = limits.filter((it) => it.isUpdated);
    const updatedLimitCount = await iterateWithProgress(
      updatedLimits,
      async (limit) => {
        if (limit.limitations?.maxAmount == null && limit.limitations?.minAmount == null && limit.id) {
          await dealerDistributionLimitationDeleteApi(limit.id);
        } else {
          await dealerDistributionLimitationSaveApi({ productId, dealerId, ...limit });
        }
      },
      (p) => onSaveProgress('dealerLimit', p)
    );
    const updatedDiscounts = discounts.filter((it) => it.isUpdated);
    const updatedDiscountCount = await iterateWithProgress(
      updatedDiscounts,
      async (discount) => {
        if (!discount.distributionDiscount && discount.id) {
          await dealerDistributionDiscountDeleteApi(discount.id);
        } else {
          await dealerDistributionDiscountSaveApi({ productId, dealerId, ...discount });
        }
      },
      (p) => onSaveProgress('dealerDiscount', p)
    );

    if (updatedLimitCount) await limitsApi.execute((b) => b);
    if (updatedDiscountCount) await discountsApi.execute((b) => b);
  }
  const isUpdated = limits.some((it) => it.isUpdated) || discounts.some((it) => it.isUpdated);
  return {
    saveAll,
    getDistributionLimitation,
    getDiscountPrice,
    updateDistributionLimitation,
    dealerId,
    setDealerId,
    discounts,
    isUpdated,
    limits,
    dealers,
    dealer,
    canEdit: !!permissions?.PRICINGLIMITS_DEALER || !isDealerOrDistributor,
    isDealerOrDistributor,
    hasErrors,
  };
}
