import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import React from 'react';
import { usePayeeTypeProvider } from '../PayeeType.Hooks';

export default function PayeeTypeEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = usePayeeTypeProvider();

  return (
    <>
      <ModalForm<Api.Core.PayeeType.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.payeeType}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={(data) => saveApi.execute(data)}
        validation={(data, errors) => {
          if (!data.code) errors.code = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="code"
                label={lang.code}
                value={form.data.code}
                error={form.errors.code}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
