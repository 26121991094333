export type Verify = Utils.VerifyExtends<Effect.FontSize, Utils.ValueOf<typeof FontSizeVariable>>;
enum FontSizeVariable {
  XXS = 'var(--fontXXS)',
  XS = 'var(--fontXS)',
  S = 'var(--fontS)',
  M = 'var(--fontM)',
  L = 'var(--fontL)',
  XL = 'var(--fontXL)',
  XXL = 'var(--fontXXL)',
}
export default FontSizeVariable;
