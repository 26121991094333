import { envUrlMappings } from 'config/Api.Config';
import { flattenKeys, nestData } from '../data/Object';
import qs from 'query-string';
import { isEmpty } from '../parsersAndValidation/Validators';

export function isLocalhost() {
  return document.location.href.indexOf('localhost') >= 0 || document.location.port ? true : false;
}
export function getJsonFromUrl(url?: string) {
  if (!url) url = window.location.href;
  const question = url.indexOf('?');
  let hash = url.indexOf('#');
  if (hash == -1 && question == -1) return {};
  if (hash == -1) hash = url.length;
  const query = question == -1 || hash == question + 1 ? url.substring(hash) : url.substring(question + 1, hash);
  const result: { [key: string]: string[] | string } = {};
  query.split('&').forEach(function (part) {
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    const eq = part.indexOf('=');
    let key = eq > -1 ? part.substr(0, eq) : part;
    const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    const from = key.indexOf('[');
    if (from == -1) result[decodeURIComponent(key)] = val;
    else {
      const to = key.indexOf(']', from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index || !Number(index)) (result[key] as string[]).push(val);
      else (result[key] as string[])[Number(index)] = val;
    }
  });
  return result;
}

export function createPathFromRoute<T, Q = any>(route: string, params: T = {} as T, query?: Q) {
  let newRoute = route;
  for (const [key, value] of Object.entries(params || ({} as T))) {
    newRoute = newRoute.replace(new RegExp(`:${key}\\??`, 'g'), value?.toString() || '');
  }
  newRoute = newRoute
    .replace(/\/\:\w+(((-|_)\w+)+)?\?/g, '')
    .replace(/\/+/g, '/')
    .replace(/\/$/g, '');
  return `${newRoute}${stringifyQueryString(query)}`;
}
export function parseQueryString<T>(queryString: string, opts?: { parseNumber: true; parseBoolean: true }) {
  const preNestingObj = qs.parse(queryString, {
    parseBooleans: opts && opts.parseBoolean,
    parseNumbers: opts && opts.parseNumber,
  });
  return nestData<T>(preNestingObj, { ...opts, separator: '.' });
}
export function stringifyQueryString<Q = any>(query?: Q, opts?: { skipNulls?: boolean }) {
  if (typeof query === 'object' && isEmpty(query as any)) return '';
  const flattenObj = query ? flattenKeys(query) : {};
  const qString = Object.entries(flattenObj)
    .map(([key, value]) => {
      if (opts && opts.skipNulls && value === null) return '';
      else if (value === undefined) return '';
      if (Array.isArray(value)) return value.map((it) => `${key}=${it}`).join('&');
      else return `${key}=${value}`;
    })
    .filter((it) => (it ? true : false))
    .join('&');
  return qString ? `?${qString}` : qString;
}

export function parseValue(data: any, options: { parseNumber?: boolean; parseBoolean?: boolean }) {
  if (options.parseBoolean && data === 'true') return true as any;
  else if (options.parseBoolean && data === 'false') return false as any;
  else if (options.parseNumber && typeof data == 'string' && !isNaN(data as any)) return parseInt(data) as any;
  else if (data === 'null') return null;
  else if (data === 'undefined') return undefined;
  else return data;
}

export function getUrlInfo(url: string) {
  const link = document.createElement('a');
  link.href = url;
  const extension = link.pathname.match(/\.\w+$/g);
  let server_url = link.href;
  server_url = link.port ? server_url.replace(`:${link.port}`, `:5001`) : server_url;
  if (envUrlMappings[link.hostname]) {
    server_url = server_url ? server_url.replace(link.hostname, envUrlMappings[link.hostname]) : server_url;
  }
  return {
    hash: link.hash || undefined,
    hostname: link.hostname || undefined,
    pathname: link.pathname || undefined,
    port: link.port || undefined,
    protocol: (link.protocol as any) || undefined,
    search: link.search || undefined,
    href: link.href || undefined,
    extension: extension && extension[0],
    server_url,
    url: url,
    same_site: link.hostname == window.location.hostname,
    is_nav_path: /^(\/(\w|\-|\_)+)+$/g.test(url),
  };
}
