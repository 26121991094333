import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import { isMobile } from 'react-device-detect';
import useClaim, { ClaimAction } from './Claim.Hooks';
import ClaimAdd from './sections/Claim.Add';
import ClaimDelete from './sections/Claim.Delete';
import ClaimFilters from './sections/Claim.Filters';
import ClaimList from './sections/Claim.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const claimIndexRoute = claimsHomeRoute + '/claim/:action?/:id?';
export default function ClaimIndex() {
  const { lang } = getLocales();
  const claimHook = useClaim();

  if (!claimHook.hasAnyPermission) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={claimHook}
        render={({ listApi, views, pageRouter, canAddClaim }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.claims} />
                <ClaimFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  disabled={!canAddClaim}
                  onClick={pageRouter.updateParams}
                  data={{ action: ClaimAction.ADD, id: undefined }}
                />
              </>
            }
            render={
              <>
                {views.delete && <ClaimDelete />}
                {views.add && <ClaimAdd />}
                <ClaimList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
