import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { omit, pick } from 'lodash';

type Params = Api.Core.Contract.PricingInfo.IParams;
type Request = Api.Core.Contract.PricingInfo.IRequest;
type Response = Api.Core.Contract.PricingInfo.IResponse;
type Headers = Api.Core.Contract.PricingInfo.IHeaders;
const url = '/api/Sales/Contract/pricingInfo/:dealerId/:distributorId/:vehicleModelId';
export default async function contractPricingInfoApi(data: Params & Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url: createPathFromRoute(
      url,
      pick(data, 'dealerId', 'distributorId', 'vehicleModelId'),
      omit(data, 'dealerId', 'distributorId', 'vehicleModelId')
    ),
    headers: getDefaultHeaders(),
  });
}
