import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import React from 'react';
import { useEmailTemplateProvider } from '../EmailTemplate.Hooks';

export default function EmailTemplateFilters() {
  const { lang } = getLocales();
  const { listApi } = useEmailTemplateProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
    </>
  );
}
