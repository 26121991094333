import Model from 'models/Model';
import Contract from '../contract/Model.Contract';
import contractConsentFindApi from './find/Api.ContractConsent.Find';
import contractConsentListApi from './list/Api.ContractConsent.List';
import Product from 'models/productManagement/product/Model.Product';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import { isNull } from 'lodash';

export default class ContractConsent
  extends Model<Model.IContractConsent>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IContractConsent>
{
  id: number;
  type: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  contract: Contract;
  productId: number;
  product: Product;
  dealerId: number;
  dealer: Dealer;
  salePriceCAD: number;
  dealerCost: number;
  insurancePremiumCAD: number;
  administrationFeeCAD: number;
  term: number;
  limitOfLiabilityAggregateCAD: number;
  programName: string;
  customerName: string;
  customerEmail: string;
  coverage: string;
  dealership: string;
  answeredAt?: string;
  isAccepted?: boolean;
  pricePointId?: number;

  constructor(
    data: Partial<Model.IContractConsent> | Utils.FormData<Model.IContractConsent>,
    language?: Locale.Languages
  ) {
    super(
      {
        contract: Contract,
        product: Product,
        dealer: Dealer,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: `${this?.contract.appNumber}` || this.id.toString(),
      subtitle: `Consent: ${
        isNull(this.isAccepted) ? 'Waiting for consent' : this.isAccepted ? 'Consent Given' : 'Consent Rejected'
      }`,
    };
  }
  static async list(body?: Api.Core.ContractConsent.List.IRequest) {
    const { payload, ...rest } = await contractConsentListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ContractConsent(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: { id: number }) {
    const { payload, ...rest } = await contractConsentFindApi(params);
    return {
      ...rest,
      payload: new ContractConsent(payload),
    };
  }
}
