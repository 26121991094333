
export enum UICommand {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  strikeThrough = 'strikeThrough',
  subscript = 'subscript',
  superscript = 'superscript',

  copy = 'copy',
  cut = 'cut',
  delete = 'delete',
  paste = 'paste',
  undo = 'undo',
  redo = 'redo',
  selectAll = 'selectAll',
  removeFormat = 'removeFormat',

  fontName = 'fontName',
  fontSize = 'fontSize',

  foreColor = 'foreColor',
  hiliteColor = 'hiliteColor',

  indent = 'indent',
  outdent = 'outdent',
  justifyCenter = 'justifyCenter',
  justifyFull = 'justifyFull',
  justifyLeft = 'justifyLeft',
  justifyRight = 'justifyRight',

  createLink = 'createLink',
  unlink = 'unlink',

  insertHorizontalRule = 'insertHorizontalRule',
  insertOrderedList = 'insertOrderedList',
  insertUnorderedList = 'insertUnorderedList',

  insertImage = 'insertImage',

  formatBlock = 'formatBlock',
  insertText = 'insertText',
  insertHTML = 'insertHTML',
}
export enum UITag {
  paragraph = 'p',
  heading1 = 'h1',
  heading2 = 'h2',
  heading3 = 'h3',
  heading4 = 'h4',
  heading5 = 'h5',
  heading6 = 'h6',
}
export enum UIDefaultFont {
  Arial = 'Arial,"Helvetica Neue",Helvetica,sans-serif',
  ArialBlack = '"Arial Black","Arial Bold",Gadget,sans-serif',
  ArialNarrow = '"Arial Narrow",Arial,sans-serif',
  ArialRounded = '"Arial Rounded MT Bold","Helvetica Rounded",Arial,sans-serif',
  AvantGarde = '"Avant Garde",Avantgarde,"Century Gothic",CenturyGothic,AppleGothic,sans-serif',
  Calibri = 'Calibri,Candara,Segoe,"Segoe UI",Optima,Arial,sans-serif',
  Candara = 'Candara,Calibri,Segoe,"Segoe UI",Optima,Arial,sans-serif',
  CenturyGothic = '"Century Gothic",CenturyGothic,AppleGothic,sans-serif',
  FranklinGothic = '"Franklin Gothic Medium","Franklin Gothic","ITC Franklin Gothic",Arial,sans-serif',
  Futura = 'Futura,"Trebuchet MS",Arial,sans-serif',
  Geneva = 'Geneva,Tahoma,Verdana,sans-serif',
  GillSans = '"Gill Sans","Gill Sans MT",Calibri,sans-serif',
  HelveticaNeue = '"Helvetica Neue",Helvetica,Arial,sans-serif',
  Impact = 'Impact,Haettenschweiler,"Franklin Gothic Bold",Charcoal,"Helvetica Inserat","Bitstream Vera Sans Bold","Arial Black","sans serif"',
  LucidaGrande = '"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Verdana,sans-serif',
  Optima = 'Optima,Segoe,"Segoe UI",Candara,Calibri,Arial,sans-serif',
  SegoeUI = '"Segoe UI",Frutiger,"Frutiger Linotype","Dejavu Sans","Helvetica Neue",Arial,sans-serif',
  Tahoma = 'Tahoma,Verdana,Segoe,sans-serif',
  TrebuchetMS = '"Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif',
  Verdana = 'Verdana,Geneva,sans-serif',
  BigCaslon = '"Big Caslon","Book Antiqua","Palatino Linotype",Georgia,serif',
  BodoniMT = '"Bodoni MT",Didot,"Didot LT STD","Hoefler Text",Garamond,"Times New Roman",serif',
  BookAntiqua = '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
  CalistoMT = '"Calisto MT","Bookman Old Style",Bookman,"Goudy Old Style",Garamond,"Hoefler Text","Bitstream Charter",Georgia,serif',
  Cambria = 'Cambria,Georgia,serif',
  Didot = 'Didot,"Didot LT STD","Hoefler Text",Garamond,"Times New Roman",serif',
  Garamond = 'Garamond,Baskerville,"Baskerville Old Face","Hoefler Text","Times New Roman",serif',
  Georgia = 'Georgia,Times,"Times New Roman",serif',
  GoudyOldStyle = '"Goudy Old Style",Garamond,"Big Caslon","Times New Roman",serif',
  HoeflerText = '"Hoefler Text","Baskerville Old Face",Garamond,"Times New Roman",serif',
  LucidaBright = '"Lucida Bright",Georgia,serif',
  Palatino = 'Palatino,"Palatino Linotype","Palatino LT STD","Book Antiqua",Georgia,serif',
  Perpetua = 'Perpetua,Baskerville,"Big Caslon","Palatino Linotype",Palatino,"URW Palladio L","Nimbus Roman No9 L",serif',
  Rockwell = 'Rockwell,"Courier Bold",Courier,Georgia,Times,"Times New Roman",serif',
  RockwellExtraBold = '"Rockwell Extra Bold","Rockwell Bold",monospace',
  Baskerville = 'Baskerville,"Baskerville Old Face","Hoefler Text",Garamond,"Times New Roman",serif',
  TimesNewRoman = 'TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif',
  Consolas = 'Consolas,monaco,monospace',
  CourierNew = '"Courier New",Courier,"Lucida Sans Typewriter","Lucida Typewriter",monospace',
  LucidaConsole = '"Lucida Console","Lucida Sans Typewriter",monaco,"Bitstream Vera Sans Mono",monospace',
  LucidaSansTypewriter = '"Lucida Sans Typewriter","Lucida Console",monaco,"Bitstream Vera Sans Mono",monospace',
  Monaco = 'monaco, Consolas,"Lucida Console",monospace',
  AndaleMono = '"Andale Mono",AndaleMono,monospace',
  Copperplate = 'Copperplate,"Copperplate Gothic Light",fantasy',
  Papyrus = 'Papyrus,fantasy',
  BrushScriptMT = '"Brush Script MT",cursive',
}
export enum UIFontSize {
  'XXS' = '1',
  'XS' = '2',
  'S' = '3',
  'M' = '4',
  'L' = '5',
  'XL' = '6',
  'XXL' = '7',
}
export enum UIBorderStyle {
  dashed = 'dashed',
  dotted = 'dotted',
  double = 'double',
  solid = 'solid',
  wavy = 'wavy',
  inset = 'inset',
  outset = 'outset',
  groovy = 'groovy',
  ridge = 'ridge',
}
export enum UILine {
  lineThrough = 'line-through',
  underline = 'underline',
  overline = 'overline',
}
export enum UILineStyle {
  dashed = 'dashed',
  dotted = 'dotted',
  double = 'double',
  solid = 'solid',
  wavy = 'wavy',
}
export enum UITagName {
  a = 'a',
  abbr = 'abbr',
  acronym = 'acronym',
  address = 'address',
  applet = 'applet',
  area = 'area',
  article = 'article',
  aside = 'aside',
  audio = 'audio',
  b = 'b',
  base = 'base',
  basefont = 'basefont',
  bdi = 'bdi',
  bdo = 'bdo',
  big = 'big',
  blockquote = 'blockquote',
  body = 'body',
  br = 'br',
  button = 'button',
  canvas = 'canvas',
  caption = 'caption',
  center = 'center',
  cite = 'cite',
  code = 'code',
  col = 'col',
  colgroup = 'colgroup',
  data = 'data',
  datalist = 'datalist',
  dd = 'dd',
  del = 'del',
  details = 'details',
  dfn = 'dfn',
  dialog = 'dialog',
  dir = 'dir',
  div = 'div',
  dl = 'dl',
  dt = 'dt',
  em = 'em',
  embed = 'embed',
  fieldset = 'fieldset',
  figcaption = 'figcaption',
  figure = 'figure',
  font = 'font',
  footer = 'footer',
  form = 'form',
  frame = 'frame',
  frameset = 'frameset',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  head = 'head',
  header = 'header',
  hr = 'hr',
  html = 'html',
  i = 'i',
  iframe = 'iframe',
  img = 'img',
  input = 'input',
  ins = 'ins',
  kbd = 'kbd',
  label = 'label',
  legend = 'legend',
  li = 'li',
  link = 'link',
  main = 'main',
  map = 'map',
  mark = 'mark',
  meta = 'meta',
  meter = 'meter',
  nav = 'nav',
  noframes = 'noframes',
  noscript = 'noscript',
  object = 'object',
  ol = 'ol',
  optgroup = 'optgroup',
  option = 'option',
  output = 'output',
  p = 'p',
  param = 'param',
  picture = 'picture',
  pre = 'pre',
  progress = 'progress',
  q = 'q',
  rp = 'rp',
  rt = 'rt',
  ruby = 'ruby',
  s = 's',
  samp = 'samp',
  script = 'script',
  section = 'section',
  select = 'select',
  small = 'small',
  source = 'source',
  span = 'span',
  strike = 'strike',
  strong = 'strong',
  style = 'style',
  sub = 'sub',
  summary = 'summary',
  sup = 'sup',
  svg = 'svg',
  table = 'table',
  tbody = 'tbody',
  td = 'td',
  template = 'template',
  textarea = 'textarea',
  tfoot = 'tfoot',
  th = 'th',
  thead = 'thead',
  time = 'time',
  title = 'title',
  tr = 'tr',
  track = 'track',
  tt = 'tt',
  u = 'u',
  ul = 'ul',
  var = 'var',
  video = 'video',
  wbr = 'wbr',
}

export type UICommandList = typeof UICommand;
export type UIStates = { [K in Utils.ValueOf<UICommandList>]: any };
export type UICommandValueObj =
  | UICommand
  | {
      command: UICommand;
      title?: string;
      text?: string;
      icon?: string;
      value: string | { [key: string]: string } | (() => string | undefined);
    };
export type UICommandGroups = {
  [key: string]: UICommandValueObj[];
};

export const uiCommandIcons = {
  [UICommand.bold]: 'if-bold',
  [UICommand.italic]: 'if-italic',
  [UICommand.underline]: 'if-underline',
  [UICommand.strikeThrough]: 'if-strike-through',
  [UICommand.subscript]: 'if-subscript',
  [UICommand.superscript]: 'if-superscript',

  [UICommand.removeFormat]: 'if-eraser',
  [UICommand.copy]: 'if-copy',
  [UICommand.cut]: 'if-cut',
  [UICommand.delete]: 'if-trash',
  [UICommand.paste]: 'fas-paste',
  [UICommand.undo]: 'if-undo',
  [UICommand.redo]: 'if-redo',
  [UICommand.selectAll]: 'if-hand',

  [UICommand.fontName]: 'if-font',
  [UICommand.fontSize]: 'if-text-height',

  [UICommand.foreColor]: 'fas-paint-brush',
  [UICommand.hiliteColor]: 'fas-paint-roller',

  [UICommand.indent]: 'if-indent',
  [UICommand.outdent]: 'if-outdent',
  [UICommand.justifyCenter]: 'if-justify-center',
  [UICommand.justifyFull]: 'if-justify-all',
  [UICommand.justifyLeft]: 'if-justify-left',
  [UICommand.justifyRight]: 'if-justify-right',

  [UICommand.createLink]: 'if-link',
  [UICommand.unlink]: 'if-link-broken',

  [UICommand.insertHorizontalRule]: 'if-minus',
  [UICommand.insertOrderedList]: 'if-listing-number',
  [UICommand.insertUnorderedList]: 'if-listine-dots',

  [UICommand.insertImage]: 'if-image',
  [UICommand.formatBlock]: 'if-paragraph',
  [UICommand.insertText]: 'if-heading',
  [UICommand.insertHTML]: 'fab-html5',
};
