import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import React from 'react';
import { useDealerGroupProvider } from '../DealerGroup.Hooks';

export default function DealerGroupDelete() {
  const { lang } = getLocales();
  const { deleteApi, selected, pageRouter } = useDealerGroupProvider();

  return (
    <Confirm
      data={selected}
      onConfirm={deleteApi.execute}
      onClose={pageRouter.updateParams}
      actionName={lang.delete}
      itemName={selected.displayInfo.title}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
