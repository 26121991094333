import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { defaultLimit, defaultOffset, HttpStatus, managementHomeRoute, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import { get } from 'lodash';
import Application from 'models/core/application/Model.Application';
import Category from 'models/core/category/Model.Category';
import Permission from 'models/core/permission/Model.Permission';

enum Actions {
  EDIT = 'edit',
  TOGGLE = 'toggle',
}
export type Verify = Utils.VerifyExtends<Module.Management.Permission.Actions, Utils.ValueOf<typeof Actions>>;
export const permissionIndexRoute = managementHomeRoute + '/permission/:action?/:id?';
export default function PermissionIndex() {
  const { lang } = getLocales();
  const { hasAccess, user, permissions: currentPermissions, isDealerOrDistributor } = useAuthStore();
  const canView = hasAccess(AuthPermission.viewPermission);
  const { query, updateQuery } = usePageRouter<Module.Management.Permission.Params, Module.Management.Permission.Query>(
    { route: permissionIndexRoute }
  );
  const {
    body: filters,
    payload,
    execute: reload,
    reset,
    isExecuting,
  } = useApi({
    action: Permission.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: { ...query.list, userId: user?.id },
    callback: (req) => updateQuery({ ...query, list: req }),
    wait: !canView || isDealerOrDistributor,
  });

  const permissionsApi = useApi({ action: Permission.list });
  const permissions = permissionsApi.payload?.data;
  const applicationsApi = useApi({ action: Application.list });
  const applications = applicationsApi.payload?.data;
  const categoryApi = useApi({ action: Category.list });
  const categories = categoryApi.payload?.data;

  if (!canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <Page
        class={ThemePageList}
        isLoading={isExecuting}
        header={
          !canView ? null : (
            <>
              <b style={{ marginRight: 'auto' }} children={lang.permissions} />
              <SearchInput
                value={filters?.search}
                onSearch={(search) => reload((body) => ({ ...body, search }))}
                threshold={2}
                placeholder={`${lang.search}...`}
              />
              &nbsp;
              <DropdownButton
                buttonMedia={<Icon class="fas-sort" />}
                horizontalPosition="auto_fixed"
                verticalPosition="auto_fixed"
                htmlElementProps={{ title: lang.sort }}
                subcomponents={ThemeDropdownButtonPopup}
                dropdownOptions={
                  <>
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.newerFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: undefined,
                        orderType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.olderFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'id',
                        orderType: OrderType.asc,
                      })}
                    />
                  </>
                }
              />
              <FilterForm
                subcomponents={ThemeFilterFormDrawer}
                filters={filters}
                onFilter={(newFilers) => reload(newFilers)}
                onReset={reset}
                render={({ tempFilters, setFilters }) => (
                  <>
                    <SelectInput
                      name="applicationId"
                      label={lang.applications}
                      data={applications?.map((item) => ({
                        id: item.id,
                        title: item.name,
                        icon: <Icon class="fas-cloud" />,
                      }))}
                      isMultiselect
                      value={tempFilters?.applicationId}
                      onChange={({ applicationId }) => setFilters({ ...tempFilters, applicationId })}
                      icon={<Icon class="fas-cloud" />}
                    />
                    <SelectInput
                      name="categoryId"
                      label={lang.categories}
                      data={categories?.map((item) => ({
                        id: item.id,
                        title: item.name,
                        icon: <Icon class="if-sub-listing" />,
                      }))}
                      isMultiselect
                      value={tempFilters?.categoryId}
                      onChange={({ categoryId }) => setFilters({ ...tempFilters, categoryId })}
                      icon={<Icon class="if-sub-listing" />}
                    />
                  </>
                )}
              />
            </>
          )
        }
        render={
          !canView ? (
            <ErrorPage status={HttpStatus.unauthorized} />
          ) : (
            <>
              <Collection class={ThemeCollectionList}>
                <DataMap
                  data={
                    isDealerOrDistributor ? permissions?.filter((p) => get(currentPermissions, p.key)) : payload?.data
                  }
                  render={({ data: permission }) => (
                    <Row
                      key={permission.id}
                      children={
                        <>
                          <RowImage render={<Icon class="fas-shield-alt" />} />
                          <RowData
                            title={permission.name}
                            info={
                              <>
                                {permission.application?.name}
                                :&nbsp;
                                {permission.category?.name}
                              </>
                            }
                            subtitle={permission.description}
                          />
                        </>
                      }
                    />
                  )}
                />
              </Collection>
            </>
          )
        }
        footer={
          !canView ? null : (
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          )
        }
      />
    </>
  );
}
