import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Dealer.UploadProductDocument.IRequest;
type Response = Api.Core.Dealer.UploadProductDocument.IResponse;
type Headers = Api.Core.Dealer.UploadProductDocument.IHeaders;
const apiDealerUploadProductDocumentPath = '/api/DealerManagement/Dealer/:id/UploadProductDealerDocument/:productId/:productDocumentId';
export default async function dealerUploadProductDocumentApi(params:{ id:number; productId:number; productDocumentId:number; }, request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiDealerUploadProductDocumentPath, params),
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
