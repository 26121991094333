import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import distributorDeleteApi from 'models/sales/distributor/delete/Api.Distributor.Delete';
import Distributor from 'models/sales/distributor/Model.Distributor';
import distributorSaveApi from 'models/sales/distributor/save/Api.Distributor.Save';
import { useMemo } from 'react';
import { distributorIndexRoute } from './Distributor.Index';
import { compact } from 'lodash';

export enum DistributorAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyDistributorAction = Utils.VerifyExtends<
  Module.Claims.Distributor.Actions,
  Utils.ValueOf<typeof DistributorAction>
>;
export default function useDistributor() {
  const { permissions, user } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Distributor.Params, Module.Claims.Distributor.Query>({
    route: distributorIndexRoute,
  });
  const canView = permissions.CREATE_DISTRIBUTOR || permissions.EDIT_DISTRIBUTOR || permissions.DELETE_DISTRIBUTOR;
  const listApi = useApi(
    {
      action: Distributor.list,
      default: { limit: defaultLimit, offset: defaultOffset },
      body: {
        ...pageRouter.query.list,
        id: compact([user?.distributor?.id ?? user?.distributorId]),
      },
      callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
      wait: !canView || !user,
    },
    [user, canView]
  );

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: distributorDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: distributorSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [DistributorAction.DELETE]:
        pageRouter.params.action === DistributorAction.DELETE && selected && permissions.DELETE_DISTRIBUTOR,
      [DistributorAction.EDIT]:
        pageRouter.params.action === DistributorAction.EDIT &&
        ((permissions.CREATE_DISTRIBUTOR && !selected) || (permissions.EDIT_DISTRIBUTOR && selected)),
    },
  };
}

export function useDistributorProvider() {
  return useDataProvider<ReturnType<typeof useDistributor>>();
}
