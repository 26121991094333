import { defaultLanguage, getLanguage } from 'assets/locales/Locale';
import { cleanValues } from 'assets/utils/data/Object';
import { compact, xor } from 'lodash';

export default function Model<T>(props: Model.Extension) {
  const icon = props.icon;
  abstract class Model {
    static icon = icon;
    icon = icon;
    private _mapping: Model.ClassMappings<T>;
    private _superProperties: string[];
    _language: Locale.Languages = defaultLanguage;

    constructor(mapping: Model.ClassMappings<T>, language: Locale.Languages = getLanguage()) {
      this._mapping = mapping;
      this._language = language;
      this._superProperties = Object.getOwnPropertyNames(this);
    }

    update(data: Partial<T> | Utils.FormData<T>) {
      for (const [key, value] of Object.entries(data || {})) {
        if (!Object.getOwnPropertyDescriptor(this, key)?.get) {
          const MappingModel = this._mapping[key as Model.NestedObjectKeys<T>];
          if (MappingModel) {
            if (Array.isArray(MappingModel)) {
              if (Array.isArray(value))
                this[key] = compact(
                  value.map((v) => v && new MappingModel[0](v as any, this._language))?.filter((it) => it)
                );
              else this[key] = [];
            } else {
              this[key] =
                (value && new (MappingModel as unknown as Model.ClassImport<T>)(value as any, this._language)) ||
                undefined;
            }
          } else if (!MappingModel) this[key] = value;
        }
      }
    }
    toJSON(options?: { disableNested?: boolean }) {
      return cleanValues(
        xor(this._superProperties, Object.getOwnPropertyNames(this)).reduce((jsonObj, key) => {
          const val = this._mapping[key]
            ? !options?.disableNested
              ? Array.isArray(this._mapping[key])
                ? Array.isArray(this[key])
                  ? compact(this[key]?.map((v) => v?.toJSON()))
                  : []
                : this[key]?.toJSON()
              : undefined
            : this[key];
          return val === undefined ? jsonObj : { ...jsonObj, [key]: val };
        }, {} as T)
      );
    }
  }
  return Model;
}
