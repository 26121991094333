import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.StatementSummary.List.IRequest;
type Response = Api.Core.StatementSummary.List.IResponse;
type Headers = Api.Core.StatementSummary.List.IHeaders;
const url = '/api/DealerManagement/StatementSummary';
export default async function statementSummaryListApi(body?: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url,
    body,
    headers: getDefaultHeaders(),
  });
}
