import { addLog } from 'assets/components/feedback/Feedback';

export async function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const dataUrl = reader.result?.toString() || '';
      const base64String = dataUrl.split(',')[1] || '';
      resolve(base64String);
    };

    reader.onerror = () => {
      addLog({ error: reader.error?.message });
      reject(reader.error);
    };
  });
}
