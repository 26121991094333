import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import dealerGroupDeleteApi from 'models/dealerManagement/dealerGroup/delete/Api.DealerGroup.Delete';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';
import { useMemo } from 'react';
import { dealerGroupIndexRoute } from './DealerGroup.Index';
import { compact } from 'lodash';

export enum DealerGroupAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyDealerGroupAction = Utils.VerifyExtends<
  Module.Claims.DealerGroup.Actions,
  Utils.ValueOf<typeof DealerGroupAction>
>;
export default function useDealerGroup() {
  const { permissions, user, isDealerOrDistributor } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.DealerGroup.Params, Module.Claims.DealerGroup.Query>({
    route: dealerGroupIndexRoute,
  });
  const canView = permissions?.CREATE_DEALERGROUP || permissions?.EDIT_DEALERGROUP || permissions?.DELETE_DEALERGROUP;
  const dealerGroupIds = compact(user?.dealers?.map((d) => d.dealerGroupId));
  const listApi = useApi(
    {
      action: DealerGroup.list,
      default: { limit: defaultLimit, offset: defaultOffset },
      body: {
        ...pageRouter.query.list,
        distributorId: user?.distributorId ? [user?.distributorId] : undefined,
      },
      callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
      wait: !canView || !user || (isDealerOrDistributor && !dealerGroupIds?.length),
    },
    [canView, user, isDealerOrDistributor]
  );

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: dealerGroupDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    selected,
    permissions,
    views: {
      [DealerGroupAction.DELETE]:
        pageRouter.params.action === DealerGroupAction.DELETE && selected && permissions?.DELETE_DEALERGROUP,
      [DealerGroupAction.EDIT]:
        pageRouter.params.action === DealerGroupAction.EDIT &&
        ((permissions?.CREATE_DEALERGROUP && !selected) || (permissions?.EDIT_DEALERGROUP && selected)),
    },
  };
}

export function useDealerGroupProvider() {
  return useDataProvider<ReturnType<typeof useDealerGroup>>();
}
