import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useClaimType, { ClaimTypeAction } from './ClaimType.Hooks';
import ClaimTypeDelete from './sections/ClaimType.Delete';
import ClaimTypeEdit from './sections/ClaimType.Edit';
import ClaimTypeFilters from './sections/ClaimType.Filters';
import ClaimTypeInfo from './sections/ClaimType.Info';
import ClaimTypeList from './sections/ClaimType.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const claimTypeIndexRoute = claimsHomeRoute + '/claim-type/:action?/:id?';
export default function ClaimTypeIndex() {
  const { lang } = getLocales();
  const claimTypeHook = useClaimType();

  if (!claimTypeHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={claimTypeHook}
        render={({ listApi, views, pageRouter, permissions, deleteApi, softDeleteApi }) =>
          views.info ? (
            <ClaimTypeInfo />
          ) : (
            <Page
              class={ThemePageList}
              isLoading={listApi.isExecuting}
              header={
                <>
                  <b style={{ marginRight: 'auto' }} children={lang.claimTypes} />
                  <ClaimTypeFilters />
                  &nbsp;
                  <Button
                    class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                    active
                    media={<Icon class="fas-plus" />}
                    render={!isMobile && lang.addNew}
                    onClick={pageRouter.updateParams}
                    disabled={!permissions.CREATE_CLAIMTYPE}
                    data={{ action: ClaimTypeAction.EDIT }}
                  />
                </>
              }
              render={
                <>
                  {(views.edit || views.copy) && <ClaimTypeEdit />}
                  {views.delete && (
                    <ClaimTypeDelete actionName={lang.delete} onConfirm={({ id }) => deleteApi.execute(id)} />
                  )}
                  {views.archive && (
                    <ClaimTypeDelete actionName={lang.archive} onConfirm={({ id }) => softDeleteApi.execute(id)} />
                  )}
                  <ClaimTypeList />
                </>
              }
              footer={
                <Pagination
                  class={ThemePaginationRounded}
                  count={listApi.payload?.count}
                  offset={listApi.body?.offset}
                  limit={listApi.body?.limit}
                  onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                  onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
                />
              }
            />
          )
        }
      />
    </>
  );
}
