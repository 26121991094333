import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import Page from 'assets/components/page/Page';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { defaultLimit, defaultOffset, HttpStatus, managementHomeRoute, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import Application from 'models/core/application/Model.Application';
import Permission from 'models/core/permission/Model.Permission';
import roleDeleteApi from 'models/core/role/delete/Api.Role.Delete';
import Role from 'models/core/role/Model.Role';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import RoleEdit from './Role.Edit';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type Verify = Utils.VerifyExtends<Module.Management.Role.Actions, Utils.ValueOf<typeof Actions>>;
export const roleIndexRoute = managementHomeRoute + '/role/:action?/:id?';
export default function RoleIndex() {
  const { lang } = getLocales();
  const { hasAccess, user, isDealerOrDistributor } = useAuthStore();
  const canCreate = hasAccess(AuthPermission.createRole);
  const canEdit = hasAccess(AuthPermission.editRole);
  const canDelete = hasAccess(AuthPermission.deleteRole);
  const canView = canCreate || canEdit || canDelete;

  const permissionsApi = useApi({ action: Permission.list });
  const permissions = permissionsApi.payload?.data;
  const applicationsApi = useApi({ action: Application.list });
  const applications = applicationsApi.payload?.data;

  const { params, query, updateParams, redirect } = usePageRouter<
    Module.Management.Role.Params,
    Module.Management.Role.Query
  >({ route: roleIndexRoute });

  const {
    body: filters,
    payload,
    execute: reload,
    reset,
    isExecuting,
  } = useApi(
    {
      action: Role.list,
      default: { limit: defaultLimit, offset: defaultOffset },
      body: {
        ...query.list,
        userId: isDealerOrDistributor ? user?.id : undefined,
      },
      callback: (req) => redirect({}, { ...query, list: req }),
      wait: !canView || !user,
    },
    [user, canView]
  );

  const deleteApi = useApi({
    action: roleDeleteApi,
    callback: () => {
      reload((body) => body);
    },
    wait: true,
  });

  const selected = useMemo(() => payload?.data?.find((it) => it.id.toString() == params.id), [params.id]);
  if (!canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      {params.action == Actions.EDIT && ((canCreate && !selected) || (canEdit && selected)) && (
        <RoleEdit
          data={selected}
          onCancel={updateParams}
          onSave={() => {
            reload((body) => body);
          }}
        />
      )}
      {params.action == Actions.DELETE && selected && canDelete && (
        <Confirm
          data={selected}
          onConfirm={({ id }) => deleteApi.execute(id)}
          onClose={updateParams}
          actionName={lang.delete}
          itemName={selected.name}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <Page
        class={ThemePageList}
        isLoading={isExecuting}
        header={
          <>
            <b style={{ marginRight: 'auto' }} children={lang.roles} />
            <SearchInput
              value={filters?.search}
              onSearch={(search) => reload((body) => ({ ...body, search }))}
              threshold={2}
              placeholder={`${lang.search}...`}
            />
            &nbsp;
            <DropdownButton
              buttonMedia={<Icon class="fas-sort" />}
              horizontalPosition="auto_fixed"
              verticalPosition="auto_fixed"
              htmlElementProps={{ title: lang.sort }}
              subcomponents={ThemeDropdownButtonPopup}
              dropdownOptions={
                <>
                  <Button
                    class={ThemeButtonMenuItem}
                    render={lang.newerFirst}
                    media={<Icon class="fas-caret-up" />}
                    onClick={reload}
                    data={(currentFilters) => ({
                      ...currentFilters,
                      orderBy: undefined,
                      orderType: undefined,
                    })}
                  />
                  <Button
                    class={ThemeButtonMenuItem}
                    render={lang.olderFirst}
                    media={<Icon class="fas-caret-up" />}
                    onClick={reload}
                    data={(currentFilters) => ({
                      ...currentFilters,
                      orderBy: 'id',
                      orderType: OrderType.asc,
                    })}
                  />
                </>
              }
            />
            &nbsp;
            <FilterForm
              subcomponents={ThemeFilterFormDrawer}
              filters={filters}
              onFilter={(newFilers) => reload(newFilers)}
              onReset={reset}
              render={({ tempFilters, setFilters }) => (
                <>
                  <SelectInput
                    name="applicationId"
                    label={lang.applications}
                    data={applications?.map((item) => ({
                      id: item.id,
                      title: item.name,
                      icon: <Icon class="fas-cloud" />,
                    }))}
                    isMultiselect
                    value={tempFilters?.applicationId}
                    onChange={({ applicationId }) => setFilters({ ...tempFilters, applicationId })}
                    icon={<Icon class="fas-cloud" />}
                  />
                </>
              )}
            />
            &nbsp;
            <Button
              class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
              active
              media={<Icon class="fas-plus" />}
              render={!isMobile && lang.addNew}
              onClick={updateParams}
              data={{ action: Actions.EDIT }}
            />
          </>
        }
        render={
          !canView ? (
            <ErrorPage status={HttpStatus.unauthorized} />
          ) : (
            <>
              <Collection class={ThemeCollectionList}>
                <DataMap
                  data={payload?.data}
                  render={({ data: role }) => (
                    <Row
                      key={role.id}
                      children={
                        <>
                          <RowImage render={<Icon class="fas-user-shield" />} />
                          <RowData
                            info={permissions?.find((p) => p.id == role.permissions[0]?.id)?.application?.name}
                            title={role.name}
                            subtitle={
                              <>
                                {role.description}
                                <br />
                                <br />
                                <DataMap
                                  data={role.permissions.map((rp) => permissions?.find((p) => p.id == rp.id))}
                                  render={({ data: permission, index: permissionIndex }) =>
                                    permission && (
                                      <Box key={permissionIndex} class={ThemeTagRounded} render={permission.name} />
                                    )
                                  }
                                />
                              </>
                            }
                          />
                          <RowActions isVertical>
                            <Button
                              onClick={updateParams}
                              data={{
                                id: role.id.toString(),
                                action: Actions.EDIT,
                              }}
                              disabled={!canEdit}
                              class={ThemeButtonCircle}
                              media={<Icon class="fas-edit" />}
                              htmlElementProps={{ title: lang.edit }}
                            />
                            <Button
                              onClick={updateParams}
                              data={{
                                id: role.id.toString(),
                                action: Actions.DELETE,
                              }}
                              disabled={!canDelete}
                              class={ThemeButtonCircle}
                              media={<Icon class="fas-trash" />}
                              htmlElementProps={{ title: lang.delete }}
                            />
                          </RowActions>
                        </>
                      }
                    />
                  )}
                />
              </Collection>
            </>
          )
        }
        footer={
          !canView ? null : (
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          )
        }
      />
    </>
  );
}
