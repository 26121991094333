import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import { date, defaultInputDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { useStatementSummaryProvider } from '../StatementSummary.Hooks';

export default function StatementSummaryCreate() {
  const { lang } = getLocales();
  const { createApi, pageRouter } = useStatementSummaryProvider();
  const { payload: dealerGroupPayload } = useApi({
    action: DealerGroup.list,
  });

  return (
    <>
      <ModalForm<Api.Core.StatementSummary.CreateForDealerGroup.IRequest>
        subcomponents={ThemeModalFormDefault}
        title={lang.statementSummary}
        default={{}}
        onCancel={pageRouter.updateParams}
        onSubmit={createApi.execute}
        validation={(data, errors) => {
          if (!data.dealerGroupId) errors.dealerGroupId = lang.mustNotBeEmpty;
          if (!data.from) errors.from = lang.mustNotBeEmpty;
          if (!data.to) errors.to = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <SelectInput
                name="dealerGroupId"
                label={lang.dealerGroup}
                data={dealerGroupPayload?.data?.map((it) => ({
                  ...it.displayInfo,
                  id: it.id,
                }))}
                value={form.data?.dealerGroupId}
                error={form.errors.dealerGroupId}
                onChange={({ dealerGroupId }) => {
                  form.update({
                    dealerGroupId,
                  });
                }}
                icon={<Icon class="if-cloud" />}
              />
              <DateInput
                dateTimeFormat={defaultInputDateTimeFormat}
                name="from"
                value={form.data?.from}
                error={form.errors.from}
                label={lang.date}
                max={date().endOf('day').add(-1, 'second').tz('UTC').format(defaultServerDateTimeFormat)}
                onChange={({ from }) => {
                  form.update({
                    from,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                dateTimeFormat={defaultInputDateTimeFormat}
                name="to"
                value={form.data?.to}
                error={form.errors.to}
                label={lang.date}
                min={form.data.from}
                max={date().endOf('day').add(-1, 'second').tz('UTC').format(defaultServerDateTimeFormat)}
                onChange={({ to }) => {
                  form.update({
                    to,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
