import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

export const defaultDateFormat = 'DD/MMM/YYYY';
export const defaultTimeFormat = 'HH:mm';
export const defaultDateTimeFormat = 'DD/MMM/YYYY HH:mm';
export const defaultInputDateFormat = 'DD/MM/YYYY';
export const defaultInputTimeFormat = 'HH:mm';
export const defaultInputDateTimeFormat = 'DD/MM/YYYY HH:mm';
export const defaultServerDateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const defaultServerDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const daysOfWeek: Utils.Date.DayOfWeek[] = [
  { id: 0, name: 'monday', abbreviation: 'mon' },
  { id: 1, name: 'tuesday', abbreviation: 'tue' },
  { id: 2, name: 'wednesday', abbreviation: 'wed' },
  { id: 3, name: 'thursday', abbreviation: 'thu' },
  { id: 4, name: 'friday', abbreviation: 'fri' },
  { id: 5, name: 'saturday', abbreviation: 'sat' },
  { id: 6, name: 'sunday', abbreviation: 'sun' },
];
export const monthsInYear: Utils.Date.MonthInYear[] = [
  { id: 1, name: 'january', abbreviation: 'jan' },
  { id: 2, name: 'february', abbreviation: 'feb' },
  { id: 3, name: 'march', abbreviation: 'mar' },
  { id: 4, name: 'april', abbreviation: 'apr' },
  { id: 5, name: 'may', abbreviation: 'may' },
  { id: 6, name: 'june', abbreviation: 'jun' },
  { id: 7, name: 'july', abbreviation: 'jul' },
  { id: 8, name: 'august', abbreviation: 'aug' },
  { id: 9, name: 'september', abbreviation: 'sep' },
  { id: 10, name: 'october', abbreviation: 'oct' },
  { id: 11, name: 'november', abbreviation: 'nov' },
  { id: 12, name: 'december', abbreviation: 'dec' },
];

export const date = (date?: string | number | dayjs.Dayjs | Date, format?: dayjs.OptionType, strict?: boolean) => {
  const d = !date
    ? dayjs(date, format, strict)
    : dayjs(date, format, strict).utc(true).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return d.isValid() ? d : dayjs();
};
export const dayOfWeek = (date: dayjs.Dayjs) => (parseInt(date.format('d')) + 6) % 7;

export function formatDateRange({ from, until, format }: { from: string; until: string; format?: string }): string {
  if (!from || !until) return '';

  const fromDate = dayjs(from, defaultDateFormat);
  const untilDate = dayjs(until, defaultDateFormat);
  let finalString = '';
  if (untilDate.diff(fromDate, 'days') == 0) {
    finalString = `${fromDate.format(format || defaultDateFormat)}, ${fromDate.format(
      defaultTimeFormat
    )} - ${untilDate.format(defaultTimeFormat)}`;
  } else {
    finalString = `${fromDate.format(format || defaultDateTimeFormat)} - ${untilDate.format(
      format || defaultDateTimeFormat
    )}`;
  }
  return finalString;
}
