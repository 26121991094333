import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import { useEffect, useMemo } from 'react';
import ClaimDocumentPreview from '../../claim/other/Claim.DocumentPreview';
import { useClaimEdit } from '../ClaimEdit.Hooks';
import Theme from 'assets/themes/Theme.Common';
import claimInvoiceSaveApi from 'models/claims/claimInvoice/save/Api.ClaimInvoice.Save';
import claimInvoiceDeleteApi from 'models/claims/claimInvoice/delete/Api.ClaimInvoice.Delete';

export default function ClaimEditInvoices() {
  const { lang } = getLocales();
  const { reload, claim, isClaimAdmin, isClaimOwner, isEditable, isClaimInvoiceEditableByStatus } = useClaimEdit();
  const docForm = useForm<Utils.FormData<Model.IClaimInvoice>>({
    default: { claimId: claim?.id },
    onSubmit: async (doc) => {
      await claimInvoiceSaveApi({ ...doc });
      docForm.reload();
      reload();
    },
    validation: (data, errors) => {
      if (!data.formFile) errors.formFile = lang.required;
    },
  });

  useEffect(() => {
    if (docForm?.data?.claimId !== claim?.id) {
      docForm.set({ claimId: claim?.id });
    }
  }, [claim?.id]);

  const onClaimInvoiceDelete = async (invoiceId: number) => {
    await claimInvoiceDeleteApi({ id: claim?.id, invoiceId });
    reload();
  };

  const formFile = useMemo(
    () =>
      docForm.data.formFile && {
        original: docForm.data.formFile,
        type: docForm.data.formFile.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(docForm.data.formFile),
      },
    [docForm.data.formFile]
  );

  const isClaimAdminOrOwner = isClaimAdmin || (isClaimOwner && isEditable);
  const isDisabled = isClaimInvoiceEditableByStatus || docForm.hasErrors || docForm.isSubmitted || !docForm.isUpdated;

  return (
    <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
      <h2>{lang.invoices}</h2>
      <ClaimDocumentPreview
        documents={claim?.claimInvoices?.map((it) => ({
          id: it.id,
          url: it.mediaFile.url,
          name: it.name,
          extension: it.mediaFile?.extension,
          description: it.notes,
          info: lang.uploaded,
          onDelete: () => onClaimInvoiceDelete(it.id),
        }))}
        isDisabled={isClaimInvoiceEditableByStatus || !isClaimAdminOrOwner}
      />
      <br />
      <FileInput
        name="file"
        fileLimit={1}
        value={formFile ? [formFile] : []}
        onChange={({ file }) => {
          const formFile = file && file[0]?.original;
          docForm.update({ formFile });
        }}
        class={ThemeFileInputDropzone}
        error={docForm.errors.formFile?._objectError}
        label={lang.documentUpload}
        disabled={isClaimInvoiceEditableByStatus || !isClaimAdminOrOwner}
        renderAfter={
          <>
            <br />
            <TextInput
              name="name"
              label={lang.number}
              value={docForm.data.name}
              onChange={docForm.update}
              error={docForm.errors.name}
              disabled={isDisabled}
            />
            <br />
            <MultilineTextInput
              name="notes"
              label={lang.notes}
              value={docForm.data.notes}
              onChange={docForm.update}
              error={docForm.errors.notes}
              disabled={isDisabled}
            />
          </>
        }
      />
      <br />
      <Button
        render={lang.add}
        media={<Icon class="fas-upload" />}
        onClick={docForm.submit}
        disabled={isDisabled}
        active={!docForm.hasErrors}
        class={ThemeButtonCircle}
        style={{ marginLeft: 'auto' }}
      />
    </div>
  );
}
