import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import usePayee, { PayeeAction } from './Payee.Hooks';
import PayeeDelete from './sections/Payee.Delete';
import PayeeEdit from './sections/Payee.Edit';
import PayeeFilters from './sections/Payee.Filters';
import PayeeList from './sections/Payee.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const payeeIndexRoute = claimsHomeRoute + '/payee/:action?/:id?';
export default function PayeeIndex() {
  const { lang } = getLocales();
  const payeeHook = usePayee();
  const { permissions } = useAuthStore();

  if (!payeeHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={payeeHook}
        render={({ listApi, views, pageRouter }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.payees} />
                <PayeeFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_PAYEE}
                  data={{ action: PayeeAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <PayeeEdit />}
                {views.delete && <PayeeDelete />}
                <PayeeList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
