import { getAuthToken } from './Auth.Config';

export const envUrlMappings = {
  localhost: 'autoshieldweb-dev.azurewebsites.net',
  'autoshield-dev.azurewebsites.net': 'autoshieldweb-dev.azurewebsites.net',
  'autoshield-test.azurewebsites.net': 'autoshieldweb-test.azurewebsites.net',
  'autoshield-staging.azurewebsites.net': 'autoshieldweb-staging.azurewebsites.net',
  'autoshield.azurewebsites.net': 'autoshieldweb.azurewebsites.net',
  'autoshield.app': 'autoshieldweb.azurewebsites.net',
};
export const defaultOffset = 0;
export const defaultLimit = 10;
export const defaultWithDeleted = false;
export const homeRoute = '/';
export const claimRealtimeViewersRoute = 'IClaimViewers';
export const managementHomeRoute = '/management';
export const claimsHomeRoute = '/claims';
export const publicHomeRoute = '/public';
export const salesHomeRoute = '/sales';
export const productManagementHomeRoute = '/productManagement';
export const dealerManagementHomeRoute = '/dealerManagement';

export enum ApiHeaderApplication {
  management = 1,
  claims = 2,
  public = 3,
  sales = 5,
  productManagement = 7,
  dealerManagement = 8,
}

export const ApplicationSettings = {
  [ApiHeaderApplication.management]: { path: managementHomeRoute, icon: 'fas-cogs', name: 'Management' },
  [ApiHeaderApplication.claims]: { path: claimsHomeRoute, icon: 'fas-car-crash', name: 'Claims' },
  [ApiHeaderApplication.public]: { path: publicHomeRoute, icon: 'fas-cloud', name: 'Public' },
  [ApiHeaderApplication.sales]: { path: salesHomeRoute, icon: 'fas-chart-area', name: 'Sales' },
  [ApiHeaderApplication.productManagement]: {
    path: productManagementHomeRoute,
    icon: 'fas-clipboard-list',
    name: 'Product management',
  },
  [ApiHeaderApplication.dealerManagement]: {
    path: dealerManagementHomeRoute,
    icon: 'fas-users',
    name: 'Dealer management',
  },
};

export type VerifyApplication = Utils.VerifyExtends<
  Api.Headers.Application,
  Utils.ValueOf<typeof ApiHeaderApplication>
>;

export enum HttpStatus {
  ok = 200,
  badRequest = 400,
  unauthorized = 401,
  notFound = 404,
  internalServerError = 500,
}
export type VerifyHttpStatus = Utils.VerifyExtends<Api.HttpStatus, Utils.ValueOf<typeof HttpStatus>>;

export enum AcceptType {
  JSON = 'application/json',
  HTML = 'text/html',
  XML = 'application/xml',
}
export type VerifyAcceptType = Utils.VerifyExtends<Api.AcceptType, Utils.ValueOf<typeof AcceptType>>;

export enum ContentType {
  JSON = 'application/json; charset=utf-8',
  MultipartForm = 'multipart/form-data; charset=utf-8',
  Text = 'text/plain; charset=utf-8',
}
export type VerifyContentType = Utils.VerifyExtends<Api.ContentType, Utils.ValueOf<typeof ContentType>>;

export enum HttpMethod {
  post = 'POST',
  get = 'GET',
  delete = 'DELETE',
  put = 'PUT',
}
export type VerifyHttpMethod = Utils.VerifyExtends<Api.HttpMethod, Utils.ValueOf<typeof HttpMethod>>;

export enum OrderType {
  asc = 'asc',
  desc = 'desc',
}
export type VerifyOrderType = Utils.VerifyExtends<Api.OrderType, Utils.ValueOf<typeof OrderType>>;

export function getCurrentApp() {
  return Object.entries(ApiHeaderApplication).reduce(
    (prev, [key, val]) => (document.location.pathname.indexOf(key) >= 0 ? (val as any) : prev),
    ApiHeaderApplication.management
  );
}
export function getDefaultHeaders() {
  const token = getAuthToken();
  const currentApp = getCurrentApp();
  const h: Api.Headers.IDefault = {
    'Current-Application': currentApp,
  };
  if (token) h.Authorization = `Bearer ${token}`;
  return h;
}
