import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import emailTemplateDeleteApi from 'models/core/emailTemplate/delete/Api.EmailTemplate.Delete';
import EmailTemplate from 'models/core/emailTemplate/Model.EmailTemplate';
import emailTemplateSaveApi from 'models/core/emailTemplate/save/Api.EmailTemplate.Save';
import { useMemo } from 'react';
import { emailTemplateIndexRoute } from './EmailTemplate.Index';

export enum EmailTemplateAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyEmailTemplateAction = Utils.VerifyExtends<
  Module.Claims.EmailTemplate.Actions,
  Utils.ValueOf<typeof EmailTemplateAction>
>;
export default function useEmailTemplate() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.EmailTemplate.Params, Module.Claims.EmailTemplate.Query>({
    route: emailTemplateIndexRoute,
  });

  const canView =
    permissions.CREATE_EMAILTEMPLATE || permissions.EDIT_EMAILTEMPLATE || permissions.DELETE_EMAILTEMPLATE;
  const listApi = useApi({
    action: EmailTemplate.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const deleteApi = useApi({
    action: emailTemplateDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: emailTemplateSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  return {
    canView,
    pageRouter,
    listApi,
    saveApi,
    deleteApi,
    selected,
    permissions,
    views: {
      [EmailTemplateAction.DELETE]: !!(
        pageRouter.params.action === EmailTemplateAction.DELETE &&
        selected &&
        permissions.DELETE_EMAILTEMPLATE
      ),
      [EmailTemplateAction.EDIT]: !!(
        pageRouter.params.action === EmailTemplateAction.EDIT &&
        ((permissions.CREATE_EMAILTEMPLATE && !selected) || (permissions.EDIT_EMAILTEMPLATE && selected))
      ),
    },
  };
}

export function useEmailTemplateProvider() {
  return useDataProvider<ReturnType<typeof useEmailTemplate>>();
}
