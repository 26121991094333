import Model from 'models/Model';
import payeeTypeDeleteApi from './delete/Api.PayeeType.Delete';
import payeeTypeListApi from './list/Api.PayeeType.List';
import payeeTypeSaveApi from './save/Api.PayeeType.Save';

export default class PayeeType
  extends Model<Model.IPayeeType>({ icon: 'fas-coins' })
  implements Model.Instance<Model.IPayeeType>
{
  static CLIENT_ID = 1;
  static DEALER_ID = 2;
  static OTHER_ID = 3;
  static REPAIR_FACILITY_ID = 4;

  id: number;
  name: string;
  code?: string;

  constructor(data: Partial<Model.IPayeeType> | Utils.FormData<Model.IPayeeType>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      media: this.icon,
      title: this.name || this.id.toString(),
    };
  }
  static async list(body?: Api.Core.PayeeType.List.IRequest) {
    const { payload, ...rest } = await payeeTypeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new PayeeType(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await payeeTypeSaveApi(this);
  }
  async delete() {
    return await payeeTypeDeleteApi(this.id);
  }
}
