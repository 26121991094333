import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { first } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import PurchaseState from 'models/enums/PurchaseState';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import { useEffect } from 'react';
import { useSalesQuoteProvider } from '../Sales.Quote.Hooks';

export default function SalesQuoteVehicle() {
  const { lang } = getLocales();
  const { form, updateVehicle } = useSalesQuoteProvider();
  const { user } = useAuthStore();
  const { payload: vehicleMakesPayload } = useApi({ action: VehicleMake.list });
  const { payload: vehicleModelPayload } = useApi({ action: VehicleModel.list });

  const vehicle = form?.data?.vehicle;
  const errors = form?.errors?.vehicle;
  const product = form?.data?.product;

  const { payload: dealersPayload } = useApi({
    action: Dealer.list,
    body: {
      id: user?.dealers?.map((d) => d.id) ?? undefined,
      distributorId: !user?.dealers?.length && user?.distributorId ? [user?.distributorId] : undefined,
    },
  });

  useEffect(() => {
    if (dealersPayload?.data?.length === 1 && !form.data.products?.length) {
      form.update({
        dealer: first(dealersPayload.data)?.toJSON() || null,
        products: [],
      });
    }
  }, [dealersPayload?.data]);

  useEffect(() => {
    async function getAndSetVehicleInfo() {
      if (vehicle?.makeCode && vehicle?.model) {
        const vehicleModels = await VehicleModel.list({
          makeCode: vehicle.makeCode,
          modelCode: vehicle.model,
        });
        const vehicleModel = first(vehicleModels.payload?.data || [])?.toJSON();
        form.update({
          vehicle: {
            ...vehicle,
            vehicleModel,
          },
        });
      }
    }
    getAndSetVehicleInfo();
  }, [vehicle?.makeCode && vehicle?.model]);

  const isPurchaseDetailsVisible =
    product?.isPurchaseDetailsVisible || form.data.dealer?.products?.some((p) => p.isPurchaseDetailsVisible);

  return (
    <FormWrapper style={{ minHeight: '360px' }}>
      <SelectInput
        name="dealerId"
        label={lang.dealer}
        data={dealersPayload?.data?.map((it) => ({
          ...it.displayInfo,
          id: it.id,
        }))}
        value={form.data?.dealer?.id}
        error={form.errors.dealer?._objectError}
        onChange={({ dealerId }) => {
          form.update({
            dealer: dealersPayload?.data?.find((it) => it.id === dealerId)?.toJSON() || null,
            products: [],
          });
        }}
        icon={<Icon class="if-cloud" />}
      />
      <SelectInput
        name="makeCode"
        label={lang.makeCode}
        data={
          vehicleMakesPayload &&
          Object.values(
            vehicleMakesPayload.data.map((vehicleMake) => ({
              id: vehicleMake.makeCode,
              title: vehicleMake.displayInfo.title,
            }))
          )
        }
        value={vehicle?.makeCode}
        error={errors?.makeCode}
        onChange={updateVehicle}
        icon={<Icon class="if-listing-box" />}
      />
      {vehicle?.makeCode && (
        <SelectInput
          name="model"
          label={lang.model}
          data={
            vehicleModelPayload &&
            Object.values(
              vehicleModelPayload.data
                ?.filter((vehicleModel) => vehicleModel.vehicleMake.makeCode.toString().includes(vehicle?.makeCode))
                .map((vehicleModel) => ({
                  id: vehicleModel.code,
                  title: vehicleModel.displayInfo.title,
                }))
            )
          }
          value={vehicle?.model}
          error={errors?.model}
          onChange={updateVehicle}
          icon={<Icon class="if-listing-box" />}
        />
      )}
      {vehicle?.model && (
        <NumberInput
          name="posOdometerReading"
          label={lang.odometerReading}
          value={vehicle?.posOdometerReading}
          error={errors?.posOdometerReading}
          onChange={updateVehicle}
        />
      )}
      {isPurchaseDetailsVisible && vehicle?.model && (
        <>
          <NumberInput
            name="purchasePrice"
            label={lang.purchasePrice}
            value={vehicle?.purchasePrice}
            error={errors?.purchasePrice}
            step={0.01}
            onChange={updateVehicle}
          />
          <SelectInput
            name="purchaseState"
            label={lang.purchaseState}
            data={Object.values(PurchaseState)
              .filter((purchaseState) => purchaseState !== PurchaseState.USED)
              .map((purchaseState) => ({
                id: purchaseState,
                title: lang[purchaseState.toLowerCase() === 'pre-owned' ? 'preOwned' : purchaseState.toLowerCase()],
              }))}
            value={vehicle?.purchaseState}
            error={errors?.purchaseState}
            onChange={updateVehicle}
          />
        </>
      )}
    </FormWrapper>
  );
}
