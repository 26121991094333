enum DataSize {
  byte = 'Bytes',
  kiloByte = 'KB',
  megaByte = 'MB',
  gigaByte = 'GB',
  teraByte = 'TB',
  petaByte = 'PB',
  exaByte = 'EB',
  zettaByte = 'ZB',
  yottaByte = 'YB',
}
export default DataSize;
export type Verify = Utils.VerifyExtends<Utils.Units.DataSize, Utils.ValueOf<typeof DataSize>>;
