import Button from 'assets/components/button/Button';
import DataMap from 'assets/components/dataMap/DataMap';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon from 'assets/components/icon/Icon';
import Modal from 'assets/components/page/templates/Modal';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React, { useContext, useState } from 'react';
import useForm from '../hooks/Form';

let ModalFormContext: React.Context<any>;

export function useModalForm<T>(): Template.ModalForm.Export<T> {
  return useContext(ModalFormContext);
}
export default function ModalForm<T>(props: Template.ModalForm.Import<T>) {
  const { lang } = getLocales();

  const currentTab = props.tabs?.length ? props.tabs[0] : undefined;
  const [activeTab, setActiveTab] = useState<Template.ModalForm.Tab<T> | undefined>(currentTab);

  const form = useForm<T>(
    {
      default: props.default,
      onSubmit: props.onSubmit,
      beforeUpdate: !props.beforeUpdate ? undefined : (data, updates) => props.beforeUpdate(data, updates, activeTab),
      validation: props.validation,
    },
    props.dependencyArray
  );
  const exportData: Template.ModalForm.Export<T> = { form, activeTab };
  const disabled = typeof props.disabled === 'function' ? props.disabled(exportData) : props.disabled;
  if (!ModalFormContext) ModalFormContext = React.createContext<Template.ModalForm.Export<T>>(exportData);

  return (
    <ModalFormContext.Provider value={exportData}>
      <Modal
        subcomponents={props.subcomponents?.modal}
        onClose={props.onCancel}
        neverClose={props.neverClose}
        header={props.title}
        sidebar={props.sidebar}
        subHeader={
          props.tabs && (
            <DataMap
              data={props.tabs}
              render={({ data: tab }) => {
                const errors = typeof tab.errors === 'function' ? tab.errors(exportData) : tab.errors;
                return (
                  <Button
                    {...props.subcomponents?.tab}
                    key={tab.id}
                    data={tab}
                    onClick={setActiveTab}
                    active={activeTab?.id === tab.id}
                    style={
                      errors
                        ? {
                            color: Theme.Color.error,
                            fontWeight: 'bold',
                          }
                        : null
                    }
                    htmlElementProps={{
                      title: errors,
                    }}
                    media={errors ? <Icon class="fas-exclamation-triangle" /> : tab.icon && <Icon class={tab.icon} />}
                    disabled={typeof tab.disabled === 'function' ? tab.disabled(exportData) : tab.disabled}
                    render={tab.text}
                  />
                );
              }}
            />
          )
        }
        render={RenderProps(props, 'render', exportData)}
        footer={
          RenderProps(props, 'footer', exportData) || (
            <>
              {RenderProps(props, 'footerButtons', exportData)}
              {props.onCancel && !props.neverClose && (
                <Button
                  {...(props.subcomponents && props.subcomponents.cancelButton)}
                  onClick={props.onCancel}
                  render={lang.cancel}
                  htmlElementProps={{
                    title: lang.cancel,
                  }}
                />
              )}
              &nbsp;
              <Button
                {...(props.subcomponents && props.subcomponents.submitButton)}
                onClick={form.submit}
                render={props.submitButtonText || lang.submit}
                disabled={disabled || form.hasErrors || form.isSubmitted}
                active={!form.hasErrors}
                isLoading={form.isSubmitted}
                htmlElementProps={{
                  title: props.submitButtonText || lang.submit,
                }}
              />
              {RenderProps(props, 'footerButtonsRight', exportData)}
            </>
          )
        }
      />
    </ModalFormContext.Provider>
  );
}
