import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { ascInsuranceLogo } from 'assets/themes/assets/Images';
import Theme from 'assets/themes/Theme.Common';
import { HttpStatus, publicHomeRoute } from 'config/Api.Config';
import publicProcessContractConsentApi from 'models/public/processContractConsent/Api.ProcessContractConsent';
import { useEffect, useState } from 'react';

const flexContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const processContractConsentRoute = publicHomeRoute + '/processContractConsent';
export default function ProcessContractConsentIndex() {
  const { query } = usePageRouter<
    undefined,
    Api.Core.Public.ProcessContractConsent.IRequest & { access_token: string }
  >({
    route: processContractConsentRoute,
  });
  const { lang } = getLocales();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>(undefined);

  useEffect(() => {
    (async () => {
      if (query) {
        const { access_token, ...rest } = query;
        const res = await publicProcessContractConsentApi({ body: { ...rest }, access_token });
        if (res.status !== HttpStatus.ok) {
          setMessage(lang.youAlreadySubmittedYourResponseForThisConsent);
          setIsLoading(false);
        } else {
          setMessage(lang.thankYouForYourResponse);
          setIsLoading(false);
        }
      }
    })();
  }, [query]);

  return (
    <Page
      class={ThemePageStatic2}
      style={{
        pageContent: {
          ...flexContainer,
          borderRadius: '10px',
          marginTop: 'auto',
          height: '50%',
        },
        pageWrapper: {
          ...flexContainer,
          height: '100%',
        },
      }}
      isLoading={isLoading}
      render={
        <div
          style={{
            ...flexContainer,
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            gap: '50px',
          }}
        >
          <div style={{ ...flexContainer, flexDirection: 'column', gap: '20px' }}>
            <img width={400} height="auto" src={ascInsuranceLogo} style={{ alignSelf: 'center' }} />
            <p style={{ fontSize: Theme.FontSize.L }}>{lang.welcomeToAutoshieldCanada}!</p>
          </div>
          <>
            <p>Dear customer,</p>
            <p
              style={{
                color: Theme.Color.success,
              }}
            >
              {message}
            </p>
          </>
        </div>
      }
    />
  );
}
