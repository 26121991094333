import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales, Language } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { get } from 'lodash';
import Permission from 'models/core/permission/Model.Permission';
import callApiRoleSave from 'models/core/role/save/Api.Role.Save';

export default function RoleEdit(
  props: Template.ModalForm.MinimalImport<
    Model.IRole,
    Api.Core.Role.Save.IRequest,
    Api.Res<Api.Core.Role.Save.IResponse>
  >
) {
  const { lang } = getLocales();
  const { permissions: currentUserPermissions } = useAuthStore();
  const save = useApi({ action: callApiRoleSave, callback: props.onSave, wait: true });

  const permissionsApi = useApi({ action: Permission.list });
  const permissions = permissionsApi.payload?.data?.filter((it) => get(currentUserPermissions, it.key));

  return (
    <>
      <ModalForm<Api.Core.Role.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[props.data]}
        title={lang.role}
        default={{
          ...props.data,
          translations: props.data?.translations || [],
          permissions: props.data?.permissions || [],
        }}
        onCancel={props.onCancel}
        onSubmit={(data) => save.execute(data)}
        validation={(data, errors) => {
          if (!data.translations?.find((it) => it.languageIsoCode === Language.english)?.name)
            errors.name = lang.mustNotBeEmpty;
          if (!data.permissions?.length) errors.permissions = { _objectError: lang.mustSelectAtleastOneOption };
        }}
        beforeUpdate={(data, updates, activeTab) => {
          if (updates.name || updates.description) {
            if (!data.translations) data.translations = [];
            const translation = data.translations?.find((it) => it.languageIsoCode == activeTab.id);
            if (translation && updates.name) translation.name = data.name;
            else if (translation && updates.description) translation.description = data.description;
            else
              data.translations.push({ name: data.name, description: data.description, languageIsoCode: activeTab.id });
            delete data.name;
            delete data.description;
            return data;
          } else return data;
        }}
        tabs={[
          { id: Language.english, text: lang.en, icon: 'fas-globe' },
          { id: Language.french, text: lang.fr, icon: 'fas-globe' },
        ]}
        render={({ form, activeTab }) => (
          <>
            <FormWrapper>
              <TextInput<Utils.ReadonlyKeys<Model.IRole>>
                name="name"
                label={lang.name}
                value={form.data.translations?.find((it) => it.languageIsoCode === activeTab.id)?.name}
                onChange={form.update}
                error={activeTab.id === Language.english ? form.errors?.name : null}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="description"
                label={lang.description}
                error={form.errors?.description}
                value={form.data.translations?.find((it) => it.languageIsoCode === activeTab.id)?.description}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="permissions"
                label={lang.permissions}
                error={form.errors?.permissions?._objectError}
                data={permissions?.map((item) => ({
                  id: item.id,
                  title: item.name,
                  subtitle: item.application?.name,
                  icon: item.icon,
                }))}
                isMultiselect
                value={form.data.permissions?.map((it) => it.id)}
                onChange={({ permissions: idList }) => form.update({ permissions: idList.map((id) => ({ id })) })}
                icon={<Icon class="if-cloud" />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
