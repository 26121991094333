import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useVehicleClass, { VehicleClassAction } from './VehicleClass.Hooks';
import VehicleClassDelete from './sections/VehicleClass.Delete';
import VehicleClassEdit from './sections/VehicleClass.Edit';
import VehicleClassFilters from './sections/VehicleClass.Filters';
import VehicleClassList from './sections/VehicleClass.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import VehicleClassCopy from './sections/VehicleClass.Copy';
import VehicleClassPrint from './sections/VehicleClass.Print';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
  PRINT = 'print',
}
export type Verify = Utils.VerifyExtends<Module.ProductManagement.VehicleClass.Actions, Utils.ValueOf<typeof Actions>>;
export const vehicleClassIndexRoute = productManagementHomeRoute + '/vehicleClass/:action?/:id?';
export default function VehicleClassIndex() {
  const { lang } = getLocales();
  const vehicleClassHook = useVehicleClass();

  if (!vehicleClassHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={vehicleClassHook}
        render={({ listApi, views, pageRouter, permissions }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.vehicleClass} />
                <VehicleClassFilters />
                &nbsp;
                <Button
                  class={ThemeButtonCircle}
                  media={<Icon class="fas-print" />}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_VEHICLECLASS}
                  data={{ action: VehicleClassAction.PRINT }}
                  htmlElementProps={{ title: lang.print }}
                />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_VEHICLECLASS}
                  data={{ action: VehicleClassAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <VehicleClassEdit />}
                {views.delete && <VehicleClassDelete />}
                {views.copy && <VehicleClassCopy />}
                {views.print && <VehicleClassPrint />}
                <VehicleClassList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
