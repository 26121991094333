import React from 'react';
import { useTheme } from '../../hooks/theme/useTheme';
import { getLocales } from 'assets/locales/Locale';
import { date } from 'assets/utils/data/Date';

export enum FeedbackStructure {
  feedback = 'feedback',
}
export type Verify = Utils.VerifyExtends<Component.Feedback.Structure, typeof FeedbackStructure>;
export default function Feedback(props: Component.Feedback.Import) {
  const extend = useTheme(FeedbackStructure, props);

  return <div {...extend(FeedbackStructure.feedback, true)} />;
}

export function addLog(logResponse: Component.Feedback.LogResponse) {
  const feedbackWindow = document.querySelector(`.${FeedbackStructure.feedback}`);
  const { translate } = getLocales();
  if (feedbackWindow) {
    if (feedbackWindow.childElementCount > 3 && feedbackWindow.firstChild)
      feedbackWindow.removeChild(feedbackWindow.firstChild);

    const element = document.createElement('div');
    if (logResponse.feedback) element.dataset.log = logResponse.feedback;
    element.dataset.type = logResponse.error ? 'error' : logResponse.warning ? 'warning' : 'success';
    element.dataset.time = date().format('HH:mm');
    element.innerHTML = translate(`${logResponse.error || logResponse.warning || logResponse.success || ''}`);
    element.className = 'feedbackLog';

    feedbackWindow.prepend(element);

    const feedbackLogTimeout: NodeJS.Timeout = setTimeout(() => {
      if (element) element.remove();
    }, 10000);
    element.addEventListener('click', () => {
      if (feedbackLogTimeout) clearTimeout(feedbackLogTimeout);
      if (element) element.remove();
    });
  }
}
