import React, { useContext } from 'react';
import RenderProps from 'assets/components/helpers/RenderProps';

let DataStoreContext: React.Context<any>;

export function DataProvider<T>(props: Provider.Data.ProviderImport<T>) {
  if (!DataStoreContext) DataStoreContext = React.createContext<T>(props.data);
  return (
    <>
      <DataStoreContext.Provider value={props.data}>
        {RenderProps(props, 'render', props.data)}
      </DataStoreContext.Provider>
    </>
  );
}

export function useDataProvider<T>() {
  return useContext(DataStoreContext) as T;
}
export function useDataProviderPartition<T>(sectionName: string) {
  const dataProvider = useContext(DataStoreContext);
  if (dataProvider && dataProvider[sectionName]) return dataProvider[sectionName] as T;
  else return {} as T;
}
