import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { defaultLanguage, getLanguage, getLocales } from 'assets/locales/Locale';
import React, { useMemo } from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';

const InputColStyle: React.CSSProperties = {
  width: 'unset',
  textAlign: 'right',
};
const InputStyle: React.CSSProperties = {
  textAlign: 'right',
};
const FormInputStyle: React.CSSProperties = {
  minWidth: '120px',
};
export default function ClaimEditJobIndemityCalc() {
  const currentLanguage = getLanguage();
  const { claimTypeReport, form } = useClaimJobEdit();
  const { lang } = getLocales();
  const job = form.data;

  const report = useMemo(() => {
    const firstReport = job?.claimJobReports && job?.claimJobReports[0];
    return claimTypeReport?.buildReportObject(firstReport?.productReport || {});
  }, [job, claimTypeReport]);

  function updateReport(data: any) {
    report.update(data);
    if (job?.claimJobReports && claimTypeReport) {
      job.claimJobReports = [
        {
          productReport: report.toJSON(),
          claimTypeReportSettingId: claimTypeReport?.id,
        },
      ];
    }
    form.update({ ...form.data });
  }
  return (
    <table className={ThemeTableSimple}>
      <thead>
        <tr>
          <th>{claimTypeReport?.displayInfo?.title || lang.report}</th>
          <th colSpan={2} style={InputColStyle}>
            {lang.claimIndemnityCalculation}
          </th>
        </tr>
      </thead>
      <tbody>
        <DataMap
          data={claimTypeReport?.reportRows}
          render={({ data: row, index }) => (
            <tr key={index} data-active={row.isHighlighted ? true : undefined}>
              <td>
                {row.label && (
                  <>
                    <b>{row.label}</b>&nbsp;
                  </>
                )}
                {row.name[currentLanguage] || row.name[defaultLanguage]}
              </td>
              <td>
                {row.totalAmountOperation && (
                  <>
                    <InputWrapper>
                      <NumberInput
                        name={row.primaryKey}
                        value={report[row.primaryKey]}
                        onChange={updateReport}
                        step={0.01}
                        style={{ input: InputStyle, formInput: FormInputStyle }}
                        readOnly={row.isReadOnly}
                      />
                      <NumberInput
                        name={row.secondaryKey}
                        value={report[row.secondaryKey]}
                        onChange={updateReport}
                        step={0.01}
                        style={{ input: InputStyle, formInput: FormInputStyle }}
                        iconBefore={row.totalAmountOperation && <Icon class={row.totalAmountIcon} />}
                      />
                    </InputWrapper>
                  </>
                )}
              </td>
              <td
                style={InputColStyle}
                children={
                  row.key &&
                  (!row.totalAmountOperation ? (
                    <NumberInput
                      name={row.primaryKey}
                      value={report[row.primaryKey]}
                      onChange={updateReport}
                      step={0.01}
                      style={{ input: InputStyle, formInput: FormInputStyle }}
                      iconBefore={row.summationOperation && <Icon class={row.summationIcon} />}
                      readOnly={row.isReadOnly}
                    />
                  ) : (
                    <NumberInput
                      name={row.totalKey}
                      value={report[row.totalKey]}
                      onChange={updateReport}
                      step={0.01}
                      style={{ input: InputStyle, formInput: FormInputStyle }}
                      iconBefore={row.summationOperation && <Icon class={row.summationIcon} />}
                      readOnly
                    />
                  ))
                }
              />
            </tr>
          )}
        />
      </tbody>
    </table>
  );
}
