import Model from 'models/Model';
import draftDeleteApi from './delete/Api.Draft.Delete';
import draftListApi from './list/Api.Draft.List';
import draftSaveApi from './save/Api.Draft.Save';

export default class Draft extends Model<Model.IDraft>({ icon: 'if-login' }) implements Model.Instance<Model.IDraft> {
  id: number;
  data?: object;
  entityName?: string;
  createdById: number;

  constructor(data: Partial<Model.IDraft> | Utils.FormData<Model.IDraft>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.entityName || this.id?.toString(),
    };
  }
  static async list(body?: Api.Core.Draft.List.IRequest) {
    const { payload, ...rest } = await draftListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Draft(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await draftSaveApi(this);
  }
  async delete() {
    return await draftDeleteApi(this.id);
  }
}
