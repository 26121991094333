import { ApplicationSettings, getCurrentApp } from 'config/Api.Config';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export default function useCurrentApp() {
  const location = useLocation();
  const currentApp = useMemo(() => getCurrentApp(), [location.pathname]);
  return {
    id: currentApp,
    path: ApplicationSettings[currentApp].path,
  };
}
