import { isIcon, isUrl } from 'assets/utils/parsersAndValidation/Validators';
import { useTheme } from '../../hooks/theme/useTheme';
import RenderProps from '../helpers/RenderProps';
import Icon from '../icon/Icon';
import React from 'react';

export enum CardStructure {
  card = 'card',
  cardContainer = 'cardContainer',
  cardInfo = 'cardInfo',
  cardMedia = 'cardMedia',
  cardActions = 'cardActions',
  cardInfoTitle = 'cardInfoTitle',
  cardTitle = 'cardTitle',
  cardSubtitle = 'cardSubtitle',
  cardDescription = 'cardDescription',
  cardHighlight = 'cardHighlight',
  cardSubHighlight = 'cardSubHighlight',
}
export type Verify = Utils.VerifyExtends<Component.Card.Structure, typeof CardStructure>;
export default function Card(props: Component.Card.Import) {
  const extend = useTheme(CardStructure, props);

  return (
    <div {...extend(CardStructure.card, true)}>
      <div {...extend(CardStructure.cardMedia)}>
        {typeof props.media === 'string' ? (
          isUrl(props.media) ? (
            <img src={props.media} />
          ) : isIcon(props.media) ? (
            <Icon class={props.media} />
          ) : (
            props.media
          )
        ) : (
          RenderProps(props, 'media')
        )}
      </div>
      <div {...extend(CardStructure.cardContainer)}>
        <div {...extend(CardStructure.cardInfo)}>
          {props.info && <div {...extend(CardStructure.cardInfoTitle)}>{RenderProps(props, 'info')}</div>}
          {props.title && <div {...extend(CardStructure.cardTitle)}>{RenderProps(props, 'title')}</div>}
          {props.subtitle && <div {...extend(CardStructure.cardSubtitle)}>{RenderProps(props, 'subtitle')}</div>}
          {props.description && (
            <div {...extend(CardStructure.cardDescription)}>{RenderProps(props, 'description')}</div>
          )}
          {props.highlight && (
            <div {...extend(CardStructure.cardHighlight)}>
              {RenderProps(props, 'highlight')}
              <div {...extend(CardStructure.cardSubHighlight)}>{RenderProps(props, 'subHighlight')}</div>
            </div>
          )}
        </div>
        {props.actions && <div {...extend(CardStructure.cardActions)}>{RenderProps(props, 'actions')}</div>}
      </div>
    </div>
  );
}
