import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import vehicleClassDeleteApi from 'models/productManagement/vehicleClass/delete/Api.VehicleClass.Delete';
import VehicleClass from 'models/productManagement/vehicleClass/Model.VehicleClass';
import vehicleClassSaveApi from 'models/productManagement/vehicleClass/save/Api.VehicleClass.Save';
import { useMemo } from 'react';
import { vehicleClassIndexRoute } from './VehicleClass.Index';
import vehicleClassCopyApi from 'models/productManagement/vehicleClass/copy/Api.VehicleClass.Copy';

export enum VehicleClassAction {
  EDIT = 'edit',
  DELETE = 'delete',
  COPY = 'copy',
  PRINT = 'print',
}
export type VerifyVehicleClassAction = Utils.VerifyExtends<
  Module.ProductManagement.VehicleClass.Actions,
  Utils.ValueOf<typeof VehicleClassAction>
>;
export default function useVehicleClass() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.VehicleClass.Params,
    Module.ProductManagement.VehicleClass.Query
  >({
    route: vehicleClassIndexRoute,
  });

  const canView = permissions.CREATE_VEHICLECLASS || permissions.EDIT_VEHICLECLASS || permissions.DELETE_VEHICLECLASS;
  const listApi = useApi({
    action: VehicleClass.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: vehicleClassDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: vehicleClassSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const copyApi = useApi({
    action: vehicleClassCopyApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    copyApi,
    selected,
    permissions,
    views: {
      [VehicleClassAction.DELETE]:
        pageRouter.params.action === VehicleClassAction.DELETE && selected && permissions.DELETE_VEHICLECLASS,
      [VehicleClassAction.EDIT]:
        pageRouter.params.action === VehicleClassAction.EDIT &&
        ((permissions.CREATE_VEHICLECLASS && !selected) || (permissions.EDIT_VEHICLECLASS && selected)),
      [VehicleClassAction.COPY]: pageRouter.params.action === VehicleClassAction.COPY && selected,
      [VehicleClassAction.PRINT]:
        pageRouter.params.action === VehicleClassAction.PRINT &&
        ((permissions.CREATE_VEHICLECLASS && !selected) || (permissions.EDIT_VEHICLECLASS && selected)),
    },
  };
}

export function useVehicleClassProvider() {
  return useDataProvider<ReturnType<typeof useVehicleClass>>();
}
