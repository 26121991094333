import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React, { Fragment, useState } from 'react';
import ClaimTypeEditInfoInputForm from './ClaimType.Edit.InfoInputForm';

export default function ClaimTypeEditInfoInputsList() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const claimTypeReportSettings = form.data.claimTypeReportSettings?.length
    ? form.data.claimTypeReportSettings
    : [
        {
          reportRows: [],
          infoInputs: [],
        },
      ];
  const report = claimTypeReportSettings && claimTypeReportSettings[0];
  const rowsForMapping = report?.infoInputs?.map((it) => it.key);
  const [activeIndex, setActiveIndex] = useState<number>();

  function addNew(data: Utils.FormData<Model.IClaimTypeReportRow>) {
    report.infoInputs.push(data);
    form.update({ claimTypeReportSettings });
    setActiveIndex(null);
  }
  function deleteAt(index: number) {
    report.infoInputs.splice(index, 1);
    form.update({ claimTypeReportSettings });
  }
  function updateAt(data: Utils.FormData<Model.IClaimTypeReportRow>, index: number) {
    report.infoInputs[index] = data;
    form.update({ claimTypeReportSettings });
    setActiveIndex(null);
  }

  return (
    <Collection>
      <DataMap
        data={report?.infoInputs}
        render={({ data: input, index }) => (
          <Fragment key={`${index}-${input.key || ''}`}>
            <Card
              class={ThemeCardRow}
              media={<Icon class="fas-bell" />}
              info={input.key}
              title={`#${index + 1} ${input.name?.en}`}
              subtitle={input.type}
              actions={
                <>
                  <Button
                    class={{ button: ThemeButtonCircle }}
                    media="fas-chevron-up"
                    disabled={index === 0 || activeIndex === index}
                    onClick={() => {
                      const temp = report?.infoInputs[index];
                      report.infoInputs[index] = report.infoInputs[index - 1];
                      report.infoInputs[index - 1] = temp;
                      form.update({ claimTypeReportSettings });
                    }}
                    htmlElementProps={{ title: lang.moveUp }}
                  />
                  <Button
                    class={{ button: ThemeButtonCircle }}
                    media="fas-chevron-down"
                    disabled={index === report.infoInputs.length || activeIndex === index}
                    onClick={() => {
                      const temp = report.infoInputs[index];
                      report.infoInputs[index] = report.infoInputs[index + 1];
                      report.infoInputs[index + 1] = temp;
                      form.update({ claimTypeReportSettings });
                    }}
                    htmlElementProps={{ title: lang.moveDown }}
                  />
                  <Button
                    class={{ button: ThemeButtonCircle }}
                    media={activeIndex === index ? 'fas-ban' : 'fas-edit'}
                    onClick={setActiveIndex}
                    data={index === activeIndex ? null : index}
                    htmlElementProps={{ title: activeIndex === index ? lang.ban : lang.edit }}
                  />
                  <Button
                    class={{ button: ThemeButtonCircle }}
                    media="fas-trash"
                    onClick={deleteAt}
                    data={index}
                    disabled={activeIndex === index}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </>
              }
            />
            {activeIndex === index && (
              <ClaimTypeEditInfoInputForm
                input={input}
                inputsForMapping={rowsForMapping}
                onSave={(data) => {
                  updateAt(data, index);
                }}
              />
            )}
          </Fragment>
        )}
      />
      {activeIndex === -1 && (
        <ClaimTypeEditInfoInputForm input={{}} inputsForMapping={rowsForMapping} onSave={addNew} />
      )}
      <Button
        class={{ button: ThemeButtonCircle }}
        media={activeIndex === -1 ? 'fas-ban' : 'fas-plus'}
        children={activeIndex === -1 ? lang.remove : lang.addNew}
        onClick={setActiveIndex}
        data={activeIndex === -1 ? null : -1}
        style={{
          margin: `${Theme.Size.M} ${Theme.Size.L}  ${Theme.Size.M} auto`,
        }}
      />
    </Collection>
  );
}
