import { HubConnectionBuilder } from '@microsoft/signalr';
import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { combineStrings } from 'assets/utils/data/String';
import { envUrlMappings, claimRealtimeViewersRoute } from 'config/Api.Config';
import { compact, get, reduce } from 'lodash';
import User from 'models/core/user/Model.User';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router';

const URL = `https://${envUrlMappings[location.hostname]}${claimRealtimeViewersRoute}`;
type ClaimViewer = {
  claimId: number;
  user: {
    id: number;
    name: string;
    accessedAtUtc: string;
  };
};
type NewClaimViewer = {
  claimId: number;
  claimViewer: ClaimViewer;
};

function useLiveClaimViewsCore() {
  const connection = useMemo(() => new HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build(), []);
  const [isConnected, setIsConnected] = useState(false);
  const [views, setViews] = useState<Record<number, ClaimViewer['user']>>({});
  const history = useHistory();
  const { user } = useAuthStore();
  const viewedClaimId = useRef<number>(null);

  function getCurrentPageInfo() {
    const urlPaths = compact(history.location.pathname.split('/'));
    if (/^\/claims\/claim-edit(\/\d+)?/g.test(history.location.pathname)) {
      return { claimId: parseInt(urlPaths[3]), path: '/claims/claim-edit' };
    } else if (/^\/claims\/claim-job-edit(\/\d+)?/g.test(history.location.pathname)) {
      return { claimId: parseInt(urlPaths[2]), path: '/claims/claim-job-edit' };
    } else {
      return { claimId: null, path: null };
    }
  }
  const { claimId, path } = useMemo(() => getCurrentPageInfo(), [history.location.pathname]);
  const viewerId = get(views, claimId)?.id;
  useEffect(() => {
    function handleLeave() {
      if (claimId === viewedClaimId.current && history.location.search.indexOf('kickedBy') < 0) {
        stopViewClaim(viewedClaimId.current, user);
        viewedClaimId.current = null;
      }
    }
    if (user && isConnected) {
      if (claimId) {
        if (viewerId === undefined) {
          getClaimViewers([claimId]);
          subscribeToClaim(claimId);
        }
        viewedClaimId.current = claimId;
        viewClaim(claimId, user);
      }
      if (claimId !== viewedClaimId.current && history.location.search.indexOf('kickedBy') < 0) {
        stopViewClaim(viewedClaimId.current, user);
        viewedClaimId.current = null;
      }

      window.addEventListener('beforeunload', handleLeave);
      return () => window.removeEventListener('beforeunload', handleLeave);
    }
  }, [claimId, path, viewerId, user, isConnected]);
  useEffect(() => {
    if (connection && user) {
      connection
        .start()
        .then(() => {
          setIsConnected(true);
          connection.on('NotifyNewViewerOnClaim', (newViewer: NewClaimViewer) => {
            const urlInfo = getCurrentPageInfo();
            if (urlInfo.claimId === newViewer.claimId && user?.id !== newViewer.claimViewer?.user?.id) {
              history.push(`${urlInfo.path}?kickedBy=${newViewer.claimViewer?.user?.name}`);
            }
            setViews((old) => ({
              ...old,
              [newViewer.claimId]: newViewer?.claimViewer?.user,
            }));
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection, user]);

  const subscribeToClaim = async (claimId: number) => {
    if (isConnected) {
      try {
        await connection.send('SubscribeToClaim', claimId);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('No connection to server yet.');
    }
  };
  const viewClaim = async (claimId: number, user: User) => {
    if (isConnected) {
      try {
        await connection.send('ViewingClaim', claimId, {
          id: user?.id,
          name: combineStrings(' ', user?.firstName, user?.lastName),
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('No connection to server yet.');
    }
  };
  const stopViewClaim = async (claimId: number, user: User) => {
    if (isConnected) {
      try {
        await connection.send('StoppedViewingClaim', claimId, {
          id: user?.id,
          name: combineStrings(' ', user?.firstName, user?.lastName),
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('No connection to server yet.');
    }
  };
  const getClaimViewers = async (claimIds: number[]) => {
    if (isConnected) {
      try {
        const currentViews = await connection.invoke<Record<number, ClaimViewer>>('GetViewersForClaims', claimIds);
        setViews((views) => ({
          ...views,
          ...reduce(
            currentViews,
            (prev, curr) => ({ ...prev, ...(curr ? { [curr.claimId]: curr.user ?? null } : {}) }),
            {}
          ),
        }));
      } catch (e) {
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  };

  return {
    views,
    isConnected,
    subscribeToClaim,
    viewClaim,
    stopViewClaim,
    getClaimViewers,
  };
}

const { Provider: LiveClaimViewsProvider, useProvider: useLiveClaimViews } = ProviderBuilder(useLiveClaimViewsCore);
export default useLiveClaimViews;
export { LiveClaimViewsProvider };
