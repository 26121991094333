import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import Product from 'models/productManagement/product/Model.Product';
import { useInsurerProvider } from '../Insurer.Hooks';

export default function InsurerEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useInsurerProvider();
  const productApi = useApi({ action: Product.list });

  return (
    <>
      <ModalForm<Api.Core.Insurer.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.insurer}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={saveApi.execute}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.code) errors.code = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <InputWrapper>
                <TextInput
                  name="name"
                  label={lang.name}
                  value={form.data.name}
                  error={form.errors.name}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
                <TextInput
                  name="code"
                  label={lang.code}
                  value={form.data.code}
                  error={form.errors.code}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
              </InputWrapper>
              <CheckInput
                name="isActive"
                label={lang.isActive}
                value={form.data.isActive}
                error={form.errors.isActive}
                onChange={form.update}
                class={inputStyles.check.switch}
                htmlElementProps={{ title: form.data.isActive ? lang.disable : lang.enable }}
              />
              <SelectInput
                name="products"
                label={lang.products}
                data={productApi?.payload?.data?.map((item) => item.displayInfo)}
                isMultiselect
                value={form.data.products?.map((it) => it.id)}
                onChange={({ products: idList }) => form.update({ products: idList.map((id) => ({ id })) })}
                icon={<Icon class="fas-clipboard-list" />}
              />
              <LocationSearch
                onSelect={(location) => {
                  form.update({
                    streetAddress: location?.Line1 || null,
                    provinceCode: location?.ProvinceCode || null,
                    city: location?.City || null,
                    countryCode: location?.CountryIso3 || null,
                    postalCode: location?.PostalCode || null,
                  });
                }}
              />
              <InputWrapper>
                <TextInput
                  name="streetAddress"
                  label={lang.streetAddress}
                  value={form.data.streetAddress}
                  error={form.errors.streetAddress}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="postalCode"
                  label={lang.postalCode}
                  value={form.data.postalCode}
                  error={form.errors.postalCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="city"
                  label={lang.city}
                  value={form.data.city}
                  error={form.errors.city}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="provinceCode"
                  label={lang.provinceCode}
                  value={form.data.provinceCode}
                  error={form.errors.provinceCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                  style={{ width: Theme.Size.XXL }}
                />
                <TextInput
                  name="countryCode"
                  label={lang.countryCode}
                  value={form.data.countryCode}
                  error={form.errors.countryCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  style={{ width: Theme.Size.XXL }}
                  readOnly
                />
              </InputWrapper>
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
