import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { defaultDateTimeFormat, date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import { ContractDraft, SalesNewContractAction } from '../../newContract/Sales.NewContract.Hooks';
import { salesNewContractIndexRoute } from '../../newContract/Sales.NewContract.Index';
import { SalesDraftAction, useSalesDraftProvider } from '../Sales.Draft.Hooks';
import RowActions from 'assets/components/data/rows/RowActions';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';

export default function DraftList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, permissions } = useSalesDraftProvider();
  const { redirect: redirectToNewContract } = usePageRouter<
    Module.Sales.NewContract.Params,
    Module.Sales.NewContract.Query
  >({ route: salesNewContractIndexRoute });

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.drafts}
      render={lang.noInfo}
    />
  ) : (
    <Page
      class={ThemePageList}
      isLoading={listApi.isExecuting}
      header={<b style={{ marginRight: 'auto' }} children={lang.drafts} />}
      render={
        <Collection class={ThemeCollectionList}>
          <HeaderRow>
            <HeaderData>{lang.appNumber}</HeaderData>
            <HeaderData>
              {lang.vehicle}/{lang.vin}
            </HeaderData>
            <HeaderData children={lang.client} />
            <HeaderData children={lang.dealer} />
            <HeaderData children={lang.createdAt} />
            <HeaderData numOfActions={1} />
          </HeaderRow>
          <DataMap
            data={listApi.payload?.data}
            render={({ data: draft }) => {
              const contractDraft = draft.data as ContractDraft;
              const selectedProducts = contractDraft?.form?.products?.map((it) => it.code);
              return (
                contractDraft?.form && (
                  <Row
                    key={draft.id}
                    children={
                      <>
                        <RowImage render={<Icon class="fas-file-draft" />} />
                        <RowData info="DRAFT" title={selectedProducts?.join(', ') || lang.unknownProduct} />
                        <RowData
                          title={combineStrings(
                            '/',
                            contractDraft.form.vehicle.year?.toString(),
                            contractDraft.form.vehicle.makeCode,
                            contractDraft.form.vehicle.model
                          )}
                          info={contractDraft.form.vehicle?.vin}
                        />
                        <RowData>
                          {combineStrings(
                            ' ',
                            contractDraft.form.client?.firstName,
                            contractDraft.form.client?.lastName
                          )}
                        </RowData>
                        <RowData>{contractDraft.form.dealer?.name}</RowData>
                        <RowData>
                          <span>
                            {date(contractDraft?.dateUpdated, defaultServerDateTimeFormat).format(
                              defaultDateTimeFormat
                            )}
                          </span>
                        </RowData>
                        <RowActions isVertical>
                          <Button
                            class={ThemeButtonCircle}
                            media={<Icon class="fas-edit" />}
                            onClick={() =>
                              redirectToNewContract({ action: SalesNewContractAction.VEHICLE }, { draftId: draft.id })
                            }
                            disabled={!permissions.CREATE_CONTRACT}
                            htmlElementProps={{ title: lang.edit }}
                          />
                          <Button
                            onClick={pageRouter.updateParams}
                            data={{
                              id: draft.id.toString(),
                              action: SalesDraftAction.DELETE,
                            }}
                            disabled={!permissions.CREATE_CONTRACT}
                            class={ThemeButtonCircle}
                            media={<Icon class="fas-trash" />}
                            htmlElementProps={{ title: lang.delete }}
                          />
                        </RowActions>
                      </>
                    }
                  />
                )
              );
            }}
          />
        </Collection>
      }
      footer={
        <Pagination
          class={ThemePaginationRounded}
          count={listApi.payload?.count}
          offset={listApi.body?.offset}
          limit={listApi.body?.limit}
          onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
          onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
        />
      }
    />
  );
}
