import { UICommand, UIDefaultFont, UITag } from 'assets/utils/dom/Commands';
import UICommandButton from './uiCommandButton/UICommandButton';
import UICommandSelectInput from './uiCommandSelect/UICommandSelectInput';
import UICssButton from './uiCssButton/UICssButton';
import UICssSelectInput from './uiCssSelectInput/UICssSelectInput';

export const Undo = () => <UICommandButton data={UICommand.undo} media="if-undo" />;
export const Redo = () => <UICommandButton data={UICommand.redo} media="if-redo" />;

export const Cut = () => <UICommandButton data={UICommand.cut} media="if-cut" />;
export const Copy = () => <UICommandButton data={UICommand.copy} media="if-copy" />;
export const Paste = () => <UICommandButton data={UICommand.paste} media="fas-paste" />;
export const SelectAll = () => <UICommandButton data={UICommand.selectAll} media="if-hand" />;
export const Delete = () => <UICommandButton data={UICommand.delete} media="if-trash" />;
export const RemoveFormat = () => <UICommandButton data={UICommand.removeFormat} media="if-eraser" />;

//Insert
export const InsertHTML = (/*html: string*/) => <UICommandButton data={UICommand.insertHTML} media="if-eraser" />;

//Styling
export const Bold = () => <UICssButton data={{ fontWeight: 'bold' }} media="if-bold" />;
export const Italic = () => <UICssButton data={{ fontStyle: 'italic' }} media="if-italic" />;
export const Superscript = () => <UICommandButton data={UICommand.superscript} media="if-superscript" />;
export const Subscript = () => <UICommandButton data={UICommand.subscript} media="if-subscript" />;
export const JustifyLeft = () => <UICommandButton data={UICommand.justifyLeft} media="if-justify-left" />;
export const JustifyCenter = () => <UICommandButton data={UICommand.justifyCenter} media="if-justify-center" />;
export const JustifyRight = () => <UICommandButton data={UICommand.justifyRight} media="if-justify-right" />;
export const JustifyFull = () => <UICommandButton data={UICommand.justifyFull} media="if-justify-all" />;

export const FormatBlock = () => <UICommandSelectInput name={UICommand.formatBlock} data={UITag} icon="if-cubes" />;
export const FontFamily = () => <UICssSelectInput name="fontFamily" data={UIDefaultFont} icon="if-font" />;

//Insert
export const OrderedList = () => <UICommandButton data={UICommand.insertOrderedList} media="if-listing-number" />;
export const UnorderedList = () => <UICommandButton data={UICommand.insertUnorderedList} media="if-listine-dots" />;
export const HorizontalRule = () => <UICommandButton data={UICommand.insertHorizontalRule} media="if-minus" />;
