export type Verify = Utils.VerifyExtends<Effect.Size, Utils.ValueOf<typeof SizeVariable>>;
enum SizeVariable {
  XXS = 'var(--sizeXXS)',
  XS = 'var(--sizeXS)',
  S = 'var(--sizeS)',
  M = 'var(--sizeM)',
  L = 'var(--sizeL)',
  XL = 'var(--sizeXL)',
  XXL = 'var(--sizeXXL)',
  fitContent = 'fit-content',
  fill = '100%',
  none = 'none',
}
export default SizeVariable;
