import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useClaimEdit } from '../ClaimEdit.Hooks';

export default function ClaimEditSubmitButton() {
  const { lang } = getLocales();
  const { form, isClaimAdmin, isClaimOwner, isEditable } = useClaimEdit();

  return (
    <Button
      onClick={form.submit}
      media={<Icon class="fas-save" />}
      render={lang.save}
      active={!form.hasErrors && form.isUpdated}
      disabled={form.hasErrors || !form.isUpdated || (!isClaimAdmin && !isClaimOwner && !isEditable)}
      isLoading={form.isSubmitted}
      class={ThemeButtonCircle}
      htmlElementProps={{ title: lang.save }}
    />
  );
}
