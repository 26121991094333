import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';
import { SalesContractInfoTab, useSalesContractProvider } from '../../Sales.Contract.Hooks';
import ContractInfoDetails from './Contract.Info.Details';

const titleStyle: React.CSSProperties = { fontSize: Theme.FontSize.L, fontWeight: 'bold' };
export default function ContractInfoNavigation() {
  const { lang } = getLocales();
  const { pageRouter, selected, revisionsApi, subfeeApi } = useSalesContractProvider();

  return (
    <>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.DOCUMENTS ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.DOCUMENTS })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.documents}</div>
        <br />
        {lang.autoFilledDocuments}: {selected?.documents?.length ?? '0'}
        <br />
        {lang.productDocuments}: {selected?.productDocuments?.length ?? '0'}
        <br />
        {lang.surchargeDocuments}:{' '}
        {subfeeApi?.payload?.data?.reduce((p, c) => p + (c.documents?.length ?? 0), 0) ?? '0'}
        <br />
        {lang.additionalDocumentation}: {selected?.contractDocuments?.length ?? '0'}
      </div>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.REVISIONS ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.REVISIONS })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.revisions}</div>
        <br />
        {revisionsApi?.payload?.length ? (
          <>
            {revisionsApi?.payload?.length} {lang.revisions}
          </>
        ) : (
          lang.noInfo
        )}
      </div>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.INFO ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.INFO })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.info}</div>
        <ContractInfoDetails contract={selected} />
      </div>
    </>
  );
}
