import DataMap from 'assets/components/dataMap/DataMap';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ClaimEditJobItemDeductible from '../ClaimEdit.Job.Item.Deductible';

type Props = {
  items: Array<{ item: Utils.FormData<Model.IClaimJobItem>; index: number }>;
  isWarrantyProduct: boolean;
  updateJobItem(data: Utils.FormData<Model.IClaimJobItem>, index?: number): void;
};

const ClaimEditJobDeductibleTable = ({ items, isWarrantyProduct, updateJobItem }: Props) => {
  const { lang } = getLocales();

  return (
    <>
      <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
        <h4>{lang.deductible}</h4>
      </div>
      <table className={ThemeTableSimple} style={{ fontSize: Theme.FontSize.S }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>{lang.payee}</th>
            <th style={{ textAlign: 'center' }}>Requested amt</th>
            <th style={{ textAlign: 'center' }}>Adjusted amt</th>
            <th style={{ textAlign: 'center' }}>{lang.total}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <DataMap
            data={items}
            render={({ data: { item, index } }) => (
              <ClaimEditJobItemDeductible
                key={index}
                data={{ ...item, isWarrantyProduct }}
                onUpdate={(data) => updateJobItem({ ...item, ...data }, index)}
              />
            )}
          />
        </tbody>
      </table>
    </>
  );
};

export default ClaimEditJobDeductibleTable;
