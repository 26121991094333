import { getLocales } from 'assets/locales/Locale';
import Model from 'models/Model';
import customPricePointDeleteApi from './delete/Api.CustomPricePoint.Delete';
import customPricePointListApi from './list/Api.CustomPricePoint.List';
import customPricePointSaveApi from './save/Api.CustomPricePoint.Save';

export default class CustomPricePoint
  extends Model<Model.ICustomPricePoint>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ICustomPricePoint>
{
  id: number;
  termInMonths: number;
  customInfo: string;
  priceCAD: number;
  productId: number;
  pricingDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  productProvinceGroupId?: number;
  customInfoGroup: string;

  constructor(
    data: Partial<Model.ICustomPricePoint> | Utils.FormData<Model.ICustomPricePoint>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: `${this.termInMonths}${lang.month} | ${this.customInfo}$`,
      info: `${this.priceCAD}$`,
    };
  }
  static async list(body?: Api.Core.CustomPricePoint.List.IRequest) {
    const { payload, ...rest } = await customPricePointListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new CustomPricePoint(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await customPricePointSaveApi(this);
  }
  async delete() {
    return await customPricePointDeleteApi(this.id);
  }
}
