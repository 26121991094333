import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import Icon from 'assets/components/icon/Icon';
import { textBoxStyles } from 'assets/components/textBox/TextBox';
import { getLocales } from 'assets/locales/Locale';
import Sidebar from 'assets/templates/sidebar/Sidebar';
import ValueDisplay from 'assets/templates/valueDisplay/ValueDisplay';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { useAudtiLogProvider } from '../AuditLog.Hooks';

export default function AuditLogInfo() {
  const { lang } = getLocales();
  const { pageRouter, selected, isSelectedEdited } = useAudtiLogProvider();

  return (
    <Sidebar
      onClose={() => pageRouter.updateParams({})}
      icon={<Icon class="if-database" />}
      info={date(selected?.changedAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
      title={
        <>
          {selected.application?.name} - {selected.entityName}
        </>
      }
      subtitle={
        <>
          {selected.user?.firstName} {selected.user?.lastName}
        </>
      }
      content={
        <>
          <DataObjectMap
            data={selected.changes}
            render={({ data, key }) => (
              <div key={key} style={{ marginBottom: Theme.Size.M }}>
                <h2>{lang[key] || key}</h2>
                <p>
                  {isSelectedEdited && (
                    <>
                      <ValueDisplay
                        value={data.old}
                        subcomponents={{
                          listValue: {
                            class: textBoxStyles.layout.infoTag,
                            style: { backgroundColor: Theme.Color.error },
                          },
                        }}
                      />
                      &nbsp;&nbsp;
                      <b>&gt;</b>
                      &nbsp;&nbsp;
                    </>
                  )}
                  <ValueDisplay
                    value={data.current}
                    subcomponents={{
                      listValue: {
                        class: textBoxStyles.layout.infoTag,
                        style: { backgroundColor: Theme.Color.success },
                      },
                    }}
                  />
                </p>
              </div>
            )}
          />
        </>
      }
    />
  );
}
