import React, { Fragment } from 'react';
import RenderProps from '../helpers/RenderProps';
import { useTheme } from '../../hooks/theme/useTheme';
import DataMap from '../dataMap/DataMap';

export enum CollectionStructure {
  collection = 'collection',
}
export type Verify = Utils.VerifyExtends<Component.Collection.Structure, typeof CollectionStructure>;
export default function Collection<T, S>(props: Component.Collection.Import<T, S>) {
  const extend = useTheme(CollectionStructure, props);

  return (
    <div {...extend(CollectionStructure.collection, true)}>
      {RenderProps(props, 'render')}
      {props.map && props.data && (
        <>
          <DataMap
            data={props.data}
            extra={props.extra}
            render={(dataMap) => props.map && <Fragment key={dataMap.index}>{props.map(dataMap)}</Fragment>}
          />
        </>
      )}
    </div>
  );
}
