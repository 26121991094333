import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useProductCategory, { ProductCategoryAction } from './ProductCategory.Hooks';
import ProductCategoryDelete from './sections/ProductCategory.Delete';
import ProductCategoryEdit from './sections/ProductCategory.Edit';
import ProductCategoryFilters from './sections/ProductCategory.Filters';
import ProductCategoryList from './sections/ProductCategory.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type Verify = Utils.VerifyExtends<
  Module.ProductManagement.ProductCategory.Actions,
  Utils.ValueOf<typeof Actions>
>;
export const productCategoryIndexRoute = productManagementHomeRoute + '/productCategory/:action?/:id?';
export default function ProductCategoryIndex() {
  const { lang } = getLocales();
  const productCategoryHook = useProductCategory();

  if (!productCategoryHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={productCategoryHook}
        render={({ listApi, views, pageRouter, permissions }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.programs} />
                <ProductCategoryFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_PRODUCT}
                  data={{ action: ProductCategoryAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <ProductCategoryEdit />}
                {views.delete && <ProductCategoryDelete />}
                <ProductCategoryList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
