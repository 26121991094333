import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import lossCodeDeleteApi from 'models/claims/lossCode/delete/Api.LossCode.Delete';
import LossCode from 'models/claims/lossCode/Model.LossCode';
import lossCodeSaveApi from 'models/claims/lossCode/save/Api.LossCode.Save';
import { useMemo } from 'react';
import { lossCodeIndexRoute } from './LossCode.Index';

export enum LossCodeAction {
  EDIT = 'edit',
  DELETE = 'delete',
  IMPORT = 'import',
}
export type VerifyLossCodeAction = Utils.VerifyExtends<
  Module.Claims.LossCode.Actions,
  Utils.ValueOf<typeof LossCodeAction>
>;
export default function useLossCode() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.LossCode.Params, Module.Claims.LossCode.Query>({
    route: lossCodeIndexRoute,
  });

  const canView =
    permissions.CREATE_LOSSCODE ||
    permissions.EDIT_LOSSCODE ||
    permissions.DELETE_LOSSCODE ||
    permissions.IMPORT_LOSSCODE;

  const listApi = useApi({
    action: LossCode.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: lossCodeDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: lossCodeSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [LossCodeAction.DELETE]:
        pageRouter.params.action === LossCodeAction.DELETE && selected && permissions.DELETE_LOSSCODE,
      [LossCodeAction.EDIT]:
        pageRouter.params.action === LossCodeAction.EDIT &&
        ((permissions.CREATE_LOSSCODE && !selected) || (permissions.EDIT_LOSSCODE && selected)),
      [LossCodeAction.IMPORT]:
        pageRouter.params.action === LossCodeAction.IMPORT && permissions.IMPORT_LOSSCODE,
    },
  };
}

export function useLossCodeProvider() {
  return useDataProvider<ReturnType<typeof useLossCode>>();
}
