export function preventDefaultBehavior(event: any, callback?: (event: any) => void) {
  if (event && event.preventDefault && event.stopPropagation) {
    event.preventDefault();
    event.stopPropagation();
    if (callback) callback(event);
  }
}
export function cleanAppName(appName: string) {
  return appName.replace(/\d+|\s+/gi, '').toLowerCase();
}
