import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import Subfee from 'models/productManagement/subfee/Model.Subfee';
import { useState } from 'react';
import { dispatchPricingDistribution, usePricingDistributionEvent } from './PricingDistributionTable';
import './PricingDistributionTable.scss';

type Price = Utils.SurchargeInfo;
type Props<T> = {
  columnKey: string;
  columns?: Subfee[];
  rows?: T[];
  getPrice: (row: T) => Array<Utils.SurchargeInfo> | null | undefined;
  updatePrice: (row: T, price: Price) => void;
};
export default function SubfeeDistributionTable<T>({ columnKey, columns, rows, getPrice, updatePrice }: Props<T>) {
  const { lang } = getLocales();
  const [isOpen, setIsOpen] = useState(false);

  usePricingDistributionEvent(
    {
      callback: (event) => {
        setIsOpen((old) => (columnKey === event.id ? !old : false));
      },
    },
    [columnKey]
  );
  return !(columns && rows) ? null : (
    <>
      <Button
        class={ThemeButtonCircle}
        style={{ button: { display: 'inline-block' } }}
        media="fas-shopping-bag"
        onClick={dispatchPricingDistribution}
        data={{ data: { id: columnKey } }}
        disabled={!columns.length || !rows.length}
        htmlElementProps={{ title: lang.openSubfeeDistributionTable }}
      />
      {isOpen && (
        <ObjectTable
          className={'PricingDistributionTable'}
          paths={columns.map((it) => it.name)}
          pathAliases={columns}
          data={rows}
          focusOnLoad
          translateHeader
          render={({ path: col, data: row, pathAlias: subfee }) => {
            const subfees = getPrice(row);
            const distributedValue = subfees?.find((s) => s.subfeeId === subfee.id);
            return (
              <td key={col}>
                <NumberInput
                  htmlElementProps={{ title: subfee.name }}
                  name="amount"
                  value={distributedValue?.amount}
                  onChange={({ amount }) => updatePrice(row, { amount, currency: 'CAD', subfeeId: subfee.id })}
                  style={{
                    formInput: {
                      minWidth: '157px',
                    },
                    input: {
                      textAlign: 'right',
                    },
                  }}
                  icon={<Icon class="fas-dollar-sign" />}
                  min={0}
                  step={0.01}
                  roundOnBlur
                />
              </td>
            );
          }}
        />
      )}
    </>
  );
}
