import MimeType from 'assets/utils/file/mimeType/MimeType';
import getGoogleDocsPreviewUrl from 'assets/utils/other/Utils.Other.GetGoogleDocsPreviewUrl';
import getOfficeLivePreviewUrl from 'assets/utils/other/Utils.Other.OfficeLivePreviewUrl';
import { isMimeType } from 'assets/utils/parsersAndValidation/Validators';
import React from 'react';
import Icon from '../icon/Icon';

export default function Media(props: Component.Media.Import) {
  const { useThumbnail, media, ...restProps } = props;
  const url = (useThumbnail && media.thumbnail) || media.url;
  const isImage = media.mime_type && isMimeType([MimeType.allImageFiles], media.mime_type);
  const isVideo = !isImage && props.media.mime_type && isMimeType([MimeType.allVideoFiles], media.mime_type);
  function getFinalIcon() {
    if (props.icon) return props.icon;

    let finalIcon: string;
    switch (media.mime_type) {
      case MimeType.appPDF:
        finalIcon = 'fas-file-pdf';
        break;
      case MimeType.appMS_EXCEL:
        finalIcon = 'fas-file-excel';
        break;
      case MimeType.appMS_POWERPOINT:
        finalIcon = 'fas-file-powerpoint';
        break;
      case MimeType.appMS_WORD:
        finalIcon = 'fas-file-word';
        break;
      case MimeType.appXML:
        finalIcon = 'fas-file-code';
        break;
      case MimeType.textHTML:
        finalIcon = 'fab-html5';
        break;
      case MimeType.textCSS:
        finalIcon = 'fab-css3-alt';
        break;
      case MimeType.textJS:
        finalIcon = 'fab-js';
        break;
      case MimeType.appZIP:
        finalIcon = 'fas-file-archive';
        break;
      case MimeType.textCSV:
        finalIcon = 'fas-file-csv';
        break;
    }
    if (!finalIcon && isImage) finalIcon = 'fas-image';
    else if (!finalIcon && isMimeType([MimeType.allAudioFiles], media.mime_type)) finalIcon = 'fas-music';
    else if (!finalIcon && isVideo) finalIcon = 'fas-film';
    else if (!finalIcon) finalIcon = 'fas-file-alt';
    return finalIcon;
  }

  return isImage ? (
    <img src={url} alt={props.media.alt} />
  ) : isVideo && !props.useThumbnail ? (
    <video autoPlay loop muted children={<source src={url} />} />
  ) : !props.useThumbnail && props.media.url ? (
    <embed
      title={props.media.alt || 'N/A'}
      src={
        isMimeType([MimeType.appPDF], media.mime_type)
          ? getGoogleDocsPreviewUrl(props.media.url)
          : getOfficeLivePreviewUrl(props.media.url)
      }
    />
  ) : (
    <Icon {...restProps} class={getFinalIcon()} />
  );
}
