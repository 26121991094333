import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import FilterWrapper from 'assets/components/inputs/wrappers/FilterWrapper';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import React, { useEffect, useState } from 'react';

export default function FilterForm<T>(props: Template.FilterForm.Import<T>) {
  const { lang } = getLocales();
  const [tempFilters, setFilters] = useState<T>(props.filters || ({} as T));
  const [isUpdated, setIsUpdated] = useState(true);
  useEffect(() => {
    setIsUpdated(true);
  }, [tempFilters]);
  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);
  return (
    <>
      <DropdownButton
        subcomponents={props.subcomponents && props.subcomponents.dropdownButton}
        onAnimateIn={props.onOpen}
        horizontalPosition="right"
        htmlElementProps={{ title: lang.filters }}
        buttonMedia={<Icon class={iconStyles.common.filters} />}
        dropdownActions={(anim) => (
          <Button
            {...(props.subcomponents && props.subcomponents.cancelButton)}
            media={<Icon class={iconStyles.common.right} />}
            tabIndex={1}
            onClick={anim.animateOut}
            htmlElementProps={{ title: lang.close }}
          />
        )}
        title={lang.filters}
        dropdownOptions={() => (
          <>
            <FilterWrapper>{RenderProps(props, 'render', { tempFilters, setFilters })}</FilterWrapper>
            <Box
              {...(props.subcomponents && props.subcomponents.buttonWrapperBox)}
              children={
                <>
                  {props.onReset && (
                    <Button
                      {...(props.subcomponents && props.subcomponents.submitButton)}
                      media={<Icon class={iconStyles.common.restore} />}
                      render={lang.reset}
                      tabIndex={1}
                      onClick={props.onReset}
                    />
                  )}
                  <Button
                    {...(props.subcomponents && props.subcomponents.resetButton)}
                    media={<Icon class={iconStyles.common.reload} />}
                    render={lang.apply}
                    disabled={!isUpdated}
                    active={isUpdated}
                    tabIndex={1}
                    data={tempFilters}
                    onClick={props.onFilter}
                  />
                </>
              }
            />
          </>
        )}
      />
    </>
  );
}
