import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import statementUploadFileApi from 'models/sales/statement/uploadFile/Api.Statement.UploadFile';
import { getLocales } from 'assets/locales/Locale';
import { useStatementProvider } from '../Statement.Hooks';
import { addLog } from 'assets/components/feedback/Feedback';
import { HttpStatus } from 'config/Api.Config';
import { useState } from 'react';

export type StatementForm = Omit<Api.Core.Statement.Save.IRequest, 'statementDocuments'> & {
  files: Hooks.Input.File.Info[];
};

export default function StatementUploadDocuments() {
  const { lang } = getLocales();
  const { pageRouter, selected, listApi } = useStatementProvider();
  const [hasFiles, setHasFiles] = useState<boolean>(false);

  return (
    <ModalForm<StatementForm>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      onCancel={pageRouter.updateParams}
      validation={(data) => {
        data?.files.length > 0 ? setHasFiles(true) : setHasFiles(false);
      }}
      disabled={!hasFiles}
      onSubmit={async ({ files }) => {
        let totalFiles = 0;
        let uploadedFiles = 0;

        for (const formFile of files || []) {
          const fileUploadResponse = await statementUploadFileApi(selected.id, { formFile: formFile.original });
          if (fileUploadResponse.status === HttpStatus.ok) {
            uploadedFiles += 1;
          }
          totalFiles += 1;
        }

        listApi.execute((body) => body);

        if (uploadedFiles !== totalFiles) {
          addLog({
            warning: !uploadedFiles
              ? 'Please check if all documents are in correct format!'
              : `${uploadedFiles}/${totalFiles} files uploaded! Please check if all documents are in correct format!`,
          });
        } else {
          addLog({ success: lang.saveSuccess });
        }
      }}
      render={({ form }) => (
        <FormWrapper>
          <FileInput
            name="files"
            label={lang.documents}
            value={form.data?.files || []}
            onChange={form.update}
            class={ThemeFileInputDropzone}
          />
        </FormWrapper>
      )}
    />
  );
}
