import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonTab from 'assets/components/button/themes/Theme.Button.Tab';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';

const ThemeModalFormDefault: Template.ModalForm.Import<any>['subcomponents'] = {
  modal: ThemeModalDefault,
  cancelButton: { class: ThemeButtonCircle },
  submitButton: { class: ThemeButtonCircle },
  tab: { class: ThemeButtonTab },
};

export default ThemeModalFormDefault;
