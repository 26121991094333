import React from 'react';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { useContractProvider } from '../Contract.Hooks';
import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';

export default function ContractFilters() {
  const { lang } = getLocales();
  const { listApi, dealersApi, productsApi, statuses } = useContractProvider();

  return (
    <>
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.firstModified}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'modifiedAtUtc',
                orderType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.lastModified}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'modifiedAtUtc',
                orderType: OrderType.desc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByName}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'appNumber',
                orderType: OrderType.asc,
                thenBy: ['client.FirstName', 'client.LastName', 'vehicle.Vin'],
                thenType: [OrderType.asc, OrderType.asc, OrderType.asc],
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByName}
              media={<Icon class="fas-caret-down" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'appNumber',
                orderType: OrderType.desc,
                thenBy: ['client.FirstName', 'client.LastName', 'vehicle.Vin'],
                thenType: [OrderType.desc, OrderType.desc, OrderType.desc],
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilers) => listApi.execute(newFilers)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!dealersApi.payload) dealersApi.execute((body) => body);
          if (!productsApi.payload) productsApi.execute((body) => body);
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <TextInput
              name="contractNumber"
              label={lang.contractNumber}
              value={tempFilters?.contractNumber}
              onChange={setFilters}
              icon={<Icon class="if-quill-pen" />}
            />
            <SelectInput
              name="statusCode"
              label={lang.status}
              data={statuses.map((item) => ({
                id: item.id,
                title: item.title,
                icon: <Icon class="fas-check" />,
              }))}
              isMultiselect
              value={tempFilters?.statusCode}
              onChange={({ statusCode }) => setFilters({ ...tempFilters, statusCode })}
              icon={<Icon class="fas-cloud" />}
            />
            <TextInput
              name="vin"
              label={lang.vin}
              value={tempFilters?.vin}
              onChange={setFilters}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="clientName"
              label={lang.clientName}
              value={tempFilters?.clientName}
              onChange={setFilters}
              icon={<Icon class="if-quill-pen" />}
            />
            {dealersApi?.payload?.data?.length > 1 && (
              <SelectInput
                name="dealerId"
                label={lang.dealers}
                data={dealersApi?.payload?.data?.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.dealerId}
                onChange={({ dealerId }) => setFilters({ ...tempFilters, dealerId })}
                icon={<Icon class="fas-cloud" />}
              />
            )}
            <SelectInput
              name="productId"
              label={lang.products}
              data={productsApi.payload?.data?.map((item) => ({
                ...item.displayInfo,
                icon: <Icon class="fas-box" />,
              }))}
              isMultiselect
              value={tempFilters?.productId}
              onChange={({ productId }) => setFilters({ ...tempFilters, productId })}
              icon={<Icon class="fas-box" />}
            />
            <InputWrapper>
              <DateInput
                name="expiryFromDateUtc"
                label={lang.expiryFromDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.expiryFromDateUtc}
                onChange={({ expiryFromDateUtc }) => setFilters({ ...tempFilters, expiryFromDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                name="expiryToDateUtc"
                label={lang.expiryToDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.expiryToDateUtc}
                onChange={({ expiryToDateUtc }) => setFilters({ ...tempFilters, expiryToDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </InputWrapper>
            <InputWrapper>
              <DateInput
                name="effectiveFromDateUtc"
                label={lang.effectiveFromDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.effectiveFromDateUtc}
                onChange={({ effectiveFromDateUtc }) => setFilters({ ...tempFilters, effectiveFromDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                name="effectiveToDateUtc"
                label={lang.effectiveToDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.effectiveToDateUtc}
                onChange={({ effectiveToDateUtc }) => setFilters({ ...tempFilters, effectiveToDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </InputWrapper>
          </>
        )}
      />
    </>
  );
}
