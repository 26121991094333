import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import VehicleClass from '../vehicleClass/Model.VehicleClass';
import VehicleMake from '../vehicleMake/Model.VehicleMake';
import vehicleModelDeleteApi from './delete/Api.VehicleModel.Delete';
import vehicleModelListApi from './list/Api.VehicleModel.List';
import vehicleModelSaveApi from './save/Api.VehicleModel.Save';

export default class VehicleModel
  extends Model<Model.IVehicleModel>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVehicleModel>
{
  id: number;
  code: string;
  name: string;
  mfgPowertrainWarrantyMonths?: string;
  mfgPowertrainWarrantyKm?: string;
  mfgWholeVehicleWarrantyMonths?: string;
  mfgWholeVehicleWarrantyKm?: string;
  vehicleMakeId?: number;
  vehicleMake?: VehicleMake;
  vehicleClasses?: VehicleClass[];

  constructor(data: Partial<Model.IVehicleModel> | Utils.FormData<Model.IVehicleModel>, language?: Locale.Languages) {
    super({ vehicleMake: VehicleMake, vehicleClasses: [VehicleClass] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.vehicleMake?.makeCode, this.name) || this.id.toString(),
      info: this.vehicleClasses?.map((it) => combineStrings(' - ', it.primaryCode, it.secondaryCode))?.join(' | '),
    };
  }

  static async list(body?: Api.Core.VehicleModel.List.IRequest) {
    const { payload, ...rest } = await vehicleModelListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new VehicleModel(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await vehicleModelSaveApi(this);
  }
  async delete() {
    return await vehicleModelDeleteApi(this.id);
  }
}
