import ThemeBoxBlank from 'assets/components/box/themes/Theme.Box.Blank';
import Button from 'assets/components/button/Button';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import { useClose } from 'assets/hooks/events/useClose';
import React from 'react';
import useAnimation from '../../animation/hooks/Animation';
import { ApplicationPopupWindowsId } from '../../application/Application';
import Box from '../../box/Box';
import Portal from '../../helpers/Portal';
import RenderProps from '../../helpers/RenderProps';
import Page from '../Page';
import { getLocales } from 'assets/locales/Locale';

export default function Modal(props: Component.Modal.Import) {
  const { lang } = getLocales();
  const animation = useAnimation({
    autoLoad: true,
    onAnimateOut: props.onClose,
  });
  function closeModal() {
    if (!props.neverClose) {
      animation.animateOut();
    }
  }
  useClose({ onEscape: closeModal });
  return !animation.isVisible ? null : (
    <>
      <Portal dontPort={props.dontPort} querySelector={`#${ApplicationPopupWindowsId}`}>
        {props.hideBlankSpace ? null : (
          <Box class={ThemeBoxBlank} htmlElementProps={{ onClick: closeModal }} active={animation.isIn} />
        )}
        <Page
          {...(props.subcomponents && props.subcomponents.page)}
          animation={{
            page: { animate: animation.isIn },
            pageWrapper: { animate: animation.isIn },
          }}
          header={
            <>
              {RenderProps(props, 'header')}
              {!props.neverClose && (
                <Button
                  onClick={closeModal}
                  {...(props.subcomponents && props.subcomponents.closeButton)}
                  media={<Icon class={iconStyles.common.close} />}
                  htmlElementProps={{
                    title: lang.close,
                  }}
                />
              )}
            </>
          }
          subHeader={props.subHeader}
          render={props.render}
          footer={props.footer}
          sidebar={props.sidebar}
        />
      </Portal>
    </>
  );
}
