import { isArray, isObject, reduce } from 'lodash';
import { parseValue } from '../dom/UrlParsing';
import { isEmpty, isValidValue } from '../parsersAndValidation/Validators';
import isFile from './Data';

export function flattenKeys<T>(obj: T, path: string[] = [], separator: string = '.'): any {
  return !isObject(obj) || isFile(obj) || isEmpty(obj)
    ? { [path.join(separator).replace(new RegExp(`\\${separator}\\[`, 'g'), '[')]: obj }
    : reduce(
        obj as any,
        (fin: object, next: object, key: string) => ({
          ...fin,
          ...flattenKeys(next, [...path, isArray(obj) ? `[${key}]` : key], separator),
        }),
        {} as object
      );
}
export function nestData<T>(
  data: any,
  options: { separator: string; parseNumber?: boolean; parseBoolean?: boolean } = { separator: '.' }
): T {
  if (!isValidValue(data) || typeof data != 'object') {
    return parseValue(data, options);
  } else if (Array.isArray(data)) {
    return data.map((it) => nestData(it, options)) as any;
  } else {
    const finalObject = {} as any;
    for (const [key, value] of Object.entries(data)) {
      if (isValidValue(value)) {
        const objKeys = key.split(options.separator);
        if (objKeys.length == 1) finalObject[key] = nestData(value, options);
        else {
          let tempObj = finalObject;
          let tempKey: any;
          objKeys.forEach((subKey, i) => {
            tempKey = subKey;
            if (i == objKeys.length - 1) tempObj[tempKey] = nestData(value, options);
            else if (!tempObj[tempKey]) tempObj[tempKey] = {};
            tempObj = tempObj[tempKey];
          });
        }
      }
    }
    return finalObject;
  }
}
export function hasEmptyFields(obj: any, opts?: { discardkeys?: Array<string>; ignoreNullKeys?: boolean }): boolean {
  if (!obj) return true;
  else {
    const discardKeys = (opts && opts.discardkeys) || [];
    const ignoreNullKeys = (opts && opts.ignoreNullKeys) || false;
    const isEmptyObj = isEmpty(obj);
    const valueCount = Object.keys(obj).filter((k) => {
      if (ignoreNullKeys && obj[k] === null) return true;
      else return !isEmpty(obj[k]) && discardKeys.indexOf(k) < 0;
    }).length;

    return isEmptyObj || valueCount == 0;
  }
}
export function cleanValues<T>(data: T, opts: { delete: any; nullify: any } = { delete: undefined, nullify: '' }) {
  for (const key of Object.keys(data)) {
    if (data[key] === (typeof opts.delete == 'function' ? opts.delete() : opts.delete)) delete data[key];
    if (data[key] === (typeof opts.nullify == 'function' ? opts.nullify() : opts.nullify)) data[key] = null;
  }
  return data;
}
