import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import { useTierProvider } from '../Tier.Hooks';

export default function TierEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useTierProvider();
  const { payload: vehicleMakesPayload } = useApi({ action: VehicleMake.list });

  return (
    <>
      <ModalForm<Api.Core.Tier.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.tier}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={(data) => saveApi.execute(data)}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="vehicleMakeId"
                label={lang.vehicles}
                data={vehicleMakesPayload?.data
                  ?.filter((it) => !it.tiers?.length || form.data.vehicleMakes?.some((v) => v.id === it.id))
                  .map((it) => it.displayInfo)}
                value={form.data.vehicleMakes?.map((it) => it.id)}
                error={form.errors.vehicleMakes?._objectError}
                onChange={(selectData) =>
                  form.update({
                    vehicleMakes: selectData.vehicleMakeId.map((id) => ({ id })),
                  })
                }
                isMultiselect
                icon={<Icon class="fas-globe" />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
