export const isInsideVertical = (element: Element, container: Element) => {
  const e = element.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  if (e.top < c.top || e.top + e.height > c.top + c.height) return false;
  else return true;
};
export const isInsideHorizontal = (element: Element, container: Element) => {
  const e = element.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  if (e.left < c.left || e.left + e.width > c.left + c.width) return false;
  else return true;
};
export const isOutsideVertical = (element: Element, container: Element) => {
  const e = element.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  if (e.top > c.top + c.height || e.top + e.height < c.top) return true;
  else return false;
};
export const isOutsideHorizontal = (element: Element, container: Element) => {
  const e = element.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  if (e.left > c.left + c.width || e.left + e.width < c.left) return true;
  else return false;
};

export const isInside = (element: Element, container: Element) =>
  isInsideHorizontal(element, container) && isInsideHorizontal(element, container);
export const isOutside = (element: Element, container: Element) =>
  isOutsideHorizontal(element, container) && isOutsideHorizontal(element, container);
export const isOverlaping = (element: Element, otherElement: Element) =>
  !isInside(element, otherElement) && !isOutside(element, otherElement);

export const optimalPosition = (element: Element, container: Element) => {
  if (!element.parentElement) return {};

  const e = element.getBoundingClientRect();
  const p = element.parentElement.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  const left_space = p.left - c.left;
  const right_space = c.left + c.width - (p.left + p.width);
  const top_space = p.top - c.top;
  const bottom_space = c.top + c.height - (p.top + p.height);

  let horizontal, vertical;

  if (left_space < right_space && c.width + right_space > e.width) horizontal = 'left';
  else if (right_space < left_space && c.width + left_space > e.width) horizontal = 'right';
  else horizontal = 'center';

  if (bottom_space > e.height) vertical = 'bottom';
  else if (top_space > e.height) vertical = 'top';
  else vertical = 'center';

  return {
    horizontal,
    vertical,
  };
};
export const optimalOffset = (element: Element, container: Element) => {
  if (!element.parentElement) return {};

  const e = element.getBoundingClientRect();
  const p = element.parentElement.getBoundingClientRect();
  const c = container.getBoundingClientRect();

  const left_space = p.left - c.left;
  const right_space = c.left + c.width - (p.left + p.width);
  const top_space = p.top - c.top;
  const bottom_space = c.top + c.height - (p.top + p.height);

  let left, top;
  const maxWidth = e.width > p.width ? p.width : p.width;

  if (c.width - left_space > e.width) left = left_space;
  else if (c.width - right_space > e.width) left = left_space + p.width - e.width;
  else left = left_space + p.width / 2 - e.width / 2;

  if (bottom_space >= e.height) top = top_space + p.height;
  else if (top_space >= e.height) top = top_space - e.height;
  else top = top_space + p.height / 2 - e.width / 2;

  return {
    left,
    top,
    maxWidth,
  };
};
