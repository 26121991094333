import { mapServerJson } from 'assets/utils/parsersAndValidation/Parsers';
import User from 'models/core/user/Model.User';
import Model from 'models/Model';
import Contract from 'models/sales/contract/Model.Contract';

export default class ContractRevision
  extends Model<Model.IContractRevision>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IContractRevision>
{
  id: number;
  revisionNumber: number;
  contractJson: Contract;
  userId: number;
  contractId: number;
  user: User;

  constructor(
    data: Partial<Model.IContractRevision> | Utils.FormData<Model.IContractRevision>,
    language?: Locale.Languages
  ) {
    super(
      {
        contractJson: Contract,
        user: User,
      },
      language
    );
    this.update({ ...data, contractJson: mapServerJson(data.contractJson) });
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title:
        `${this?.contractJson?.appNumber}/${this?.contractJson?.client?.displayInfo?.title}/${this?.contractJson?.vehicle?.vin}` ||
        this.id.toString(),
      info: this?.contractJson.statusCode,
      subtitle: this?.contractJson.dealer?.displayInfo.title,
    };
  }
}
