import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import usePricingLimitTemplate, { PricingLimitTemplateAction } from './PricingLimitTemplate.Hooks';
import PricingLimitTemplateDelete from './sections/PricingLimitTemplate.Delete';
import PricingLimitTemplateEdit from './sections/PricingLimitTemplate.Edit';
import PricingLimitTemplateFilters from './sections/PricingLimitTemplate.Filters';
import PricingLimitTemplateList from './sections/PricingLimitTemplate.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const pricingLimitTemplateIndexRoute = productManagementHomeRoute + '/pricingLimitTemplate/:action?/:id?';
export default function PricingLimitTemplateIndex() {
  const { lang } = getLocales();
  const pricingLimitTemplateHook = usePricingLimitTemplate();
  const { permissions } = useAuthStore();

  if (!pricingLimitTemplateHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={pricingLimitTemplateHook}
        render={({ listApi, views, pageRouter }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.limits} />
                <PricingLimitTemplateFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_PRICINGLIMITTEMPLATE}
                  data={{ action: PricingLimitTemplateAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <PricingLimitTemplateEdit />}
                {views.delete && <PricingLimitTemplateDelete />}
                <PricingLimitTemplateList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
