export const applicationQuerySelector = '.application';
export const pageQuerySelector = '.page';
export const modalQuerySelector = '.modal';
export const collectionQuerySelector = '.collection';
export const scrollableBoxQuerySelector = '.scrollableBox';

export const closestScrollableParent = (element: HTMLElement) => {
  const closest =
    element.closest(scrollableBoxQuerySelector) ||
    element.closest(collectionQuerySelector) ||
    element.closest(pageQuerySelector) ||
    element.closest(pageQuerySelector) ||
    element.closest(applicationQuerySelector);
  return closest && closest.className !== element.className ? closest : undefined;
};

export const resetScroll = (element: HTMLElement) => {
  element.scrollTop = 0;
  element.scrollLeft = 0;
};
export const scrollToDocumentTop = () => {
  const target = document.querySelector(applicationQuerySelector);
  if (target)
    target.scrollIntoView({
      behavior: 'auto',
    });
};
export const scrollToElement: DOM.Scroll.ScrollToElement = (props) => {
  const container =
    typeof props.containerSelector == 'string'
      ? document.querySelector(props.containerSelector)
      : props.containerSelector;
  const target = container
    ? props.direction != 'parent'
      ? container.querySelector(props.querySelector)
      : container.closest(props.querySelector)
    : document.querySelector(props.querySelector);
  if (target) {
    target.scrollIntoView({
      behavior: 'smooth',
      ...props.options,
    });
  }
};
export const scrollInsideElement: DOM.Scroll.ScrollInsideElement = (props) => {
  const container =
    typeof props.containerSelector == 'string'
      ? document.querySelector(props.containerSelector)
      : props.containerSelector || document.querySelector(applicationQuerySelector);

  const target = container
    ? props.direction != 'parent'
      ? container.querySelector(props.querySelector)
      : container.closest(props.querySelector)
    : document.querySelector(props.querySelector);

  if (container && target) {
    const rect = target.getBoundingClientRect();
    container.scrollLeft = (target as any).offsetLeft - (container as any).offsetLeft;
    container.scrollTop = rect.top;
  }
};
