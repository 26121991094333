import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { salesHomeRoute } from 'config/Api.Config';
import { salesContractIndexRoute } from '../contract/Sales.Contract.Index';
import { compact, first, pick, uniq, values } from 'lodash';
import useApi from 'assets/hooks/api/useApi';
import contractListApi from 'models/sales/contract/list/Api.Contract.List';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import HeaderData from 'assets/components/data/rows/HeaderData';
import DataMap from 'assets/components/dataMap/DataMap';
import { useEffect, useMemo, useState } from 'react';
import Row from 'assets/components/data/rows/Row';
import RowImage from 'assets/components/data/rows/RowImage';
import RowData from 'assets/components/data/rows/RowData';
import { combineStrings } from 'assets/utils/data/String';
import RowActions from 'assets/components/data/rows/RowActions';
import LinkButton from 'assets/components/button/LinkButton';
import { SalesContractAction } from '../contract/Sales.Contract.Hooks';
import contractSendDocumentForSigningApi from 'models/sales/contract/sendDocumentForSigning/Api.Contract.SendDocumentForSigning';
import contractRequestPaymentUrlApi from 'models/sales/contract/requestPaymentUrl/Api.Contract.RequestPaymentUrl';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import productListApi from 'models/productManagement/product/list/Api.Product.List';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Contract from 'models/sales/contract/Model.Contract';
import ObjectInfo from 'assets/templates/objectInfo/ObjectInfo';

type Params = undefined;
type Query = {
  contractId?: number | Array<number>;
};
export const salesPayAndSignContractIndexRoute = salesHomeRoute + '/pay-and-sign-contracts';
export default function SalesPayAndSignContractIndex() {
  const { lang, translate } = getLocales();
  const contractPageRouter = usePageRouter<Module.Sales.Contract.Params, Module.Sales.Contract.Query>({
    route: salesContractIndexRoute,
  });

  const pageRouter = usePageRouter<Params, Query>({
    route: salesPayAndSignContractIndexRoute,
  });

  const entityIds = useMemo(() => compact(values(pageRouter.query).flat()), [pageRouter.query]);

  const contractsApi = useApi({
    action: contractListApi,
    wait: true,
  });
  useEffect(() => {
    contractsApi.execute({ id: entityIds });
  }, [entityIds]);

  const productIds = useMemo(
    () => compact(uniq(contractsApi.payload?.data?.map((it) => it.productId ?? it.product?.id))),
    [contractsApi.payload?.data]
  );
  const productsApi = useApi({
    action: productListApi,
    wait: true,
  });
  useEffect(() => {
    productsApi.execute({ productId: productIds });
  }, [productIds]);

  const [signedIds, setSignedIds] = useState<Record<number, 'signing' | 'done' | 'failed'>>([]);
  const areAllDocsSigned = useMemo(() => values(signedIds).every((it) => it), [signedIds]);
  const contracts = useMemo(
    () =>
      (contractsApi.payload?.data ?? []).map((contract) => ({
        contract,
        isPaid: contract.isPayed,
        isSigned: contract.isSigned,
        isSentForSigning: contract.isSentForSigning,
        isPayable: !!contract.dealer?.isPaymentRequired,
        isSignable: productsApi.payload?.data
          ?.find((p) => p.id === (contract.productId ?? contract.product?.id))
          ?.productDocuments?.some((pd) => pd.productSignatureFields?.length),
      })),
    [contractsApi.payload?.data, productsApi.payload?.data, signedIds]
  );

  async function signAll() {
    for (const contract of contractsApi.payload.data ?? []) {
      setSignedIds((old) => ({ ...old, [contract.id]: 'signing' }));
      const docSigningResponse = await contractSendDocumentForSigningApi({
        contractId: contract.id,
      });
      if (docSigningResponse.status === 200) setSignedIds((old) => ({ ...old, [contract.id]: 'done' }));
      else setSignedIds((old) => ({ ...old, [contract.id]: 'failed' }));
    }
  }
  async function payAll() {
    const client = first(contracts)?.contract?.client;
    if (!client) return;
    const entityIds = compact(contracts.map((c) => c.contract.id));
    const paymentUrl = await contractRequestPaymentUrlApi({
      entityIds,
      amount: contracts.reduce((p, c) => {
        const taxExemptPrice = new Contract(c.contract).taxExemptPrices;
        return p + (taxExemptPrice.paymentAmountTotalCAD ?? 0);
      }, 0),
      redirectUrl: `${document.location.origin}${createPathFromRoute(
        salesPayAndSignContractIndexRoute,
        {},
        {
          contractId: entityIds,
        }
      )}`,
      client: {
        firstName: client.firstName,
        lastName: client.lastName,
        address: client.streetAddress,
        city: client.city,
        province: client.provinceCode,
        zip: client.postalCode,
        email: client.email,
        phone: client.phone,
      },
    });
    if (paymentUrl.payload) {
      document.location.assign(paymentUrl.payload);
    }
  }

  const canPay = contracts.some((c) => c.isPayable && !c.isPaid);
  const canSign = contracts.some((c) => c.isSignable && !c.isSigned && !c.isSentForSigning);
  return (
    <Page
      class={ThemePageStatic2}
      style={{
        page: {
          overflow: 'auto',
          height: '100vh',
        },
      }}
      header={
        <Card
          class={ThemeCardRow}
          media={<Icon class="fas-file-contract" />}
          info="Sign & pay"
          title={lang.contracts}
        />
      }
      render={
        <Collection class={ThemeCollectionList}>
          <HeaderRow>
            <HeaderData children={lang.contract} />
            <HeaderData children={lang.client} />
            <HeaderData children={lang.amount} style={{ justifyContent: 'flex-end' }} />
            <HeaderData children={lang.status} />
            <HeaderData numOfActions={1} />
          </HeaderRow>
          <DataMap
            data={contracts}
            render={({ data: { contract, isPaid, isPayable, isSignable, isSigned, isSentForSigning } }) => {
              const status = signedIds[contract.id];
              const taxExemptPrice = new Contract(contract).taxExemptPrices;
              return (
                <Row
                  key={contract.id}
                  disabled={!isPayable && !isSignable}
                  children={
                    <>
                      <RowImage
                        render={
                          <Icon
                            class={
                              status === 'done'
                                ? 'fas-check'
                                : status === 'signing'
                                ? 'fas-spinner'
                                : status === 'failed'
                                ? 'fas-exclamation-triangle'
                                : 'fas-file-contract'
                            }
                          />
                        }
                      />
                      <RowData
                        info={combineStrings(
                          ' | ',
                          contract.appNumber
                            ? contract.appNumber + (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')
                            : null,
                          translate(contract.statusCode)
                        )}
                        title={combineStrings(
                          '/',
                          contract.vehicle.year?.toString(),
                          contract.vehicle.makeCode,
                          contract.vehicle.model
                        )}
                        subtitle={contract.vehicle?.vin}
                      />
                      <RowData>
                        <ObjectInfo
                          object={pick(
                            {
                              ...contract?.client,
                              name: combineStrings(' ', contract.client?.firstName, contract.client?.lastName),
                              address: combineStrings(
                                ', ',
                                contract.client?.streetAddress,
                                contract.client?.city,
                                contract.client?.provinceCode,
                                contract.client?.postalCode
                              ),
                            },
                            'name',
                            'email',
                            'phone',
                            'address',
                            'taxExempt'
                          )}
                          objectName={undefined}
                        />
                      </RowData>
                      <RowData style={{ alignItems: 'flex-end' }}>
                        <b>
                          <FormatValue value={`${(taxExemptPrice.paymentAmountTotalCAD || 0).toFixed(2)}$`} />
                        </b>
                      </RowData>
                      <RowData>
                        <b>{isPayable ? (isPaid ? 'Paid!' : 'Ready for payment') : 'Payment not required'}</b>
                        <b>
                          {isSignable
                            ? isSigned
                              ? 'Signed!'
                              : isSentForSigning
                              ? 'Waiting for signatures'
                              : 'Ready for sign'
                            : 'Signature not required'}
                        </b>
                      </RowData>
                      <RowActions isVertical>
                        <LinkButton
                          class={ThemeButtonCircle}
                          url={contract?.documentationAggregate?.url}
                          media={<Icon class="fas-download" />}
                          disabled={!contract?.documentationAggregate?.url || !areAllDocsSigned}
                          target="_blank"
                          htmlElementProps={{ title: lang.download }}
                        />
                        <Button
                          class={ThemeButtonCircle}
                          media={<Icon class="fas-info-circle" />}
                          onClick={contractPageRouter.updateParams}
                          disabled={!contract?.id || !areAllDocsSigned}
                          data={{ id: contract?.id?.toString(), action: SalesContractAction.INFO }}
                          htmlElementProps={{ title: lang.info }}
                        />
                      </RowActions>
                    </>
                  }
                />
              );
            }}
          />
        </Collection>
      }
      footer={
        <>
          <Button
            class={ThemeButtonCircle}
            render={lang.viewAllContracts}
            onClick={contractPageRouter.updateParams}
            data={{}}
          />
          <Button class={ThemeButtonCircle} render={lang.payAll} onClick={payAll} active disabled={!canPay} />
          <Button class={ThemeButtonCircle} render={lang.signAll} onClick={signAll} active disabled={!canSign} />
        </>
      }
    />
  );
}
