import Box from 'assets/components/box/Box';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import { first, xorBy } from 'lodash';
import { useEffect } from 'react';
import { useSalesQuoteProvider } from '../Sales.Quote.Hooks';

export default function SalesQuoteProduct() {
  const { lang } = getLocales();
  const { form, productWithPrices, productWithPricesLoading } = useSalesQuoteProvider();

  function selectProduct(product: Model.IProduct) {
    form.update({
      products: xorBy(
        form.data.products,
        [
          {
            ...product,
            effectiveDate: date().startOf('day').tz('UTC').format(defaultServerDateTimeFormat),
            documentsFromTemplates: product.productDocumentTemplates?.map((it) => ({
              ...it,
              updatedName: it.name,
            })),
          },
        ],
        (p) => p.id
      ),
    });
  }
  useEffect(() => {
    if (!form.data.product && !form.data.products?.length && productWithPrices?.length === 1) {
      selectProduct(first(productWithPrices).product);
    }
  }, [productWithPrices]);
  return (
    <>
      <FormWrapper>
        <Collection class={ThemeCollectionTable} isLoading={productWithPricesLoading}>
          <Box active children={lang.products} style={{ paddingLeft: Theme.Size.L, paddingRight: Theme.Size.L }} />
          {!form.data.products?.length && (
            <Card
              class={ThemeCardRow}
              style={{ color: Theme.Color.error }}
              title={form?.data?.product ? lang.cantChangeProduct : lang.mustNotBeEmpty}
            />
          )}
          <DataMap
            data={productWithPrices}
            render={({ data: { product } }) => {
              const selectedProduct =
                product?.id === form.data.productId || form?.data?.products?.some((p) => p.id === product?.id);

              return (
                <Card
                  key={product.id}
                  class={ThemeCardRow}
                  style={product ? null : { color: Theme.Color.error }}
                  clickable={!!product && !form.data.product}
                  disabled={!product || !!form.data.product}
                  title={combineStrings(' - ', product.category?.name, product.name)}
                  subtitle={product.pricingDistributionTemplate?.name}
                  info={combineStrings(' | ', product.code, product.productType ? lang[product.productType] : null)}
                  htmlElementProps={{
                    card: {
                      onClick: !product || !!form.data.product ? undefined : () => selectProduct(product),
                    },
                    title: selectedProduct ? lang.unselect : lang.select,
                  }}
                  actions={<Icon class={selectedProduct ? 'fas-check-square' : 'far-square'} />}
                />
              );
            }}
          />
        </Collection>
      </FormWrapper>
    </>
  );
}
