import './TextBox.scss';
import React from 'react';
import RenderProps from '../helpers/RenderProps';
import { useTheme } from '../../hooks/theme/useTheme';

export enum TextBoxStructure {
  textBox = 'textBox',
}
export type Verify = Utils.VerifyExtends<Component.TextBox.Structure, typeof TextBoxStructure>;
export default function TextBox(props: Component.TextBox.Import) {
  const extend = useTheme(TextBoxStructure, props);

  if (props.text && props.afterText) {
    return (
      <div {...extend(TextBoxStructure.textBox, true)}>
        <span>{props.text}</span>
        <span className={textBoxStyles.layout.dots}>&nbsp;</span>
        <span>{props.afterText}</span>
      </div>
    );
  } else if (props.title || props.subtitle || props.info) {
    return (
      <div {...extend(TextBoxStructure.textBox, true)}>
        {RenderProps(props, 'render')}
        {props.info && <TextBox class={textBoxStyles.layout.info}>{props.info}</TextBox>}
        {props.title && <TextBox class={textBoxStyles.layout.title}>{props.title}</TextBox>}
        {props.subtitle && <TextBox class={textBoxStyles.layout.subtitle}>{props.subtitle}</TextBox>}
      </div>
    );
  } else {
    return <span {...extend(TextBoxStructure.textBox, true)}>{props.text || RenderProps(props, 'render')}</span>;
  }
}

export const textBoxStyles = {
  layout: {
    title: 'title',
    subtitle: 'subtitle',
    info: 'info',
    infoLabel: 'infoLabel',
    notes: 'notes',
    dataLabel: 'dataLabel',
    infoTag: 'infoTag',
    formatSpecialCharacters: 'formatSpecialCharacters',
    textRight: 'textRight',
    textLeft: 'textLeft',
    textCenter: 'textCenter',
    currency: 'currency',
    measurement: 'measurement',
    measurementUnit: 'measurementUnit',
    dots: 'dots',
    dottedBlock: 'dottedBlock',
    empty: 'empty',
    showEmpty: 'showEmpty',
    newValue: 'newValue',
    oldValue: 'oldValue',
    citation: 'citation',
    unit: 'unit',
  },
  block: {
    tagBlock: 'tagBlock',
    dropdownBox: 'dropdownBox',
    flexBlock: 'flexBlock',
    nested: {
      infoTitle: 'infoTitle',
      title: 'title',
      subtitle: 'subtitle',
      dropdownSubBox: 'dropdownSubBox',
    },
  },
};
