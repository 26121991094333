import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { HttpStatus, managementHomeRoute } from 'config/Api.Config';
import { DataProvider } from 'assets/providers/data/DataProvider';
import useAuditLog from './AuditLog.Hooks';
import AuditLogFilters from './sections/AuditLog.Filters';
import AuditLogInfo from './sections/AuditLog.Info';
import AuditLogList from './sections/AuditLog.List';

export const auditLogIndexRoute = managementHomeRoute + '/audit-log/:id?';
export default function AuditLogIndex() {
  const auditLogHook = useAuditLog();

  if (!auditLogHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <DataProvider
      data={auditLogHook}
      render={({ listApi: { payload, isExecuting, body: filters, execute: reload }, selected }) => (
        <Page
          class={ThemePageList}
          isLoading={isExecuting}
          sidebar={selected && <AuditLogInfo />}
          header={<AuditLogFilters />}
          render={<AuditLogList />}
          footer={
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          }
        />
      )}
    />
  );
}
