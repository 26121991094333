import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Lender.Find.IRequest;
type Response = Api.Core.Lender.Find.IResponse;
type Headers = Api.Core.Lender.Find.IHeaders;

const url = '/api/Sales/Lender/:id';
export default async function lenderFindApi(params: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url: createPathFromRoute(url, params),
    headers: getDefaultHeaders(),
  });
}
