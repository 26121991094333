export type Verify = Utils.VerifyExtends<Effect.Speed, Utils.ValueOf<typeof SpeedVariable>>;
enum SpeedVariable {
  slow = 'var(--speedS)',
  slower = 'var(--speedSS)',
  slowest = 'var(--speedSS)',
  fast = 'var(--speedF)',
  faster = 'var(--speedFF)',
  fastest = 'var(--speedFFF)',
  disabled = 'var(--speedD)',
}
export default SpeedVariable;
