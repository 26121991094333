import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import LossCode from 'models/claims/lossCode/Model.LossCode';
import React, { useState } from 'react';

export default function ClaimTypeEditLossCodes() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const [{ limit, offset }, setLimits] = useState({ limit: defaultLimit, offset: defaultOffset });
  const {
    payload: lossCodePayload,
    execute: reloadLossCodes,
    body: LossCodeReq,
  } = useApi({
    action: LossCode.list,
  });
  const areAllSelected = lossCodePayload?.data?.every((d) => form.data.lossCodes?.some((l) => l.id === d.id));
  const areSomeSelected = lossCodePayload?.data?.some((d) => form.data.lossCodes?.some((l) => l.id === d.id));
  return (
    <>
      <Collection class={ThemeCollectionTable}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            class={{ button: ThemeButtonCircle }}
            media={areAllSelected ? 'fas-check-square' : areSomeSelected ? 'fas-minus-square' : 'far-square'}
            disabled={!lossCodePayload?.count}
            onClick={() => {
              form.update({ lossCodes: areAllSelected ? [] : lossCodePayload?.data?.map(({ id }) => ({ id })) });
            }}
            htmlElementProps={{ title: lang.moveUp }}
            render={lang.selectAll}
          />
          <SearchInput
            value={LossCodeReq?.search}
            onSearch={(search) => reloadLossCodes((body) => ({ ...body, search }))}
            threshold={2}
            placeholder={`${lang.search}...`}
          />
        </Box>
        <DataMap
          data={lossCodePayload?.data?.slice(offset * limit, offset * limit + limit)}
          render={({ data: lossCode }) => {
            const currentLossCode = form.data.lossCodes?.find((it) => it.id === lossCode.id);

            return (
              <Card
                class={ThemeCardRow}
                clickable
                key={lossCode.id}
                info={
                  <>
                    {lossCode.code} - {lossCode.category}
                  </>
                }
                title={lossCode.descnEn}
                subtitle={lossCode.notes}
                htmlElementProps={{
                  card: {
                    onClick: () => {
                      if (currentLossCode)
                        form.update({ lossCodes: form.data.lossCodes?.filter((it) => it?.id !== lossCode.id) });
                      else form.update({ lossCodes: [...(form.data.lossCodes || []), { id: lossCode.id }] });
                    },
                    title: currentLossCode ? lang.unselect : lang.select,
                  },
                }}
                actions={<Icon class={currentLossCode ? 'fas-check-square' : 'far-square'} />}
              />
            );
          }}
        />
        <Pagination
          class={ThemePaginationRounded}
          count={lossCodePayload?.count}
          offset={offset}
          limit={limit}
          onLimitChange={(limit) => setLimits({ limit, offset: 0 })}
          onOffsetChange={(offset) => setLimits((old) => ({ ...old, offset }))}
        />
      </Collection>
    </>
  );
}
