import DataMap from 'assets/components/dataMap/DataMap';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import { useState } from 'react';
import { useClaimEdit } from '../../ClaimEdit.Hooks';

export default function ClaimEditEmails() {
  const { claim, product } = useClaimEdit();
  const [selected, setSelected] = useState<Model.IClaimEmail>(undefined);
  const { lang } = getLocales();

  return (
    <>
      <div style={{ margin: Theme.Size.XL, marginBottom: 0 }}>
        <h2 style={{ marginTop: 0 }}>{lang.emailHistory}</h2>
        {!claim?.claimEmails?.length && <ErrorWrapper children={lang.noInfo} />}
      </div>
      {!!claim?.claimEmails?.length && (
        <table className={ThemeTableSimple}>
          <thead>
            <tr>
              <th>{lang.claim}</th>
              <th>{lang.subject}</th>
              <th>{lang.senderEmail}</th>
              <th>{lang.additionalRecipentEmails}</th>
              <th>{lang.sendDate}</th>
            </tr>
          </thead>
          <tbody>
            {!claim?.claimEmails?.length && (
              <tr>
                <td colSpan={5}>{lang.noInfo}</td>
              </tr>
            )}
            <DataMap
              data={claim?.claimEmails}
              render={({ data: email }) => {
                return (
                  <>
                    <tr
                      key={email?.id}
                      onClick={() => setSelected((prevState) => (prevState?.id !== email?.id ? email : undefined))}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <td>
                        <b>{combineStrings('-', product?.category?.code, claim.id.toString())}</b>
                        <br />
                        {product?.name ?? lang.unknownProduct}
                      </td>
                      <td>
                        <b>{email?.subject}</b>
                      </td>
                      <td>{email?.from}</td>
                      <td style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <DataMap
                          data={email?.tos}
                          render={({ data: additionalRecipentEmail }) => <span>{additionalRecipentEmail}</span>}
                        />
                      </td>
                      <td>{date(email?.sendDateUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}</td>
                    </tr>
                    {selected?.id === email?.id && (
                      <tr style={{ borderTop: '1px solid rgba(0,0,0,0.1)', borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                        <td
                          style={{ textAlign: 'center' }}
                          colSpan={5}
                          dangerouslySetInnerHTML={{ __html: selected?.body ?? lang.noInfo }}
                        />
                      </tr>
                    )}
                  </>
                );
              }}
            />
          </tbody>
        </table>
      )}
    </>
  );
}
