import { getLocales } from 'assets/locales/Locale';
import React, { useEffect, useState } from 'react';
import Icon, { iconStyles } from '../../icon/Icon';
import { inputStyles } from '../Input';
import TextInput from '../text/TextInput';

export default function SearchInput(props: Component.Input.Search.Import) {
  const { lang } = getLocales();

  const [query, setQuery] = useState<string | undefined>(props.value);

  useEffect(() => {
    if (query !== undefined && query !== null) {
      const search = () => {
        if (!props.threshold || props.threshold <= query.length) props.onSearch(query || undefined);
        else if (props.threshold && props.threshold > query.length) props.onSearch(undefined);
      };
      const timeout = setTimeout(search, 1000);
      return () => clearTimeout(timeout);
    }
  }, [query, props.threshold]);

  return (
    <TextInput
      name="search"
      placeholder={props.placeholder || lang.search}
      value={query}
      onChange={({ search }) => setQuery(search || '')}
      class={inputStyles.variations.search}
      icon={<Icon class={iconStyles.common.search} />}
    />
  );
}
