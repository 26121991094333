import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import Client from 'models/claims/client/Model.Client';
import { clientIndexRoute } from './Client.Index';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';

export enum ClientAction {
  EDIT = 'edit',
}
export type VerifyClaimAction = Utils.VerifyExtends<Module.Claims.Client.Actions, Utils.ValueOf<typeof ClientAction>>;
export function useClientRouter() {
  return usePageRouter<Module.Claims.Client.Params, Module.Claims.Client.Query>({ route: clientIndexRoute });
}
export default function useClient() {
  const pageRouter = useClientRouter();
  const { permissions } = useAuthStore();

  const canEditClient = permissions?.EDIT_CONTRACT;

  //#region Api calls
  const listApi = useApi({
    action: Client.list,
    default: { limit: defaultLimit, offset: defaultOffset, hasVeraforteCustomerNumber: true },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.updateQuery({ ...pageRouter.query, list: req }),
    wait: !canEditClient,
  });

  const syncWithVertaforeApi = useApi({
    action: Client.syncWithVertafore,
    wait: true,
  });
  //#endregion

  return {
    listApi,
    syncWithVertaforeApi,
    pageRouter,
    canEditClient,
    views: {
      [ClientAction.EDIT]: pageRouter.params.action === ClientAction.EDIT,
    },
  };
}

export function useClientProvider() {
  return useDataProvider<ReturnType<typeof useClient>>();
}
