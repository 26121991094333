import Role from 'models/core/role/Model.Role';
import Model from 'models/Model';
import emailTemplateDeleteApi from './delete/Api.EmailTemplate.Delete';
import emailTemplateListApi from './list/Api.EmailTemplate.List';
import emailTemplateSaveApi from './save/Api.EmailTemplate.Save';

export default class EmailTemplate
  extends Model<Model.IEmailTemplate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IEmailTemplate>
{
  id: number;
  name: string;
  claimStatus?: Model.Enum.ClaimStatusCode;
  senderEmail: string;
  senderName: string;
  subject?: string;
  htmlBody: string;
  recipientsWithRole?: Role[];

  sendToClient = false;
  sendToContactEmail = false;
  sendToClaimOwner = false;
  additionalRecipentEmails: string[] = [];

  constructor(data: Partial<Model.IEmailTemplate> | Utils.FormData<Model.IEmailTemplate>, language?: Locale.Languages) {
    super({ recipientsWithRole: [Role] }, language);
    this.update(data);
    if (!this.additionalRecipentEmails) this.additionalRecipentEmails = [];
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name,
      info: this.senderName,
      subtitle: this.senderEmail,
    };
  }
  static async list(body?: Api.Core.EmailTemplate.List.IRequest) {
    const { payload, ...rest } = await emailTemplateListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new EmailTemplate(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await emailTemplateSaveApi(this);
  }
  async delete() {
    return await emailTemplateDeleteApi(this.id);
  }
}
