import { formatNumberWithSeparatorWithoutDecimales } from 'assets/utils/data/Number';
import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import pricingLimitTemplateDeleteApi from './delete/Api.PricingLimitTemplate.Delete';
import pricingLimitTemplateListApi from './list/Api.PricingLimitTemplate.List';
import pricingLimitTemplateSaveApi from './save/Api.PricingLimitTemplate.Save';

export default class PricingLimitTemplate
  extends Model<Model.IPricingLimitTemplate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IPricingLimitTemplate>
{
  id: number;
  term: number;
  maxKm: number;

  get type() {
    return combineStrings(
      '/',
      `${formatNumberWithSeparatorWithoutDecimales(this.term || 0)}m`,
      `${formatNumberWithSeparatorWithoutDecimales(this.maxKm || 0)}km`
    );
  }

  constructor(
    data: Partial<Model.IPricingLimitTemplate> | Utils.FormData<Model.IPricingLimitTemplate>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.type,
    };
  }

  static async list(body?: Api.Core.PricingLimitTemplate.List.IRequest) {
    const { payload, ...rest } = await pricingLimitTemplateListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new PricingLimitTemplate(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await pricingLimitTemplateSaveApi(this);
  }
  async delete() {
    return await pricingLimitTemplateDeleteApi(this.id);
  }
}
