import { getLocales } from 'assets/locales/Locale';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import Model from 'models/Model';
import ClaimJobCommentUser from '../claimCommentUser/ClaimCommentUser';
import claimJobCommentDeleteApi from './delete/Api.ClaimJobComment.Delete';
import claimJobCommentListApi from './list/Api.ClaimJobComment.List';
import claimJobCommentSaveApi from './save/Api.ClaimJobComment.Save';

export default class ClaimJobComment
  extends Model<Model.IClaimJobComment>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimJobComment>
{
  id: number;
  comment: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  claimJobId: number;
  userId: number;
  user?: ClaimJobCommentUser;

  get createdAtUtcUnix() {
    return date(this.createdAtUtc, defaultServerDateTimeFormat).unix();
  }
  get modifiedAtUtcUnix() {
    return this.modifiedAtUtc ? date(this.modifiedAtUtc, defaultServerDateTimeFormat).unix() : null;
  }

  constructor(
    data: Partial<Model.IClaimJobComment> | Utils.FormData<Model.IClaimJobComment>,
    language?: Locale.Languages
  ) {
    super({ user: ClaimJobCommentUser }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: this.user?.displayInfo?.title || lang.unknownUser,
      info: date(this.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat),
      subtitle: this.comment,
      media: this.user?.profilePhotoUrl || ClaimJobCommentUser.icon,
    };
  }
  async save() {
    return await claimJobCommentSaveApi(this);
  }
  async delete() {
    return await claimJobCommentDeleteApi({ id: this.id });
  }

  static async list(body?: Api.Core.ClaimJobComment.List.IRequest) {
    const { payload, ...rest } = await claimJobCommentListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ClaimJobComment(it)),
        count: payload.count,
      },
    };
  }
}
