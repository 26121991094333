import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React, { Fragment } from 'react';
import ValueDisplay from '../valueDisplay/ValueDisplay';

export default function ObjectInfo(props: Template.ObjectInfo.Import) {
  const { translate } = getLocales();
  return (
    <div style={{ width: Theme.Size.fill, ...props.style }}>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <h3>{props.objectName}</h3>
        {props.objectSubtitle}
      </div>
      <DataObjectMap
        data={props.object}
        render={({ data, key, isLast }) => {
          const isPrice = (key as string).toLowerCase().match(/price|cad/g);
          const finalKey = (key as string).replace(/(CAD|Utc)$/g, '');
          return (
            typeof data !== 'object' && (
              <Fragment key={finalKey as string}>
                {translate(finalKey)}:&nbsp;
                <ValueDisplay
                  value={isPrice ? `${typeof data === 'number' ? (data as number).toFixed(2) : data}$` : data}
                  subcomponents={{
                    validValue: {
                      style: {
                        fontWeight: 'bold',
                      },
                    },
                  }}
                />
                {!isLast && <br />}
              </Fragment>
            )
          );
        }}
      />
    </div>
  );
}
