import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '../../../hooks/theme/useTheme';
import useInput from '../Input';

export enum MultilineTextInputStructure {
  formInput = 'formInput',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.MultilineText.Structure, typeof MultilineTextInputStructure>;
export default function MultilineTextInput<Name>(props: Component.Input.MultilineText.Import<Name>) {
  const extend = useTheme(MultilineTextInputStructure, props);
  const { value, setValue } = useInput(props);
  const [autoUpdateCounter, setAutoUpdateCounter] = useState(0);
  const inputRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (inputRef.current) {
      const currentValue = props.useHtmlMode ? inputRef.current.innerHTML : inputRef.current.innerText;
      if (value !== currentValue) setAutoUpdateCounter((curr) => curr + 1);
    }
  }, [value, props.useHtmlMode]);
  useEffect(() => {
    if (inputRef.current) {
      if (props.useHtmlMode) inputRef.current.innerHTML = value || '';
      else inputRef.current.innerText = value || '';
    }
  }, [inputRef.current, props.useHtmlMode, autoUpdateCounter]);
  return (
    <div
      {...extend(MultilineTextInputStructure.formInput, true)}
      data-label={props.label}
      data-error={props.error || undefined}
      data-read-only={props.readOnly || undefined}
      children={
        <>
          <div
            {...extend(MultilineTextInputStructure.input)}
            tabIndex={0}
            ref={inputRef}
            contentEditable
            title={typeof props.label == 'string' ? props.label : undefined}
            placeholder={props.placeholder || ' '}
            onInput={(event) => {
              if (event.currentTarget)
                setValue(props.useHtmlMode ? event.currentTarget.innerHTML : event.currentTarget.innerText);
            }}
          />
        </>
      }
    />
  );
}
