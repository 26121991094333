import { combineStrings } from 'assets/utils/data/String';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Model from 'models/Model';
import AdditionalPermission from '../additionalPermission/Model.AdditionalPermission';
import MediaFile from '../mediaFile/Model.MediaFile';
import Role from '../role/Model.Role';
import userDeleteApi from './delete/Api.User.Delete';
import userListApi from './list/Api.User.List';
import userResetPasswordApi from './resetPassword/Api.User.ResetPassword';
import userSaveApi from './save/Api.User.Save';
import Distributor from 'models/sales/distributor/Model.Distributor';

export default class User extends Model<Model.IUser>({ icon: 'if-login' }) implements Model.Instance<Model.IUser> {
  id: number;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  languageIsoCode?: Locale.Languages;
  isDisabled = false;
  roles: Role[];
  additionalPermissions: AdditionalPermission[];
  dealers: Dealer[];
  profilePhoto?: MediaFile;
  profilePhotoId?: number;
  distributorId?: number;
  distributor?: Distributor;

  static checkPasswordValidity(pass?: string) {
    return pass && pass.length >= 7 && new RegExp(/\d/g).test(pass) && new RegExp(/[a-zA-Z]/g).test(pass);
  }

  constructor(data: Partial<Model.IUser> | Utils.FormData<Model.IUser>, language?: Locale.Languages) {
    super(
      {
        roles: [Role],
        profilePhoto: MediaFile,
        additionalPermissions: [AdditionalPermission],
        dealers: [Dealer],
        distributor: Distributor,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.firstName, this.lastName) || this.email || this.id?.toString(),
      media: this.profilePhoto?.url,
    };
  }
  static async list(body?: Api.Core.User.List.IRequest) {
    const { payload, ...rest } = await userListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new User(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await userSaveApi(this);
  }
  async delete() {
    return await userDeleteApi(this.id);
  }
  async resetPassword(props: Api.Core.User.ResetPassword.IRequest) {
    return await userResetPasswordApi(this.id, props);
  }
}
