export enum ContractConsentRequestType {
  EMAIL = 'email',
  TEXT = 'text',
}

export default ContractConsentRequestType;
export type VerifyContractConsentRequestType = Utils.VerifyExtends<
  Model.Enum.ContractConsentRequestType,
  Utils.ValueOf<typeof ContractConsentRequestType>
>;
