import Theme from 'assets/themes/Theme.Common';

export default function BigHr(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>) {
  return (
    <hr
      {...props}
      style={{
        borderColor: Theme.Color.backgroundDark,
        borderWidth: Theme.Size.S,
        marginBottom: Theme.Size.L,
        marginTop: Theme.Size.L,
        ...props.style,
      }}
    />
  );
}
