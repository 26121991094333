import Navigation from 'assets/components/navigation/Navigation';
import ThemeNavigationSidebar from 'assets/components/navigation/themes/Theme.Navigation.Sidebar';
import AppMenu from 'assets/templates/appMenu/AppMenu';
import LanguageMenu from 'assets/templates/languageMenu/LanguageMenu';
import UserActionMenu from 'assets/templates/userActionMenu/UserActionMenu';
import { ascLogo } from 'assets/themes/assets/Images';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import React from 'react';
import useRouter from 'app/hooks/useRouter';
import DealerMenu from '../dealerMenu/DealerMenu';

export default function AppNavigation() {
  const { allRoutes, routes, homeRoute } = useRouter();
  const { appName, logo, user } = useAuthStore();

  return (
    <>
      <Navigation
        class={ThemeNavigationSidebar}
        routes={routes}
        homePage={homeRoute?.path ?? '/'}
        logo={logo ?? (appName ? { icon: 'fas-cloud', title: appName } : ascLogo)}
        renderAfter={(animation) => (
          <>
            <LanguageMenu />
            <DealerMenu />
            <AppMenu routes={allRoutes} />
            <UserActionMenu isUsernameVisible={animation.isIn && user?.dealers.length <= 1} />
          </>
        )}
      />
    </>
  );
}
