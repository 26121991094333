import { getLocales } from 'assets/locales/Locale';
import { roundToStep } from 'assets/utils/data/Number';
import { flatten } from 'lodash';
import ModelCore from 'models/Model';

export enum ReportInputType {
  number = 'number',
  text = 'text',
  multilineText = 'multilineText',
  date = 'date',
}

export enum RowOperation {
  addition = '+',
  subtraction = '-',
  multiplication = '*',
  division = '/',
  percent = '%',
}
export class ReportObject {
  constructor(data: object) {
    this.update(data);
  }
  update(data: object) {
    for (const key of Object.keys(data)) {
      if (!Object.getOwnPropertyDescriptor(this, key)?.get) {
        this[key] = data[key];
      }
    }
  }
  toJSON() {
    const json = {};
    for (const key of Object.keys(this)) {
      if (!Object.getOwnPropertyDescriptor(json, key)?.get) {
        json[key] = this[key];
      }
    }
    return json;
  }
}
export class ClaimTypeReportInput implements Model.IClaimTypeReportInput {
  key: string;
  name: { en: string; fr?: string };
  type: ReportInputType;

  constructor(data: Partial<Model.IClaimTypeReportInput>) {
    this.key = data.key;
    this.name = data.name;
    this.type = data.type;
  }

  static getIcon(type: ReportInputType) {
    return type == ReportInputType.date
      ? 'fas-calendar-alt'
      : type == ReportInputType.multilineText
      ? 'fas-bars'
      : type == ReportInputType.number
      ? 'fas-calculator'
      : type == ReportInputType.text
      ? 'fas-font'
      : 'fas-input-text';
  }
  toJSON() {
    return {
      key: this.key,
      name: this.name,
      type: this.type,
    };
  }
}
export class ClaimTypeReportRow implements Model.IClaimTypeReportRow {
  key: string;
  name: { en: string; fr?: string };
  secondaryName?: { en: string; fr?: string };
  label?: string;
  isHighlighted: boolean;

  primaryValue?: number | string | string[];
  secondaryValue?: number;

  summationOperation: RowOperation;
  totalAmountOperation?: RowOperation;

  get primaryKey() {
    return this.key + 'Primary';
  }
  get secondaryKey() {
    return this.key + 'Secondary';
  }
  get totalKey() {
    return this.key + 'Total';
  }

  get isReadOnly() {
    return Array.isArray(this.primaryValue) || typeof this.primaryValue == 'string';
  }
  static getIcon(operation: RowOperation) {
    return operation == RowOperation.addition
      ? 'fas-plus'
      : operation == RowOperation.subtraction
      ? 'fas-minus'
      : operation == RowOperation.multiplication
      ? 'fas-times'
      : operation == RowOperation.division
      ? 'fas-divide'
      : operation == RowOperation.percent
      ? 'fas-percent'
      : 'fas-equals';
  }
  get summationIcon() {
    return ClaimTypeReportRow.getIcon(this.summationOperation);
  }
  get totalAmountIcon() {
    return ClaimTypeReportRow.getIcon(this.totalAmountOperation);
  }

  switchTotalOperation(val1?: number, val2?: number) {
    return ClaimTypeReportRow.switchOperation(this.totalAmountOperation, val1, val2);
  }
  static switchOperation(operation?: RowOperation, val1 = 0.0, val2?: number) {
    switch (operation) {
      case RowOperation.addition:
        return val1 + (val2 || 0);
      case RowOperation.subtraction:
        return val1 - (val2 || 0);
      case RowOperation.multiplication:
        return val1 * (val2 || 1);
      case RowOperation.division:
        return val1 / (val2 || 1);
      case RowOperation.percent:
        return val1 * ((val2 || 100) / 100);
      default:
        return val1 + (val2 || 0);
    }
  }

  constructor(data: Partial<Model.IClaimTypeReportRow>) {
    this.key = data.key;
    this.name = data.name;
    this.label = data.label;
    this.isHighlighted = data.isHighlighted;
    this.primaryValue = data.primaryValue;
    this.secondaryValue = data.secondaryValue;
    this.summationOperation = data.summationOperation;
    this.totalAmountOperation = data.totalAmountOperation;
  }

  toJSON() {
    return {
      key: this.key,
      name: this.name,
      label: this.label,
      isHighlighted: this.isHighlighted,
      primaryValue: this.primaryValue,
      secondaryValue: this.secondaryValue,
      summationOperation: this.summationOperation,
      totalAmountOperation: this.totalAmountOperation,
    };
  }
}
export default class ClaimTypeReportSettings
  extends ModelCore<Model.IClaimTypeReportSettings>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimTypeReportSettings>
{
  id: number;
  version: number;
  name: string;
  infoInputs: ClaimTypeReportInput[];
  reportRows: ClaimTypeReportRow[];

  get reportRowsForMapping() {
    return ClaimTypeReportSettings.reportRowsForMapping(this.reportRows);
  }
  static reportRowsForMapping(reportRows: Utils.FormData<Model.IClaimTypeReportRow>[]) {
    const { lang } = getLocales();
    return flatten(
      reportRows?.map((row) => {
        const map: Component.Input.Select.SelectionItem[] = [
          {
            id: row.key + 'Total',
            title: `${lang.totalValue}: ${row.key}`,
          },
        ];
        if (row.totalAmountOperation) {
          map.push(
            {
              id: row.key + 'Primary',
              title: `${lang.primaryValue}: ${row.key}`,
            },
            {
              id: row.key + 'Secondary',
              title: `${lang.secondaryValue}: ${row.key}`,
            }
          );
        }
        return map;
      })
    );
  }

  getRowByKey(key: string) {
    return this.reportRows.find((it) => it.key === key.replace(/(Primary|Secondary|Total)$/g, ''));
  }
  buildReportObject(data?: object) {
    const reportObj = new ReportObject({});
    const self = this;
    for (const row of self.reportRows.filter((it) => it.key)) {
      if (Array.isArray(row.primaryValue)) {
        const sumKeys = row.primaryValue;
        Object.defineProperty(reportObj, row.primaryKey, {
          get: function () {
            return roundToStep(
              sumKeys.reduce((sum, key) => {
                const currentRow = self.getRowByKey(key);
                return ClaimTypeReportRow.switchOperation(currentRow.summationOperation, sum, this[key]);
              }, 0.0),
              0.01
            );
          },
        });
      } else if (typeof row.primaryValue === 'string') {
        const key = row.primaryValue;
        Object.defineProperty(reportObj, row.primaryKey, {
          get: function () {
            return this[key];
          },
        });
      } else reportObj[row.primaryKey] = row.primaryValue;
      reportObj[row.secondaryKey] = row.secondaryValue;
      Object.defineProperty(reportObj, row.totalKey, {
        get: function () {
          return row.switchTotalOperation(this[row.primaryKey], this[row.secondaryKey]);
        },
      });
    }
    for (const key of Object.keys(data)) {
      if (!Object.getOwnPropertyDescriptor(this, key)?.get) {
        this[key] = data[key];
      }
    }
    reportObj.update(data);
    return reportObj;
  }

  constructor(
    data: Partial<Model.IClaimTypeReportSettings> | Utils.FormData<Model.IClaimTypeReportSettings>,
    language?: Locale.Languages
  ) {
    super({ reportRows: [ClaimTypeReportRow], infoInputs: [ClaimTypeReportInput] }, language);
    try {
      if (typeof data.infoInputs === 'string') data.infoInputs = JSON.parse(data.infoInputs);
      if (typeof data.reportRows === 'string') data.reportRows = JSON.parse(data.reportRows);
    } catch (e) {
      console.log(e);
    }
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id?.toString(),
    };
  }
}
