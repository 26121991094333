import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardComment from 'assets/components/card/themes/Theme.Card.Comment';
import Collection from 'assets/components/data/Collection';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import Sanitizer from 'assets/components/textBox/Sanitizer';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { orderBy } from 'lodash';
import ClaimCommentType from 'models/enums/ClaimCommentType';
import { useClaimComments } from '../ClaimEdit.Hooks';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';

type Props = {
  commentType: ClaimCommentType;
};
export default function ClaimEditComments(props: Props) {
  const { lang } = getLocales();
  const { isLoadingComments, comments, deleteApi, form, action, setAction, canEditComments } = useClaimComments(props);

  return (
    <div
      style={{
        marginLeft: Theme.Size.XL,
        marginRight: Theme.Size.XL,
      }}
    >
      <h2>{props.commentType === ClaimCommentType.external && lang.EXTERNAL_COMMENT}</h2>
      {action === 'delete' && (
        <Confirm
          data={form.data}
          onConfirm={({ id }) => deleteApi.execute({ id })}
          onClose={() => {
            setAction(null);
            form.update({});
          }}
          actionName={lang.delete}
          itemName={lang.comment}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: Theme.Size.M,
        }}
      >
        {!comments?.length && !isLoadingComments && <ErrorWrapper children={lang.noComments} />}
        <Collection
          isLoading={isLoadingComments}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: Theme.Size.S,
          }}
          data={orderBy(comments || [], (it) => it.createdAtUtcUnix, ['asc'])}
          map={({ data: comment }) => (
            <Card
              info={comment.displayInfo.info}
              title={comment.displayInfo.title}
              subtitle={<Sanitizer text={comment.displayInfo.subtitle} />}
              media={comment.displayInfo.media}
              class={ThemeCardComment}
              description={
                !(action === 'edit' && form.data.id === comment.id) ? null : (
                  <div
                    style={{
                      maxWidth: `calc(365px - ${Theme.Size.M})`,
                    }}
                  >
                    <MultilineTextInput name="comment" value={form.data.comment} onChange={form.update} />
                  </div>
                )
              }
              actions={
                <>
                  <div
                    //title={comment.likedByUsers.map(it => it.displayInfo.title).join(', ')}
                    style={{
                      marginRight: 'auto',
                      alignSelf: 'center',
                      fontSize: Theme.FontSize.S,
                    }}
                  >
                    {/*comment.likedByUsers?.length || 0} {lang.likes*/}
                    {comment.createdAtUtcUnix < comment.modifiedAtUtcUnix && <>&nbsp;({lang.edited})</>}
                  </div>
                  {action === 'edit' && form.data.id === comment.id ? (
                    <>
                      <Button
                        class={ThemeButtonCircle}
                        onClick={() => {
                          form.set({});
                          setAction(null);
                        }}
                        render={lang.cancel}
                      />
                      <Button
                        class={ThemeButtonCircle}
                        onClick={form.submit}
                        render={lang.save}
                        disabled={form.hasErrors || form.isSubmitted || !canEditComments}
                        active={!form.hasErrors || form.isSubmitted || !canEditComments}
                        isLoading={form.isSubmitted}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        class={ThemeButtonCircle}
                        onClick={() => {
                          setAction('edit');
                          form.set(comment);
                        }}
                        media="fas-edit"
                        disabled={!canEditComments}
                      />
                      <Button
                        class={ThemeButtonCircle}
                        onClick={() => {
                          setAction('delete');
                          form.set(comment);
                        }}
                        disabled={!canEditComments}
                        media="fas-trash"
                      />
                    </>
                  )}
                </>
              }
            />
          )}
        />
        {!action && !isLoadingComments && (
          <div>
            <MultilineTextInput name="comment" value={form.data.comment} onChange={form.update} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: Theme.Size.M }}>
              <Button
                class={ThemeButtonCircle}
                onClick={form.submit}
                render={lang.addComent}
                disabled={form.hasErrors || form.isSubmitted}
                active={!form.hasErrors}
                isLoading={form.isSubmitted}
                media="fas-paper-plane"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
