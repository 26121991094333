import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Params = Api.Core.Claim.SavePublic.IParams;
type Request = Api.Core.Claim.SavePublic.IRequest;
type Response = Api.Core.Claim.SavePublic.IResponse;
type Headers = Api.Core.Claim.SavePublic.IHeaders;
const url = '/api/Public/Claim/:contractNumber/:vin/:clientName';
export default async function claimSavePublic(props: { body: Request; params: Params }): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, props.params),
    body: props.body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
