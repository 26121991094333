import FormatValue from 'assets/components/formatValue/FormatValue';
import { getLocales } from 'assets/locales/Locale';
import { sumBy } from 'lodash';
import ClaimJob from 'models/claims/claimJob/Model.ClaimJob';
import React from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import Theme from 'assets/themes/Theme.Common';

export default function ClaimEditJobAggregateInfo() {
  const { lang } = getLocales();
  const { form, claimTypeReport } = useClaimJobEdit();
  const aggregates = form.data && !claimTypeReport ? ClaimJob.aggregates(new ClaimJob(form.data)) : null;

  return !aggregates ? null : (
    <div style={{ marginRight: 'auto', fontSize: Theme.FontSize.M, fontWeight: 'normal' }}>
      {form.data?.claimJobItems?.length ? `${form.data?.claimJobItems?.length} ${lang.totalItems}` : lang.noItemsAdded}
      &nbsp;&nbsp;|&nbsp;&nbsp;
      {lang.amount}:{' '}
      <b>
        <FormatValue value={`${sumBy(aggregates, (it) => it.total)}$`} />
      </b>{' '}
      &nbsp;&nbsp;|&nbsp;&nbsp;
      {lang.paidAmount}:{' '}
      <b>
        <FormatValue value={`${sumBy(aggregates, (it) => it.paidAmount)}$`} />
      </b>
    </div>
  );
}
