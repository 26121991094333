import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Account.ChangePassword.IRequest;
type Response = Api.Core.Account.ChangePassword.IResponse;
type Headers = Api.Core.Account.ChangePassword.IHeaders;
const apiAccountChangePasswordPath = '/api/Account/ChangePassword';
export default async function accountChangePasswordApi(request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: apiAccountChangePasswordPath,
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
