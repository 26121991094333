import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import { useClaimTypeProvider } from '../ClaimType.Hooks';

type Props = {
  actionName: string;
  onConfirm(data?: ClaimType & { confirmation?: any }): void;
};

export default function ClaimTypeDelete(props: Props) {
  const { selected, pageRouter } = useClaimTypeProvider();

  return (
    <Confirm
      data={selected}
      onConfirm={props.onConfirm}
      onClose={pageRouter.updateParams}
      actionName={props.actionName}
      itemName={selected.code}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
