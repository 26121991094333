import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.CustomPricePoint.Delete.IRequest;
type Response = Api.Core.CustomPricePoint.Delete.IResponse;
type Headers = Api.Core.CustomPricePoint.Delete.IHeaders;
const url = '/api/ProductManagement/CustomPricePoint/:id';
export default async function customPricePointDeleteApi(id: number): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.delete,
    url: createPathFromRoute(url, { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
