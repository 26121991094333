import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import DataMap from '../../dataMap/DataMap';
import { useTheme } from '../../../hooks/theme/useTheme';
import HeaderData from './HeaderData';
import './HeaderRow.scss';

export enum HeaderRowStructure {
  dataHeader = 'dataHeader',
  headerData = 'headerData',
  headerActions = 'headerActions',
}
export type Verify = Utils.VerifyExtends<Component.HeaderRow.Structure, typeof HeaderRowStructure>;
export default function HeaderRow(props: Component.HeaderRow.Import) {
  const extend = useTheme(HeaderRowStructure, props);

  return (
    <div {...extend(HeaderRowStructure.dataHeader, true)}>
      {props.headerData && (
        <DataMap
          data={props.headerData}
          render={({ isFirst, isLast, index, data }) => (
            <HeaderData
              key={index}
              {...extend(
                props.numOfActions && props.numOfActions > 0 && isLast
                  ? HeaderRowStructure.headerActions
                  : HeaderRowStructure.headerData
              )}
              disableImage={isFirst ? props.disableImage : null}
              numOfActions={isLast ? props.numOfActions : null}
            >
              {data}
            </HeaderData>
          )}
        />
      )}
      {RenderProps(props, 'render')}
    </div>
  );
}
