export default function freshworksIdentifyUser(name: string, email: string) {
  (window as any).FreshworksWidget(
    'identify',
    'ticketForm',
    {
      name,
      email,
    },
    {
      formId: 15413,
    }
  );
}
