import Model from 'models/Model';
import Insurer from '../../productManagement/insurer/Model.Insurer';

export default class ClaimTypeAggregate
  extends Model<Model.IClaimTypeAggregate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimTypeAggregate>
{
  id: number;
  version: number;

  totalAggregate: number;
  totalAggregatePriority: number;

  maxClaimAmount: number;
  maxClaimAmountPriority: number;

  maxNumberOfClaims: number;
  maxNumberOfClaimsPriority: number;

  houseAmount: number;
  insurerAmount: number;
  houseOpeningNotice: boolean;
  insurerOpeningNotice: boolean;
  allowOverride: boolean;
  insurer: Insurer;
  insurerId: number;

  constructor(
    data: Partial<Model.IClaimTypeAggregate> | Utils.FormData<Model.IClaimTypeAggregate>,
    language?: Locale.Languages
  ) {
    super({ insurer: Insurer }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.insurer?.name || this.id.toString(),
    };
  }
}
