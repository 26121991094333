export const CSS_COLOR_VARIABLES = {
  primary: ['--colorPrimary', '--colorPrimaryAlt', '--colorPrimaryDark', '--colorPrimaryLight'],
  secondary: ['--colorSecondary', '--colorSecondaryAlt', '--colorSecondaryDark', '--colorSecondaryLight'],
  neutral: ['--colorNeutral', '--colorNeutralAlt', '--colorNeutralDark', '--colorNeutralLight'],
  shadow: ['--colorShadow', '--colorShadowDark', '--colorShadowDarker', '--colorShadowLight', '--colorShadowLighter'],
  highlight: ['--colorHighlight', '--colorHighlightLight', '--colorHighlightLighter'],
  text: ['--colorText', '--colorTextAlt', '--colorDisabledText', '--colorIcon'],
  background: ['--colorBackground', '--colorBackgroundDark', '--colorBackgroundLight', '--colorDisabledBackground'],
  messages: ['--colorError', '--colorWarning', '--colorSuccess'],
};
