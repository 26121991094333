import RenderProps from 'assets/components/helpers/RenderProps';
import React, { useContext } from 'react';

type Default<T> = {
  children?: Utils.ReactNodes;
  render?: Utils.RenderProps<T>;
};
type Props<P, R> = Default<R> & (P extends Utils.ValueTypes | undefined | null ? { value?: P } : P);
export default function ProviderBuilder<F extends (params: any) => any>(useCustomHook: F) {
  let Context!: React.Context<ReturnType<F>>;

  function Provider({ children, render, ...props }: Props<Parameters<F>['0'], ReturnType<F>>) {
    const hook = useCustomHook(props);
    if (!Context) Context = React.createContext(hook);
    return <Context.Provider value={hook} children={RenderProps({ children, render }, 'render', hook)} />;
  }

  function useProvider() {
    return useContext(Context);
  }

  return { Context, Provider, useProvider };
}

export function DataProviderBuilder<T>() {
  let Context!: React.Context<T>;

  function useProvider() {
    try {
      return useContext(Context);
    } catch {
      return null;
    }
  }
  function Provider({ children, render, data }: Default<T> & { data: T }) {
    if (!Context) Context = React.createContext(data);

    return <Context.Provider value={data} children={RenderProps({ children, render }, 'render', data)} />;
  }

  return { Context, Provider, useProvider };
}
