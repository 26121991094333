import React from 'react';
import _ from 'lodash';

type Structure = {
  [key: string]: string;
};
export function useTheme<S extends Structure>(
  structure: S,
  props: Utils.Import<S>,
  localProps?: Utils.Import<S>
): Utils.Hooks<Utils.KeyOf<S>> {
  return (htmlTag: keyof S, isMain?: boolean): Utils.CustomElementProps => {
    let snippetProps: Utils.CustomElementProps = {};
    const finalProps = _.merge(localProps || {}, props || {});

    const className = `${String(htmlTag)} ${extendClass(finalProps, htmlTag, isMain)}`;
    const style = extendStyle(structure, finalProps, htmlTag, isMain);
    const htmlElementProps = extendHtmlElementProps(structure, finalProps, htmlTag, isMain);
    const animationEvents = extendAnimation(structure, finalProps, htmlTag, isMain);

    if (className) snippetProps.className = className.trim();
    if (style) snippetProps.style = style;
    if (finalProps.active && isMain) snippetProps['data-active'] = true;
    if (finalProps.clickable && isMain) snippetProps['data-clickable'] = true;
    if (finalProps.disabled && isMain) snippetProps['data-disabled'] = true;
    if (finalProps.isLoading && isMain) snippetProps['data-loading'] = true;
    if (htmlElementProps) {
      const { data, ...restProps } = htmlElementProps;
      const finalData = {} as any;
      if (data)
        Object.entries(data).forEach(([key, val]) => {
          finalData[`data-${key}`] = val;
        });
      snippetProps = _.merge(snippetProps, restProps, finalData);
    }
    if (animationEvents) snippetProps = _.merge(snippetProps, animationEvents);
    return snippetProps;
  };
}
function extendClass<S extends Structure>(props: Utils.Import<S>, htmlTag: Utils.KeyOf<S>, isMain?: boolean): string {
  if (typeof props.class == 'string') return isMain ? props.class : '';
  else return (props.class && typeof props.class == 'object' && (props.class[htmlTag] as string)) || '';
}
function extendStyle<S extends Structure>(
  structure: S,
  props: Utils.Import<S>,
  htmlTag: Utils.KeyOf<S>,
  isMain?: boolean
): React.CSSProperties | null {
  let final: React.CSSProperties = {};
  let appended: React.CSSProperties | null = null;
  iterateProps(
    structure,
    props.style,
    (val, key) => {
      if (isMain) (final as any)[key] = val as any;
    },
    (nested, key) => {
      if (key == htmlTag) appended = nested;
    }
  );
  if (appended) final = _.merge(final, appended);
  return final;
}
function extendHtmlElementProps<S extends Structure>(
  structure: S,
  props: Utils.Import<S>,
  htmlTag: Utils.KeyOf<S>,
  isMain?: boolean
): Utils.HTMLElementProps {
  let final: Utils.HTMLElementProps = {};
  let appended: Utils.HTMLElementProps | null = null;
  iterateProps(
    structure,
    props.htmlElementProps,
    (val, key) => {
      if (isMain) final[key] = val as any;
    },
    (nested, key) => {
      if (key == htmlTag) appended = nested;
    }
  );
  if (appended) final = _.merge(final, appended);
  return final;
}
function extendAnimation<S extends Structure>(
  structure: S,
  props: Utils.Import<S>,
  htmlTag: Utils.KeyOf<S>,
  isMain?: boolean
): Utils.AnimationEvents {
  let anim: Utils.AnimationOptions = {};
  let appended: Utils.AnimationOptions | null = null;
  iterateProps(
    structure,
    props.animation,
    (val, key) => {
      if (isMain) anim[key as any] = val as any;
    },
    (nested, key) => {
      if (key == htmlTag) appended = nested;
    }
  );
  if (appended) anim = _.merge(anim, appended);

  const animationEvents: Utils.AnimationEvents = {};
  let tryToAnimate: any = null;
  if (props.animation) {
    switch (anim.trigger) {
      case 'hover':
        if (props.animateIn) {
          animationEvents.onMouseEnter = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            if (props.animateIn) props.animateIn(event);
          };
        }
        if (props.animateOut) {
          animationEvents.onMouseLeave = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            if (props.animateOut) props.animateOut(event);
          };
        }
        break;
      case 'click':
        if (props.animateIn) {
          animationEvents.onClick = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            if (tryToAnimate) {
              clearTimeout(tryToAnimate);
              tryToAnimate = null;
            } else if (props.animateIn) props.animateIn(event);
          };
        }
        if (props.animateOut) {
          animationEvents.onBlur = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            tryToAnimate = setTimeout(() => {
              tryToAnimate = null;
              if (props.animateOut) props.animateOut(event);
            }, 200);
          };
        }
        animationEvents.tabIndex = 0;
        break;
      case 'focus':
        if (props.animateIn) {
          animationEvents.onFocus = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            if (tryToAnimate) {
              clearTimeout(tryToAnimate);
              tryToAnimate = null;
            }
            if (props.animateIn) props.animateIn(event);
          };
        }
        if (props.animateOut) {
          animationEvents.onBlur = (event: any): void => {
            event.preventDefault();
            event.stopPropagation();
            tryToAnimate = setTimeout(() => {
              tryToAnimate = null;
              if (props.animateOut) props.animateOut(event);
            }, 200);
          };
        }
        animationEvents.tabIndex = 0;
        break;
    }
  }
  if (typeof anim.animate == 'boolean') {
    animationEvents['data-animation'] = anim.animate ? 'in' : 'out';
  }
  return animationEvents;
}

function iterateProps<T, S extends Structure>(
  structure: S,
  propData: T | Utils.Modifier<S, T> | undefined,
  onData: (data: Utils.ValueOf<T>, key: Utils.KeyOf<T>) => void,
  onNested: (data: Utils.Modifier<S, T>, key: Utils.KeyOf<S>) => void
) {
  if (!propData) return;
  Object.keys(propData).forEach((key) => {
    if (!structure[key]) onData((propData as any)[key], key as Utils.KeyOf<T>);
    else onNested((propData as any)[key], key);
  });
}
