import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageSplit from 'assets/components/page/themes/Theme.Page.Split';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import Theme from 'assets/themes/Theme.Common';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { salesPayAndSignContractIndexRoute } from '../../payAndSignContract/Sales.PayAndSignContract.Index';
import { SalesContractInfoTab, useSalesContractProvider } from '../Sales.Contract.Hooks';
import ContractInfoDocuments from './info/Contract.Info.Documents';
import ContractInfoGeneralInfo from './info/Contract.Info.GeneralInfo';
import ContractInfoNavigation from './info/Contract.Info.Navigation';
import ContractInfoRevisions from './info/Contract.Info.Revisions';
import ContractConsentRequestType from 'models/enums/ContractConsentRequestType';
import { isNull } from 'lodash';

export default function ContractInfo() {
  const { lang } = getLocales();
  const { selected, selectedProduct, pageRouter, resendContractConsent } = useSalesContractProvider();
  const requiresPayment =
    !selected?.isPayed && selected?.dealer?.isPaymentRequired && selected?.contractConsent?.isAccepted === true;
  const requiresSignature =
    !selected?.isSigned && !selected?.isSentForSigning && selected?.contractConsent?.isAccepted === true;
  selectedProduct?.productDocuments?.some((d) => d.productSignatureFields?.length);
  const canResendConsent = isNull(selected?.contractConsent?.isAccepted);
  return !selected ? null : (
    <Page
      class={ThemePageSplit}
      header={
        <Card
          class={ThemeCardRow}
          media={<Icon class="if-database" />}
          info={selected?.statusCode?.replace('_', ' - ')}
          title={selected?.appNumber + (selected?.revisionNumber ? `-R${selected?.revisionNumber}` : '')}
          subtitle={selected?.vehicle?.vin}
          description={
            <>
              {selected?.isPayed && (
                <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                  <Icon class="fas-check" />
                  &nbsp; Paid
                </Box>
              )}
              {(selected?.isSigned || selected?.isSentForSigning) && (
                <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                  &nbsp;
                  <Icon class="fas-check" />
                  {selected?.isSigned ? 'Signed' : 'Sent for signing'}
                </Box>
              )}
            </>
          }
        />
      }
      sidebar={
        <>
          {(!pageRouter.query.infoTab || pageRouter.query.infoTab === SalesContractInfoTab.INFO) && (
            <ContractInfoGeneralInfo />
          )}
          {pageRouter.query.infoTab === SalesContractInfoTab.DOCUMENTS && <ContractInfoDocuments />}
          {pageRouter.query.infoTab === SalesContractInfoTab.REVISIONS && <ContractInfoRevisions />}
        </>
      }
      render={<ContractInfoNavigation />}
      footer={
        <>
          {(requiresPayment || requiresSignature) && (
            <LinkButton
              class={ThemeButtonCircle}
              url={createPathFromRoute(
                salesPayAndSignContractIndexRoute,
                {},
                {
                  contractId: selected.id,
                }
              )}
              media={<Icon class="fas-receipt" />}
              render={requiresPayment && requiresSignature ? 'Pay & sign' : requiresPayment ? 'Pay' : 'Sign'}
            />
          )}
          {canResendConsent && (
            <DropdownButton
              title={lang.sendConsent}
              buttonMedia={<Icon class="fas-envelope" />}
              buttonBody={lang.resendConsent}
              horizontalPosition="auto_fixed"
              verticalPosition="auto_fixed"
              constrainment="unconstrained"
              disableWidthConstraint
              subcomponents={ThemeDropdownButtonPopup}
              htmlElementProps={{ title: lang.sendConsent }}
              dropdownOptions={
                <>
                  <Button
                    onClick={resendContractConsent}
                    render={lang.email}
                    class={ThemeButtonMenuItem}
                    data={{ id: selected?.contractConsentId, requestType: ContractConsentRequestType.EMAIL }}
                  />
                  <Button
                    onClick={resendContractConsent}
                    render={lang.TEXT}
                    class={ThemeButtonMenuItem}
                    disabled={true}
                    data={{ id: selected?.contractConsentId, requestType: ContractConsentRequestType.TEXT }}
                  />
                </>
              }
            />
          )}
        </>
      }
    />
  );
}
