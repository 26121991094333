import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Params = Api.Core.ClaimComment.Like.IParams;
type Response = Api.Core.ClaimComment.Like.IResponse;
type Headers = Api.Core.ClaimComment.Like.IHeaders;
const url = '/api/Claims/ClaimComment/Like/:commentId';
export default async function claimCommentLikeApi(params: Params): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.put,
    url: createPathFromRoute(url, params),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
