import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import { combineStrings } from 'assets/utils/data/String';
import { flatten } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import contractDocumentDeleteApi from 'models/sales/contract/contractDocument/delete/Api.ContractDocument.Delete';
import contractDocumentSaveApi from 'models/sales/contract/contractDocument/save/Api.ContractDocument.Save';
import React, { useEffect, useMemo } from 'react';
import { useSalesContractProvider } from '../../Sales.Contract.Hooks';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormatValue from 'assets/components/formatValue/FormatValue';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';

export default function ContractInfoDocuments() {
  const { lang } = getLocales();
  const { selected, subfeeApi, listApi, permissions } = useSalesContractProvider();
  const subfeeDocs = flatten(subfeeApi?.payload?.data?.map((it) => it.documents));
  const productApi = useApi({
    action: Product.find,
    wait: true,
    body: {
      id: selected?.productId,
    },
  });
  useEffect(() => {
    if (selected?.productId) productApi.execute((b) => b);
    else productApi.reset(true);
  }, [selected?.productId]);

  const { execute: deleteDoc } = useApi({
    action: contractDocumentDeleteApi,
    wait: true,
    callback: () => listApi.execute((b) => b),
  });
  const { execute: saveDoc } = useApi({
    action: contractDocumentSaveApi,
    wait: true,
    callback: () => listApi.execute((b) => b),
  });
  const onDelete = (documentId: number) => deleteDoc({ id: selected?.id, documentId });
  const docForm = useForm<Utils.FormData<Model.IContractDocument>>({
    default: {
      contractId: selected?.id,
    },
    validation: (data, errors) => {
      if (!data.formFile) errors.formFile = lang.required;
    },
    onSubmit: saveDoc,
  });
  useEffect(() => {
    if (docForm?.data?.contractId !== selected?.id) {
      docForm.update({ contractId: selected?.id });
    }
  }, [selected?.id]);
  const formFile = useMemo(
    () =>
      docForm.data.formFile && {
        original: docForm.data.formFile,
        type: docForm.data.formFile.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(docForm.data.formFile),
      },
    [docForm.data.formFile]
  );
  return (
    <>
      <Collection class={combineStrings(' ', ThemeCollectionTable, ThemeWrapperPaper)} style={{ padding: 0 }}>
        <div
          style={{
            fontSize: Theme.FontSize.L,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {lang.documents}
        </div>
        {!selected.productDocuments?.length &&
          !subfeeDocs?.length &&
          !selected.documents?.length &&
          !selected?.documentationAggregate && <ErrorWrapper children={lang.noFiles} />}
        {selected?.documentationAggregate && (
          <Card
            class={ThemeCardRow}
            info={lang.contractDocument}
            title={selected?.documentationAggregate?.name}
            media={<Icon class="fas-folder" />}
            actions={
              <>
                <LinkButton
                  class={ThemeButtonCircle}
                  url={selected.documentationAggregate.url}
                  media={<Icon class="fas-download" />}
                  target="_blank"
                  htmlElementProps={{ title: lang.download }}
                />
              </>
            }
          />
        )}
        <DataMap
          data={selected.documents}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              info={lang.generated}
              media={<Icon class="fas-file" />}
              actions={
                <LinkButton
                  class={ThemeButtonCircle}
                  url={doc.url}
                  media={<Icon class="fas-download" />}
                  target="_blank"
                  disabled={!doc.id}
                  htmlElementProps={{ title: lang.download }}
                />
              }
            />
          )}
        />
        <DataMap
          data={subfeeDocs}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              info={lang.surcharge}
              media={<Icon class="fas-dollar-sign" />}
              actions={
                <>
                  <LinkButton
                    class={ThemeButtonCircle}
                    url={doc.url}
                    media={<Icon class="fas-download" />}
                    target="_blank"
                    disabled={!doc.id}
                    htmlElementProps={{ title: lang.download }}
                  />
                </>
              }
            />
          )}
        />
        <DataMap
          data={selected.productDocuments}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              info={lang.uploaded}
              subtitle={combineStrings(': ', lang.notes, doc.notes)}
              media={<Icon class="fas-upload" />}
              actions={
                <LinkButton
                  class={ThemeButtonCircle}
                  url={doc.mediaFile.url}
                  media={<Icon class="fas-download" />}
                  target="_blank"
                  disabled={!doc.id}
                  htmlElementProps={{ title: lang.download }}
                />
              }
            />
          )}
        />
        <DataMap
          data={selected.contractDocuments}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              info={
                <>
                  {lang.uploadedBy}{' '}
                  {combineStrings(' ', doc.createdBy?.firstName, doc.createdBy?.lastName) ?? lang.unknownUser} {lang.at}{' '}
                  <FormatValue value={doc.createdAtUtc} />
                </>
              }
              subtitle={combineStrings(': ', lang.notes, doc.notes)}
              media={<Icon class="fas-file-upload" />}
              actions={
                <>
                  <LinkButton
                    class={ThemeButtonCircle}
                    url={doc.mediaFile.url}
                    media={<Icon class="fas-download" />}
                    target="_blank"
                    disabled={!doc.id}
                    htmlElementProps={{ title: lang.download }}
                  />
                  <Button
                    class={ThemeButtonCircle}
                    onClick={onDelete}
                    data={doc.id}
                    media={<Icon class="fas-trash" />}
                    disabled={!doc.id || !permissions.UPLOAD_DOCUMENTS_CONTRACT}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </>
              }
            />
          )}
        />
      </Collection>
      {permissions.UPLOAD_DOCUMENTS_CONTRACT && (
        <>
          <br />
          <FormWrapper class={ThemeWrapperPaper}>
            <h3>{lang.additionalDocumentation}</h3>
            <TextInput
              name="name"
              label={lang.name}
              value={docForm.data.name}
              onChange={docForm.update}
              error={docForm.errors.name}
            />
            <MultilineTextInput
              name="notes"
              label={lang.notes}
              value={docForm.data.notes}
              onChange={docForm.update}
              error={docForm.errors.notes}
            />
            <FileInput
              name="file"
              fileLimit={1}
              value={formFile ? [formFile] : []}
              onChange={({ file }) => {
                const formFile = file && file[0]?.original;
                docForm.update({ formFile });
              }}
              class={ThemeFileInputDropzone}
              error={docForm.errors.formFile?._objectError}
            />
            <Button
              render={lang.add}
              media={<Icon class="fas-upload" />}
              onClick={docForm.submit}
              disabled={docForm.hasErrors}
              active={!docForm.hasErrors}
              class={ThemeButtonCircle}
              htmlElementProps={{ title: lang.add }}
            />
          </FormWrapper>
        </>
      )}
    </>
  );
}
