import React, { Children } from 'react';
import RenderProps from '../helpers/RenderProps';
import { useTheme } from '../../hooks/theme/useTheme';

export enum BoxStructure {
  box = 'box',
}
export type Verify = Utils.VerifyExtends<Component.Box.Structure, typeof BoxStructure>;
export default function Box(props: Component.Box.Import) {
  const extend = useTheme(BoxStructure, props);

  if (props.disableOnEmpty && (Children.count(props.children) === 0 || !props.children) && !props.render) return null;
  if (props.dontWrap) return <>{RenderProps(props)}</>;
  return <div {...extend(BoxStructure.box, true)}>{RenderProps(props)}</div>;
}
