import { PricingPlanInfo, ProductPriceInfo, ProductSubfeeInfo } from 'app/scripts/contract/calculatePricesForContract';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import NumberInputTextValue from 'assets/components/inputs/number/NumberInputTextValue';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { compact, sortBy, uniq, uniqBy } from 'lodash';
import ProductType from 'models/enums/ProductType';
import { Fragment, useState } from 'react';
import { ContractProduct, useSalesQuoteProvider } from '../Sales.Quote.Hooks';
import RenderProps from 'assets/components/helpers/RenderProps';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';

//#region Subfees
type SubfeeTableProps = {
  product: ContractProduct;
  updateProduct(product: ContractProduct): void;
  pricePointSubfees?: Array<ProductSubfeeInfo>;
};
function SubfeeTable({ product, updateProduct, pricePointSubfees }: SubfeeTableProps) {
  const { lang } = getLocales();

  const subfees = product.subfee ?? [];

  const updateSubfee = (
    subfee: Model.ISubfee,
    currentSubfee: Utils.PricingInfo,
    delta: number,
    amount: number,
    currency?: string
  ) => {
    const quantity = (currentSubfee?.quantity || 0) + delta;

    if (quantity <= 0) {
      if (product.subfees) {
        product.subfees = product.subfees.filter((it) => it.subfeeId !== subfee.id);
      }
    } else {
      const currentSubfee = product.subfees?.find((it) => it.subfeeId === subfee.id);
      if (currentSubfee) {
        currentSubfee.amount = amount;
        currentSubfee.currency = currency;
        currentSubfee.quantity = quantity;
      } else {
        product.subfees = [...(product.subfees ?? []), { amount, currency, quantity, subfeeId: subfee.id }];
      }
    }
    updateProduct({
      ...product,
      surchargesPriceCAD: product.subfees?.reduce((p, c) => p + (c.amount ?? 0) * (c.quantity ?? 1), 0) ?? 0,
    });
  };

  return (
    <table className={ThemeTableSimple2}>
      <thead>
        <tr>
          <th style={{ textAlign: 'left' }}>{lang.surcharge}</th>
          <th style={{ textAlign: 'center' }}>{lang.quantity}</th>
          <th style={{ textAlign: 'right' }}>{lang.price}</th>
        </tr>
      </thead>
      <tbody>
        <DataMap
          data={subfees}
          render={({ data: subfee }) => {
            const pricePointSubfee = pricePointSubfees?.find((pps) => pps.subfeeId === subfee.id);
            const amount = pricePointSubfee?.amount || subfee.amount;
            const currency = pricePointSubfee?.currency || subfee.currency;
            const currentSubfee = product.subfees?.find((it) => it.subfeeId === subfee.id);
            return (
              <tr key={subfee.id}>
                <td style={{ textAlign: 'left' }}>{subfee.name}</td>
                <td style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: Theme.Size.S, alignSelf: 'center' }}>
                    <Button
                      media="fas-minus"
                      class={ThemeButtonCircle}
                      disabled={currentSubfee?.quantity === 0 || !currentSubfee?.quantity}
                      onClick={() => {
                        product.salePriceCAD = product.salePriceCAD - amount;
                        updateSubfee(subfee, currentSubfee, -1, amount, currency);
                      }}
                      htmlElementProps={{ title: lang.remove }}
                    />
                    <span>{currentSubfee?.quantity || 0}</span>
                    <Button
                      media="fas-plus"
                      class={ThemeButtonCircle}
                      disabled={currentSubfee?.quantity >= 1}
                      onClick={() => {
                        product.salePriceCAD = product.salePriceCAD + amount;
                        updateSubfee(subfee, currentSubfee, 1, amount, currency);
                      }}
                      htmlElementProps={{ title: lang.add }}
                    />
                  </div>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <b>
                    <FormatValue value={`${amount.toFixed(2)}${!currency || currency === 'CAD' ? '$' : currency}`} />
                  </b>
                </td>
              </tr>
            );
          }}
        />
      </tbody>
    </table>
  );
}
//#endregion

//#region Price
type PriceCellProps = {
  foundPrice: ProductPriceInfo;
  selectedPrice?: ProductPriceInfo;
  surchargesPriceCAD?: number;
  product: ContractProduct;
  updateProduct(product: ContractProduct): void;
};
function PriceCell({ foundPrice, selectedPrice, product, updateProduct }: PriceCellProps) {
  if (!foundPrice) {
    return (
      <td
        style={{
          textAlign: 'right',
          cursor: 'no-drop',
        }}
      >
        N/A
      </td>
    );
  }
  return (
    <td
      style={{
        textAlign: 'right',
        cursor: 'pointer',
        backgroundColor: selectedPrice?.pricePointId === foundPrice?.pricePointId ? Theme.Color.primary : null,
        color: selectedPrice?.pricePointId === foundPrice?.pricePointId ? Theme.Color.primaryAlt : null,
      }}
      onClick={() => {
        updateProduct({
          ...product,
          pricePointId: foundPrice.pricePointId,
          priceCAD: foundPrice.priceCAD,
          salePriceCAD: foundPrice.salePriceCAD + (product?.surchargesPriceCAD ?? 0),
          surchargesPriceCAD: product?.surchargesPriceCAD ?? 0,
          minPriceCAD: foundPrice.minPriceCAD,
          maxPriceCAD: foundPrice.maxPriceCAD,
          originalPriceCAD: foundPrice.originalPriceCAD,
          discountPriceCAD: foundPrice.discountPriceCAD,
          distributorPriceCAD: foundPrice.distributorPriceCAD,
          pricingDistributions: foundPrice.pricingDistributions,
          distributorDistributions: foundPrice.distributorDistributions,
          maxKm: foundPrice.maxKm,
          termInMonths: foundPrice.termInMonths,
          customInfo: foundPrice.customInfo,
          customInfoGroup: foundPrice.customInfoGroup,
          maxVehiclePriceCAD: foundPrice.maxVehiclePriceCAD,
          minVehiclePriceCAD: foundPrice.minVehiclePriceCAD,
          tier: foundPrice.tier,
          class: foundPrice.class,
          warrantyOption: product.warrantyOption,
          postSale: product.postSale,
          isPackage: product.isPackage,
          deductible: foundPrice.deductible,
          subfees: product.subfees?.map((v, index: number) => {
            const pricePointSubfee = foundPrice.subfees?.find((s) => s.subfeeId === v.subfeeId);
            if (!pricePointSubfee) return product.subfees[index];
            return {
              ...v,
              currency: pricePointSubfee.currency,
              amount: pricePointSubfee.amount,
            };
          }),
        });
      }}
    >
      <b>
        <FormatValue value={`${foundPrice?.priceCAD?.toFixed(2)}$`} />
      </b>
    </td>
  );
}
//#endregion

//#region Pricing selection
type PricingProps = {
  product: ContractProduct;
  prices: Array<ProductPriceInfo>;
  updateProduct(product: ContractProduct): void;
};

function CustomSelection({ product, prices, updateProduct }: PricingProps) {
  const { lang } = getLocales();
  const terms = uniq(prices?.map((it) => it.termInMonths));
  const customInfoList = uniq(prices?.map((it) => it.customInfo));
  const customInfoGroupList = uniq(prices?.map((it) => it.customInfoGroup ?? null));

  const selectedPricePoint =
    prices?.find((it) => it.pricePointId === product.pricePointId) ??
    prices?.find(
      (pp) =>
        pp.customInfo === product.customInfo &&
        pp.termInMonths === product.termInMonths &&
        pp.customInfoGroup === product.customInfoGroup
    );

  return (
    <>
      <DataMap
        data={customInfoGroupList}
        render={({ data: customInfoGroup, isFirst }) => (
          <Fragment key={customInfoGroup}>
            {!isFirst && <br />}
            <table className={ThemeTableSimple2}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'right' }}>{customInfoGroup ?? lang.prices}</th>
                  <DataMap
                    data={terms}
                    render={({ data: termInMonths }) => (
                      <th key={termInMonths} style={{ textAlign: 'right' }}>
                        {termInMonths}m
                      </th>
                    )}
                  />
                </tr>
              </thead>
              <tbody>
                <DataMap
                  data={customInfoList}
                  render={({ data: customInfo }) => (
                    <tr key={customInfo}>
                      <td style={{ textAlign: 'right' }}>{customInfo}</td>
                      <DataMap
                        data={terms}
                        render={({ data: termInMonths }) => (
                          <PriceCell
                            key={termInMonths}
                            product={product}
                            updateProduct={updateProduct}
                            selectedPrice={selectedPricePoint}
                            foundPrice={prices?.find(
                              (it) =>
                                it.termInMonths === termInMonths &&
                                it.customInfo === customInfo &&
                                it.customInfoGroup == customInfoGroup
                            )}
                          />
                        )}
                      />
                    </tr>
                  )}
                />
              </tbody>
            </table>
          </Fragment>
        )}
      />
      {!!product.subfee.length && selectedPricePoint && (
        <>
          <br />
          <SubfeeTable
            product={product}
            updateProduct={updateProduct}
            pricePointSubfees={selectedPricePoint?.subfees}
          />
        </>
      )}
    </>
  );
}

function NonWarrantySelection({ product, prices, updateProduct }: PricingProps) {
  const { lang } = getLocales();
  const terms = uniq(prices?.map((it) => it.termInMonths));
  const ranges = uniqBy(
    prices?.map(({ minVehiclePriceCAD, maxVehiclePriceCAD }) => ({
      maxVehiclePriceCAD,
      minVehiclePriceCAD,
      label: `${minVehiclePriceCAD?.toFixed(2)}CAD - ${maxVehiclePriceCAD?.toFixed(2)}$`,
    })),
    (it) => it.label
  );
  const selectedPricePoint =
    prices?.find((it) => it.pricePointId === product.pricePointId) ??
    prices?.find(
      (pp) =>
        pp.minVehiclePriceCAD === product.minVehiclePriceCAD &&
        pp.maxVehiclePriceCAD === product.maxVehiclePriceCAD &&
        pp.termInMonths === product?.termInMonths
    );
  return (
    <>
      <table className={ThemeTableSimple2}>
        <thead>
          <tr>
            <th style={{ textAlign: 'right' }}>{lang.price}</th>
            <DataMap
              data={terms}
              render={({ data: termInMonths }) => (
                <th key={termInMonths} style={{ textAlign: 'right' }}>
                  {termInMonths}m
                </th>
              )}
            />
          </tr>
        </thead>
        <tbody>
          <DataMap
            data={ranges}
            render={({ data: { maxVehiclePriceCAD, minVehiclePriceCAD, label } }) => (
              <tr key={label}>
                <td style={{ textAlign: 'right' }}>
                  <FormatValue value={`${minVehiclePriceCAD?.toFixed(2)}$`} /> -{' '}
                  <FormatValue value={`${maxVehiclePriceCAD?.toFixed(2)}$`} />
                </td>
                <DataMap
                  data={terms}
                  key={label}
                  render={({ data: termInMonths }) => (
                    <PriceCell
                      key={termInMonths}
                      product={product}
                      updateProduct={updateProduct}
                      selectedPrice={selectedPricePoint}
                      foundPrice={prices?.find(
                        (it) =>
                          it.termInMonths === termInMonths &&
                          it.maxVehiclePriceCAD === maxVehiclePriceCAD &&
                          it.minVehiclePriceCAD === minVehiclePriceCAD
                      )}
                    />
                  )}
                />
              </tr>
            )}
          />
        </tbody>
      </table>
      {!!product.subfee.length && selectedPricePoint && (
        <>
          <br />
          <SubfeeTable
            product={product}
            updateProduct={updateProduct}
            pricePointSubfees={selectedPricePoint?.subfees}
          />
        </>
      )}
    </>
  );
}

function WarrantyTierBased({ product, prices, updateProduct }: PricingProps) {
  const { lang } = getLocales();
  const terms = uniq(prices?.map((it) => it.termInMonths));
  const tiers = uniqBy(
    prices?.map((it) => ({ tierLabel: it.tier, tierId: it.tierId })),
    (it) => it.tierId
  );
  const selectedPricePoint =
    prices?.find((it) => it.pricePointId === product.pricePointId) ??
    prices?.find((pp) => pp.tier === product.tier && pp.termInMonths === product?.termInMonths);
  return (
    <>
      <div style={{ overflow: 'auto' }}>
        <table className={ThemeTableSimple2}>
          <thead>
            <tr>
              <th style={{ textAlign: 'right' }}>{lang.tiers}</th>
              <DataMap
                data={terms}
                render={({ data: termInMonths }) => (
                  <th key={termInMonths} style={{ textAlign: 'right' }}>
                    {termInMonths}m
                  </th>
                )}
              />
            </tr>
          </thead>
          <tbody>
            <DataMap
              data={tiers}
              render={({ data: { tierId, tierLabel } }) => (
                <tr key={tierLabel}>
                  <td style={{ textAlign: 'right' }}>{tierLabel}</td>
                  <DataMap
                    data={terms}
                    render={({ data: termInMonths }) => (
                      <PriceCell
                        key={termInMonths}
                        product={product}
                        updateProduct={updateProduct}
                        selectedPrice={selectedPricePoint}
                        foundPrice={prices?.find((it) => it.termInMonths === termInMonths && it.tierId === tierId)}
                      />
                    )}
                  />
                </tr>
              )}
            />
          </tbody>
        </table>
      </div>
      {!!product.subfee.length && selectedPricePoint && (
        <>
          <br />
          <SubfeeTable
            product={product}
            updateProduct={updateProduct}
            pricePointSubfees={selectedPricePoint?.subfees}
          />
        </>
      )}
    </>
  );
}

function WarrantyClassBasedTableSegment(props: {
  isVisible?: boolean;
  render: Utils.RenderProps<{ isVisible: boolean; setIsVisible: React.Dispatch<React.SetStateAction<boolean>> }>;
}) {
  const [isVisible, setIsVisible] = useState(props.isVisible ?? false);
  return <>{RenderProps(props, 'render', { isVisible, setIsVisible })}</>;
}

function WarrantyClassBased({ product, prices, updateProduct }: PricingProps) {
  const { lang } = getLocales();
  const { form } = useSalesQuoteProvider();

  const pricingPlans = prices.map((productPriceInfo) => productPriceInfo.pricingPlanInfo);
  const filteredpricingPlans: PricingPlanInfo[] = pricingPlans.reduce(
    (accumulator, current) => {
      if (!accumulator.ids.has(current.pricingPlanId)) {
        accumulator.ids.add(current.pricingPlanId);
        accumulator.filtered.push(current);
      }
      return accumulator;
    },
    { ids: new Set(), filtered: [] as Array<PricingPlanInfo> }
  ).filtered;

  const customInfos = uniq(compact(prices?.map((it) => it.customInfo)));

  const deductibles = uniqBy(
    prices?.map(({ deductible }) => deductible || 0),
    (it) => it
  );

  const limits = (pricingPlanId: number) =>
    uniqBy(
      prices
        ?.filter((it) => it.pricingPlanInfo.pricingPlanId === pricingPlanId)
        .map((it) => ({
          termInMonths: it.termInMonths,
          maxKm: it.maxKm,
        }))
        .filter((it) => it.maxKm && it.termInMonths),
      (it) => `${it.termInMonths}-${it.maxKm}`
    );

  const classes = uniqBy(
    prices?.map(({ classId }) => {
      const vc = form.data.vehicle?.vehicleModel?.vehicleClasses?.find((vc) => vc.id === classId);
      return {
        classId,
        label: combineStrings(' - ', vc?.primaryCode, vc?.secondaryCode),
      };
    }),
    (it) => it.classId
  );

  const selectedPricePoint =
    prices?.find((it) => it.pricePointId === product.pricePointId) ??
    prices?.find(
      (pp) =>
        pp.class === product?.class &&
        pp.customInfo === product?.customInfo &&
        pp.maxKm === product?.maxKm &&
        pp.termInMonths === product?.termInMonths &&
        pp.deductible === product?.deductible &&
        pp.pricingPlanInfo.warrantyOption === product?.warrantyOption
    );

  return (
    <>
      <div
        style={{
          display: 'block',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '100%',
          height: '600px',
        }}
      >
        <DataMap
          data={filteredpricingPlans}
          render={({ data: pricingPlan }) => (
            <Fragment key={pricingPlan.pricingPlanId}>
              <table className={ThemeTableSimple2}>
                <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10 }}>
                  <tr data-active>
                    <th
                      style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                      colSpan={1 + deductibles.length * classes.length}
                    >
                      <div style={{ fontSize: Theme.FontSize.L }}>
                        {combineStrings(
                          ' | ',
                          pricingPlan.name,
                          pricingPlan.isForNewVehicle ? lang.new : lang.preOwned,
                          pricingPlan.warrantyOption
                        )}
                        {' | '}
                        <FormatValue value={`${pricingPlan.minOdometerKm}km`} />
                        {' - '}
                        <FormatValue value={`${pricingPlan.maxOdometerKm}km`} />
                      </div>
                      {pricingPlan.primaryDescription && (
                        <div style={{ fontSize: '16px', fontWeight: 200, whiteSpace: 'normal', padding: '5px 0' }}>
                          {pricingPlan.primaryDescription}
                        </div>
                      )}
                      {pricingPlan.secondaryDescription && (
                        <div style={{ fontSize: '16px', fontWeight: 600, whiteSpace: 'normal', padding: '5px 0' }}>
                          {pricingPlan.secondaryDescription}
                        </div>
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th>{lang.deductible}</th>
                    <DataMap
                      data={classes}
                      render={({ data: vehicleClass }) => (
                        <Fragment key={vehicleClass.classId}>
                          <DataMap
                            data={sortBy(deductibles)}
                            render={({ data: deductible }) => (
                              <th
                                key={deductible}
                                style={{ textAlign: 'center', borderLeft: `1px solid ${Theme.Color.shadow}` }}
                              >
                                {deductible}
                              </th>
                            )}
                          />
                        </Fragment>
                      )}
                    />
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={sortBy(
                      limits(pricingPlan.pricingPlanId),
                      (it) => it.termInMonths,
                      (it) => it.maxKm
                    )}
                    render={({ data: limit }) => (
                      <Fragment key={`${limit.termInMonths} ${limit.maxKm}`}>
                        <WarrantyClassBasedTableSegment
                          isVisible={
                            selectedPricePoint?.termInMonths === limit.termInMonths &&
                            selectedPricePoint?.maxKm === limit.maxKm &&
                            selectedPricePoint?.pricingPlanInfo.pricingPlanId === pricingPlan.pricingPlanId
                          }
                          render={({ isVisible, setIsVisible }) => (
                            <>
                              <tr
                                style={{ backgroundColor: Theme.Color.secondary, cursor: 'pointer' }}
                                onClick={() => setIsVisible(!isVisible)}
                              >
                                <td colSpan={1 + classes.length * deductibles.length}>
                                  <FormatValue value={`${limit.termInMonths}m`} />
                                  /
                                  <FormatValue value={`${limit.maxKm}km`} />
                                </td>
                              </tr>
                              {isVisible && (
                                <DataMap
                                  data={sortBy(customInfos)}
                                  render={({ data: customInfo }) => (
                                    <tr key={customInfo}>
                                      <td>{customInfo}</td>
                                      <DataMap
                                        data={sortBy(classes, (it) => it.label)}
                                        render={({ data: vehicleClass }) => (
                                          <Fragment key={vehicleClass.classId}>
                                            <DataMap
                                              data={sortBy(deductibles)}
                                              render={({ data: deductible }) => {
                                                return (
                                                  <PriceCell
                                                    key={deductible}
                                                    product={{
                                                      ...product,
                                                      warrantyOption: pricingPlan?.warrantyOption,
                                                    }}
                                                    updateProduct={updateProduct}
                                                    selectedPrice={selectedPricePoint}
                                                    foundPrice={prices
                                                      ?.filter(
                                                        (it) =>
                                                          it.pricingPlanInfo.pricingPlanId === pricingPlan.pricingPlanId
                                                      )
                                                      .find(
                                                        (it) =>
                                                          it.termInMonths === limit.termInMonths &&
                                                          it.maxKm === limit.maxKm &&
                                                          it.classId === vehicleClass.classId &&
                                                          it.customInfo === customInfo &&
                                                          (it.deductible || 0) === deductible
                                                      )}
                                                  />
                                                );
                                              }}
                                            />
                                          </Fragment>
                                        )}
                                      />
                                    </tr>
                                  )}
                                />
                              )}
                            </>
                          )}
                        />
                      </Fragment>
                    )}
                  />
                </tbody>
              </table>
            </Fragment>
          )}
        />
      </div>
      {!!product.subfee.length && selectedPricePoint && (
        <>
          <br />
          <SubfeeTable
            product={product}
            updateProduct={updateProduct}
            pricePointSubfees={selectedPricePoint?.subfees}
          />
        </>
      )}
    </>
  );
}
//#endregion

export default function SalesNewContractContract() {
  const { lang } = getLocales();
  const { form, updateProduct, productWithPrices } = useSalesQuoteProvider();

  return (
    <DataMap
      data={form.data.products}
      render={({ data: product, isFirst }) => {
        const pp = productWithPrices.find((pp) => pp.product.id === product.id);
        return (
          <FormWrapper>
            <Fragment key={product.id}>
              {!isFirst && <hr />}
              <h2>{product.name}</h2>
              {product.productType === ProductType.custom && form.data.dealer && (
                <CustomSelection product={product} updateProduct={updateProduct} prices={pp.customPrices} />
              )}
              {product.productType === ProductType.nonWarranty && form.data.dealer && (
                <NonWarrantySelection product={product} updateProduct={updateProduct} prices={pp.nonWarrantyPrices} />
              )}
              {product.productType === ProductType.warrantyTierBased && form.data.dealer && (
                <WarrantyTierBased product={product} updateProduct={updateProduct} prices={pp.warrantyTierPrices} />
              )}
              {product.productType === ProductType.warrantiClassBased && form.data.dealer && (
                <WarrantyClassBased product={product} updateProduct={updateProduct} prices={pp.warrantyClassPrices} />
              )}
              <br />
              <br />
              <NumberInputTextValue
                name="total"
                label={lang.total}
                value={product?.priceCAD + (product.surchargesPriceCAD ?? 0)}
                readOnly={true}
                onChange={() => {
                  updateProduct(product);
                }}
                icon={<Icon class="fas-dollar-sign" />}
              />
            </Fragment>
          </FormWrapper>
        );
      }}
    />
  );
}
