import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import Page from 'assets/components/page/Page';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperBox from 'assets/themes/wrappers/Theme.Wrapper.Box';
import { defaultLimit, defaultOffset, HttpStatus, managementHomeRoute, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import Application from 'models/core/application/Model.Application';
import Permission from 'models/core/permission/Model.Permission';
import Role from 'models/core/role/Model.Role';
import userDeleteApi from 'models/core/user/delete/Api.User.Delete';
import User from 'models/core/user/Model.User';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import UserEdit from './User.Edit';
import UserResetPassword from './User.ResetPassword';
import { combineStrings } from 'assets/utils/data/String';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import userSendResetPasswordEmailApi from 'models/core/user/sendResetPasswordEmail/Api.User.SendResetPasswordEmail';
import { addLog } from 'assets/components/feedback/Feedback';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
  RESET_PASSWORD = 'resetPassword',
}
export type Verify = Utils.VerifyExtends<Module.Management.User.Actions, Utils.ValueOf<typeof Actions>>;

export const userIndexRoute = managementHomeRoute + '/user/:action?/:id?';
export default function UserIndex() {
  const { lang } = getLocales();
  const { hasAccess, user } = useAuthStore();
  const canCreate = hasAccess(AuthPermission.createUser);
  const canEdit = hasAccess(AuthPermission.editUser);
  const canDelete = hasAccess(AuthPermission.deleteUser);
  const canResetPassword = hasAccess(AuthPermission.resetPassword);
  const canView = canCreate || canEdit || canDelete || canResetPassword;

  const { params, query, updateParams, redirect } = usePageRouter<
    Module.Management.User.Params,
    Module.Management.User.Query
  >({ route: userIndexRoute });
  const {
    body: filters,
    payload,
    execute: reload,
    reset,
    isExecuting,
  } = useApi(
    {
      action: User.list,
      default: { limit: defaultLimit, offset: defaultOffset },
      body: {
        ...query.list,
        ...(user?.distributorId ? { distributorId: [user?.distributorId] } : null),
        ...(user?.dealers?.length ? { dealerId: user?.dealers.map((d) => d.id) } : null),
      },
      callback: (req) => redirect({}, { ...query, list: req }),
      wait: !canView || !user,
    },
    [canView, user]
  );
  const deleteApi = useApi({
    action: userDeleteApi,
    callback: () => reload((body) => body),
    wait: true,
  });
  const selected = useMemo(
    () =>
      (
        payload?.data?.find((it) => it.id.toString() === params.id) || new User({ distributorId: user?.distributorId })
      )?.toJSON(),
    [params.id]
  );

  const permissionsApi = useApi({ action: Permission.list });
  const permissions = permissionsApi.payload?.data;
  const applicationsApi = useApi({ action: Application.list });
  const applications = applicationsApi.payload?.data;
  const rolesApi = useApi({ action: Role.list });
  const roles = rolesApi.payload?.data;
  if (!canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      {params.action === Actions.EDIT && ((canCreate && !selected) || (canEdit && selected)) && (
        <UserEdit data={selected} onCancel={updateParams} onSave={() => reload((body) => body)} />
      )}
      {params.action === Actions.RESET_PASSWORD && selected && canResetPassword && (
        <UserResetPassword data={selected} onCancel={updateParams} onSave={() => reload((body) => body)} />
      )}
      {params.action === Actions.DELETE && selected && canDelete && (
        <Confirm
          data={selected}
          onConfirm={({ id }) => deleteApi.execute(id)}
          onClose={updateParams}
          actionName={lang.delete}
          itemName={`${selected.firstName} ${selected.lastName}`}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <Page
        class={ThemePageList}
        isLoading={isExecuting}
        header={
          !canView ? null : (
            <>
              <b style={{ marginRight: 'auto' }} children={lang.users} />
              <SearchInput
                value={filters?.search}
                onSearch={(search) => reload((body) => ({ ...body, search }))}
                threshold={2}
                placeholder={`${lang.search}...`}
              />
              &nbsp;
              <DropdownButton
                buttonMedia={<Icon class="fas-sort" />}
                horizontalPosition="auto_fixed"
                verticalPosition="auto_fixed"
                htmlElementProps={{ title: lang.sort }}
                subcomponents={ThemeDropdownButtonPopup}
                dropdownOptions={
                  <>
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.newerFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: undefined,
                        orderType: undefined,
                        thenBy: undefined,
                        thenType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.olderFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'id',
                        orderType: OrderType.asc,
                        thenBy: undefined,
                        thenType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.ascendingByUserName}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'firstName',
                        orderType: OrderType.asc,
                        thenBy: 'lastName',
                        thenType: OrderType.asc,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.descendingByUserName}
                      media={<Icon class="fas-caret-down" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'firstName',
                        orderType: OrderType.desc,
                        thenBy: 'lastName',
                        thenType: OrderType.desc,
                      })}
                    />
                  </>
                }
              />
              &nbsp;
              <FilterForm
                subcomponents={ThemeFilterFormDrawer}
                filters={filters}
                onFilter={(newFilers) => reload(newFilers)}
                onReset={reset}
                render={({ tempFilters, setFilters }) => (
                  <>
                    <SelectInput
                      name="applicationId"
                      label={lang.applications}
                      data={applications?.map((item) => ({
                        id: item.id,
                        title: item.name,
                        icon: <Icon class="fas-cloud" />,
                      }))}
                      isMultiselect
                      value={tempFilters?.applicationId}
                      onChange={({ applicationId }) => setFilters({ ...tempFilters, applicationId })}
                      icon={<Icon class="if-cloud" />}
                    />
                    <SelectInput
                      name="permissionId"
                      label={lang.permissions}
                      data={permissions?.map((item) => ({
                        id: item.id,
                        title: item.name,
                        icon: <Icon class="fas-shield" />,
                      }))}
                      isMultiselect
                      value={tempFilters?.permissionId}
                      onChange={({ permissionId }) => setFilters({ ...tempFilters, permissionId })}
                      icon={<Icon class="if-cloud" />}
                    />
                  </>
                )}
              />
              &nbsp;
              <Button
                class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                active
                media={<Icon class="fas-plus" />}
                render={!isMobile && lang.addNew}
                onClick={updateParams}
                disabled={!canCreate}
                data={{ action: Actions.EDIT }}
              />
            </>
          )
        }
        render={
          !canView ? (
            <ErrorPage status={HttpStatus.unauthorized} />
          ) : (
            <>
              <Collection class={ThemeCollectionList}>
                <DataMap
                  data={payload?.data}
                  render={({ data: user }) => (
                    <Row
                      key={user.id}
                      children={
                        <>
                          <RowImage
                            render={
                              user?.profilePhoto?.url ? <img src={user.profilePhoto.url} /> : <Icon class="fas-user" />
                            }
                          />
                          <RowData
                            info={user.email}
                            title={
                              <>
                                {user.firstName} {user.lastName}
                              </>
                            }
                            subtitle={
                              <>
                                <DataMap
                                  data={applications}
                                  render={({ data: application, index: appIndex }) => {
                                    const finalRoles = user?.roles
                                      ?.map((ur) =>
                                        roles?.find(
                                          (r) =>
                                            r.id === ur.id &&
                                            permissions?.some(
                                              (p) =>
                                                r.permissions?.some((rp) => rp.id === p.id) &&
                                                p.application?.id === application.id
                                            )
                                        )
                                      )
                                      .filter((it) => it);
                                    if (finalRoles.length === 0) return null;
                                    else
                                      return (
                                        <Box key={appIndex} class={ThemeWrapperBox}>
                                          <b style={{ marginBottom: Theme.Size.XS, display: 'block' }}>
                                            {application.name}
                                          </b>
                                          <DataMap
                                            data={finalRoles}
                                            render={({ data: role, index: roleIndex }) =>
                                              role && <Box key={roleIndex} class={ThemeTagRounded} render={role.name} />
                                            }
                                          />
                                        </Box>
                                      );
                                  }}
                                />
                                {!!user.additionalPermissions.length && (
                                  <Box class={ThemeWrapperBox}>
                                    <b style={{ marginBottom: Theme.Size.XS, display: 'block' }}>
                                      {lang.additionalPermissions}
                                    </b>
                                    <DataMap
                                      data={user.additionalPermissions}
                                      render={({ data: p }) =>
                                        p && (
                                          <Box key={p.id} class={ThemeTagRounded}>
                                            {combineStrings(
                                              ' - ',
                                              p.permission?.name,
                                              p.expiresAtUtc &&
                                                date(p.expiresAtUtc, defaultServerDateTimeFormat).format(
                                                  defaultDateTimeFormat
                                                )
                                            )}
                                          </Box>
                                        )
                                      }
                                    />
                                  </Box>
                                )}
                              </>
                            }
                          />
                          <RowActions isVertical>
                            <Button
                              class={ThemeButtonCircle}
                              media={<Icon class="fas-envelope" />}
                              onClick={async () => {
                                const resp = await userSendResetPasswordEmailApi(user.id);
                                if (resp.status === HttpStatus.ok)
                                  addLog({ success: resp.message ?? lang.emailSuccessfullySent });
                                else addLog({ error: resp.message ?? lang.unableToSendEmail });
                              }}
                              htmlElementProps={{
                                title: lang.sendResetPasswordEmail,
                              }}
                            />
                            <Button
                              class={ThemeButtonCircle}
                              media={<Icon class="fas-key" />}
                              onClick={updateParams}
                              disabled={!canResetPassword}
                              data={{
                                id: user.id.toString(),
                                action: Actions.RESET_PASSWORD,
                              }}
                              htmlElementProps={{
                                title: lang.RESET_PASSWORD,
                              }}
                            />
                            <Button
                              onClick={updateParams}
                              data={{
                                id: user.id.toString(),
                                action: Actions.EDIT,
                              }}
                              class={ThemeButtonCircle}
                              disabled={!canEdit}
                              media={<Icon class="fas-edit" />}
                              htmlElementProps={{
                                title: lang.edit,
                              }}
                            />
                            <Button
                              onClick={updateParams}
                              data={{
                                id: user.id.toString(),
                                action: Actions.DELETE,
                              }}
                              class={ThemeButtonCircle}
                              disabled={!canDelete}
                              media={<Icon class={'fas-trash'} />}
                              htmlElementProps={{
                                title: lang.delete,
                              }}
                            />
                          </RowActions>
                        </>
                      }
                    />
                  )}
                />
              </Collection>
            </>
          )
        }
        footer={
          !canView ? null : (
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          )
        }
      />
    </>
  );
}
