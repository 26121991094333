import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.StatementSummary.CreateForDealerGroup.IRequest;
type Response = Api.Core.StatementSummary.CreateForDealerGroup.IResponse;
type Headers = Api.Core.StatementSummary.CreateForDealerGroup.IHeaders;
const url = '/api/DealerManagement/StatementSummary/ForDealerGroup';
export default async function statementSummaryCreateForDealerGroupApi(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
