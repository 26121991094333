import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import React from 'react';
import useContract from './Contract.Hooks';
import ContractFilters from './sections/Contract.Filters';
import ContractInfo from './sections/Contract.Info';
import ContractList from './sections/Contract.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const contractIndexRoute = claimsHomeRoute + '/contract/:id?';
export default function ContractIndex() {
  const { lang } = getLocales();
  const contractHook = useContract();

  if (!contractHook.hasAccess) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={contractHook}
        render={({ listApi }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            sidebar={<ContractInfo />}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.contract} />
                <ContractFilters />
              </>
            }
            render={<ContractList />}
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
