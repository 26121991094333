import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultWithDeleted, defaultLimit, defaultOffset } from 'config/Api.Config';
import claimTypeDeleteApi from 'models/claims/claimType/delete/Api.ClaimType.Delete';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import claimTypeSaveApi from 'models/claims/claimType/save/Api.ClaimType.Save';
import { useMemo } from 'react';
import { claimTypeIndexRoute } from './ClaimType.Index';
import claimTypeSoftDeleteApi from 'models/claims/claimType/softDelete/Api.ClaimType.SoftDelete';
import { isEmpty } from 'lodash';
import ClaimJob from 'models/claims/claimJob/Model.ClaimJob';
import { addLog } from 'assets/components/feedback/Feedback';
import Product from 'models/productManagement/product/Model.Product';

export enum ClaimTypeAction {
  INFO = 'info',
  EDIT = 'edit',
  COPY = 'copy',
  DELETE = 'delete',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}
export type VerifyClaimTypeAction = Utils.VerifyExtends<
  Module.Claims.ClaimType.Actions,
  Utils.ValueOf<typeof ClaimTypeAction>
>;
export default function useClaimType() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.ClaimType.Params, Module.Claims.ClaimType.Query>({
    route: claimTypeIndexRoute,
  });

  const canView = permissions.CREATE_CLAIMTYPE || permissions.EDIT_CLAIMTYPE || permissions.DELETE_CLAIMTYPE;

  //#region APIS
  const listApi = useApi({
    action: ClaimType.list,
    default: { limit: defaultLimit, offset: defaultOffset, withDeleted: defaultWithDeleted },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const deleteApi = useApi({
    action: claimTypeDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: claimTypeSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const softDeleteApi = useApi({
    action: claimTypeSoftDeleteApi,
    callback: (req, res) => {
      listApi.execute((body) => body);
      if (res) {
        addLog({ success: 'Claim type successfully archived!' });
      }
    },
    wait: true,
  });

  const claimJobListApi = useApi({
    action: ClaimJob.list,
    wait: !canView,
  });

  const productsApi = useApi({ action: Product.list, wait: true });
  //#endregion

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  function canDeleteClaimType(currentClaimType: ClaimType) {
    const anyClaimJobExist = claimJobListApi.payload?.data?.some((it) => it.claimType?.id === currentClaimType?.id);
    return !anyClaimJobExist && isEmpty(currentClaimType?.claimTypeDocuments);
  }

  return {
    canView,
    pageRouter,
    listApi,
    saveApi,
    deleteApi,
    softDeleteApi,
    productsApi,
    selected,
    permissions,
    canDeleteClaimType,
    views: {
      [ClaimTypeAction.INFO]: pageRouter.params.action === ClaimTypeAction.INFO && selected,
      [ClaimTypeAction.DELETE]:
        pageRouter.params.action === ClaimTypeAction.DELETE && selected && permissions.DELETE_CLAIMTYPE,
      [ClaimTypeAction.ARCHIVE]:
        pageRouter.params.action === ClaimTypeAction.ARCHIVE && selected && permissions.DELETE_CLAIMTYPE,
      [ClaimTypeAction.COPY]:
        pageRouter.params.action === ClaimTypeAction.COPY && permissions.CREATE_CLAIMTYPE && selected,
      [ClaimTypeAction.EDIT]:
        pageRouter.params.action === ClaimTypeAction.EDIT &&
        ((permissions.CREATE_CLAIMTYPE && !selected) || (permissions.EDIT_CLAIMTYPE && selected)),
    },
  };
}

export function useClaimTypeProvider() {
  return useDataProvider<ReturnType<typeof useClaimType>>();
}
