import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ProductDocument.UpdateAll.IRequest;
type Response = Api.Core.ProductDocument.UpdateAll.IResponse;
type Headers = Api.Core.ProductDocument.UpdateAll.IHeaders;
const url = '/api/ProductManagement/Product/:id/Documents';
export default async function productDocumentUpdateAllApi(
  productId: number,
  body: Request
): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.put,
    url: createPathFromRoute(url, { id: productId }),
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
