import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import claimDocumentDeleteApi from './delete/Api.ClaimDocument.Delete';
import claimDocumentSaveApi from './save/Api.ClaimDocument.Save';
import claimDocumentSavePublicApi from './savePublic/Api.ClaimDocument.SavePublic';

export default class ClaimDocument
  extends Model<Model.IClaimDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimDocument>
{
  id: number;
  name: string;
  notes?: string;
  claimId: number;

  mediaFile: MediaFile;
  formFile?: File;

  constructor(data: Partial<Model.IClaimDocument> | Utils.FormData<Model.IClaimDocument>, language?: Locale.Languages) {
    super({ mediaFile: MediaFile }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
  async save() {
    return await claimDocumentSaveApi(this);
  }
  async savePublic(params: Api.Core.ClaimDocument.SavePublic.IParams) {
    return await claimDocumentSavePublicApi({ body: this, params });
  }
  async delete() {
    return await claimDocumentDeleteApi({ id: this.claimId, documentId: this.id });
  }
}
