import dayjs from 'dayjs';
import { sum } from 'lodash';

export type ProgressInfo = {
  currentItem: number;
  totalItems: number;
  timeEstimateMs: number;
  percentCompleted: number;
};

export default async function iterateWithProgress<T>(
  dataList: Array<T>,
  iteratee: (data: T) => Promise<void>,
  progressFunction?: (p: ProgressInfo) => void
) {
  const totalItems = dataList.length;
  const timesMs: number[] = [];
  let currentItem = 0;
  if (progressFunction && totalItems) {
    progressFunction({
      currentItem,
      totalItems,
      timeEstimateMs: 0,
      percentCompleted: 0,
    });
  }
  if (totalItems) {
    for (const data of dataList) {
      const start = dayjs();
      await iteratee(data);
      timesMs.push(dayjs().diff(start, 'millisecond'));
      currentItem += 1;
      if (progressFunction) {
        progressFunction({
          currentItem,
          totalItems,
          timeEstimateMs: (totalItems - currentItem) * (timesMs.length ? sum(timesMs) / timesMs.length : 0),
          percentCompleted: (currentItem / totalItems) * 100,
        });
      }
    }
  }
  return totalItems;
}
