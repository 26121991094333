import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.LossCode.Import.IRequest;
type Response = Api.Core.LossCode.Import.IResponse;
type Headers = Api.Core.LossCode.Import.IHeaders;
const url = '/api/Claims/LossCode/ImportFromExcel';
export default async function lossCodeImportApi(request: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    body: request,
    method: HttpMethod.post,
    url: createPathFromRoute(url),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
