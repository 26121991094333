import Model from 'models/Model';
import RoleTranslation from 'models/translations/roleTranslation/Model.RoleTranslation';
import Application from '../application/Model.Application';
import Permission from '../permission/Model.Permission';
import roleDeleteApi from './delete/Api.Role.Delete';
import roleListApi from './list/Api.Role.List';
import roleSaveApi from './save/Api.Role.Save';

export default class Role
  extends Model<Model.IRole>({ icon: 'fas-user-shield' })
  implements Model.Instance<Model.IRole>
{
  id: number;
  translations: RoleTranslation[];
  permissions: Permission[];

  get name(): string {
    return (
      this.translations?.find((it) => it.languageIsoCode === this._language)?.name ||
      this.translations?.find((it) => it)?.name ||
      ''
    );
  }
  get description(): string {
    return (
      this.translations?.find((it) => it.languageIsoCode === this._language)?.description ||
      this.translations?.find((it) => it)?.description
    );
  }

  constructor(data: Partial<Model.IRole> | Utils.FormData<Model.IRole>, language?: Locale.Languages) {
    super({ translations: [RoleTranslation], permissions: [Permission] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      media: this.icon,
      title: this.name || this.id.toString(),
      subtitle: this.description,
    };
  }

  getPermissionUnion = (app: Application) =>
    this.permissions
      ?.filter((p) => app?.permissions?.some((ap) => ap.id === p.id))
      ?.map(
        (it) =>
          new Permission({
            ...it,
            ...app?.permissions?.find((p) => p.id === it.id),
          })
      );
  static getPermissionUnion = (roles: Array<Role>, app: Application) => {
    const list = roles?.map((role) => ({ app, role, unionPermissions: role.getPermissionUnion(app) }));
    return list.filter((it) => it.unionPermissions.length > 0);
  };

  static async list(body?: Api.Core.Role.List.IRequest) {
    const { payload, ...rest } = await roleListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Role(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await roleSaveApi(this);
  }
  async delete() {
    return await roleDeleteApi(this.id);
  }
}
