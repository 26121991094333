import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Theme from 'assets/themes/Theme.Common';
import ThemePageCompactModal from '../../themes/Theme.Page.CompactModal';

const ThemeConfirmDefault: Template.Confirm.Import<any>['subcomponents'] = {
  button: { class: ThemeButtonCircle },
  modal: {
    page: { class: ThemePageCompactModal },
    closeButton: { class: ThemeButtonCircle, style: { marginLeft: Theme.Size.L } },
  },
};
export default ThemeConfirmDefault;
