import ClaimType from 'models/claims/claimType/Model.ClaimType';
import SignatureType from 'models/enums/SignatureType';
import Model from 'models/Model';

export default class ProductSignatureField
  extends Model<Model.IProductSignatureField>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductSignatureField>
{
  id: number;
  key: string;
  type?: SignatureType;
  signerSource?: string;
  routingOrder: number;
  productDocumentId: number;

  constructor(
    data: Partial<Model.IProductSignatureField> | Utils.FormData<Model.IProductSignatureField>,
    language?: Locale.Languages
  ) {
    super({ claimTypes: [ClaimType] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.key,
      info: this.signerSource,
      subtitle: this.type,
    };
  }
}
