enum StatementStatusCode {
  OPEN = 'OPEN',
  APPROVED = 'APPROVED',
  RECONCILED =  'RECONCILED',
  CANCELED = 'CANCELED'
}

export default StatementStatusCode;
export type VerifyStatementStatusCode = Utils.VerifyExtends<
  Model.Enum.StatementStatusCode,
  Utils.ValueOf<typeof StatementStatusCode>
>;
