import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ClaimJob.ChangeOwnership.IRequest;
type Response = Api.Core.ClaimJob.ChangeOwnership.IResponse;
type Headers = Api.Core.ClaimJob.ChangeOwnership.IHeaders;
const url = '/api/Claims/ClaimJob/ChangeOwnership';
export default async function claimJobChangeOwnershipApi(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.put,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
