import DataMap from 'assets/components/dataMap/DataMap';
import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import EmailHtml from 'assets/components/html-templates/email/EmailTemplate';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { isEmail, isEmailDomain } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import claimEmailSendEmailApi from 'models/claims/claimEmail/sendEmail/Api.ClaimEmail.SendEmail';
import EmailTemplate from 'models/core/emailTemplate/Model.EmailTemplate';
import EmailTemplateTags from 'module/management/components/emailTemplate/EmailTemplateTags';
import { renderToString } from 'react-dom/server';
import { useClaimEdit, useClaimEditPageRouter } from '../../ClaimEdit.Hooks';

export default function ClaimEditSendEmail() {
  const { lang } = getLocales();
  const pageRouter = useClaimEditPageRouter();
  const { setEmailAction, reloadClaim } = useClaimEdit();
  const origin =
    window.location.hostname === 'localhost' ? 'https://autoshield-dev.azurewebsites.net' : window.location.origin;

  async function sendEmail(emailTemplate: Model.IEmailTemplate) {
    const res = await claimEmailSendEmailApi({
      params: { claimId: parseInt(pageRouter.params?.claimId) },
      body: emailTemplate,
    });

    if (res.status === HttpStatus.ok) {
      addLog({ success: 'Email successfully sent to all email recipients.' });
      setEmailAction(null);
      reloadClaim();
    } else {
      addLog({ error: res.message });
    }
  }

  return (
    <ModalForm<Api.Core.ClaimEmail.SendEmail.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={`${lang.claim} ${lang.emailTemplate}`}
      default={{
        htmlBody: renderToString(EmailHtml({ body: 'Enter custom message here.', origin })),
        additionalRecipentEmails: [],
      }}
      dependencyArray={[]}
      onCancel={() => setEmailAction(null)}
      onSubmit={(data) => sendEmail(new EmailTemplate(data))}
      validation={(data, errors) => {
        if (!data.senderEmail) errors.senderEmail = lang.mustNotBeEmpty;
        if (data.senderEmail && !isEmailDomain(data.senderEmail, 'goautoshield.com'))
          errors.senderEmail = lang.mustBeEmailDomain.replace('{domain}', 'goautoshield.com');
        if (!data.senderName) errors.senderName = lang.mustNotBeEmpty;
        if (!data.subject) errors.subject = lang.mustNotBeEmpty;
        if (!data.htmlBody) errors.htmlBody = lang.mustNotBeEmpty;
        if (
          !data.sendToClaimOwner &&
          !data.sendToClient &&
          !data.sendToContactEmail &&
          (!data.additionalRecipentEmails || data.additionalRecipentEmails.every((it) => !isEmail(it)))
        )
          errors.additionalRecipentEmails = lang.mustNotBeEmpty;
      }}
      render={({ form }) => (
        <FormWrapper>
          <InputWrapper>
            <TextInput
              name="senderName"
              label={lang.senderName}
              value={form.data.senderName}
              error={form.errors.senderName}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="senderEmail"
              label={lang.senderEmail}
              value={form.data.senderEmail}
              error={form.errors.senderEmail}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <h2>{lang.sendTo}</h2>
          <CheckWrapper>
            <CheckInput
              name="sendToClient"
              label={lang.client}
              class={inputStyles.check.switch}
              value={form.data.sendToClient}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToClient ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToContactEmail"
              label={lang.contact}
              class={inputStyles.check.switch}
              value={form.data.sendToContactEmail}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToContactEmail ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToClaimOwner"
              label={lang.claimOwner}
              class={inputStyles.check.switch}
              value={form.data.sendToClaimOwner}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToClaimOwner ? lang.disable : lang.enable }}
            />
          </CheckWrapper>
          <h2>{lang.additionalRecipentEmails}</h2>
          <DataMap
            data={[...(form.data.additionalRecipentEmails || []), '']}
            render={({ data: email, index }) => (
              <TextInput
                key={index}
                name="email"
                label={lang.email}
                value={email}
                error={form.errors.additionalRecipentEmails}
                onChange={({ email }) => {
                  let additionalRecipentEmails = form.data.additionalRecipentEmails || [];
                  additionalRecipentEmails.splice(index, 1, email);
                  additionalRecipentEmails = additionalRecipentEmails.filter((it) => it);
                  form.update({ additionalRecipentEmails });
                }}
                icon={<Icon class="if-quill-pen" />}
              />
            )}
          />
          <h2>{lang.email}</h2>
          <TextInput
            name="subject"
            label={lang.subject}
            value={form.data.subject}
            error={form.errors.subject}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
          />
          <RichTextInput
            name="htmlBody"
            label={lang.htmlBody}
            value={form.data.htmlBody}
            error={form.errors.htmlBody}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
            customActions={<EmailTemplateTags />}
          />
        </FormWrapper>
      )}
    />
  );
}
