import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useDistributor, { DistributorAction } from './Distributor.Hooks';
import DistributorDelete from './sections/Distributor.Delete';
import DistributorEdit from './sections/Distributor.Edit';
import DistributorFilters from './sections/Distributor.Filters';
import DistributorList from './sections/Distributor.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const distributorIndexRoute = salesHomeRoute + '/distributor/:action?/:id?';
export default function DistributorIndex() {
  const { lang } = getLocales();
  const distributorHook = useDistributor();
  const { permissions } = useAuthStore();

  if (!distributorHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={distributorHook}
        render={({ listApi, views, pageRouter }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.distributors} />
                <DistributorFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_DISTRIBUTOR}
                  data={{ action: DistributorAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <DistributorEdit />}
                {views.delete && <DistributorDelete />}
                <DistributorList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
