import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { orderBy } from 'lodash';
import React from 'react';
import { VehicleClassAction, useVehicleClassProvider } from '../VehicleClass.Hooks';

export default function VehicleClassList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, permissions } = useVehicleClassProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.classes}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: vehicleClass }) => (
          <Row
            key={vehicleClass.id}
            children={
              <>
                <RowImage render={<Icon class="fas-user-shield" />} />
                <RowData {...vehicleClass.displayInfo} />
                <RowData>
                  {orderBy(vehicleClass.vehicleModels, ['vehicleMake.makeCode', 'name'], ['asc', 'asc'])
                    .map((it) => combineStrings(' ', it.vehicleMake?.makeCode, it.name))
                    .join(', ')}
                </RowData>
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: vehicleClass.id.toString(),
                      action: VehicleClassAction.EDIT,
                    }}
                    disabled={!permissions.EDIT_VEHICLECLASS}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-edit" />}
                    htmlElementProps={{ title: lang.edit }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: vehicleClass.id.toString(),
                      action: VehicleClassAction.DELETE,
                    }}
                    disabled={!permissions.DELETE_VEHICLECLASS}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{ title: lang.delete }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: vehicleClass.id.toString(),
                      action: VehicleClassAction.COPY,
                    }}
                    disabled={!permissions.EDIT_VEHICLECLASS}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-copy" />}
                    htmlElementProps={{ title: lang.copy }}
                  />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
