import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import { getLanguage, getLocales, Language, setLanguage } from 'assets/locales/Locale';
import React from 'react';
import { isMobile } from 'react-device-detect';
import DropdownButton from '../dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from '../dropdownButton/themes/Theme.DropdownButton.Popup';

export default function LanguageMenu() {
  const { lang } = getLocales();
  const currentLanguage = getLanguage();
  function updateLanguage(language: Locale.Languages) {
    setLanguage(language);
    window.location.reload();
  }

  return (
    <>
      <DropdownButton
        title={lang.selectLanguage}
        buttonMedia={isMobile ? <Icon class="fas-globe" /> : currentLanguage}
        buttonBody={isMobile ? currentLanguage : undefined}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        subcomponents={ThemeDropdownButtonPopup}
        htmlElementProps={{ title: lang.selectLanguage }}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.en}
              htmlElementProps={{ title: lang.en }}
              onClick={updateLanguage}
              data={Language.english}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.fr}
              htmlElementProps={{ title: lang.fr }}
              onClick={updateLanguage}
              data={Language.french}
            />
          </>
        }
      />
    </>
  );
}
