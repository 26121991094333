import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import FormatValue from 'assets/components/formatValue/FormatValue';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import Theme from 'assets/themes/Theme.Common';
import { omit } from 'lodash';
import { useState } from 'react';
import { dispatchPricingDistribution, usePricingDistributionEvent } from './PricingDistributionTable';
import './PricingDistributionTable.scss';

type Props<T> = {
  columnKey: string;
  rows?: T[];
  getLimitation: (row: T) => Utils.DistributionLimitation;
  getDiscountPrice: (row: T) => number;
  getDistributorPrice: (row: T) => number;
  updateLimitation: (row: T, limitation: Utils.DistributionLimitation) => void;
  priceOption?: Utils.RenderProps<{
    property?: 'minAmount' | 'maxAmount';
    row: T;
    unit?: Utils.CalcUnit;
    delta: number;
  }>;
};
export default function DealerPricingLimitationTable<T>({
  columnKey,
  rows,
  priceOption,
  getLimitation,
  getDiscountPrice,
  getDistributorPrice,
  updateLimitation,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const { lang } = getLocales();

  usePricingDistributionEvent(
    {
      callback: (event) => {
        setIsOpen((old) => (columnKey === event.id ? !old : false));
      },
    },
    [columnKey]
  );
  return !rows ? null : (
    <>
      <Button
        class={ThemeButtonCircle}
        style={{ button: { display: 'inline-block' } }}
        media="fas-chart-bar"
        onClick={dispatchPricingDistribution}
        data={{ data: { id: columnKey } }}
        disabled={!rows.length}
        htmlElementProps={{ title: lang.openDealerPricingLimitationTable }}
      />
      {isOpen && (
        <ObjectTable
          className={'PricingDistributionTable'}
          paths={[lang.discountPrice, lang.suggestedRetailPrice, lang.maxAmount]}
          data={rows}
          focusOnLoad
          translateHeader
          renderRow={({ data: row }) => {
            const distributionLimitation = getLimitation(row);
            const basePriceWithDiscount = getDiscountPrice(row);
            const distributorPrice = getDistributorPrice(row);
            return (
              <tr>
                <td style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      minWidth: '157px',
                    }}
                  >
                    {distributorPrice ? (
                      <>
                        <FormatValue value={`${(basePriceWithDiscount + distributorPrice).toFixed(2)}$`} />
                        <br />
                        <span style={{ fontSize: Theme.FontSize.S }}>
                          Base price: <FormatValue value={`${basePriceWithDiscount.toFixed(2)}$`} />
                        </span>
                      </>
                    ) : (
                      <FormatValue value={`${basePriceWithDiscount.toFixed(2)}$`} />
                    )}
                  </div>
                </td>
                <td>
                  <NumberInput
                    name="minAmount"
                    value={distributionLimitation?.minAmount}
                    icon={<Icon class={'fas-dollar-sign'} />}
                    min={0}
                    step={0.01}
                    style={{
                      formInput: {
                        minWidth: '157px',
                      },
                      input: {
                        textAlign: 'right',
                      },
                    }}
                    error={
                      distributionLimitation?.minAmount != null
                        ? distributionLimitation?.maxAmount != null &&
                          distributionLimitation?.minAmount > distributionLimitation?.maxAmount
                          ? lang.minMaxMissmatchMessage
                          : distributionLimitation?.minAmount <= 0
                          ? lang.mustBeMoreThanAmount.replace('{amount}', '0')
                          : undefined
                        : undefined
                    }
                    onChange={({ minAmount }) =>
                      updateLimitation(row, {
                        ...omit(distributionLimitation, 'minAmount'),
                        minAmount,
                        unit: distributionLimitation?.unit ?? 'replacement',
                      })
                    }
                    iconBefore={RenderProps({ priceOption }, 'priceOption', {
                      row,
                      property: 'minAmount',
                      unit: distributionLimitation?.unit ?? 'replacement',
                      delta: distributionLimitation?.minAmount
                        ? distributionLimitation.minAmount - (basePriceWithDiscount + distributorPrice)
                        : 0,
                    })}
                  />
                </td>
                <td>
                  <NumberInput
                    name="maxAmount"
                    icon={<Icon class={'fas-dollar-sign'} />}
                    min={0}
                    step={0.01}
                    style={{
                      formInput: {
                        minWidth: '157px',
                      },
                      input: {
                        textAlign: 'right',
                      },
                    }}
                    value={distributionLimitation?.maxAmount}
                    error={
                      distributionLimitation?.maxAmount != null
                        ? distributionLimitation.minAmount != null &&
                          distributionLimitation.minAmount > distributionLimitation.maxAmount
                          ? lang.minMaxMissmatchMessage
                          : distributionLimitation.maxAmount <= 0
                          ? lang.mustBeMoreThanAmount.replace('{amount}', '0')
                          : undefined
                        : undefined
                    }
                    onChange={({ maxAmount }) =>
                      updateLimitation(row, {
                        ...omit(distributionLimitation, 'maxAmount'),
                        maxAmount,
                        unit: distributionLimitation?.unit ?? 'replacement',
                      })
                    }
                    iconBefore={RenderProps({ priceOption }, 'priceOption', {
                      row,
                      property: 'maxAmount',
                      unit: distributionLimitation?.unit ?? 'replacement',
                      delta: distributionLimitation?.maxAmount
                        ? distributionLimitation.maxAmount - (basePriceWithDiscount + distributorPrice)
                        : 0,
                    })}
                  />
                </td>
              </tr>
            );
          }}
        />
      )}
    </>
  );
}
