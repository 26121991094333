import React, { useEffect, useState } from 'react';
import { useTheme } from 'assets/hooks/theme/useTheme';
import RenderProps from '../helpers/RenderProps';
import { useLocation } from 'react-router';

export enum ApplicationStructure {
  application = 'application',
  applicationHeader = 'applicationHeader',
  applicationSubheader = 'applicationSubheader',
  applicationContent = 'applicationContent',
  applicationFooter = 'applicationFooter',
}
export const ApplicationPopupWindowsId = 'popupWindows';
export type Verify = Utils.VerifyExtends<Component.Application.Structure, typeof ApplicationStructure>;
export default function Application(props: Component.Application.Import) {
  const extend = useTheme(ApplicationStructure, props);

  const [scrolledElement, setScrolledElement] = useState<HTMLDivElement | null>();
  const _location = useLocation();

  useEffect(() => {
    if (scrolledElement) scrolledElement.scrollTo({ top: 0, behavior: 'smooth' });
  }, [_location.pathname, scrolledElement]);
  return (
    <>
      <div {...extend(ApplicationStructure.application, true)} ref={setScrolledElement}>
        <div {...extend(ApplicationStructure.applicationHeader)}>{RenderProps(props, 'header')}</div>
        {props.subheader && (
          <div {...extend(ApplicationStructure.applicationSubheader)}>{RenderProps(props, 'subheader')}</div>
        )}
        <div {...extend(ApplicationStructure.applicationContent)}>{RenderProps(props, 'render')}</div>
        {props.footer && <div {...extend(ApplicationStructure.applicationFooter)}>{RenderProps(props, 'footer')}</div>}
        <div id={ApplicationPopupWindowsId} />
      </div>
    </>
  );
}
