import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { getLocales } from 'assets/locales/Locale';
import { debounce } from 'lodash';
import React, { useState } from 'react';

type Next = 'Find' | 'Retrieve';
type Location = {
  Cursor: number;
  Description: string;
  Highlight: string;
  Id: string;
  Next: Next;
  Text: string;
};
type LocationDetails = {
  City: string;
  CountryIso2: string;
  CountryIso3: string;
  CountryName: string;
  Id: string;
  Label: string;
  Line1: string;
  ProvinceCode: string;
  Street: string;
  Type: string;
  PostalCode: string;
};
type Props = {
  onSelect(locationDetails?: LocationDetails): void;
};
const getUrl = (type: Next) =>
  `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/${type}/v2.10/json3.ws?Key=bd73-ea89-en61-dr16`;
export function LocationSearch({ onSelect }: Props) {
  const { lang } = getLocales();
  const [locations, setLocations] = useState<Array<Location>>([]);

  const handleSubmit = debounce(async function (searchTerm: string, isId?: boolean) {
    let requestUrl = getUrl('Find');

    requestUrl += `&${isId ? 'LastId' : 'SearchTerm'}=` + encodeURIComponent(searchTerm);
    requestUrl += '&Country=' + encodeURIComponent('CAN');
    requestUrl += '&MaxResults=' + encodeURIComponent(10);

    try {
      const response = await fetch(requestUrl);
      const data = await response.json();
      setLocations(data.Items);
    } catch (e) {
      console.log(e);
      setLocations([]);
    }
  }, 500);

  async function handleSelection(id: string) {
    let requestUrl = getUrl('Retrieve');
    requestUrl += '&Id=' + encodeURIComponent(id);
    try {
      const response = await fetch(requestUrl);
      const data = await response.json();
      onSelect(data.Items[0]);
    } catch (e) {
      console.log(e);
      onSelect(null);
    }
    setLocations([]);
  }
  return (
    <SelectInput
      name="locationId"
      onChange={({ locationId }) => {
        const location = locations.find((l) => l.Id === locationId);
        if (location?.Next === 'Retrieve') {
          handleSelection(location.Id);
          return false;
        } else if (location?.Next === 'Find') {
          handleSubmit(location.Id, true);
          return true;
        }
      }}
      onSearch={handleSubmit}
      label={lang.searchLocation}
      icon={
        <Button
          onClick={() => onSelect(null)}
          class={ThemeButtonCircle}
          style={{ margin: 0 }}
          media={<Icon class="fas-trash" />}
          htmlElementProps={{ title: `${lang.clear} ${lang.address}` }}
        />
      }
      data={locations.map((it) => ({
        id: it.Id,
        title: it.Text,
        subtitle: it.Description,
      }))}
    />
  );
}
