import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import React from 'react';
import { useLossCodeProvider } from '../LossCode.Hooks';

export default function LossCodeEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useLossCodeProvider();

  return (
    <>
      <ModalForm<Api.Core.LossCode.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.lossCode}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={(data) => saveApi.execute(data)}
        validation={(data, errors) => {
          if (!data.category) errors.category = lang.mustNotBeEmpty;
          if (!data.code) errors.code = lang.mustNotBeEmpty;
          if (!data.descnEn) errors.descnEn = lang.mustNotBeEmpty;
          if (!data.notes) errors.notes = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="category"
                label={lang.category}
                value={form.data.category}
                error={form.errors.category}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="code"
                label={lang.code}
                value={form.data.code}
                error={form.errors.code}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="descnEn"
                label={lang.descnEn}
                value={form.data.descnEn}
                error={form.errors.descnEn}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="notes"
                label={lang.notes}
                value={form.data.notes}
                error={form.errors.notes}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
