import Model from 'models/Model';
import pricingDistributionTemplateDeleteApi from './delete/Api.PricingDistributionTemplate.Delete';
import pricingDistributionTemplateListApi from './list/Api.PricingDistributionTemplate.List';
import pricingDistributionTemplateSaveApi from './save/Api.PricingDistributionTemplate.Save';
import Distributor from 'models/sales/distributor/Model.Distributor';

export default class PricingDistributionTemplate
  extends Model<Model.IPricingDistributionTemplate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IPricingDistributionTemplate>
{
  id: number;
  name: string;
  keys: string[];
  distributorId?: number;
  distributor?: Distributor;

  constructor(
    data: Partial<Model.IPricingDistributionTemplate> | Utils.FormData<Model.IPricingDistributionTemplate>,
    language?: Locale.Languages
  ) {
    super({ distributor: Distributor }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name,
    };
  }

  static async list(body?: Api.Core.PricingDistributionTemplate.List.IRequest) {
    const { payload, ...rest } = await pricingDistributionTemplateListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new PricingDistributionTemplate(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await pricingDistributionTemplateSaveApi(this);
  }
  async delete() {
    return await pricingDistributionTemplateDeleteApi(this.id);
  }
}
