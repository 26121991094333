import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { usePricingLimitTemplateProvider } from '../PricingLimitTemplate.Hooks';

export default function PricingLimitTemplateEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = usePricingLimitTemplateProvider();

  return (
    <>
      <ModalForm<Api.Core.PricingLimitTemplate.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.limit}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={saveApi.execute}
        validation={(data, errors) => {
          if (!isValidValue(data.term)) errors.term = lang.mustNotBeEmpty;
          if (data.term < 0) errors.term = lang.cannotBeNegativeNumber;
          if (!isValidValue(data.term)) errors.maxKm = lang.mustNotBeEmpty;
          if (data.maxKm < 0) errors.maxKm = lang.cannotBeNegativeNumber;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <InputWrapper>
                <NumberInput
                  name="term"
                  label={lang.term}
                  value={form.data.term}
                  error={form.errors.term}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
                <NumberInput
                  name="maxKm"
                  label={lang.maxKm}
                  value={form.data.maxKm}
                  error={form.errors.maxKm}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
              </InputWrapper>
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
