import { useReducer, useEffect, useState } from 'react';

const defaultTimeout = 200;
export default function useAnimation(props?: Hooks.Animation.Import): Hooks.Animation.Export {
  const [state, dispatch] = useReducer(animationReducer, {
    isPassive: false,
    isActive: props && props.autoLoad ? props.autoLoad : false,
    isAnimated: false,
  });
  const [onOutEnd, setOnOutEnd] = useState<{ callback: () => void } | undefined>(undefined);
  const [canDismount, setCanDismount] = useState(false);
  let animationTimeout: any;
  useEffect(() => {
    if (!state.isActive) {
      if (animationTimeout) clearTimeout(animationTimeout);
      animationTimeout = setTimeout(
        () => {
          dispatch({ type: props && props.autoLoad ? 'END_WITH_ACTIVE' : 'OUT_END' });
          animationTimeout = null;
          setCanDismount(true);
        },
        props && props.timeout ? props.timeout : defaultTimeout
      );
    } else {
      if (animationTimeout) clearTimeout(animationTimeout);
      animationTimeout = setTimeout(
        () => {
          dispatch({ type: 'IN_END' });
          animationTimeout = null;
        },
        props && props.timeout ? props.timeout : defaultTimeout
      );
    }
    return () => {
      if (animationTimeout) clearTimeout(animationTimeout);
    };
  }, [state.isActive]);

  useEffect(() => {
    if (canDismount && onOutEnd && onOutEnd.callback) {
      onOutEnd.callback();
    }
  }, [canDismount]);
  const toggle = (callback?: () => void) => {
    if (state.isActive || state.isAnimated) {
      animateOut(callback);
    } else {
      animateIn(callback);
    }
  };
  const animateIn = (callback?: () => void): void => {
    dispatch({ type: 'IN' });
    setCanDismount(false);
    if (props && props.onAnimateIn) props.onAnimateIn();
    if (typeof callback == 'function') callback();
  };
  const animateOut = (callback?: () => void): void => {
    if (state.isActive) dispatch({ type: 'OUT' });
    if (typeof callback == 'function') setOnOutEnd({ callback: callback });
    else if (props && props.onAnimateOut) setOnOutEnd({ callback: props.onAnimateOut });
  };

  return {
    timeout: props && props.timeout ? props.timeout : defaultTimeout,
    isVisible: state.isActive || state.isAnimated,
    isIn: state.isActive,
    isOut: !state.isActive,
    isInEnd: state.isPassive,
    isOutEnd: !state.isPassive && !state.isAnimated,
    toggle: toggle,
    animateIn: animateIn,
    animateOut: animateOut,
  };
}

function animationReducer(state: Hooks.Animation.State, action: Hooks.Animation.Action) {
  switch (action.type) {
    case 'IN':
      return {
        isActive: true,
        isAnimated: true,
      };
    case 'OUT':
      return {
        isActive: false,
        isAnimated: true,
        isPassive: false,
      };
    case 'IN_END':
      return {
        ...state,
        isPassive: true,
      };
    case 'OUT_END':
      return {
        ...state,
        isActive: false,
        isAnimated: false,
      };
    case 'END_WITH_ACTIVE':
      return {
        ...state,
        isActive: true,
        isAnimated: false,
      };
    default:
      throw new Error();
  }
}
