import { getLocales } from 'assets/locales/Locale';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { AuthPermission } from 'config/Auth.Config';
import { camelCase, isEqual, sortBy, uniq } from 'lodash';
import Model from 'models/Model';
import Claim from 'models/claims/claim/Model.Claim';
import Application from 'models/core/application/Model.Application';
import Role from 'models/core/role/Model.Role';
import User from 'models/core/user/Model.User';
import Contract from 'models/sales/contract/Model.Contract';
import auditLogListApi from './list/Api.AuditLog.List';

export default class AuditLog
  extends Model<Model.IAuditLog>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IAuditLog>
{
  static LOGGABLE_ENTITIES = { User, Role, Claim, Contract };

  id: number;
  changedAtUtc: string;
  permissionKey?: Auth.Permission;
  entityName?: string;
  oldEntity: { [key: string]: any };
  currentEntity: { [key: string]: any };
  application: Application;
  user: User;

  get changedAtUtcParsed() {
    return date(this.changedAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat);
  }
  get isChange(): boolean {
    const changeablePermissions = [
      AuthPermission.editRole,
      AuthPermission.editUser,
      AuthPermission.editClaim,
      AuthPermission.createClaim,
    ];
    return changeablePermissions.find((it) => it === this.permissionKey) ? true : false;
  }
  get changes(): Model.IAuditLog['changes'] {
    const isEdited = this.isChange;
    const allKeys = uniq([...Object.keys(this.oldEntity), ...Object.keys(this.currentEntity)]).filter(
      (it) => it.indexOf('_') < 0
    );
    const changes: Model.IAuditLog['changes'] = {};
    for (const key of allKeys) {
      const old = Array.isArray(this.oldEntity[key])
        ? sortBy(this.oldEntity[key].map((it) => it.displayInfo?.title))
        : typeof this.oldEntity[key] === 'object'
        ? this.oldEntity[key]?.displayInfo?.title
        : this.oldEntity[key];
      const current = Array.isArray(this.currentEntity[key])
        ? sortBy(this.currentEntity[key].map((it) => it.displayInfo?.title))
        : typeof this.currentEntity[key] === 'object'
        ? this.currentEntity[key]?.displayInfo?.title
        : this.currentEntity[key];
      if (!isEqual(old, current) || !isEdited) changes[key] = { old, current };
    }
    return changes;
  }

  constructor(data: Partial<Model.IAuditLog> | Utils.FormData<Model.IAuditLog>, language?: Locale.Languages) {
    super({ application: Application, user: User }, language);
    this.update(data);

    const _oldEntity = AuditLog.parseEntity(data.oldEntity);
    this.oldEntity =
      (AuditLog.LOGGABLE_ENTITIES[data.entityName] && _oldEntity
        ? new AuditLog.LOGGABLE_ENTITIES[data.entityName](_oldEntity)
        : _oldEntity) ?? {};

    const _currentEntity = AuditLog.parseEntity(data.currentEntity);
    this.currentEntity =
      (AuditLog.LOGGABLE_ENTITIES[data.entityName]
        ? new AuditLog.LOGGABLE_ENTITIES[data.entityName](_currentEntity)
        : _currentEntity) ?? {};
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      info: this.changedAtUtcParsed,
      title: getLocales().lang[this.permissionKey] || this.permissionKey || this.id.toString(),
      subtitle: `${this.entityName}, ID: ${this?.currentEntity?.id}`,
    };
  }

  static parseEntity(entity: any) {
    if (!entity) return null;
    const newEntity = {};
    for (const key of Object.keys(entity || {})) {
      if (key.indexOf('$') === -1) {
        const camelKey = camelCase(key);
        if (!entity[key] || typeof entity[key] !== 'object') newEntity[camelKey] = entity[key];
        else if (Array.isArray(entity[key]['$values'])) {
          newEntity[camelKey] = [];
          for (const nestedEntity of entity[key]['$values']) {
            newEntity[camelKey].push(AuditLog.parseEntity(nestedEntity));
          }
        } else {
          newEntity[camelKey] = AuditLog.parseEntity(entity[key]);
        }
      }
    }
    return newEntity;
  }

  static async list(body?: Api.Core.AuditLog.List.IRequest) {
    const { payload, ...rest } = await auditLogListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new AuditLog(it)),
        count: payload.count,
      },
    };
  }
}
