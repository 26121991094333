import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useLossCode, { LossCodeAction } from './LossCode.Hooks';
import LossCodeDelete from './sections/LossCode.Delete';
import LossCodeEdit from './sections/LossCode.Edit';
import LossCodeFilters from './sections/LossCode.Filters';
import LossCodeList from './sections/LossCode.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import LossCodeImport from './sections/LossCode.Import';

export const lossCodeIndexRoute = claimsHomeRoute + '/loss-code/:action?/:id?';
export default function LossCodeIndex() {
  const { lang } = getLocales();
  const lossCodeHook = useLossCode();

  if (!lossCodeHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={lossCodeHook}
        render={({ listApi, views, pageRouter, permissions }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.lossCodes} />
                <LossCodeFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_LOSSCODE}
                  data={{ action: LossCodeAction.EDIT }}
                />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.import}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.IMPORT_LOSSCODE}
                  data={{ action: LossCodeAction.IMPORT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <LossCodeEdit />}
                {views.delete && <LossCodeDelete />}
                {views.import && <LossCodeImport />}
                <LossCodeList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
