import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';

export default class ClaimCommentUser
  extends Model<Model.IClaimCommentUser>({ icon: 'fas-user' })
  implements Model.Instance<Model.IClaimCommentUser>
{
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  profilePhotoUrl?: string;

  constructor(data: Partial<Model.IClaimComment> | Utils.FormData<Model.IClaimComment>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.firstName, this.lastName) || this.email || this.id.toString(),
    };
  }
}
