import { useState, useEffect, useMemo } from 'react';
import useAnimation from 'assets/components/animation/hooks/Animation';
import { getLocales } from 'assets/locales/Locale';
import { symmetricDifference } from 'assets/utils/data/Data';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';

export default function useSelect<Name>(props: Hooks.Input.Select.Import<Name>) {
  const { lang } = getLocales();
  const animation = useAnimation({ onAnimateIn: () => console.log('IN'), onAnimateOut: () => console.log('OUT') });
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const value = useMemo((): Array<any> => {
    const val = typeof props.value === 'function' ? props.value() : props.value;
    return Array.isArray(val) ? val : isValidValue(val) ? [val] : [];
  }, [props.value]);

  const displayValue = useMemo(() => {
    const isDropdownClosed = !animation.isVisible || animation.isOut;
    const hasValues = props.data && isValidValue(value);
    const isMultiselectWithTags = props.isMultiselect && !props.disableTags;
    const canSearchData = !isMultiselectWithTags && isDropdownClosed && hasValues;
    const foundData = canSearchData ? props.data?.filter((d) => isValidValue(value.find((v) => v === d.id))) : null;
    return foundData?.length > 0
      ? foundData.length > 3
        ? `${foundData.length} ${lang.itemsSelected}`
        : foundData.map((d) => d.title).join(', ')
      : searchValue || props.displayText || props.placeholder || '';
  }, [animation.isVisible, animation.isOut, props.data, props.isMultiselect, value, props.placeholder, searchValue]);

  const getFilteredData = () => {
    return props.data && searchValue
      ? props.data.filter((d) => {
          return new RegExp(`${searchValue.toLowerCase().replace(/,\s*/g, '|')}`, 'g').test(
            `${d.title} ${d.subtitle || ''}`.toLowerCase()
          );
        })
      : props.data || [];
  };
  const getSelectedData = () => {
    return value
      .map((val) => props.data?.find((data) => data.id === val))
      .filter(isValidValue) as Component.Input.Select.SelectionItem[];
  };
  const handleChange = (id: any) => {
    if (!props.isMultiselect) {
      const shouldStayOpen = props.onChange({
        [props.name.toString()]: value.indexOf(id) >= 0 ? undefined : id,
      } as any);
      if (!shouldStayOpen) {
        setSearchValue(undefined);
        animation.animateOut();
      }
    } else {
      props.onChange({
        [props.name.toString()]: symmetricDifference(value, [id]),
      } as any);
    }
  };

  useEffect(() => {
    if (searchValue !== undefined && props.onSearch) {
      const search = () => props.onSearch && props.onSearch(searchValue);
      const timeout = setTimeout(search, 1000);
      return () => clearTimeout(timeout);
    }
  }, [searchValue]);
  const search = (event: any) => {
    setSearchValue(event.currentTarget.value);
  };

  return {
    animation,
    value,
    displayValue,
    getFilteredData,
    getSelectedData,
    handleChange,
    search,
  };
}
