import Model from 'models/Model';

export default class MediaFile
  extends Model<Model.IMediaFile>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IMediaFile>
{
  id: number;
  guid?: string;
  url: string;
  extension: string;
  contentType: string;
  name?: string;
  container?: string;

  constructor(data: Partial<Model.IMediaFile> | Utils.FormData<Model.IMediaFile>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
}
