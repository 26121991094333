import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import React from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';

export default function ClaimEditJobSubmitButton() {
  const { form } = useClaimJobEdit();
  const { lang } = getLocales();
  const isActive = !form.hasErrors && form.isUpdated;

  return (
    <Button
      onClick={form.submit}
      media={<Icon class="fas-save" />}
      render={lang.save}
      active={isActive}
      disabled={!isActive}
      isLoading={form.isSubmitted}
      class={ThemeButtonCircle}
      htmlElementProps={{ title: lang.save }}
    />
  );
}
