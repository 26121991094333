import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardComment from 'assets/components/card/themes/Theme.Card.Comment';
import Collection from 'assets/components/data/Collection';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import Sanitizer from 'assets/components/textBox/Sanitizer';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { orderBy } from 'lodash';
import { useClaimEditJobComments } from './ClaimEdit.Job.Hooks';
import Icon from 'assets/components/icon/Icon';

export default function ClaimEditJobComments() {
  const { lang } = getLocales();
  const {
    comments,
    deleteApi,
    form,
    action,
    setAction,
    isLoadingComments,
    isClaimJobEditable,
    isClaimJobEditableByStatus,
  } = useClaimEditJobComments();

  return (
    <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>{lang.notes}</h2>
        {action === 'compressed' ? (
          <Button
            onClick={setAction}
            data="expanded"
            media={<Icon class="fas-chevron-down" />}
            render={lang.viewMore}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.viewMore }}
          />
        ) : (
          <Button
            onClick={setAction}
            data="compressed"
            media={<Icon class="fas-chevron-up" />}
            render={lang.viewLess}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.viewLess }}
          />
        )}
      </div>
      {action === 'delete' && (
        <Confirm
          data={form.data}
          onConfirm={({ id }) => deleteApi.execute({ id })}
          onClose={() => {
            setAction(null);
            form.update({});
          }}
          actionName={lang.delete}
          itemName={lang.comment}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: Theme.Size.M,
        }}
      >
        {!comments && !isLoadingComments && <ErrorWrapper children={lang.noNotes} />}
        {action !== 'compressed' && (
          <>
            <Collection
              isLoading={isLoadingComments}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: Theme.Size.S,
              }}
              data={orderBy(comments?.data || [], (it) => it.createdAtUtcUnix, ['asc'])}
              map={({ data: comment }) => (
                <Card
                  info={comment.displayInfo.info}
                  title={comment.displayInfo.title}
                  subtitle={<Sanitizer text={comment.displayInfo.subtitle} />}
                  media={comment.displayInfo.media}
                  class={ThemeCardComment}
                  description={
                    !(action === 'edit' && form.data.id === comment.id) ? null : (
                      <MultilineTextInput
                        name="comment"
                        disabled={isClaimJobEditableByStatus}
                        value={form.data.comment}
                        onChange={form.update}
                      />
                    )
                  }
                  actions={
                    <>
                      <div
                        style={{
                          marginRight: 'auto',
                          alignSelf: 'center',
                          fontSize: Theme.FontSize.S,
                        }}
                      >
                        {comment.createdAtUtcUnix < comment.modifiedAtUtcUnix && <>&nbsp;({lang.edited})</>}
                      </div>
                      {action === 'edit' && form.data.id === comment.id ? (
                        <>
                          <Button
                            class={ThemeButtonCircle}
                            onClick={() => {
                              form.set({});
                              setAction(null);
                            }}
                            render={lang.cancel}
                            disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
                          />
                          <Button
                            class={ThemeButtonCircle}
                            onClick={form.submit}
                            render={lang.save}
                            disabled={
                              isClaimJobEditableByStatus || form.hasErrors || form.isSubmitted || !isClaimJobEditable
                            }
                            active={isClaimJobEditableByStatus || !form.hasErrors}
                            isLoading={form.isSubmitted}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            class={ThemeButtonCircle}
                            onClick={() => {
                              setAction('edit');
                              form.set(comment);
                            }}
                            media="fas-edit"
                            disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
                          />
                          <Button
                            class={ThemeButtonCircle}
                            onClick={() => {
                              setAction('delete');
                              form.set(comment);
                            }}
                            media="fas-trash"
                            disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
                          />
                        </>
                      )}
                    </>
                  }
                />
              )}
            />
            {!isLoadingComments && (
              <div>
                <MultilineTextInput
                  name="comment"
                  value={form.data.comment}
                  onChange={form.update}
                  style={{
                    marginTop: 'auto',
                  }}
                  disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: Theme.Size.M }}>
                  <Button
                    class={ThemeButtonCircle}
                    onClick={form.submit}
                    render={lang.addNote}
                    disabled={isClaimJobEditableByStatus || form.hasErrors || form.isSubmitted || !isClaimJobEditable}
                    active={isClaimJobEditableByStatus || !form.hasErrors}
                    isLoading={form.isSubmitted}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
