import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Model from 'models/Model';
import distributorDeleteApi from './delete/Api.Distributor.Delete';
import distributorFindApi from './find/Api.Distributor.Find';
import distributorListApi from './list/Api.Distributor.List';
import distributorSaveApi from './save/Api.Distributor.Save';
import DistributorProduct from '../distributorProduct/Model.ProductDistributor';
import Lender from '../lender/Model.Lender';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';

export default class Distributor
  extends Model<Model.IDistributor>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributor>
{
  id: number;
  name?: string;
  isInactive: boolean = false;
  primaryContactEmailAddress?: string;

  streetAddress?: string;
  city?: string;
  provinceCode?: string;
  country?: string;
  postalCode?: string;
  dealers?: Dealer[];
  distributorProducts?: DistributorProduct[];
  lenders?: Lender[];
  dealerGroups?: DealerGroup[];

  constructor(data: Partial<Model.IDistributor> | Utils.FormData<Model.IDistributor>, language?: Locale.Languages) {
    super(
      { dealers: [Dealer], distributorProducts: [DistributorProduct], lenders: [Lender], dealerGroups: [DealerGroup] },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      info: this.dealers?.map((d) => d.name)?.join(', '),
    };
  }

  static async list(body?: Api.Core.Distributor.List.IRequest) {
    const { payload, ...rest } = await distributorListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Distributor(it)),
        count: payload.count,
      },
    };
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await distributorFindApi(params);
    return {
      ...rest,
      payload: new Distributor(payload),
    };
  }
  async save() {
    return await distributorSaveApi(this);
  }
  async delete() {
    return await distributorDeleteApi({ id: this.id });
  }
}
