import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { ascLogo } from 'assets/themes/assets/Images';
import React, { useEffect } from 'react';
import Button from '../../../../assets/components/button/Button';
import Icon, { iconStyles } from '../../../../assets/components/icon/Icon';
import TextInput from '../../../../assets/components/inputs/text/TextInput';
import Page from '../../../../assets/components/page/Page';
import ThemePageLogin from '../../../../assets/components/page/themes/Theme.Page.Login';
import { getLocales } from '../../../../assets/locales/Locale';

export default function Login() {
  const { lang } = getLocales();
  const auth = useAuthStore();

  const form = useForm<Hooks.AuthInfo.LoginInfo>({
    default: {},
    validation: (data, errors) => {
      if (!data.password) errors.password = lang.mustNotBeEmpty;
      if (!data.username) errors.username = lang.mustNotBeEmpty;
    },
    onSubmit: async (userLoginInfo: Hooks.AuthInfo.LoginInfo) => {
      if (userLoginInfo && !(await auth.login(userLoginInfo))) {
        form.update({ password: null });
      }
    },
  });
  useEffect(() => {
    function handleEnter(ev: KeyboardEvent) {
      if (!form.hasErrors && ev.key === 'Enter') form.submit();
    }
    document.addEventListener('keydown', handleEnter, true);
    return () => document.removeEventListener('keydown', handleEnter, true);
  }, [form.hasErrors, form.data]);

  return (
    <Page
      class={ThemePageLogin}
      render={<img src={ascLogo} />}
      sidebar={
        <>
          <FormWrapper>
            <TextInput
              name="username"
              label={lang.username}
              onChange={form.update}
              value={form.data.username}
              error={form.errors.username}
              icon={<Icon class={iconStyles.common.username} />}
            />
            <TextInput
              name="password"
              label={lang.password}
              onChange={form.update}
              value={form.data.password}
              error={form.errors.password}
              icon={<Icon class={iconStyles.common.password} />}
            />
            <Button
              onClick={form.submit}
              class={ThemeButtonCircle}
              render={lang.login}
              disabled={form.hasErrors}
              active={!form.hasErrors}
              isLoading={form.isSubmitted}
            />
          </FormWrapper>
        </>
      }
    />
  );
}
