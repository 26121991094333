import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';
import { useLenderProvider } from '../Lender.Hooks';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { compact, set, uniqBy } from 'lodash';
import { isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import Distributor from 'models/sales/distributor/Model.Distributor';
import useApi from 'assets/hooks/api/useApi';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';

export default function LenderEdit() {
  const { lang } = getLocales();
  const { isDistributor, isDealer, user } = useAuthStore();
  const { selected, pageRouter, saveApi } = useLenderProvider();
  const distributorsApi = useApi({ action: Distributor.list, wait: isDistributor && !isDealer });
  const formData = selected?.toJSON();
  return (
    <>
      <ModalForm<Api.Core.Lender.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.lender}
        default={{
          ...formData,
          distributors: uniqBy(
            compact([...(formData?.distributors ?? []), user.distributorId ? { id: user.distributorId } : null]),
            (it) => it.id
          ),
        }}
        onCancel={pageRouter.updateParams}
        onSubmit={saveApi.execute}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (data.phoneNumber && !isValidPhoneNumber(data.phoneNumber))
            set(errors, 'phoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="email"
                label={lang.email}
                value={form.data.email}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                label={lang.phoneNumber}
                name="phoneNumber"
                value={form.data.phoneNumber}
                error={form.errors.phoneNumber}
                onChange={({ phoneNumber }) =>
                  form.merge({
                    phoneNumber: formatFormPhone(form.data.phoneNumber, phoneNumber),
                  })
                }
              />
              {!isDistributor && (
                <SelectInput
                  name="distributors"
                  label={lang.distributors}
                  data={distributorsApi?.payload?.data?.map((item) => item.displayInfo)}
                  isMultiselect
                  value={form.data.distributors?.map((it) => it.id)}
                  onChange={({ distributors: idList }) =>
                    form.update({
                      distributors: idList.map((id) => form.data.distributors?.find((it) => it.id === id) ?? { id }),
                    })
                  }
                  icon={<Icon class="fas-clipboard-list" />}
                />
              )}
              <LocationSearch
                onSelect={(location) => {
                  form.update({
                    streetAddress: location?.Line1 || null,
                    provinceCode: location?.ProvinceCode || null,
                    city: location?.City || null,
                    countryCode: location?.CountryIso3 || null,
                    postalCode: location?.PostalCode || null,
                  });
                }}
              />
              <InputWrapper>
                <TextInput
                  name="streetAddress"
                  label={lang.streetAddress}
                  value={form.data.streetAddress}
                  error={form.errors.streetAddress}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="postalCode"
                  label={lang.postalCode}
                  value={form.data.postalCode}
                  error={form.errors.postalCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="city"
                  label={lang.city}
                  value={form.data.city}
                  error={form.errors.city}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="provinceCode"
                  label={lang.provinceCode}
                  value={form.data.provinceCode}
                  error={form.errors.provinceCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                  style={{ width: Theme.Size.XXL }}
                />
                <TextInput
                  name="countryCode"
                  label={lang.countryCode}
                  value={form.data.countryCode}
                  error={form.errors.countryCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                  style={{ width: Theme.Size.XXL }}
                />
              </InputWrapper>
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
