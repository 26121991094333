import { snakeCase, upperFirst } from 'lodash';
import en from './languages/en';
import fr from './languages/fr';

const locales = { en, fr };

export enum Language {
  english = 'en',
  french = 'fr',
}
export const defaultLanguage: Locale.Languages = Language.english;
export function getLocales() {
  const currentLanguage = getLanguage();
  const lang = locales[currentLanguage];

  function translate(key: any): string {
    const translation = (lang as any)[key];
    if (translation) return translation;
    else if (key && key.indexOf(' ') < 0) return upperFirst(snakeCase(key).replace(/_/g, ' '));
    else return key;
  }

  return {
    lang,
    currentLanguage,
    translate,
  };
}
const languageSessionKey = 'language';
export function getLanguage() {
  return (localStorage.getItem(languageSessionKey) as Locale.Languages) || defaultLanguage;
}
export function setLanguage(language?: Locale.Languages) {
  localStorage.setItem(languageSessionKey, language || defaultLanguage);
}
