import './Theme.Common.scss';
import AnimationVariable from './variables/AnimationVariable';
import ColorVariable from './variables/ColorVariable';
import FontSizeVariable from './variables/FontSizeVariable';
import FontNestedSizeVariable from './variables/FontNestedSizeVariable';
import SizeVariable from './variables/SizeVariable';
import SpeedVariable from './variables/SpeedVariable';
import { isMobile } from 'react-device-detect';
import { mergeWith } from 'lodash';

export default class Theme {
  private allDevices: React.CSSProperties = {};
  private desktop: React.CSSProperties = {};
  private mobile: React.CSSProperties = {};

  static Animation = AnimationVariable;
  static Speed = SpeedVariable;
  static Color = ColorVariable;
  static FontSize = FontSizeVariable;
  static FontNestedSize = FontNestedSizeVariable;
  static Size = {
    ...SizeVariable,
    viewportHeight: (val: number) => `${val}vh`,
    viewportWidth: (val: number) => `${val}vw`,
    percent: (val: number) => `${val}%`,
    pixel: (val: number) => `${val}px`,
    fraction: (val: number) => `${val}fr`,
    em: (val: number) => `${val}em`,
    rem: (val: number) => `${val}rem`,
  };

  constructor(props?: Theme.Common.Import) {
    this.allDevices = (props && props.allDevices) || this.allDevices;
    this.desktop = (props && props.desktop) || this.desktop;
    this.mobile = (props && props.mobile) || this.mobile;
  }

  static get ALL_DEVICES() {
    const stylizer = new Theme();
    return stylizer.ALL_DEVICES;
  }
  static get DESKTOP() {
    const stylizer = new Theme();
    return stylizer.DESKTOP;
  }
  static get MOBILE() {
    const stylizer = new Theme();
    return stylizer.MOBILE;
  }

  ALL_DEVICES(styles: React.CSSProperties) {
    this.allDevices = styles;
    return this;
  }
  DESKTOP(styles: React.CSSProperties) {
    this.desktop = styles;
    return this;
  }
  MOBILE(styles: React.CSSProperties) {
    this.mobile = styles;
    return this;
  }
  get BUILD() {
    return mergeWith({}, this.allDevices, isMobile ? this.mobile : this.desktop);
  }
}
