import { preventDefaultBehavior } from 'assets/utils/dom/Events';
import { hasKey } from 'assets/utils/parsersAndValidation/Validators';
import { throttle } from 'lodash';
import { useEffect } from 'react';

export function useScroll(props?: Hooks.Events.Scroll.Import, dependencyArray?: any[]) {
  const handleScroll = throttle((event: React.UIEvent<HTMLElement>) => {
    try {
      let currentPosition: Hooks.Events.Scroll.ScrollPosition;
      const querySelector = props && hasKey(props, 'querySelector') ? props.querySelector : '[data-scroll]';

      const target = (event.target || event.currentTarget) as HTMLElement;
      if (props.watchQuerySelector && !target.matches(props.watchQuerySelector)) return;
      preventDefaultBehavior(event);
      const prevScrollPosition = ((target.dataset[props.key] && parseInt(target.dataset[props.key])) || 0) as number;
      const dimensions = target.getBoundingClientRect();
      const scrollHeight = target.scrollHeight - dimensions.height;

      const topTreshold = props?.topTreshold || 0;
      const bottomTreshold = props?.bottomTreshold || 0;
      const scrollPosition = target.scrollTop;
      const min = props?.usePercentages ? (scrollHeight * topTreshold) / 100 : topTreshold;
      const max = bottomTreshold
        ? scrollHeight - (props?.usePercentages ? (scrollHeight * bottomTreshold) / 100 : bottomTreshold)
        : scrollHeight;

      const prevPosition = prevScrollPosition <= min ? 'top' : prevScrollPosition >= max ? 'bottom' : 'middle';

      if (scrollPosition >= max) {
        if (prevPosition !== 'bottom') currentPosition = 'bottom';
      } else if (scrollPosition <= min) {
        if (prevPosition !== 'top') currentPosition = 'top';
      } else if (scrollPosition > min && scrollPosition < max) {
        if (prevPosition !== 'middle') currentPosition = 'middle';
      } else currentPosition = prevPosition;
      if (currentPosition && prevPosition !== currentPosition) {
        const _export: Hooks.Events.Scroll.Export = {
          prevPosition,
          currentPosition,
          scrollHeight,
          scrollPosition,
          prevScrollPosition,
          min,
          max,
          target,
        };
        if (props.onBottom && currentPosition === 'bottom') props.onBottom(_export);
        else if (props.onTop && currentPosition === 'top') props.onTop(_export);
        else if (props.onMiddle && currentPosition === 'middle') props.onMiddle(_export);
        target.dataset[props.key] = scrollPosition.toString();
      }

      if (querySelector) {
        document.querySelectorAll(querySelector).forEach((element) => {
          const position = (element as any).dataset.scroll;
          if (currentPosition && position !== currentPosition) (element as any).dataset.scroll = currentPosition;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, 200);
  useEffect(() => {
    document.addEventListener('scroll', handleScroll as any, true);
    return () => document.removeEventListener('scroll', handleScroll as any, true);
  }, dependencyArray || [props && props.topTreshold, props && props.bottomTreshold]);
}
export function useGlobalScroll(listener: (this: Document, ev: Event) => any, dependencyArray?: any[]) {
  useEffect(() => {
    document.addEventListener('scroll', listener, true);
    return () => document.removeEventListener('scroll', listener, true);
  }, dependencyArray);
}
