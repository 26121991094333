const ValidationRegex = {
  get numberWithUnit() {
    return /^\d+(\.\d+)?\w+(\d+)?$/g;
  },
  get absolutePath() {
    return /^\/(\w+)?/g;
  },
  get url() {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
  },
  get video() {
    return /([a-z\-_0-9\/\:\.]*\.(ogg|mpeg|aac))/g;
  },
  get image() {
    return /\.(jpg|jpeg|png|gif)$/g;
  },
  get email() {
    return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g;
  },
  get validProcedure() {
    return /^\w+(_\w+)?\((\s+)?((((true|null|false|\d+|\'(.+)?\')(\s+)?\,(\s+)?)+)?((true|null|false|\d+|\'(.+)?\')(\s+)?))?\)$/g;
  },
  get phoneNumber() {
    return /^(\d{3}-){2}\d{4}$/g;
  },
  get templatePlaceholderRegex() {
    return /{{([\s\S]+?)}}/g;
  },
};
export default ValidationRegex;
