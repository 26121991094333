import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import './Row.scss';

export enum RowStructure {
  dataRow = 'dataRow',
}
export type Verify = Utils.VerifyExtends<Component.Row.Structure, typeof RowStructure>;
export default function Row(props: Component.Row.Import) {
  const extend = useTheme(RowStructure, props);

  return (
    <div
      {...extend(RowStructure.dataRow, true)}
      data-higlighted={props.isHighlighted || undefined}
      data-compressed={props.isCompressed || undefined}
      data-selectable={props.isSelectable || undefined}
      children={RenderProps(props, 'render')}
    />
  );
}
