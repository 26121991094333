import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import ToString from 'assets/components/textBox/ToString';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import PricingDistributionTable from 'module/productManagement/components/pricingDistributionTable/PricingDistributionTable';
import { Fragment, useState } from 'react';
import { useCustomPricingProvider } from '../CustomPricing.Hooks';
import SubfeeDistributionTable from 'module/productManagement/components/pricingDistributionTable/SubfeeDistributionTable';
import TextInput from 'assets/components/inputs/text/TextInput';
import Icon from 'assets/components/icon/Icon';
import FormatValue from 'assets/components/formatValue/FormatValue';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import DistributorDistributionTable from 'module/productManagement/components/pricingDistributionTable/DistributorDistributionTable';
import { compact, reduce } from 'lodash';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DealerPricingLimitationTable from 'module/productManagement/components/pricingDistributionTable/DealerPricingLimitationTable';
import DistributionPriceSwitch from 'module/productManagement/components/pricingDistributionTable/DistributionPriceSwitch';

export default function CustomPricingTable() {
  const { lang } = getLocales();
  const {
    getPrice,
    customInfoList,
    customInfoGroupList,
    terms,
    addEmptyPrices,
    updatePrice,
    updateSubfee,
    data,
    removeTerm,
    removeCustomInfo,
    removeCustomInfoGroup,
    productApi,
    isAllowedToCopy,
    copy,
    distributorPricingDistributions,
    dealerPricingSettings,
  } = useCustomPricingProvider();
  const [tempTerm, setTempTerm] = useState<number>();
  const [tempCustomInfo, setTempCustomInfo] = useState<string>();
  const [tempCustomInfoGroup, setTempCustomInfoGroup] = useState<string>();
  const disableInputs = !data.length ? !tempCustomInfoGroup || !tempCustomInfo || !tempTerm : false;

  const _addEmptyPrices = () => {
    addEmptyPrices(
      tempTerm ? [tempTerm] : terms,
      tempCustomInfo ? [tempCustomInfo] : customInfoList,
      tempCustomInfoGroup ? [tempCustomInfoGroup] : customInfoGroupList
    );
    setTempTerm(null);
    setTempCustomInfo(null);
    setTempCustomInfoGroup(null);
  };
  function updateOriginalPrice({
    termInMonths,
    customInfo,
    customInfoGroup,
    distributionKey,
    distributedValue,
  }: {
    termInMonths: number;
    customInfo: string;
    customInfoGroup: string;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const originalDistributions = getPrice(termInMonths, customInfo, customInfoGroup)?.pricingDistributions;
    if (originalDistributions) {
      updatePrice(termInMonths, customInfo, customInfoGroup, {
        ...originalDistributions,
        [distributionKey]: distributedValue,
      });
    }
  }
  function updateDistributorPrice({
    termInMonths,
    customInfo,
    customInfoGroup,
    distributionKey,
    distributedValue,
  }: {
    termInMonths: number;
    customInfo: string;
    customInfoGroup: string;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const currentPricePoint = getPrice(termInMonths, customInfo, customInfoGroup);
    if (currentPricePoint?.pricingDistributions) {
      const currentDistribution = distributorPricingDistributions.getDistributorPrice(
        currentPricePoint?.id,
        'CustomPricePoint'
      )?.distributions;
      distributorPricingDistributions.updateDistributorPrice({
        pricePointId: currentPricePoint.id,
        pricePointType: 'CustomPricePoint',
        distributions: {
          ...currentDistribution,
          [distributionKey]: distributedValue,
        },
      });
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: Theme.Size.M,
          gap: Theme.Size.M,
          justifyContent: 'flex-end',
        }}
      >
        {isAllowedToCopy && (
          <Button
            class={ThemeButtonCircle}
            media="fas-copy"
            onClick={copy}
            render={lang.copyDefaultPricesHere}
            style={{ marginRight: 'auto' }}
            htmlElementProps={{ title: lang.copy }}
          />
        )}
        {distributorPricingDistributions.canEdit && (
          <InputWrapper>
            {distributorPricingDistributions.distributors?.length &&
              !distributorPricingDistributions.isDealerOrDistributor && (
                <SelectInput
                  name="distributorId"
                  value={distributorPricingDistributions.distributorId}
                  label={lang.distributors}
                  data={distributorPricingDistributions.distributors?.map((item) => ({
                    ...item.displayInfo,
                    disabled:
                      !!dealerPricingSettings.dealerId && item.id !== dealerPricingSettings.dealer?.representativeId,
                  }))}
                  onChange={({ distributorId }) => distributorPricingDistributions.setDistributorId(distributorId)}
                  style={{ width: '200px' }}
                />
              )}
            <SelectInput
              name="templateId"
              value={distributorPricingDistributions.distributorData?.templateId}
              label={lang.pricingDistributionTemplate}
              data={distributorPricingDistributions.templates?.map((item) => item.displayInfo)}
              onChange={({ templateId }) =>
                distributorPricingDistributions.setDistributorData({
                  templateId,
                  isUpdated: true,
                  distributions: [],
                  template: distributorPricingDistributions.templates?.find((it) => it.id === templateId),
                })
              }
              disabled={!!distributorPricingDistributions.productRelation?.pricingDistributionTemplateId}
              style={{ width: '200px' }}
            />
          </InputWrapper>
        )}
        {dealerPricingSettings.canEdit && (
          <InputWrapper>
            {!!dealerPricingSettings.dealers?.length && (
              <SelectInput
                name="dealerId"
                value={dealerPricingSettings.dealerId}
                label={lang.dealers}
                data={dealerPricingSettings.dealers?.map((item) => ({
                  ...item.displayInfo,
                  disabled:
                    !!distributorPricingDistributions.distributorId &&
                    item.representativeId !== distributorPricingDistributions.distributorId,
                }))}
                onChange={({ dealerId }) => {
                  dealerPricingSettings.setDealerId(dealerId);
                  const distributorId = dealerPricingSettings.dealers?.find(
                    (it) => it.id === dealerId
                  )?.representativeId;
                  if (distributorId) distributorPricingDistributions.setDistributorId(distributorId);
                }}
                style={{ width: '200px' }}
              />
            )}
          </InputWrapper>
        )}
        {!distributorPricingDistributions.isDealerOrDistributor && (
          <InputWrapper>
            <NumberInput
              label={`${lang.add}: ${lang.term}`}
              name="term"
              value={tempTerm}
              onChange={({ term }) => setTempTerm(term)}
              style={{ formInput: { maxWidth: '200px' } }}
              step={1}
              min={0}
              roundOnBlur
            />
            <TextInput
              label={lang.customInfoGroup}
              name="customInfoGroup"
              value={tempCustomInfoGroup}
              onChange={({ customInfoGroup }) => setTempCustomInfoGroup(customInfoGroup)}
              style={{ formInput: { maxWidth: '200px' } }}
            />
            <TextInput
              label={lang.customInfo}
              name="customInfo"
              value={tempCustomInfo}
              onChange={({ customInfo }) => setTempCustomInfo(customInfo)}
              style={{ formInput: { maxWidth: '200px' } }}
            />
            <Button
              class={ThemeButtonCircle}
              media="fas-plus"
              disabled={disableInputs}
              onClick={_addEmptyPrices}
              style={{ border: `${Theme.Size.XXS} solid ${Theme.Color.shadowDark}` }}
            />
          </InputWrapper>
        )}
      </div>
      <table className={ThemeTableSimple2}>
        <DataMap
          data={customInfoGroupList}
          render={({ data: customInfoGroup }) => (
            <Fragment key={customInfoGroup}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      {!distributorPricingDistributions.isDealerOrDistributor && (
                        <Button
                          class={ThemeButtonCircle}
                          style={{ button: { display: 'inline-block' } }}
                          media="fas-trash"
                          onClick={removeCustomInfoGroup}
                          data={customInfoGroup}
                          disabled={data.some((d) => d.customInfoGroup == customInfoGroup && d.id)}
                        />
                      )}
                      {customInfoGroup ?? 'No group'}
                    </div>
                  </th>
                  <DataMap
                    data={terms}
                    render={({ data: termInMonths }) => (
                      <th key={termInMonths} style={{ textAlign: 'right', position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                            {distributorPricingDistributions.canEdit && !!distributorPricingDistributions.distributor && (
                              <DistributorDistributionTable
                                columnKey={`Distributor-${termInMonths.toString()}-${customInfoGroup}`}
                                rows={customInfoList}
                                columns={distributorPricingDistributions.distributorData?.template?.keys}
                                getPrice={(customInfo) =>
                                  distributorPricingDistributions.getDistributorPrice(
                                    getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                                    'CustomPricePoint'
                                  )?.distributions
                                }
                                updatePrice={(customInfo, distributions) =>
                                  distributorPricingDistributions.updateDistributorPrice({
                                    distributions,
                                    pricePointId: getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                                    pricePointType: 'CustomPricePoint',
                                  })
                                }
                                priceOption={({ distributedValue, distributionKey }) => (
                                  <DropdownButton
                                    buttonMedia="fas-copy"
                                    horizontalPosition="auto_fixed"
                                    verticalPosition="auto_fixed"
                                    tabIndex={null}
                                    subcomponents={ThemeDropdownButtonPopup}
                                    htmlElementProps={{ title: lang.copy }}
                                    title="Copy to:"
                                    dropdownOptions={({ animateOut }) => (
                                      <>
                                        <Button
                                          class={ThemeButtonMenuItem}
                                          onClick={() => {
                                            animateOut();
                                            for (const customInfo of customInfoList) {
                                              updateDistributorPrice({
                                                termInMonths,
                                                customInfo,
                                                customInfoGroup,
                                                distributionKey,
                                                distributedValue,
                                              });
                                            }
                                          }}
                                          htmlElementProps={{ title: '' }}
                                        >
                                          This column
                                        </Button>
                                        <Button
                                          class={ThemeButtonMenuItem}
                                          onClick={() => {
                                            animateOut();
                                            for (const customInfo of customInfoList) {
                                              for (const customInfoGroup of customInfoGroupList) {
                                                updateDistributorPrice({
                                                  termInMonths,
                                                  customInfo,
                                                  customInfoGroup,
                                                  distributionKey,
                                                  distributedValue,
                                                });
                                              }
                                            }
                                          }}
                                          htmlElementProps={{ title: '' }}
                                        >
                                          All groups
                                        </Button>
                                      </>
                                    )}
                                  />
                                )}
                              />
                            )}
                            {dealerPricingSettings.canEdit && !!dealerPricingSettings.dealer && (
                              <DealerPricingLimitationTable
                                columnKey={`Dealer-${termInMonths.toString()}-${customInfoGroup}`}
                                rows={customInfoList}
                                getDistributorPrice={(customInfo) =>
                                  reduce(
                                    distributorPricingDistributions.getDistributorPrice(
                                      getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                                      'CustomPricePoint'
                                    )?.distributions,
                                    (prev, curr) => prev + curr.amount,
                                    0
                                  )
                                }
                                getDiscountPrice={(customInfo) =>
                                  dealerPricingSettings.getDiscountPrice(
                                    getPrice(termInMonths, customInfo, customInfoGroup)?.pricingDistributions
                                  )
                                }
                                getLimitation={(customInfo) =>
                                  dealerPricingSettings.getDistributionLimitation(
                                    getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                                    'CustomPricePoint'
                                  )?.limitations
                                }
                                updateLimitation={(customInfo, limitations) =>
                                  dealerPricingSettings.updateDistributionLimitation({
                                    limitations,
                                    pricePointId: getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                                    pricePointType: 'CustomPricePoint',
                                  })
                                }
                                priceOption={({ delta, property, unit }) => (
                                  <DropdownButton
                                    buttonMedia="fas-copy"
                                    horizontalPosition="auto_fixed"
                                    verticalPosition="auto_fixed"
                                    tabIndex={null}
                                    subcomponents={ThemeDropdownButtonPopup}
                                    htmlElementProps={{ title: lang.copy }}
                                    title="Increase all:"
                                    disabled={unit !== 'replacement' || delta < 0}
                                    dropdownOptions={({ animateOut }) => (
                                      <Button
                                        htmlElementProps={{ title: '' }}
                                        class={ThemeButtonMenuItem}
                                        onClick={() => {
                                          animateOut();
                                          dealerPricingSettings.updateDistributionLimitation(
                                            compact(
                                              data.map((pricePoint) => {
                                                const currentLimitations =
                                                  dealerPricingSettings.getDistributionLimitation(
                                                    pricePoint.id,
                                                    'CustomPricePoint'
                                                  );
                                                if (
                                                  !currentLimitations?.limitations?.unit ||
                                                  currentLimitations?.limitations.unit === unit
                                                ) {
                                                  const currentBasePrice =
                                                    dealerPricingSettings.getDiscountPrice(
                                                      pricePoint.pricingDistributions
                                                    ) +
                                                    reduce(
                                                      distributorPricingDistributions.getDistributorPrice(
                                                        pricePoint.id,
                                                        'CustomPricePoint'
                                                      )?.distributions,
                                                      (prev, curr) => prev + curr.amount,
                                                      0
                                                    );
                                                  return {
                                                    ...currentLimitations,
                                                    limitations: {
                                                      ...currentLimitations?.limitations,
                                                      unit,
                                                      [property]: delta === 0 ? null : currentBasePrice + delta,
                                                    },
                                                    pricePointId: pricePoint?.id,
                                                    pricePointType: 'CustomPricePoint',
                                                  };
                                                }
                                                return null;
                                              })
                                            )
                                          );
                                        }}
                                      >
                                        <span>
                                          {property === 'minAmount' ? 'Sug. retail' : 'Max'} prices by{' '}
                                          <FormatValue value={`${delta.toFixed(2)}$`} />
                                        </span>
                                      </Button>
                                    )}
                                  />
                                )}
                              />
                            )}
                            {!distributorPricingDistributions.isDealerOrDistributor && (
                              <>
                                <Button
                                  class={ThemeButtonCircle}
                                  style={{ button: { display: 'inline-block' } }}
                                  media="fas-trash"
                                  onClick={removeTerm}
                                  data={termInMonths}
                                  disabled={data.some((d) => d.termInMonths === termInMonths && d.id)}
                                  htmlElementProps={{ title: `${lang.clear} ${lang.term}` }}
                                />
                                <PricingDistributionTable
                                  columnKey={`PricingDistribution-${termInMonths.toString()}-${customInfoGroup}`}
                                  rows={customInfoList}
                                  columns={productApi?.payload?.templateKeys}
                                  getPrice={(customInfo) =>
                                    getPrice(termInMonths, customInfo, customInfoGroup)?.pricingDistributions
                                  }
                                  updatePrice={(customInfo, distribution) =>
                                    updatePrice(termInMonths, customInfo, customInfoGroup, distribution)
                                  }
                                  priceOption={({ distributedValue, distributionKey }) => (
                                    <DropdownButton
                                      buttonMedia="fas-copy"
                                      horizontalPosition="auto_fixed"
                                      verticalPosition="auto_fixed"
                                      tabIndex={null}
                                      subcomponents={ThemeDropdownButtonPopup}
                                      htmlElementProps={{ title: lang.copy }}
                                      title="Copy to:"
                                      dropdownOptions={({ animateOut }) => (
                                        <>
                                          <Button
                                            class={ThemeButtonMenuItem}
                                            onClick={() => {
                                              animateOut();
                                              for (const customInfo of customInfoList) {
                                                updateOriginalPrice({
                                                  termInMonths,
                                                  customInfo,
                                                  customInfoGroup,
                                                  distributionKey,
                                                  distributedValue,
                                                });
                                              }
                                            }}
                                            htmlElementProps={{ title: '' }}
                                          >
                                            This column
                                          </Button>
                                          <Button
                                            class={ThemeButtonMenuItem}
                                            onClick={() => {
                                              animateOut();
                                              for (const customInfo of customInfoList) {
                                                for (const customInfoGroup of customInfoGroupList) {
                                                  updateOriginalPrice({
                                                    termInMonths,
                                                    customInfo,
                                                    customInfoGroup,
                                                    distributionKey,
                                                    distributedValue,
                                                  });
                                                }
                                              }
                                            }}
                                            htmlElementProps={{ title: '' }}
                                          >
                                            All groups
                                          </Button>
                                        </>
                                      )}
                                    />
                                  )}
                                />
                                <SubfeeDistributionTable
                                  columnKey={`Subfees-${termInMonths.toString()}-${customInfoGroup}`}
                                  rows={customInfoList}
                                  columns={productApi?.payload?.subfee}
                                  getPrice={(customInfo) =>
                                    getPrice(termInMonths, customInfo, customInfoGroup)?.subfees
                                  }
                                  updatePrice={(customInfo, subfees) =>
                                    updateSubfee(termInMonths, customInfo, customInfoGroup, subfees)
                                  }
                                />
                              </>
                            )}
                          </div>
                          <ToString value={`${termInMonths}m`} />
                        </div>
                      </th>
                    )}
                  />
                </tr>
              </thead>
              <tbody>
                <DataMap
                  data={customInfoList}
                  render={({ data: customInfo }) => (
                    <tr key={customInfo}>
                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {!distributorPricingDistributions.isDealerOrDistributor && (
                            <Button
                              class={ThemeButtonCircle}
                              style={{ button: { display: 'inline-block' } }}
                              media="fas-trash"
                              onClick={removeCustomInfo}
                              data={customInfo}
                              disabled={data.some((d) => d.customInfo === customInfo && d.id)}
                              htmlElementProps={{ title: lang.clearDistributorPrice }}
                            />
                          )}
                          {customInfo}
                        </div>
                      </td>
                      <DataMap
                        data={terms}
                        render={({ data: termInMonths }) => {
                          const pricePoint = getPrice(termInMonths, customInfo, customInfoGroup);
                          const isUnset = pricePoint?.priceCAD == null;

                          const distributorDistribution = distributorPricingDistributions.getDistributorPrice(
                            getPrice(termInMonths, customInfo, customInfoGroup)?.id,
                            'CustomPricePoint'
                          );
                          const distributorPrice = reduce(
                            distributorDistribution?.distributions || {},
                            (prev, curr) => prev + curr.amount,
                            0
                          );
                          return (
                            <td style={{ textAlign: 'right' }} key={termInMonths}>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                  {distributorPricingDistributions.canEdit &&
                                    !!distributorPricingDistributions.distributorData?.template?.keys && (
                                      <Button
                                        disabled={!distributorDistribution}
                                        onClick={() =>
                                          distributorPricingDistributions.updateDistributorPrice({
                                            pricePointId: pricePoint.id,
                                            distributions: {},
                                            pricePointType: 'CustomPricePoint',
                                          })
                                        }
                                        class={{ button: ThemeButtonCircle }}
                                        media={<Icon class="fas-user-slash" />}
                                        htmlElementProps={{ title: lang.clearDistributorPrice }}
                                      />
                                    )}
                                  {!distributorPricingDistributions.isDealerOrDistributor && (
                                    <Button
                                      disabled={isUnset}
                                      onClick={() => updatePrice(termInMonths, customInfo, customInfoGroup)}
                                      class={{ button: ThemeButtonCircle }}
                                      media={<Icon class="fas-trash-alt" />}
                                      htmlElementProps={{ title: `${lang.clear} ${lang.price}` }}
                                    />
                                  )}
                                </div>
                                <span
                                  style={{
                                    fontWeight: isUnset ? undefined : 'bold',
                                    color: isUnset ? Theme.Color.warning : undefined,
                                  }}
                                >
                                  {distributorDistribution ? (
                                    <>
                                      <FormatValue
                                        value={
                                          isUnset
                                            ? 'N/A'
                                            : `${((pricePoint?.priceCAD ?? 0) + distributorPrice).toFixed(2)}$`
                                        }
                                      />
                                      <br />
                                      <span style={{ fontSize: Theme.FontSize.S }}>
                                        Base price:{' '}
                                        <FormatValue value={isUnset ? 'N/A' : `${pricePoint?.priceCAD.toFixed(2)}$`} />
                                      </span>
                                    </>
                                  ) : (
                                    <FormatValue value={isUnset ? 'N/A' : `${pricePoint?.priceCAD.toFixed(2)}$`} />
                                  )}
                                </span>
                              </div>
                            </td>
                          );
                        }}
                      />
                    </tr>
                  )}
                />
              </tbody>
            </Fragment>
          )}
        />
      </table>
      <DistributionPriceSwitch
        templateKeys={productApi?.payload?.templateKeys ?? []}
        onUpdate={(firstKey, secondKey) => {
          for (const termInMonths of terms) {
            for (const customInfo of customInfoList) {
              for (const customInfoGroup of customInfoGroupList) {
                const originalDistributions = getPrice(termInMonths, customInfo, customInfoGroup)?.pricingDistributions;
                if (originalDistributions) {
                  updatePrice(termInMonths, customInfo, customInfoGroup, {
                    ...originalDistributions,
                    [firstKey]: originalDistributions[secondKey],
                    [secondKey]: originalDistributions[firstKey],
                  });
                }
              }
            }
          }
        }}
      />
    </>
  );
}
