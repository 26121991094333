import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { HttpStatus } from 'config/Api.Config';
import { set } from 'lodash';
import claimSaveSimpleApi from 'models/claims/claim/saveSimple/Api.Claim.SaveSimple';
import claimDocumentSaveApi from 'models/claims/claimDocument/save/Api.ClaimDocument.Save';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import Contract from 'models/sales/contract/Model.Contract';
import { claimEditIndexRoute } from '../../claimEdit/ClaimEdit.Index';

export default function useClaimSimpleClaim(props: { contract: Contract; onClose: () => void }) {
  const { lang } = getLocales();
  const { userId, dealerId } = useAuthStore();

  const claimTypesApi = useApi({ action: ClaimType.list });
  const claimTypes = claimTypesApi.payload?.data;

  const { redirect: redirectToClaim } = usePageRouter<Module.Claims.Claim.Params, Module.Claims.Claim.Query>({
    route: claimEditIndexRoute,
  });

  const form = useForm<Api.Core.Claim.SaveSimple.IRequest>({
    default: {
      submitedDateUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
      statusCode: ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_SELLING_DEALER,
      contract: props.contract,
      contractId: props.contract?.id,
      ownerId: userId,
      reporterId: userId,
      dealerId: dealerId,
      claimDocuments: [],
    },
    onSubmit: async (data) => {
      const claimTypeWithReport = claimTypes?.find(
        (it) => it.product?.id === (data.contract?.product?.id || data.contract?.productId)
      );
      const claimResponse = await claimSaveSimpleApi({
        ...data,
        contractId: data.contract?.id,
        contract: { id: data.contract?.id },
        claimJobs: !claimTypeWithReport?.claimTypeReportSettings?.length
          ? []
          : [
              {
                jobNumber: 1,
                claimType: { id: claimTypeWithReport.id },
              },
            ],
        contactEmail: data.contract?.client?.email,
        contactPhone: data.contract?.client?.phone,
        streetAddress: data.contract?.client?.streetAddress,
        city: data.contract?.client?.city,
        postalCode: data.contract?.client?.postalCode,
        countryCode: data.contract?.client?.countryCode,
        provinceCode: data.contract?.client?.provinceCode,
      });
      const id = claimResponse.payload;
      if (id && claimResponse.status === HttpStatus.ok) {
        for (const document of data.claimDocuments) {
          document.claimId = id;
          await claimDocumentSaveApi({
            claimId: id,
            formFile: document.formFile,
          });
        }
        addLog({ success: claimResponse.message || lang.saveSuccess });
        redirectToClaim({ claimId: id.toString() });
      } else {
        addLog({ error: claimResponse.message || lang.saveError });
      }
      form.reload();
    },
    validation: (data, errors) => {
      if (!data.claimDocuments) set(errors, 'claimDocuments._objectError', lang.required);
      if (!data.contract) set(errors, 'contract._objectError', lang.required);
      if (!data.descriptionOfDamage) set(errors, 'descriptionOfDamage', lang.required);
    },
  });

  return {
    form,
  };
}
