import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { useSubfeeProvider } from '../Subfee.Hooks';
import subfeeUploadFileApi from 'models/productManagement/subfee/uploadFile/Api.Subfee.UploadFile';
import subfeeSaveApi from 'models/productManagement/subfee/save/Api.Subfee.Save';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import { addLog } from 'assets/components/feedback/Feedback';
import { HttpStatus } from 'config/Api.Config';
import subfeeDeleteFileApi from 'models/productManagement/subfee/deleteFile/Api.Subfee.DeleteFile';
import { difference } from 'lodash';
import MimeType from 'assets/utils/file/mimeType/MimeType';

export default function SubfeeEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, listApi } = useSubfeeProvider();

  return (
    <ModalForm<Api.Core.Subfee.Save.IRequest & { files: Hooks.Input.File.Info[] }>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.surcharge}
      default={{
        currency: 'CAD',
        amount: 0.0,
        ...selected?.toJSON(),

        files: selected?.documents?.map((it) => ({
          id: it.id,
          dataUrl: it.url,
          name: it.name,
          type: it.contentType as Utils.File.MimeType,
        })),
      }}
      onCancel={pageRouter.updateParams}
      onSubmit={async ({ files, ...data }) => {
        const response = await subfeeSaveApi(data);
        const deletedFileIds = difference(
          selected?.documents?.map((it) => it.id),
          files?.map((it) => it.id)
        );
        for (const documentId of deletedFileIds || []) {
          await subfeeDeleteFileApi(response.payload || data.id, { documentId });
        }
        for (const formFile of files?.filter((it) => !it.id && it.original) || []) {
          await subfeeUploadFileApi(response.payload || data.id, { formFile: formFile.original });
        }

        if (response.status === HttpStatus.internalServerError) addLog({ error: response.message });
        listApi.execute((body) => body);
      }}
      validation={(data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
        if (!data.code) errors.code = lang.mustNotBeEmpty;
        if (data.amount == null) errors.amount = lang.mustNotBeEmpty;
        if (data.amount <= 0) errors.amount = lang.mustBeEqualOrMoreThanAmount.replace('{amount}', '0.00');
      }}
      render={({ form }) => (
        <>
          <FormWrapper>
            <TextInput
              name="code"
              label={lang.code}
              value={form.data.code}
              error={form.errors.code}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.name}
              error={form.errors.name}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <NumberInput
              name="amount"
              label={lang.price}
              value={form.data.amount}
              error={form.errors.amount}
              onChange={form.update}
              icon={<Icon>{form.data.currency}</Icon>}
            />
            <FileInput
              name="files"
              label={lang.contractDocuments}
              value={form.data.files || []}
              onChange={form.update}
              class={ThemeFileInputDropzone}
              allowedMimeTypes={[MimeType.appPDF]}
            />
          </FormWrapper>
        </>
      )}
    />
  );
}
