import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { claimsHomeRoute, HttpStatus } from 'config/Api.Config';
import { isMobile } from 'react-device-detect';
import useClient, { ClientAction } from './Client.Hooks';
import ClientEdit from './sections/Client.Edit';
import { ClientFilters } from './sections/Client.Filters';
import ClientList from './sections/Client.List';

export const clientIndexRoute = claimsHomeRoute + '/client/:action?/:id?';
export default function ClientIndex() {
  const { lang } = getLocales();
  const clientHook = useClient();

  if (!clientHook.canEditClient) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <DataProvider
      data={clientHook}
      render={({ listApi, views, pageRouter }) => (
        <Page
          class={ThemePageList}
          isLoading={listApi.isExecuting}
          header={
            <>
              <b style={{ marginRight: 'auto' }} children={lang.clients} />
              <ClientFilters />
              &nbsp;
              <Button
                class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                active
                media={<Icon class="fas-plus" />}
                render={!isMobile && lang.addNew}
                onClick={pageRouter.updateParams}
                data={{ action: ClientAction.EDIT, id: undefined }}
              />
            </>
          }
          render={
            <>
              {views.edit && <ClientEdit />}
              <ClientList />
            </>
          }
          footer={
            <Pagination
              class={ThemePaginationRounded}
              count={listApi.payload?.count}
              offset={listApi.body?.offset}
              limit={listApi.body?.limit}
              onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
            />
          }
        />
      )}
    />
  );
}
