import Model from 'models/Model';

export default class ProductDocumentTemplate
  extends Model<Model.IProductDocumentTemplate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductDocumentTemplate>
{
  id: number;
  version: number;
  name: string;
  description?: string;
  source?: string;
  example?: string;
  allowMultiple: boolean;
  required: boolean;
  allowNotes: boolean;

  constructor(
    data: Partial<Model.IProductDocumentTemplate> | Utils.FormData<Model.IProductDocumentTemplate>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
}
