import React from 'react';
import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import useUIEditor from 'assets/hooks/uiEditor/UIEditor';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { UICommand } from 'assets/utils/dom/Commands';

export default function EmailTemplateTags() {
  const { lang } = getLocales();
  const uiEditor = useUIEditor();
  return (
    <DropdownButton
      buttonMedia={<Icon class="fas-plus" />}
      buttonBody={lang.add}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      subcomponents={ThemeDropdownButtonPopup}
      dropdownOptions={
        <DataMap
          data={[
            { id: '{{EmailTemplate_SenderName}}', title: lang.senderName },
            { id: '{{EmailTemplate_SenderEmail}}', title: lang.senderEmail },
            { id: '{{Claim_ContactName}}', title: lang.contactName },
            { id: '{{Claim_ContactPhone}}', title: lang.phoneNumber },
            { id: '{{Claim_ContactEmail}}', title: lang.email },
            { id: '{{Claim_PublicContactName}}', title: `${lang.public} ${lang.contactName}` },
            { id: '{{Claim_PublicContactPhone}}', title: `${lang.public} ${lang.phoneNumber}` },
            { id: '{{Claim_PublicContactEmail}}', title: `${lang.public} ${lang.email}` },
            { id: '{{Contract_AppNumber}}', title: lang.contractNumber },
            { id: '{{Dealer_Name}}', title: lang.dealer },
            { id: '{{Client_FirstName}} {{Client_LastName}}', title: lang.client },
            { id: '{{Vehicle_Vin}}', title: lang.vin },
            { id: '{{ClaimInvoice_Name}}', title: `${lang.claim} ${lang.invoices} ${lang.name}` },
            { id: '{{ClaimDocument_Name}}', title: `${lang.claim} ${lang.documents} ${lang.name}` },
            { id: '{{ClaimDocument_Notes}}', title: `${lang.claim} ${lang.invoices} ${lang.notes}` },
            { id: '{{ClaimInvoice_Notes}}', title: `${lang.claim} ${lang.documents} ${lang.notes}` },
            { id: '{{MediaFile_Url}}', title: `${lang.download} link` },
          ]}
          render={({ data }) => (
            <Button
              key={data.id}
              class={ThemeButtonMenuItem}
              render={data.title}
              onClick={(data) => uiEditor.applyUiCommand(UICommand.insertHTML, data)}
              data={data.id}
            />
          )}
        />
      }
    />
  );
}
