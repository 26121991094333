import { entries, has, lowerFirst } from 'lodash';

export default function parseJwt<T>(token: string): T {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload) as T;
}

export function mapServerJson(json: object | string) {
  if (!json) return null;
  if (typeof json === 'string') {
    return mapServerJson(JSON.parse(json));
  }
  if (Array.isArray(json)) {
    return json.map((obj) => mapServerJson(obj));
  }
  const finalObj = {};
  for (const [k, v] of entries(json)) {
    if (k !== '$id') {
      finalObj[lowerFirst(k)] = typeof v === 'object' ? mapServerJson(has(v, '$values') ? v['$values'] : v) : v;
    }
  }
  return finalObj;
}
