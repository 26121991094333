import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import en from 'assets/locales/languages/en';
import fr from 'assets/locales/languages/fr';
import { managementHomeRoute } from 'config/Api.Config';
import React from 'react';

export const translationInfoIndexRoute = managementHomeRoute + '/translation-info';
export default function TranslationInfo() {
  return (
    <>
      <Page
        class={ThemePageList}
        style={{
          pageContent: {
            overflow: 'auto',
          },
        }}
        render={
          <table className={ThemeTableSimple}>
            <thead>
              <tr>
                <th>Key</th>
                <th>Translation</th>
              </tr>
            </thead>
            <tbody>
              <DataObjectMap
                data={en}
                render={({ data: translation, key }) =>
                  fr[key] === translation && (
                    <tr
                      key={key}
                      children={
                        <>
                          <td>{key}</td>
                          <td>{translation}</td>
                        </>
                      }
                    />
                  )
                }
              />
            </tbody>
          </table>
        }
      />
    </>
  );
}
