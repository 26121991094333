import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import React from 'react';
import { usePayeeProvider } from '../Payee.Hooks';

export default function PayeeFilters() {
  const { lang } = getLocales();
  const { listApi } = usePayeeProvider();
  const payeeTypeApi = useApi({ action: PayeeType.list });

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilers) => listApi.execute(newFilers)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!payeeTypeApi.payload) payeeTypeApi.execute();
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <SelectInput
              name="payeeTypeId"
              label={lang.payeeTypes}
              data={payeeTypeApi?.payload?.data?.map((item) => item.displayInfo)}
              isMultiselect
              value={tempFilters?.payeeTypeId}
              onChange={({ payeeTypeId }) => setFilters({ ...tempFilters, payeeTypeId })}
              icon={<Icon class="fas-cloud" />}
            />
          </>
        )}
      />
    </>
  );
}
