import DataMap from 'assets/components/dataMap/DataMap';
import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import ValueDisplay from 'assets/templates/valueDisplay/ValueDisplay';
import Theme from 'assets/themes/Theme.Common';
import { Fragment } from 'react';
import { useClaimEdit } from '../ClaimEdit.Hooks';

export default function ClaimEditLogs() {
  const { lang, translate } = getLocales();
  const { logs } = useClaimEdit();
  return (
    <>
      <h2 style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL, marginBottom: Theme.Size.M }}>{lang.logs}</h2>
      <table className={ThemeTableSimple}>
        <DataMap
          data={logs}
          render={({ data: auditLog }) => (
            <Fragment key={auditLog.id}>
              <thead>
                <tr>
                  <th colSpan={2}>
                    {auditLog.application?.name} ({translate(auditLog.permissionKey)})
                  </th>
                  <th style={{ textAlign: 'left' }}>{auditLog.changedAtUtcParsed}</th>
                </tr>
                <tr>
                  <th>Property</th>
                  <th>Old value</th>
                  <th style={{ textAlign: 'left' }}>New value</th>
                </tr>
              </thead>
              <tbody>
                <DataObjectMap
                  data={auditLog.changes}
                  render={({ data, key }) => (
                    <tr key={key} style={{ marginBottom: Theme.Size.M }}>
                      <td>{lang[key] || key}</td>
                      <td>
                        {auditLog?.isChange && data.old ? (
                          <ValueDisplay
                            value={data.old}
                            subcomponents={{
                              listValue: {
                                style: { backgroundColor: Theme.Color.error },
                              },
                            }}
                          />
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {data.current && (
                          <ValueDisplay
                            value={data.current}
                            subcomponents={{
                              listValue: {
                                style: { backgroundColor: Theme.Color.success },
                              },
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                />
              </tbody>
            </Fragment>
          )}
        />
      </table>
    </>
  );
}
