import { createPathFromRoute, parseQueryString } from 'assets/utils/dom/UrlParsing';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export default function usePageRouter<Params extends { [K in keyof Params]?: string }, Query>(
  props: Hooks.UsePageRouter.Props
): Hooks.UsePageRouter.Export<Params, Query> {
  const _location = useLocation();
  const _history = useHistory();
  const _query = useMemo(() => {
    return (
      _location.search ? parseQueryString(_location.search, { parseBoolean: true, parseNumber: true }) : {}
    ) as Query;
  }, [_location.search]);

  const _params = useParams<Params>();

  function redirect(params?: Params, query?: Query, merge?: boolean) {
    const finalParams = merge ? { ..._params, ...params } : params;
    const finalQuery = merge ? { ..._query, ...query } : query;
    const newPath = createPathFromRoute(props.route, finalParams, finalQuery);
    const currentPath = window.location.pathname + _location.search;
    if (newPath !== currentPath) _history.push(createPathFromRoute(props.route, finalParams, finalQuery));
  }
  function updateQuery(query?: Query) {
    _history.push(createPathFromRoute(props.route, _params || {}, query || {}));
  }
  function updateParams(params?: Params) {
    _history.push(createPathFromRoute(props.route, params || {}, _query || {}));
  }
  function clear() {
    _history.push(createPathFromRoute(props.route, {}), {});
  }

  return {
    params: _params,
    query: _query,
    redirect,
    clear,
    updateQuery,
    updateParams,
  };
}
