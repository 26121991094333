import DataMap from 'assets/components/dataMap/DataMap';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon from 'assets/components/icon/Icon';
import Media from 'assets/components/media/Media';
import { useTheme } from 'assets/hooks/theme/useTheme';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { preventDefaultBehavior } from 'assets/utils/dom/Events';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import useFileInput from './hooks/FileInput';

export enum FileInputStructure {
  formInput = 'formInput',
  input = 'input',
  fileDropArea = 'fileDropArea',
  fileInputTitle = 'fileInputTitle',
  fileInputSubtitle = 'fileInputSubtitle',
  fileInputGallery = 'fileInputGallery',
  fileInputMedia = 'fileInputMedia',
  fileInputDelete = 'fileInputDelete',
  fileInputProgress = 'fileInputProgress',
  fileInputProgressText = 'fileInputProgressText',
}
export type Verify = Utils.VerifyExtends<Component.Input.File.Structure, typeof FileInputStructure>;
export default function FileInput(props: Component.Input.File.Import) {
  const extend = useTheme(FileInputStructure, props);
  const { lang } = getLocales();
  const {
    fileInput,
    isActive,
    values,
    isDropAllowed,
    handleDrop,
    handleEnter,
    handleLeave,
    handleClick,
    handleFileSelect,
    removeFile,
    limit,
  } = useFileInput(props);
  return (
    <>
      <div
        {...extend(FileInputStructure.formInput, true)}
        data-error={props.error || undefined}
        children={
          <>
            {RenderProps(props, 'renderBefore', { limit })}
            <input
              {...extend(FileInputStructure.input)}
              onClick={(event) => event.stopPropagation()}
              onChange={handleFileSelect}
              type="file"
              ref={fileInput}
              name={props.name as string}
              accept={props.allowedMimeTypes ? props.allowedMimeTypes.join(', ') : '*'}
            />
            <div
              {...extend(FileInputStructure.fileDropArea)}
              data-active={isActive ? true : undefined}
              data-disabled={props.disabled ? true : undefined}
              onMouseDown={isDropAllowed && !props.disabled ? handleClick : undefined}
              onDrop={isDropAllowed && !props.disabled ? handleDrop : undefined}
              onDragEnter={isDropAllowed && !props.disabled ? handleEnter : undefined}
              onDragLeave={isDropAllowed && !props.disabled ? handleLeave : undefined}
              children={
                <>
                  {props.label}

                  {!props.disabled && (
                    <>
                      <div
                        {...extend(FileInputStructure.fileInputTitle)}
                        children={
                          <>
                            <Icon class="fas-upload" />
                            &nbsp;{lang.clickOrDropFilesHere}
                          </>
                        }
                      />
                      <div
                        {...extend(FileInputStructure.fileInputSubtitle)}
                        children={
                          <>
                            {combineStrings('/', values.length.toString(), props.fileLimit?.toString())}&nbsp;
                            {lang.files}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {combineStrings(
                              '/',
                              (props.currentFileUploadSizeMB || 0).toFixed(2),
                              props.maxFileUploadSizeMB?.toFixed(2)
                            )}
                            <span style={{ fontSize: Theme.Size.S, opacity: 0.5 }} children="MB" />
                            &nbsp;{lang.fileUploadSize}
                            <br />
                            {props.allowedMimeTypes && (
                              <>
                                {lang.allowed}:&nbsp;
                                {props.allowedMimeTypes && props.allowedMimeTypes.map((it) => lang[it]).join(', ')}
                              </>
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                  <div
                    {...extend(FileInputStructure.fileInputGallery)}
                    children={
                      <>
                        <DataMap
                          data={values}
                          render={({ data: file, index }) => (
                            <div
                              {...extend(FileInputStructure.fileInputMedia)}
                              key={index}
                              data-id={index}
                              onMouseDown={(event) => event.stopPropagation()}
                              title={file?.name ?? file.original?.name}
                              children={
                                <>
                                  <Media
                                    useThumbnail
                                    media={{
                                      url: file.dataUrl,
                                      mime_type: file.type,
                                    }}
                                  />
                                  {props.actionBar ? (
                                    <>
                                      {RenderProps(props, 'actionBar', {
                                        file,
                                        remove: () => removeFile(index),
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        {...extend(FileInputStructure.fileInputDelete)}
                                        onMouseDown={(event) => {
                                          preventDefaultBehavior(event);
                                          removeFile(index);
                                        }}
                                        children={<Icon class="ti-trash" />}
                                      />
                                    </>
                                  )}
                                  {isValidValue(file.progress) && (
                                    <>
                                      <div
                                        {...extend(FileInputStructure.fileInputProgress)}
                                        style={{
                                          width: `${file.progress}%`,
                                        }}
                                      />
                                      <div
                                        {...extend(FileInputStructure.fileInputProgressText)}
                                        children={
                                          file.progress === -1
                                            ? lang.uploadError
                                            : file.progress === 100
                                            ? lang.done
                                            : file.progress >= 99
                                            ? `${lang.justAMoment}...`
                                            : `${file.progress.toFixed(2)}%`
                                        }
                                      />
                                    </>
                                  )}
                                </>
                              }
                            />
                          )}
                        />
                      </>
                    }
                  />
                </>
              }
            />
            {RenderProps(props, 'renderAfter', { limit })}
          </>
        }
      />
    </>
  );
}
