import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { HttpStatus } from 'config/Api.Config';
import React from 'react';
import Icon from '../../components/icon/Icon';
import Page from '../../components/page/Page';

export default function ErrorPage(props: { status: Api.HttpStatus; message?: string; children?: Utils.ReactNodes }) {
  const { lang } = getLocales();
  return (
    <Page
      class={ThemePageError}
      sidebar={
        <Icon
          class={
            props.status === HttpStatus.unauthorized
              ? 'if-hand'
              : props.status === HttpStatus.notFound
              ? 'if-list'
              : props.status === HttpStatus.internalServerError
              ? 'if-link-broken'
              : props.status === HttpStatus.badRequest
              ? 'if-question-circle'
              : 'if-warning'
          }
        />
      }
      header={
        props.message || (
          <>
            ERROR <b>{props.status}</b>
          </>
        )
      }
      render={
        props.children
          ? props.children
          : props.status === HttpStatus.unauthorized
          ? lang.unauthorized
          : props.status === HttpStatus.notFound
          ? lang.notFound
          : props.status === HttpStatus.internalServerError
          ? lang.internalServerError
          : props.status === HttpStatus.badRequest
          ? lang.badRequest
          : lang.unknownError
      }
    />
  );
}
