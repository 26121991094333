import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import lenderDeleteApi from 'models/sales/lender/delete/Api.Lender.Delete';
import lenderImportExcelApi from 'models/sales/lender/importExcel/Api.Lender.ImportExcel';
import Lender from 'models/sales/lender/Model.Lender';
import lenderSaveApi from 'models/sales/lender/save/Api.Lender.Save';
import { useMemo } from 'react';
import { lenderIndexRoute } from './Lender.Index';

export enum LenderAction {
  EDIT = 'edit',
  DELETE = 'delete',
  IMPORT_EXCEL = 'importExcel',
}
export type VerifyLenderAction = Utils.VerifyExtends<Module.Claims.Lender.Actions, Utils.ValueOf<typeof LenderAction>>;
export default function useLender() {
  const { permissions, user, isDistributor } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Lender.Params, Module.Claims.Lender.Query>({
    route: lenderIndexRoute,
  });

  const canView = permissions.CREATE_LENDER || permissions.EDIT_LENDER || permissions.DELETE_LENDER;
  const listApi = useApi({
    action: Lender.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: {
      ...pageRouter.query.list,
      distributorId: isDistributor && user.distributorId ? [user.distributorId] : undefined,
    },
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: lenderDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: lenderSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const importExcelApi = useApi({
    action: lenderImportExcelApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    importExcelApi,
    selected,
    permissions,
    views: {
      [LenderAction.DELETE]: pageRouter.params.action === LenderAction.DELETE && selected && permissions.DELETE_LENDER,
      [LenderAction.EDIT]:
        pageRouter.params.action === LenderAction.EDIT &&
        ((permissions.CREATE_LENDER && !selected) || (permissions.EDIT_LENDER && selected)),
      [LenderAction.IMPORT_EXCEL]: pageRouter.params.action === LenderAction.IMPORT_EXCEL && permissions.CREATE_LENDER,
    },
  };
}

export function useLenderProvider() {
  return useDataProvider<ReturnType<typeof useLender>>();
}
