import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Distributor from 'models/sales/distributor/Model.Distributor';
import React from 'react';
import { DealerGroupForm } from '../DealerGroup.Edit';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';

const DealerGroupGeneralForm: React.FC = () => {
  const { lang } = getLocales();
  const { isAdministrator } = useAuthStore();
  const { form } = useModalForm<DealerGroupForm>();
  const distributorApi = useApi({ action: Distributor.list });

  return (
    <FormWrapper>
      <h3>{lang.group}</h3>
      <TextInput
        name="name"
        label={lang.name}
        value={form.data.name}
        error={form.errors.name}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <SelectInput
        name="distributorId"
        label={lang.distributor}
        data={distributorApi.payload?.data?.map((it) => it?.displayInfo)}
        value={form.data?.distributorId}
        error={form.errors?.distributorId}
        onChange={form.update}
        disabled={!isAdministrator}
        icon={<Icon class="fas-globe" />}
      />
    </FormWrapper>
  );
};
export default DealerGroupGeneralForm;
