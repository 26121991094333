import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { HttpStatus, publicHomeRoute } from 'config/Api.Config';
import accountResetPasswordApi from 'models/account/resetPassword/Api.Account.ResetPassword';
import User from 'models/core/user/Model.User';
import { useEffect } from 'react';

export const accountResetPasswordRoute = publicHomeRoute + '/resetPassword';
export default function AccountResetPasswordIndex() {
  const { query } = usePageRouter<undefined, { access_token: string }>({ route: accountResetPasswordRoute });
  const { lang } = getLocales();
  const resetPasswordApi = useApi({
    action: (b: Api.Core.Account.ResetPassword.IRequest) => accountResetPasswordApi(b, query.access_token),
    wait: true,
  });
  useEffect(() => {
    if (resetPasswordApi.status == HttpStatus.ok) {
      addLog({ success: lang.passwordWasSuccessfullySet });
      location.replace('/');
    } else if (resetPasswordApi.status) {
      addLog({ error: lang.weWereUnableToSetYourPassword });
    }
  }, [resetPasswordApi.status, resetPasswordApi.message]);

  return (
    <ModalForm<Api.Core.Account.ResetPassword.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={lang.resetPassword}
      neverClose
      default={{}}
      onSubmit={(data) => resetPasswordApi.execute(data)}
      validation={(data, errors) => {
        if (!data.newPassword) errors.newPassword = lang.mustNotBeEmpty;
        if (!data.confirmPassword) errors.confirmPassword = lang.mustNotBeEmpty;
        if (data.newPassword !== data.confirmPassword) errors.confirmPassword = lang.passwordsDoNotMatch;
        if (!User.checkPasswordValidity(data.newPassword)) errors.newPassword = lang.passwordValidityMessage;
      }}
      render={({ form }) => (
        <>
          <FormWrapper>
            <TextInput
              name="newPassword"
              label={lang.newPassword}
              onChange={form.update}
              value={form.data.newPassword}
              error={form.errors.newPassword}
              type="password"
              autoComplete="new-password"
              icon={<Icon class={iconStyles.common.password} />}
            />
            <TextInput
              name="confirmPassword"
              label={lang.confirmPassword}
              onChange={form.update}
              value={form.data.confirmPassword}
              error={form.errors.confirmPassword}
              type="password"
              autoComplete="new-password"
              icon={<Icon class={iconStyles.common.password} />}
            />
          </FormWrapper>
        </>
      )}
    />
  );
}
