import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.ListPublic.IRequest;
type Response = Api.Core.Contract.ListPublic.IResponse;
type Headers = Api.Core.Contract.ListPublic.IHeaders;
const url = '/api/Public/Contract';
export default async function contractListPublicApi(query: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url: createPathFromRoute(url, null, query),
    headers: getDefaultHeaders(),
  });
}
