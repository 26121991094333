import { getLocales } from 'assets/locales/Locale';
import Model from 'models/Model';
import productProductTierPricePointDeleteApi from './delete/Api.ProductTierPricePoint.Delete';
import productProductTierPricePointListApi from './list/Api.ProductTierPricePoint.List';
import productProductTierPricePointSaveApi from './save/Api.ProductTierPricePoint.Save';

export default class ProductTierPricePoint
  extends Model<Model.IProductTierPricePoint>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductTierPricePoint>
{
  id: number;
  term: number;
  priceCAD: number;
  productId: number;
  tierId: number;
  pricingDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  productProvinceGroupId?: number;

  constructor(
    data: Partial<Model.IProductTierPricePoint> | Utils.FormData<Model.IProductTierPricePoint>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: `${this.term}${lang.month}`,
      info: `${this.priceCAD}$`,
    };
  }
  static async list(body?: Api.Core.ProductTierPricePoint.List.IRequest) {
    const { payload, ...rest } = await productProductTierPricePointListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductTierPricePoint(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await productProductTierPricePointSaveApi(this);
  }
  async delete() {
    return await productProductTierPricePointDeleteApi(this.id);
  }
}
