import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import EmailTemplateTags from 'module/management/components/emailTemplate/EmailTemplateTags';
import { useClaimEdit } from '../../ClaimEdit.Hooks';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ClaimEditSubmitButton from '../ClaimEdit.SubmitButton';

export default function ClaimEditEmailTemplate() {
  const { lang } = getLocales();
  const { form } = useClaimEdit();

  function updateEmailTemplate(updatedEmailTemplate: Partial<Model.IEmailTemplate>) {
    form.merge({ emailTemplate: updatedEmailTemplate });
  }

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.emailTemplate}
      onClose={() => form.update({ emailTemplate: null })}
      render={
        <FormWrapper>
          <TextInput
            name="name"
            label={lang.name}
            value={form.data.emailTemplate.name}
            error={form.errors.emailTemplate?.name}
            onChange={updateEmailTemplate}
            icon={<Icon class="if-quill-pen" />}
          />
          <InputWrapper>
            <TextInput
              name="senderName"
              label={lang.senderName}
              value={form.data.emailTemplate.senderName}
              error={form.errors.emailTemplate?.senderName}
              onChange={updateEmailTemplate}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="senderEmail"
              label={lang.senderEmail}
              value={form.data.emailTemplate.senderEmail}
              error={form.errors.emailTemplate?.senderEmail}
              onChange={updateEmailTemplate}
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <h2>{lang.sendTo}</h2>
          <CheckWrapper>
            <CheckInput
              name="sendToClient"
              label={lang.client}
              class={inputStyles.check.switch}
              value={form.data.emailTemplate?.sendToClient}
              onChange={updateEmailTemplate}
              htmlElementProps={{ title: form.data.emailTemplate?.sendToClient ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToContactEmail"
              label={lang.contact}
              class={inputStyles.check.switch}
              value={form.data.emailTemplate?.sendToContactEmail}
              onChange={updateEmailTemplate}
              htmlElementProps={{ title: form.data.emailTemplate?.sendToContactEmail ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToClaimOwner"
              label={lang.claimOwner}
              class={inputStyles.check.switch}
              value={form.data.emailTemplate?.sendToClaimOwner}
              onChange={updateEmailTemplate}
              htmlElementProps={{ title: form.data.emailTemplate?.sendToClaimOwner ? lang.disable : lang.enable }}
            />
          </CheckWrapper>
          <h2>{lang.additionalRecipentEmails}</h2>
          <DataMap
            data={[...(form.data.emailTemplate?.additionalRecipentEmails || []), '']}
            render={({ data: email, index }) => (
              <TextInput
                key={index}
                name="email"
                label={lang.email}
                value={email}
                error={form.errors.emailTemplate?.additionalRecipentEmails}
                onChange={({ email }) => {
                  let additionalRecipentEmails = form.data.emailTemplate?.additionalRecipentEmails || [];
                  additionalRecipentEmails.splice(index, 1, email);
                  additionalRecipentEmails = additionalRecipentEmails.filter((it) => it);
                  form.update({ emailTemplate: { ...form.data.emailTemplate, additionalRecipentEmails } });
                }}
                icon={<Icon class="if-quill-pen" />}
              />
            )}
          />
          <h2>{lang.email}</h2>
          <TextInput
            name="subject"
            label={lang.subject}
            value={form.data.emailTemplate.subject}
            error={form.errors.emailTemplate?.subject}
            onChange={updateEmailTemplate}
            icon={<Icon class="if-quill-pen" />}
          />
          <RichTextInput
            name="htmlBody"
            label={lang.htmlBody}
            value={form.data.emailTemplate.htmlBody}
            error={form.errors.emailTemplate?.htmlBody}
            onChange={updateEmailTemplate}
            icon={<Icon class="if-quill-pen" />}
            customActions={<EmailTemplateTags />}
          />
        </FormWrapper>
      }
      footer={
        <>
          <Button
            onClick={form.update}
            data={{ emailTemplate: null }}
            media="fas-ban"
            render={lang.dontSendEmail}
            class={ThemeButtonCircle}
          />
          <ClaimEditSubmitButton />
        </>
      }
    />
  );
}
