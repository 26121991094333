import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Response = Api.Core.Account.Info.IResponse;
type Headers = Api.Core.Account.Info.IHeaders;
const apiAccountInfoPath = '/api/Account/Info';
export default async function accountInfoApi(): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    url: apiAccountInfoPath,
    method: HttpMethod.get,
    headers: getDefaultHeaders(),
  });
}
