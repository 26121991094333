import React from 'react';
import { useClientProvider } from '../Client.Hooks';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import Icon from 'assets/components/icon/Icon';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Button from 'assets/components/button/Button';
import { OrderType } from 'config/Api.Config';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { getLocales } from 'assets/locales/Locale';
import { inputStyles } from 'assets/components/inputs/Input';

export const ClientFilters = () => {
  const { lang } = getLocales();
  const { listApi } = useClientProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        subcomponents={ThemeDropdownButtonPopup}
        htmlElementProps={{ title: lang.sort }}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByUserName}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'firstName',
                orderType: OrderType.asc,
                thenBy: 'lastName',
                thenType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByUserName}
              media={<Icon class="fas-caret-down" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'firstName',
                orderType: OrderType.desc,
                thenBy: 'lastName',
                thenType: OrderType.desc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!listApi?.payload) listApi.execute();
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <CheckInput
              name="hasVeraforteCustomerNumber"
              label={lang.hasVertaforeCustomerNumber}
              class={inputStyles.check.switch}
              value={tempFilters?.hasVeraforteCustomerNumber}
              onChange={({ hasVeraforteCustomerNumber }) =>
                setFilters({ ...tempFilters, hasVeraforteCustomerNumber: Boolean(hasVeraforteCustomerNumber) })
              }
              icon={<Icon class="fas-user" />}
            />
          </>
        )}
      />
    </>
  );
};
