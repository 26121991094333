import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import './HeaderRow.scss';

export enum HeaderDataStructure {
  headerData = 'headerData',
}
export type Verify = Utils.VerifyExtends<Component.HeaderData.Structure, typeof HeaderDataStructure>;
export default function HeaderData(props: Component.HeaderData.Import) {
  const extend = useTheme(HeaderDataStructure, props);

  return (
    <div
      {...extend(HeaderDataStructure.headerData, true)}
      data-noimage={props.disableImage ? true : null}
      data-numofactions={props.numOfActions || null}
    >
      {RenderProps(props, 'render')}
    </div>
  );
}
