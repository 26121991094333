import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import React from 'react';
import { useClientProvider } from '../Client.Hooks';

export default function ClientList() {
  const { lang } = getLocales();
  const { listApi } = useClientProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.clients}
      render={lang.noInfo}
    />
  ) : (
    <>
      <Collection class={ThemeCollectionList}>
        <HeaderRow>
          <HeaderData>{lang.name}</HeaderData>
          <HeaderData>{lang.vertaforeCustomerNumber}</HeaderData>
        </HeaderRow>
        <DataMap
          data={listApi.payload?.data}
          render={({ data: client }) => (
            <Row
              key={client.id}
              children={
                <>
                  <RowImage render={<Icon class="fas-clipboard-list" />} />
                  <RowData>{combineStrings(' ', client.firstName, client.lastName)}</RowData>
                  <RowData>{client.veraForteCustomerNumber ?? 'N/A'}</RowData>
                </>
              }
            />
          )}
        />
      </Collection>
    </>
  );
}
