import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import ProductType from 'models/enums/ProductType';
import Insurer from 'models/productManagement/insurer/Model.Insurer';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import ProductCategory from 'models/productManagement/productCategory/Model.ProductCategory';
import { useProductProvider } from '../../Product.Hooks';
import Subfee from 'models/productManagement/subfee/Model.Subfee';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import NumberInput from 'assets/components/inputs/number/NumberInput';

export default function ProductEditInfo() {
  const { form } = useProductProvider();
  const { lang } = getLocales();
  const { payload: categoriesPayload } = useApi({ action: ProductCategory.list });
  const { payload: insurersPayload } = useApi({ action: Insurer.list });
  const { payload: subfeesPayload } = useApi({ action: Subfee.list });
  const { payload: distributionsPayload } = useApi({ action: PricingDistributionTemplate.list });

  return (
    <FormWrapper class={ThemeWrapperPaper}>
      <div style={{ fontSize: Theme.FontSize.L, fontWeight: 'bold' }}>{lang.generalInformation}</div>
      <br />
      <TextInput
        name="name"
        label={lang.name}
        value={form.data.name}
        error={form.errors.name}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <TextInput
        name="code"
        label={lang.code}
        value={form.data.code}
        error={form.errors.code}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <SelectInput
        name="productType"
        label={lang.type}
        data={Object.values(ProductType).map((id) => ({
          id,
          title: lang[id] || id,
        }))}
        value={form.data.productType}
        error={form.errors.productType}
        onChange={({ productType }) => form.update({ productType })}
        icon={<Icon class="if-cloud" />}
      />
      <SelectInput
        name="category"
        label={lang.program}
        data={categoriesPayload?.data?.map((item) => ({
          ...item.displayInfo,
          icon: <Icon class="fas-box" />,
        }))}
        value={form.data.category?.id}
        error={form.errors.category?._objectError}
        onChange={({ category: id }) => form.update({ category: { id }, categoryId: id })}
        icon={<Icon class="if-cloud" />}
      />
      <NumberInput
        name="limitOfLiabilityAggregateCAD"
        label={lang.limitOfLiabilityAggregate}
        value={form.data.limitOfLiabilityAggregateCAD}
        error={form.errors.limitOfLiabilityAggregateCAD}
        step={0.01}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: Theme.Size.M,
        }}
      >
        <CheckInput
          name="canSellPostSale"
          label={lang.canSellPostSale}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.canSellPostSale}
          onChange={form.update}
          htmlElementProps={{ title: form.data.canSellPostSale ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isWarrantyDetailsVisible"
          label={lang.isWarrantyDetailsVisible}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.isWarrantyDetailsVisible}
          onChange={form.update}
          htmlElementProps={{ title: form.data.isWarrantyDetailsVisible ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isPurchaseDetailsVisible"
          label={lang.isPurchaseDetailsVisible}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.isPurchaseDetailsVisible}
          onChange={form.update}
          htmlElementProps={{ title: form.data.isPurchaseDetailsVisible ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isPartOfPackage"
          label={lang.isPartOfPackage}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.isPartOfPackage}
          onChange={form.update}
          htmlElementProps={{ title: form.data.isPartOfPackage ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isCustomerConsentRequired"
          label={lang.isCustomerConsentRequired}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.isCustomerConsentRequired}
          onChange={form.update}
          htmlElementProps={{ title: form.data.isCustomerConsentRequired ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isActive"
          label={lang.isActive}
          class={inputStyles.check.switch}
          style={{ margin: `${Theme.Size.S} 0` }}
          value={form.data.isActive}
          onChange={form.update}
          htmlElementProps={{ title: form.data.isActive ? lang.disable : lang.enable }}
        />
      </div>
      <SelectInput
        name="insurers"
        label={lang.insurers}
        data={insurersPayload?.data?.map((it) => it.displayInfo)}
        value={form.data.insurers?.map((it) => it.id)}
        error={form.errors.insurers?._objectError}
        onChange={(selectData) =>
          form.update({
            insurers: selectData.insurers
              .map((it) => insurersPayload?.data?.find((d) => d.id === it))
              ?.filter((it) => (it ? true : false)),
          })
        }
        isMultiselect
        icon={<Icon class="fas-users" />}
      />
      <SelectInput
        name="subfee"
        label={lang.surcharges}
        data={subfeesPayload?.data?.map((it) => it.displayInfo)}
        value={form.data.subfee?.map((it) => it.id)}
        error={form.errors.subfee?._objectError}
        onChange={(selectData) =>
          form.update({
            subfee: selectData.subfee
              .map((it) => subfeesPayload?.data?.find((d) => d.id === it))
              ?.filter((it) => (it ? true : false)),
          })
        }
        isMultiselect
        icon={<Icon class="fas-users" />}
      />
      <SelectInput
        name="pricingDistributionTemplateId"
        label={lang.pricingDistributionTemplate}
        data={distributionsPayload?.data?.filter((it) => !it.distributorId).map((it) => it.displayInfo)}
        value={form.data.pricingDistributionTemplateId}
        error={form.errors.pricingDistributionTemplateId}
        onChange={form.update}
        icon={<Icon class="fas-balance-scale" />}
      />
    </FormWrapper>
  );
}
