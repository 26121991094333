import Box from 'assets/components/box/Box';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic1 from 'assets/components/page/themes/Theme.Page.Static.1';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { managementHomeRoute } from 'config/Api.Config';
import { Fragment } from 'react';
import { accountChangePasswordRoute } from '../changePassword/Account.ChangePassword.Index';
import { accountEditUserSettingsRoute } from '../editUserSettings/Account.EditUserSettings.Index';

export const accountUserWelcomeRoute = managementHomeRoute + '/account/welcome';
export default function AccountUserWelcome() {
  const { lang, currentLanguage, translate } = getLocales();
  const { user } = useAuthStore();
  console.log(user);
  return !user?.id ? null : (
    <>
      <Page
        class={ThemePageStatic1}
        header={
          <>
            <Card
              class={ThemeCardRow}
              media={user?.profilePhoto?.url ? <img src={user?.profilePhoto.url} /> : <Icon class="fas-user" />}
              title={
                <>
                  {lang.welcome}, {user?.displayInfo.title}.
                </>
              }
            />
          </>
        }
        render={
          <>
            <h1>{lang.personalInfo}</h1>
            {lang.firstName}: <b>{user?.firstName}</b>
            <br />
            {lang.lastName}: <b>{user?.lastName}</b>
            <br />
            <h1>{lang.accountInfo}</h1>
            {lang.currentLanguage}: <b>{lang[currentLanguage]}</b>
            <br />
            {lang.email}: <b>{user?.email}</b>
            <h1>{lang.dealers}</h1>
            {user?.dealers.length === 0 && lang.noInfo}
            <DataMap
              data={user?.dealers}
              render={({ data: dealer }) => <Box key={dealer.id} class={ThemeTagRounded} render={dealer?.name} />}
            />
            <h1>{lang.roles}</h1>
            {user?.roles.length === 0 ? (
              lang.noInfo
            ) : (
              <>
                <DataMap
                  data={user?.roles}
                  render={({ data: role }) => (
                    <Fragment key={role.id}>
                      <h2>{role.name}</h2>
                      <DataMap
                        data={role.permissions}
                        render={({ data: permission }) => (
                          <Box key={permission.id} class={ThemeTagRounded} render={translate(permission?.key)} />
                        )}
                      />
                    </Fragment>
                  )}
                />
              </>
            )}
          </>
        }
        footer={
          <>
            <LinkButton
              class={ThemeButtonCircle}
              media={<Icon class="if-lock" />}
              render={lang.changePassword}
              url={accountChangePasswordRoute}
            />
            <LinkButton
              class={ThemeButtonCircle}
              media={<Icon class="if-gear" />}
              render={lang.editMySettings}
              url={accountEditUserSettingsRoute}
            />
          </>
        }
      />
    </>
  );
}
