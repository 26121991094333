import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { defaultLimit, defaultOffset, HttpStatus, managementHomeRoute, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import Category from 'models/core/category/Model.Category';
import React from 'react';

enum Actions {
  EDIT = 'edit',
  TOGGLE = 'toggle',
}
export type Verify = Utils.VerifyExtends<Module.Management.Category.Actions, Utils.ValueOf<typeof Actions>>;
export const categoryIndexRoute = managementHomeRoute + '/category/:action?/:id?';
export default function CategoryIndex() {
  const { lang } = getLocales();
  const { hasAccess } = useAuthStore();
  const canView = hasAccess(AuthPermission.viewCategory);
  const { query, updateQuery } = usePageRouter<Module.Management.Category.Params, Module.Management.Category.Query>({
    route: categoryIndexRoute,
  });

  const {
    body: filters,
    payload,
    execute: reload,
    isExecuting,
  } = useApi({
    action: Category.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: query.list,
    callback: (req) => updateQuery({ ...query, list: req }),
    wait: !canView,
  });

  if (!canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <Page
        class={ThemePageList}
        isLoading={isExecuting}
        header={
          !canView ? null : (
            <>
              <b style={{ marginRight: 'auto' }} children={lang.categories} />
              <SearchInput
                value={filters?.search}
                onSearch={(search) => reload((body) => ({ ...body, search }))}
                threshold={2}
                placeholder={`${lang.search}...`}
              />
              &nbsp;
              <DropdownButton
                buttonMedia={<Icon class="fas-sort" />}
                horizontalPosition="auto_fixed"
                verticalPosition="auto_fixed"
                htmlElementProps={{ title: lang.sort }}
                subcomponents={ThemeDropdownButtonPopup}
                dropdownOptions={
                  <>
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.newerFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: undefined,
                        orderType: undefined,
                      })}
                    />
                    <Button
                      class={ThemeButtonMenuItem}
                      render={lang.olderFirst}
                      media={<Icon class="fas-caret-up" />}
                      onClick={reload}
                      data={(currentFilters) => ({
                        ...currentFilters,
                        orderBy: 'id',
                        orderType: OrderType.asc,
                      })}
                    />
                  </>
                }
              />
            </>
          )
        }
        render={
          !canView ? (
            <ErrorPage status={HttpStatus.unauthorized} />
          ) : (
            <>
              <Collection class={ThemeCollectionList}>
                <DataMap
                  data={payload?.data}
                  render={({ data: category }) => (
                    <Row
                      key={category.id}
                      children={
                        <>
                          <RowImage render={<Icon class="if-sub-listing" />} />
                          <RowData title={category.name} />
                        </>
                      }
                    />
                  )}
                />
              </Collection>
            </>
          )
        }
        footer={
          !canView ? null : (
            <Pagination
              class={ThemePaginationRounded}
              count={payload?.count}
              offset={filters?.offset}
              limit={filters?.limit}
              onLimitChange={(limit) => reload((body) => ({ ...body, limit, offset: 0 }))}
              onOffsetChange={(offset) => reload((body) => ({ ...body, offset }))}
            />
          )
        }
      />
    </>
  );
}
