export type Verify = Utils.VerifyExtends<Effect.Color, Utils.ValueOf<typeof ColorVariable>>;
enum ColorVariable {
  primary = 'var(--colorPrimary)',
  primaryDark = 'var(--colorPrimaryDark)',
  primaryLight = 'var(--colorPrimaryLight)',
  primaryAlt = 'var(--colorPrimaryAlt)',

  secondary = 'var(--colorSecondary)',
  secondaryDark = 'var(--colorSecondaryDark)',
  secondaryLight = 'var(--colorSecondaryLight)',
  secondaryAlt = 'var(--colorSecondaryAlt)',

  neutral = 'var(--colorNeutral)',
  neutralDark = 'var(--colorNeutralDark)',
  neutralLight = 'var(--colorNeutralLight)',
  neutralAlt = 'var(--colorNeutralAlt)',

  shadow = 'var(--colorShadow)',
  shadowDark = 'var(--colorShadowDark)',
  shadowDarker = 'var(--colorShadowDarker)',
  shadowLight = 'var(--colorShadowLight)',
  shadowLighter = 'var(--colorShadowLighter)',

  highlight = 'var(--colorHighlight)',
  highlightLight = 'var(--colorHighlightLight)',
  highlightLighter = 'var(--colorHighlightLighter)',

  background = 'var(--colorBackground)',
  backgroundDark = 'var(--colorBackgroundDark)',
  text = 'var(--colorText)',
  textAlt = 'var(--colorTextAlt)',
  input = 'var(--colorInput)',
  transparent = 'var(--colorTransparent)',
  inherit = 'var(--colorInherit)',

  error = 'var(--colorError)',
  warning = 'var(--colorWarning)',
  success = 'var(--colorSuccess)',
}
export default ColorVariable;
