import Model from 'models/Model';

export default class ClaimTypeDocument
  extends Model<Model.IClaimTypeDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimTypeDocument>
{
  id: number;
  version: number;
  name: string;
  description?: string;
  source?: string;
  example?: string;
  allowMultiple: boolean;
  required: boolean;
  allowNotes: boolean;
  approvalRequired: boolean;

  constructor(
    data: Partial<Model.IClaimTypeDocument> | Utils.FormData<Model.IClaimTypeDocument>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
}
