export default function isFile(obj: any) {
  try {
    return obj?.constructor?.prototype?.toString() === '[object File]';
  } catch {
    return false;
  }
}

export const symmetricDifference = <T>(a: Array<T>, b: Array<T>): Array<T> => {
  return a.filter((x) => !b.includes(x)).concat(b.filter((x) => !a.includes(x)));
};
export function compareValues<T>(a: T, b: T): boolean {
  return JSON.stringify(a) == JSON.stringify(b);
}
export function shallowCompare(a: any, b: any): boolean {
  if (typeof a == 'object' && typeof b == 'object' && a && b) {
    let isEqual: boolean = false;
    if (Array.isArray(a) && Array.isArray(b)) {
      for (const i of a) {
        if (a[i] !== b[i]) {
          isEqual = false;
          break;
        }
        isEqual = true;
      }
    } else {
      for (const k of Object.keys(a)) {
        if (a[k] !== b[k]) {
          isEqual = false;
          break;
        }
        isEqual = true;
      }
    }
    return isEqual;
  } else return compareValues(a, b);
}

export function listToTree<T>(list: T[], mainKey: keyof T, subKey: keyof T, value?: any): Utils.TreeOf<T> {
  if (list.length === 0) return undefined;
  return list
    .filter((it) => (value && it[subKey] === value) || (!value && !it[subKey]))
    .map((it, index) => ({
      ...it,
      _sub: listToTree(
        list.filter((it) => it[subKey] !== value),
        mainKey,
        subKey,
        it[mainKey]
      ),
      _options: {
        index,
        key: it[mainKey],
      },
    }));
}
export function valueAsList<T>(
  currentValue: Utils.ValueOf<T> | Utils.ValueOf<T>[],
  value?: Utils.ValueOf<T> | Utils.ValueOf<T>[] | null
): Utils.ValueOf<T>[] {
  const _currentValue = Array.isArray(currentValue) ? currentValue : [currentValue];
  const newValue = value ? (Array.isArray(value) ? value : [value]) : [];
  return currentValue ? symmetricDifference(_currentValue, newValue) : newValue;
}
