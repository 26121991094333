import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { xorBy } from 'lodash';
import { useStatementProvider } from '../Statement.Hooks';
import Box from 'assets/components/box/Box';
import Theme from 'assets/themes/Theme.Common';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import StatementStatusCode from 'models/enums/StatementStatusCode';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import HeaderData from 'assets/components/data/rows/HeaderData';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import { date, defaultDateFormat, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import RowActions from 'assets/components/data/rows/RowActions';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Button from 'assets/components/button/Button';
import FormatValue from 'assets/components/formatValue/FormatValue';
import { Fragment } from 'react';

export default function StatementChangeStatus() {
  const { lang } = getLocales();
  const { setStatusApi, pageRouter, selected } = useStatementProvider();

  return (
    <ModalForm<Api.Core.Statement.SetStatus.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      default={
        selected
          ? {
              statementId: selected.id,
              contractsToExclude: [],
            }
          : {}
      }
      onCancel={pageRouter.updateParams}
      onSubmit={setStatusApi.execute}
      validation={(data, errors) => {
        if (!data.statementStatus) errors.statementStatus = lang.mustNotBeEmpty;
      }}
      render={({ form }) => (
        <>
          <FormWrapper>
            <SelectInput
              name="statementStatus"
              label={lang.status}
              data={
                selected.status === StatementStatusCode.OPEN
                  ? [
                      {
                        id: StatementStatusCode.APPROVED,
                        title: StatementStatusCode.APPROVED,
                      },
                      {
                        id: StatementStatusCode.CANCELED,
                        title: StatementStatusCode.CANCELED,
                      },
                    ]
                  : selected.status === StatementStatusCode.APPROVED
                  ? [
                      {
                        id: StatementStatusCode.RECONCILED,
                        title: StatementStatusCode.RECONCILED,
                      },
                    ]
                  : []
              }
              value={form.data?.statementStatus}
              error={form.errors.statementStatus}
              onChange={form.update}
              icon={<Icon class="fas-check-double" />}
            />
          </FormWrapper>
          {selected.status === StatementStatusCode.OPEN && (
            <Collection class={ThemeCollectionTable}>
              <Box active children={lang.exclude} style={{ paddingLeft: Theme.Size.L, paddingRight: Theme.Size.L }} />
              <HeaderRow>
                <HeaderData disableImage>{lang.contract}</HeaderData>
                <HeaderData>{lang.term}</HeaderData>
                <HeaderData>{lang.client}</HeaderData>
                <HeaderData>{lang.amount}</HeaderData>
                <HeaderData>{lang.tax}</HeaderData>
                <HeaderData>{lang.total}</HeaderData>
                <HeaderData numOfActions={1} />
              </HeaderRow>
              <DataMap
                data={selected.contracts}
                render={({ data: contract }) => (
                  <Fragment key={contract.id}>
                    <Row>
                      <RowData
                        info={contract.statusCode?.replace('_', ' - ')}
                        title={contract.appNumber + (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')}
                        subtitle={contract.product?.name}
                      />
                      <RowData>
                        <FormatValue value={contract.termInMonths} />
                      </RowData>
                      <RowData>{combineStrings(' ', contract.client.firstName, contract.client.lastName)}</RowData>
                      <RowData>
                        <span>
                          <FormatValue value={`${contract.paymentAmountCAD.toFixed(2)}$`} />
                        </span>
                      </RowData>
                      <RowData>
                        <span>
                          <FormatValue value={`${contract.paymentTaxCAD.toFixed(2)}$`} />
                        </span>
                      </RowData>
                      <RowData>
                        <span>
                          <FormatValue value={`${contract.paymentAmountWithTaxCAD.toFixed(2)}$`} />
                        </span>
                      </RowData>
                      <RowActions isVertical>
                        <Button
                          class={ThemeButtonCircle}
                          media={
                            <Icon
                              class={
                                form.data.contractsToExclude?.some((c) => c.id === contract.id)
                                  ? 'fas-check-square'
                                  : 'far-square'
                              }
                            />
                          }
                          onClick={() => {
                            form.update({
                              contractsToExclude: xorBy(
                                [{ id: contract.id }],
                                form.data.contractsToExclude,
                                (c) => c.id
                              ),
                            });
                          }}
                          htmlElementProps={{
                            title: form.data.contractsToExclude?.some((c) => c.id === contract.id)
                              ? lang.unselect
                              : lang.select,
                          }}
                        />
                      </RowActions>
                    </Row>
                    <Row isCompressed>
                      <RowData disableAutoAlignment style={{ alignItems: 'flex-end' }}>
                        <span>
                          <b>Created on&nbsp;</b>
                          <span>
                            {date(contract?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                            &nbsp;by&nbsp;
                            {combineStrings(' ', contract.createdBy?.firstName, contract.createdBy?.lastName) ||
                              lang.unknownUser}
                            .&nbsp;
                          </span>
                          <b>Effective from&nbsp;</b>
                          <span>
                            {date(contract?.effectiveDate, defaultServerDateTimeFormat).format(defaultDateFormat)}
                          </span>
                        </span>
                      </RowData>
                    </Row>
                  </Fragment>
                )}
              />
            </Collection>
          )}
        </>
      )}
    />
  );
}
