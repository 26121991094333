import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import React from 'react';
import { PricingLimitTemplateAction, usePricingLimitTemplateProvider } from '../PricingLimitTemplate.Hooks';

export default function PricingLimitTemplateList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = usePricingLimitTemplateProvider();
  const { permissions } = useAuthStore();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.limits}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: pricingLimitTemplate }) => (
          <Row
            key={pricingLimitTemplate.id}
            children={
              <>
                <RowImage render={<Icon class="fas-user-shield" />} />
                <RowData {...pricingLimitTemplate.displayInfo} />
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: pricingLimitTemplate.id.toString(),
                      action: PricingLimitTemplateAction.EDIT,
                    }}
                    disabled={!permissions.EDIT_LOSSCODE}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-edit" />}
                    htmlElementProps={{ title: lang.edit }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: pricingLimitTemplate.id.toString(),
                      action: PricingLimitTemplateAction.DELETE,
                    }}
                    disabled={!permissions.DELETE_LOSSCODE}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
