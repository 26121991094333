import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { cloneDeep } from 'lodash';
import { ClaimTypeReportRow, RowOperation } from 'models/claims/claimTypeReportSettings/Model.ClaimTypeReportSettings';
import React, { useEffect, useState } from 'react';

type Props = {
  row: Utils.FormData<Model.IClaimTypeReportRow>;
  rowsForMapping: Component.Input.Select.SelectionItem[];
  onSave: (data: Model.IClaimTypeReportRow) => void;
};
export default function ClaimTypeEditReportRowForm({ row, rowsForMapping, onSave }: Props) {
  const { lang, translate } = getLocales();
  const [primaryUsage, setPrimaryUsage] = useState(lang.askForInput);
  useEffect(() => {
    if (typeof row.primaryValue == 'number' || !row.primaryValue) setPrimaryUsage(lang.askForInput);
    else if (typeof row.primaryValue === 'string') setPrimaryUsage(lang.copyValue);
    else setPrimaryUsage(lang.sumValues);
  }, []);

  const form = useForm<Utils.FormData<Model.IClaimTypeReportRow>>({
    default: cloneDeep(row),
    isUpdatedByDefault: true,
    onSubmit: onSave,
    validation: (data, errors) => {
      if (
        data.key !== row.key &&
        rowsForMapping?.some((it) => it.id.replace(/(Primary|Secondary|Total)$/g, '') === data.key)
      )
        errors.key = lang.alreadyExists;
    },
  });
  return (
    <FormWrapper>
      <InputWrapper>
        <TextInput name="key" label={lang.key} value={form.data.key} onChange={form.update} error={form.errors?.key} />
        <TextInput
          name="name"
          label={lang.name}
          value={form.data.name?.en}
          onChange={({ name: en }) => form.merge({ name: { en } })}
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput name="label" label={lang.label} value={form.data.label} onChange={form.update} />
        <SelectInput
          name="summationOperation"
          label={lang.summationOperation}
          value={form.data.summationOperation}
          onChange={form.update}
          data={Object.keys(RowOperation).map((it) => ({
            id: RowOperation[it],
            title: translate(it),
            icon: ClaimTypeReportRow.getIcon(RowOperation[it]),
          }))}
        />
      </InputWrapper>
      <InputWrapper>
        <SelectInput
          name="primaryUsageSelect"
          label={lang.primaryUsage}
          value={primaryUsage}
          onChange={({ primaryUsageSelect }) => {
            setPrimaryUsage(primaryUsageSelect);
            form.update({ primaryValue: null, secondaryValue: null, totalAmountOperation: null });
          }}
          data={[
            { id: lang.askForInput, title: lang.askForInput },
            { id: lang.copyValue, title: lang.copyValue },
            { id: lang.sumValues, title: lang.sumValues },
          ]}
        />
        {primaryUsage == lang.askForInput ? (
          <NumberInput
            name="primaryValue"
            label={lang.primaryValue}
            value={typeof form.data.primaryValue == 'number' ? form.data.primaryValue : null}
            disabled={!primaryUsage}
            onChange={form.update}
          />
        ) : (
          <SelectInput
            name="primaryValue"
            label={lang.primaryValue}
            value={typeof form.data.primaryValue != 'number' ? form.data.primaryValue : null}
            disabled={!primaryUsage}
            onChange={form.update}
            data={rowsForMapping}
            isMultiselect={primaryUsage == lang.sumValues}
            disableTags
          />
        )}
        <SelectInput
          name="totalAmountOperation"
          label={lang.totalAmountOperation}
          value={form.data.totalAmountOperation}
          disabled={!primaryUsage}
          onChange={({ totalAmountOperation }) => {
            form.update({
              totalAmountOperation,
              secondaryValue: !totalAmountOperation ? null : form.data.secondaryValue,
            });
          }}
          data={Object.keys(RowOperation).map((it) => ({
            id: RowOperation[it],
            title: translate(it),
            icon: ClaimTypeReportRow.getIcon(RowOperation[it]),
          }))}
        />
        <NumberInput
          name="secondaryValue"
          label={lang.secondaryValue}
          value={form.data.secondaryValue}
          disabled={!form.data.totalAmountOperation}
          onChange={form.update}
        />
      </InputWrapper>
      <CheckInput
        name="isHighlighted"
        label={lang.isHighlighted}
        value={form.data.isHighlighted}
        class={inputStyles.check.switch}
        onChange={form.update}
        htmlElementProps={{ title: form.data.isHighlighted ? lang.disable : lang.enable }}
      />
      <Button
        class={{ button: ThemeButtonCircle }}
        style={{
          marginLeft: 'auto',
        }}
        media="fas-save"
        children={lang.save}
        onClick={form.submit}
        active={!form.hasErrors}
        disabled={form.hasErrors}
      />
    </FormWrapper>
  );
}
