import Button from 'assets/components/button/Button';
import ThemeButtonFileItem from 'assets/components/button/themes/Theme.Button.FileItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Theme from 'assets/themes/Theme.Common';
import { get } from 'lodash';
import DropdownButton from '../dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from '../dropdownButton/themes/Theme.DropdownButton.Popup';

export default function DealerMenu() {
  const { user, updateDealer } = useAuthStore();
  const { lang } = getLocales();

  return user?.dealers?.length > 1 ? (
    <DropdownButton
      title={lang.changeLayout}
      buttonMedia={<Icon class="fas-store" />}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      constrainment="unconstrained"
      disableWidthConstraint
      htmlElementProps={{ title: lang.dealerMenu }}
      subcomponents={ThemeDropdownButtonPopup}
      dropdownOptions={
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridAutoRows: `calc(2 * ${Theme.Size.XL})`,
              gridGap: Theme.Size.S,
              padding: Theme.Size.S,
            }}
          >
            <DataMap
              data={user.dealers}
              render={({ data: dealer }) => (
                <Button
                  key={dealer.id}
                  class={ThemeButtonFileItem}
                  style={{
                    button: dealer.uiSettingsFile
                      ? {}
                      : {
                          backgroundColor: get(dealer.uiSettings?.cssVariables, '--colorPrimary'),
                          color: get(dealer.uiSettings?.cssVariables, '--colorPrimaryAlt'),
                        },
                    buttonText: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      fontSize: Theme.FontSize.S,
                    },
                  }}
                  media={dealer.uiSettingsFile ? <img src={dealer.uiSettingsFile.url} /> : <Icon class="fas-store" />}
                  render={dealer.name}
                  onClick={() => updateDealer(dealer.id)}
                  htmlElementProps={{ title: lang.updateDealer }}
                />
              )}
            />
          </div>
        </>
      }
    />
  ) : null;
}
