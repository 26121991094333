import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import ColorInput from 'assets/components/inputs/color/ColorInput';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { CSS_COLOR_VARIABLES } from 'config/Ui.Config';
import { compact, first, get, kebabCase, pick } from 'lodash';
import React, { useEffect } from 'react';
import { DealerForm } from '../Dealer.Edit';
import { getLocales } from 'assets/locales/Locale';
import useApi from 'assets/hooks/api/useApi';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';

const DealerUiSettingsForm: React.FC = () => {
  const { form } = useModalForm<DealerForm>();
  const { lang } = getLocales();
  function updateCssVariables(cssVariables: React.CSSProperties) {
    form.merge({ uiSettings: { cssVariables } });
  }

  const { payload: dealerGroups } = useApi({
    action: DealerGroup.list,
    body: { id: [form.data?.dealerGroupId] },
    wait: !form.data?.dealerGroupId,
  });
  const dealerGroupUISettings = first(dealerGroups?.data)?.uiSettings;

  function getDealerGroupCSSVariables() {
    const variables = [...CSS_COLOR_VARIABLES.primary, ...CSS_COLOR_VARIABLES.secondary];
    return pick(dealerGroupUISettings?.cssVariables || {}, variables);
  }

  useEffect(() => {
    if (!form.data?.uiSettings && dealerGroupUISettings) {
      form.update({ uiSettings: { cssVariables: getDealerGroupCSSVariables() } });
    }
  }, [form.data?.uiSettings, dealerGroupUISettings]);

  return (
    <FormWrapper>
      <h2>Primary</h2>
      <InputWrapper>
        <DataMap
          data={CSS_COLOR_VARIABLES.primary}
          render={({ data: varName }) => (
            <ColorInput
              name={varName}
              label={kebabCase(varName).replace(/-/g, ' ').trim()}
              value={get(form.data.uiSettings?.cssVariables, varName)}
              error={form.errors.name}
              onChange={updateCssVariables}
            />
          )}
        />
      </InputWrapper>
      <h2>Secondary</h2>
      <InputWrapper>
        <DataMap
          data={CSS_COLOR_VARIABLES.secondary}
          render={({ data: varName }) => (
            <ColorInput
              name={varName}
              label={kebabCase(varName).replace(/-/g, ' ').trim()}
              value={get(form.data.uiSettings?.cssVariables, varName)}
              error={form.errors.name}
              onChange={updateCssVariables}
            />
          )}
        />
      </InputWrapper>
      <FileInput
        name="logo"
        label={lang.logo}
        value={compact([form.data.logo])}
        fileLimit={1}
        onChange={({ logo }) => {
          form.update({ logo: first(logo) });
        }}
        class={ThemeFileInputDropzone}
      />
    </FormWrapper>
  );
};
export default DealerUiSettingsForm;
