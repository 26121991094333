import { getLocales } from 'assets/locales/Locale';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import ClaimCommentType from 'models/enums/ClaimCommentType';
import Model from 'models/Model';
import ClaimCommentUser from '../claimCommentUser/ClaimCommentUser';
import claimCommentDeleteApi from './delete/Api.ClaimComment.Delete';
import claimCommentLikeApi from './like/Api.ClaimComment.Like';
import claimCommentListApi from './list/Api.ClaimComment.List';
import claimCommentSaveApi from './save/Api.ClaimComment.Save';

export default class ClaimComment
  extends Model<Model.IClaimComment>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimComment>
{
  id: number;
  comment: string;
  claimCommentType: ClaimCommentType;
  createdAtUtc: string;
  modifiedAtUtc: string;
  claimId: number;
  userId: number;
  user?: ClaimCommentUser;
  likedByUsers?: ClaimCommentUser[];

  get createdAtUtcUnix() {
    return date(this.createdAtUtc, defaultServerDateTimeFormat).unix();
  }
  get modifiedAtUtcUnix() {
    return this.modifiedAtUtc ? date(this.modifiedAtUtc, defaultServerDateTimeFormat).unix() : null;
  }

  constructor(data: Partial<Model.IClaimComment> | Utils.FormData<Model.IClaimComment>, language?: Locale.Languages) {
    super({ user: ClaimCommentUser, likedByUsers: [ClaimCommentUser] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: this.user?.displayInfo?.title || lang.unknownUser,
      info: date(this.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat),
      subtitle: this.comment,
      media: this.user?.profilePhotoUrl || ClaimCommentUser.icon,
    };
  }
  async save() {
    return await claimCommentSaveApi(this);
  }
  async savePublic(params: Api.Core.ClaimComment.Like.IParams) {
    return await claimCommentLikeApi(params);
  }
  async delete() {
    return await claimCommentDeleteApi({ id: this.id });
  }

  static async list(body?: Api.Core.ClaimComment.List.IRequest) {
    const { payload, ...rest } = await claimCommentListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ClaimComment(it)),
        count: payload.count,
      },
    };
  }
}
