import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Public.ProcessContractConsent.IRequest;
type Response = Api.Core.Public.ProcessContractConsent.IResponse;
type Headers = Api.Core.Public.ProcessContractConsent.IHeaders;
const url = '/api/Public/ProcessContractConsent';
export default async function publicProcessContractConsentApi({
  body,
  access_token,
}: {
  body: Request;
  access_token: string;
}): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    body,
    url,
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${access_token}`},
  });
}
