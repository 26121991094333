import Box from 'assets/components/box/Box';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import Claim from 'models/claims/claim/Model.Claim';

export default function ClaimInfoCard(props: {
  claim: Utils.FormData<Model.IClaim>;
  product: Utils.FormData<Model.IProduct>;
  contract: Utils.FormData<Model.IContract>;
}) {
  const { lang } = getLocales();
  const claim = new Claim(props.claim);
  return (
    <Card
      class={ThemeCardRow}
      media={<Icon class="fas-car-crash" />}
      info={
        claim?.id ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: Theme.Size.XS }}>
            {claim.statusCode.replace(/_/g, ' - ') || lang.noStatusDefined}
            <div style={{ fontSize: Theme.FontSize.L }}>{claim?.authorizationNumber}</div>
          </div>
        ) : (
          <>{lang.newRepairVisit}</>
        )
      }
      title={
        !claim?.contract ? (
          lang.noContractDefined
        ) : (
          <>{claim?.number ?? combineStrings('-', props.product?.category?.code, claim?.id?.toString())}</>
        )
      }
      subtitle={
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {!(claim?.claimJobs?.length === 1 && claim.claimJobs[0].claimJobReports?.length) && (
            <>
              {lang.jobs}:&nbsp;{claim?.claimJobs?.length || 0},&nbsp;
            </>
          )}
          {lang.documents}:&nbsp;
          {(claim?.claimDocuments?.length || 0) +
            (claim?.claimJobs?.reduce((prev, curr) => (curr.claimJobDocuments?.length || 0) + prev, 0) || 0)}
          <LinkButton
            class={ThemeButtonCircle}
            url={props?.contract?.documentationAggregate?.url}
            disabled={!props?.contract?.documentationAggregate?.url}
            media={<Icon class="fas-download" />}
            target="_blank"
          >
            Download contract document {props?.contract?.appNumber}
          </LinkButton>
        </Box>
      }
    />
  );
}
