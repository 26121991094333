import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import React from 'react';

type Props = {
  data: Partial<Model.IClaimTypeDocument>;
  onSave(data: Model.IClaimTypeDocument): void;
  onCancel(): void;
};
export default function ClaimTypeDocumentEdit(props: Props) {
  const { lang } = getLocales();

  return (
    <>
      <ModalForm<Model.IClaimTypeDocument>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[props.data]}
        title={lang.documents}
        default={props.data || {}}
        onCancel={props.onCancel}
        onSubmit={props.onSave}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <FormWrapper>
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.name}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <MultilineTextInput
              name="description"
              label={lang.description}
              value={form.data.description}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <InputWrapper>
              <TextInput
                name="source"
                label={lang.source}
                value={form.data.source}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="example"
                label={lang.example}
                value={form.data.example}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
            </InputWrapper>
            <CheckWrapper>
              <CheckInput
                name="allowMultiple"
                label={lang.allowMultiple}
                class={inputStyles.check.switch}
                value={form.data.allowMultiple}
                onChange={form.update}
                htmlElementProps={{ title: form.data.allowMultiple ? lang.disable : lang.enable }}
              />
              <CheckInput
                name="required"
                label={lang.required}
                class={inputStyles.check.switch}
                value={form.data.required}
                onChange={form.update}
                htmlElementProps={{ title: form.data.required ? lang.disable : lang.enable }}
              />
              <CheckInput
                name="allowNotes"
                label={lang.allowNotes}
                class={inputStyles.check.switch}
                value={form.data.allowNotes}
                onChange={form.update}
                htmlElementProps={{ title: form.data.allowNotes ? lang.disable : lang.enable }}
              />
              <CheckInput
                name="approvalRequired"
                label={lang.approvalRequired}
                class={inputStyles.check.switch}
                value={form.data.approvalRequired}
                onChange={form.update}
                htmlElementProps={{ title: form.data.approvalRequired ? lang.disable : lang.enable }}
              />
            </CheckWrapper>
          </FormWrapper>
        )}
      />
    </>
  );
}
