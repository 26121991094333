import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import contractDocumentDeleteApi from './delete/Api.ContractDocument.Delete';
import contractDocumentSaveApi from './save/Api.ContractDocument.Save';
import User from 'models/core/user/Model.User';

export default class ContractDocument
  extends Model<Model.IContractDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IContractDocument>
{
  id: number;
  name: string;
  notes?: string;
  contractId: number;

  mediaFile: MediaFile;
  formFile?: File;
  createdBy?: User;
  createdAtUtc: string;

  constructor(
    data: Partial<Model.IContractDocument> | Utils.FormData<Model.IContractDocument>,
    language?: Locale.Languages
  ) {
    super({ mediaFile: MediaFile, createdBy: User }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
  async save() {
    return await contractDocumentSaveApi(this);
  }
  async delete() {
    return await contractDocumentDeleteApi({ id: this.contractId, documentId: this.id });
  }
}
