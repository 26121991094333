import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Dealer.DeleteUiFile.IRequest;
type Response = Api.Core.Dealer.DeleteUiFile.IResponse;
type Headers = Api.Core.Dealer.DeleteUiFile.IHeaders;
const apiDealerDeleteFilePath = '/api/DealerManagement/Dealer/:id/DeleteUiFile';
export default async function dealerDeleteUiFileApi(id: number): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    url: createPathFromRoute(apiDealerDeleteFilePath, { id }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
