/**
 * Round number to specific step
 * @param x Decimal number we want to round
 * @param y Step we want to round to, step will always be abs number
 */
export const roundToStep = (x: number, y: number) => {
  const _y = y.toString();
  const dotIndex = _y.search(/\.\d+/g);
  const strNumber = (Math.round(x / Math.abs(y)) * Math.abs(y)).toFixed(
    dotIndex < 0 ? 0 : dotIndex > 100 ? 100 : _y.length - dotIndex - 1
  );

  return dotIndex >= 0 ? parseFloat(strNumber) : parseInt(strNumber);
};

export const calculatePages = (limit: number, total: number) => Math.ceil(total / limit);
export const calculateOffset = (index?: number, limit?: number) => {
  if (!index || !limit) return 0;
  return index && limit && index >= limit ? Math.floor(index / limit) : 0;
};
export const calculateIndex = (current: number, total: number, offset: number) => {
  if (offset > 0) {
    return (current + offset) % total;
  } else if (offset < 0) {
    return (current + offset + total) % total;
  } else {
    return current;
  }
};
export const createNumberArray = (num: number, offset?: number, total?: number) => {
  const numArray = Array.from(Array(num).keys());
  if (total && offset) return numArray.map((it) => (it + offset) % total);
  else return numArray;
};
export function formatNumberWithSeparator(num: number | string, separator: string = ',', decimalSeparator = '.') {
  const [whole, decimal] = (typeof num == 'string' ? (Number(num) ?? 0).toFixed(2) : num.toFixed(2)).split('.');
  return `${whole.replace(/\B(?=(\d{3})+(?!\d))/g, separator)}${decimalSeparator}${decimal}`;
}

export function formatNumberWithSeparatorWithoutDecimales(num: number | string, separator: string = ',') {
  const [whole] = (typeof num == 'string' ? num : num.toFixed(0)).split('.');
  return `${whole.replace(/\B(?=(\d{3})+(?!\d))/g, separator)}`;
}
