import ClaimType from 'models/claims/claimType/Model.ClaimType';
import Model from 'models/Model';
import productCategoryDeleteApi from './delete/Api.ProductCategory.Delete';
import productCategoryListApi from './list/Api.ProductCategory.List';
import productCategorySaveApi from './save/Api.ProductCategory.Save';

export default class ProductCategory
  extends Model<Model.IProductCategory>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductCategory>
{
  id: number;
  code?: string;
  name?: string;

  constructor(
    data: Partial<Model.IProductCategory> | Utils.FormData<Model.IProductCategory>,
    language?: Locale.Languages
  ) {
    super({ claimTypes: [ClaimType] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      info: this.code,
    };
  }

  static async list(body?: Api.Core.ProductCategory.List.IRequest) {
    const { payload, ...rest } = await productCategoryListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductCategory(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await productCategorySaveApi(this);
  }
  async delete() {
    return await productCategoryDeleteApi(this.id);
  }
}
