import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import useInput from '../Input';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';

export enum NumberInputStructure {
  formInput = 'formInput',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.Number.Structure, typeof NumberInputStructure>;
export default function NumberInputTextValue<Name>(props: Component.Input.Number.Import<Name>) {
  const extend = useTheme(NumberInputStructure, props);
  const { value, setValue } = useInput(props);
  return (
    <div
      {...extend(NumberInputStructure.formInput, true)}
      data-label={props.label}
      data-error={props.error || undefined}
      data-read-only={props.readOnly || undefined}
    >
      {RenderProps(props, 'iconBefore')}
      <input
        {...extend(NumberInputStructure.input)}
        type="number"
        readOnly={props.readOnly}
        value={isValidValue(value) ? value.toFixed(2) : ''}
        placeholder={props.placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const val = event.currentTarget.value;
          setValue(isValidValue(val) ? parseFloat(val) : null);
        }}
      />
      {RenderProps(props, 'icon')}
    </div>
  );
}
