import { useEffect } from 'react';
export function useClose({ onEscape }: Hooks.Events.CloseImport) {
  useEffect(() => {
    function close(event?: any) {
      if (onEscape && event && event.key == 'Escape') onEscape();
    }
    if (onEscape) {
      window.addEventListener('keyup', close);
      return () => {
        window.removeEventListener('keyup', close);
      };
    }
  }, []);
}
