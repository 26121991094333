export default function useInput<Name, Value>(props: Hooks.Input.Import<Name, Value>) {
  const setValue = (value?: Value) => props.onChange({ [props.name.toString()]: value } as any);
  return { value: props.value, setValue };
}

export const inputStyles = {
  wrapper: {
    inputWrapper: 'inputWrapper',
    checkWrapper: 'checkWrapper',
    formWrapper: 'formWrapper',
    errorWrapper: 'errorWrapper',
    filterWrapper: 'filterWrapper',
    reportWrapper: 'reportWrapper',
  },
  variations: {
    search: 'search',
  },
  check: {
    checkbox: 'checkbox',
    radio: 'radio',
    switch: 'switch',
    onoff: 'on-off',
  },
};
