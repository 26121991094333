import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ProductCategory.Save.IRequest;
type Response = Api.Core.ProductCategory.Save.IResponse;
type Headers = Api.Core.ProductCategory.Save.IHeaders;
const url = '/api/ProductManagement/ProductCategory';
export default async function productCategorySaveApi(body?: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
