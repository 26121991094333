import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { compact, difference, flatten, keys, omit, set } from 'lodash';
import { CanadaProvinces } from 'models/enums/CanadaProvinces';
import productDeleteApi from 'models/productManagement/product/delete/Api.Product.Delete';
import Product from 'models/productManagement/product/Model.Product';
import productSaveApi from 'models/productManagement/product/save/Api.Product.Save';
import productDocumentDeleteApi from 'models/productManagement/productDocument/delete/Api.ProductDocument.Delete';
import productDocumentSaveApi from 'models/productManagement/productDocument/save/Api.ProductDocument.Save';
import productDocumentUpdateAllApi from 'models/productManagement/productDocument/updateAll/Api.ProductDocument.UpdateAll';
import { useEffect, useMemo, useState } from 'react';
import { productIndexRoute } from './Product.Index';

export enum ProductAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export enum ProductEditTab {
  INFO = 'info',
  DOCUMENTS = 'documents',
  DOC_TEMPLATES = 'docTemplates',
  TAXES = 'taxes',
  PROVINCE_GROUPS = 'provinceGroups',
}
export type VerifyProductAction = Utils.VerifyExtends<
  Module.ProductManagement.Product.Actions,
  Utils.ValueOf<typeof ProductAction>
>;
export type VerifyProductEditTab = Utils.VerifyExtends<
  Module.ProductManagement.Product.EditTab,
  Utils.ValueOf<typeof ProductEditTab>
>;
export default function useProduct() {
  const { permissions, user, isDealer } = useAuthStore();
  const dealerProductIds = isDealer
    ? compact(flatten(user?.dealers?.map((it) => it.products?.map((p) => p.id))))
    : undefined;
  const { lang } = getLocales();
  const pageRouter = usePageRouter<Module.ProductManagement.Product.Params, Module.ProductManagement.Product.Query>({
    route: productIndexRoute,
  });
  const productId = pageRouter.params.id ? parseInt(pageRouter.params.id) : null;
  const [isLoading, setIsLoading] = useState(false);
  const canView =
    permissions.CREATE_PRODUCT ||
    permissions.EDIT_PRODUCT ||
    permissions.DELETE_PRODUCT ||
    permissions.PRICINGLIMITS_DEALER ||
    permissions.CREATE_CUSTOMPRICEPOINT ||
    permissions.EDIT_CUSTOMPRICEPOINT ||
    permissions.DELETE_CUSTOMPRICEPOINT ||
    permissions.CREATE_NONWARRANTYPRICEPOINT ||
    permissions.EDIT_NONWARRANTYPRICEPOINT ||
    permissions.DELETE_NONWARRANTYPRICEPOINT ||
    permissions.CREATE_PRODUCTTIERPRICEPOINT ||
    permissions.EDIT_PRODUCTTIERPRICEPOINT ||
    permissions.DELETE_PRODUCTTIERPRICEPOINT ||
    permissions.CREATE_PRODUCTPRICINGPLAN ||
    permissions.EDIT_PRODUCTPRICINGPLAN ||
    permissions.DELETE_PRODUCTPRICINGPLAN;
  const listApi = useApi({
    action: Product.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
      dealerId: user?.dealers?.map((d) => d.id) ?? [],
      distributorId: user?.distributorId ?? undefined,
      productId: dealerProductIds,
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({ ...pageRouter.params }, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const {
    payload: product,
    execute: reloadProduct,
    isExecuting: isEditLoading,
    reset: resetProduct,
  } = useApi(
    {
      action: Product.find,
      body: { id: productId },
      default: null,
      wait: !productId,
    },
    [productId]
  );
  useEffect(() => {
    if (!productId) resetProduct(true);
  }, [productId]);

  const deleteApi = useApi({
    action: productDeleteApi,
    callback: () => {
      listApi.execute((body) => body);
      pageRouter.redirect({ ...pageRouter.params, action: undefined }, { ...pageRouter.query, editTab: undefined });
    },
    wait: true,
  });

  //#region Data editing
  const currentProduct = product?.toJSON();
  const currentProvinceTaxes = currentProduct?.productTaxes?.map((it) => it.provinceCode);
  const form = useForm<Utils.FormData<Model.IProduct>>(
    {
      default: {
        ...currentProduct,
        productTaxes: [
          ...(currentProduct?.productTaxes || []),
          ...difference(keys(CanadaProvinces), currentProvinceTaxes).map((provinceCode) => ({
            productId: currentProduct?.id || undefined,
            provinceCode,
            claimsProvincialSalesTax: 0,
            claimsGstHst: 0,
            contractProvincialSalesTax: 0,
            contractGstHst: 0,
          })),
        ],
      },
      onSubmit: async ({ productDocuments, ...product }) => {
        const productSaveResponse = await productSaveApi({
          ...product,
          productDocumentTemplates: product.productDocumentTemplates?.map((g) => (g.id <= 0 ? omit(g, 'id') : g)),
          productProvinceGroups: product.productProvinceGroups?.map((g) => (g.id <= 0 ? omit(g, 'id') : g)),
        });
        const productId = productSaveResponse.payload;
        if (productDocuments?.length) {
          await productDocumentUpdateAllApi(product.id || productId, {
            productAdditionalFields: flatten(productDocuments.map((d) => d.productAdditionalFields)),
            productSignatureFields: flatten(productDocuments.map((d) => d.productSignatureFields)),
          });
        }
        if (productId?.toString() === pageRouter.params.id) {
          reloadProduct({ id: productId });
        } else {
          pageRouter.updateParams({
            action: ProductAction.EDIT,
            id: productId.toString(),
          });
        }
      },
      validation: (data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
        if (!data.code) errors.code = lang.mustNotBeEmpty;
        if (!data.productType) errors.productType = lang.mustNotBeEmpty;
        if (!data.category) set(errors, 'category._objectError', lang.mustNotBeEmpty);
        if (!data.insurers || data.insurers.length === 0) set(errors, 'insurers._objectError', lang.mustNotBeEmpty);
        if (!data.productTaxes || data.productTaxes.length === 0)
          set(errors, 'productTaxes._objectError', lang.mustNotBeEmpty);
        if (data.productProvinceGroups && data.productProvinceGroups.some((g) => !g.provinceCodes?.length))
          set(errors, 'productProvinceGroups.name', lang.mustNotBeEmpty);
        if (data.productProvinceGroups && data.productProvinceGroups.some((g) => !g.name))
          set(errors, 'productProvinceGroups.provinceCodes', lang.mustNotBeEmpty);
      },
    },
    [product]
  );
  const newDocuments = form.data.productDocuments?.filter((d) => !d.id) || [];
  const uploadedDocuments = form.data.productDocuments?.filter((d) => !!d.id) || [];
  //#endregion
  function reload() {
    reloadProduct((body) => body);
  }
  async function uploadFile(formFile: File) {
    if (!formFile) return;
    setIsLoading(true);
    await productDocumentSaveApi({
      formFile,
      productId: product.id,
    });
    setIsLoading(false);
    reload();
    return;
  }
  async function deleteFile(documentId: number) {
    if (!documentId) return;
    setIsLoading(true);
    await productDocumentDeleteApi({ id: form.data?.id, documentId });
    setIsLoading(false);
    reload();
  }

  return {
    reload,
    uploadFile,
    deleteFile,
    newDocuments,
    uploadedDocuments,
    product,
    pageRouter,
    listApi,
    deleteApi,
    form,
    selected,
    permissions,
    isEditLoading: isEditLoading || isLoading,
    canView,
    views: {
      [ProductAction.DELETE]:
        pageRouter.params.action === ProductAction.DELETE && selected && permissions.DELETE_PRODUCT,
      [ProductAction.EDIT]:
        pageRouter.params.action === ProductAction.EDIT &&
        ((permissions.CREATE_PRODUCT && !selected) || (permissions.EDIT_PRODUCT && selected)),
    },
  };
}

export function useProductProvider() {
  return useDataProvider<ReturnType<typeof useProduct>>();
}
