import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { date, defaultInputDateFormat, defaultServerDateFormat } from 'assets/utils/data/Date';
import { OrderType } from 'config/Api.Config';
import { isMobile } from 'react-device-detect';
import { useAudtiLogProvider } from '../AuditLog.Hooks';

export default function AuditLogFilters() {
  const { lang } = getLocales();
  const {
    listApi: { body: filters, execute: reload, reset },
    applications,
    permissions,
    users,
  } = useAudtiLogProvider();

  return (
    <>
      <b style={{ marginRight: 'auto' }} children={lang.changeLogs} />
      {!isMobile && (
        <SearchInput
          value={filters?.search}
          onSearch={(search) => reload((body) => ({ ...body, search }))}
          threshold={2}
          placeholder={`${lang.search}...`}
        />
      )}
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByDate}
              media={<Icon class="fas-caret-up" />}
              onClick={reload}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'changedAtUtc',
                orderType: OrderType.asc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByDate}
              media={<Icon class="fas-caret-down" />}
              onClick={reload}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'changedAtUtc',
                orderType: OrderType.desc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByUserName}
              media={<Icon class="fas-caret-up" />}
              onClick={reload}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'user.FirstName',
                orderType: OrderType.asc,
                thenBy: 'user.LastName',
                thenType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByUserName}
              media={<Icon class="fas-caret-down" />}
              onClick={reload}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'user.FirstName',
                orderType: OrderType.desc,
                thenBy: 'user.LastName',
                thenType: OrderType.desc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={filters}
        onFilter={(newFilters) => reload(newFilters)}
        onReset={reset}
        render={({ tempFilters, setFilters }) => {
          return (
            <>
              <SelectInput
                name="applicationId"
                label={lang.applications}
                data={applications?.map((item) => ({
                  id: item.id,
                  title: item.name,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.applicationId}
                onChange={({ applicationId }) => setFilters({ ...tempFilters, applicationId })}
                icon={<Icon class="if-cloud" />}
              />
              <SelectInput
                name="permissionKey"
                label={lang.permissions}
                data={permissions?.map((item) => ({
                  id: item.key,
                  title: item.name,
                  icon: <Icon class="fas-shield" />,
                }))}
                value={tempFilters?.permissionKey}
                onChange={({ permissionKey }) => setFilters({ ...tempFilters, permissionKey })}
                icon={<Icon class="if-cloud" />}
              />
              <SelectInput
                name="userId"
                label={lang.changeMadeBy}
                data={users?.map((item) => ({
                  id: item.id,
                  title: item.firstName + ' ' + item.lastName,
                  icon: <Icon class="fas-user" />,
                }))}
                isMultiselect
                value={tempFilters?.userId}
                onChange={({ userId }) => setFilters({ ...tempFilters, userId })}
                icon={<Icon class="fas-users" />}
              />
              <DateInput
                name="startDateUtc"
                label={lang.startDate}
                dateTimeFormat={defaultInputDateFormat}
                value={tempFilters?.startDateUtc}
                min={date(tempFilters?.endDateUtc).subtract(1, 'month').format(defaultServerDateFormat)}
                max={date().format(defaultServerDateFormat)}
                onChange={({ startDateUtc }) => {
                  console.log(startDateUtc);
                  setFilters({
                    ...tempFilters,
                    startDateUtc,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                name="endDateUtc"
                label={lang.endDate}
                dateTimeFormat={defaultInputDateFormat}
                value={tempFilters?.endDateUtc}
                max={date().format(defaultServerDateFormat)}
                onChange={({ endDateUtc }) =>
                  setFilters({
                    ...tempFilters,
                    startDateUtc: date(endDateUtc).subtract(5, 'days').format(defaultServerDateFormat),
                    endDateUtc,
                  })
                }
                icon={<Icon class="fas-calendar-alt" />}
              />
            </>
          );
        }}
      />
    </>
  );
}
