import { getLocales } from 'assets/locales/Locale';
import Model from 'models/Model';
import PermissionTranslation from 'models/translations/permissionTranslation/Model.PermissionTranslation';
import Application from '../application/Model.Application';
import Category from '../category/Model.Category';
import permissionListApi from './list/Api.Permission.List';
import permissionSaveApi from './save/Api.Permission.Save';

export default class Permission
  extends Model<Model.IPermission>({ icon: 'fas-shield-alt' })
  implements Model.Instance<Model.IPermission>
{
  id: number;
  key: Auth.Permission;
  application: Application;
  category: Category;
  translations: PermissionTranslation[];

  get name(): string {
    return (
      this.translations?.find((it) => it.languageIsoCode === this._language)?.name ||
      this.translations?.find((it) => it)?.name ||
      (this.key && getLocales().lang[this.key]) ||
      ''
    );
  }
  get description(): string {
    return (
      this.translations?.find((it) => it.languageIsoCode === this._language)?.description ||
      this.translations?.find((it) => it)?.description
    );
  }

  constructor(data: Partial<Model.IPermission> | Utils.FormData<Model.IPermission>, language?: Locale.Languages) {
    super({ application: Application, category: Category, translations: [PermissionTranslation] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      media: this.icon,
      title: this.name || this.id.toString(),
      info: this.application?.displayInfo?.title,
      subtitle: this.description,
    };
  }

  static async list(body?: Api.Core.Permission.List.IRequest) {
    const { payload, ...rest } = await permissionListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Permission(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await permissionSaveApi(this);
  }
}
