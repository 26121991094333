import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ClaimEmail.SendPaymentRequestEmail.IRequest;
type Response = Api.Core.ClaimEmail.SendPaymentRequestEmail.IResponse;
type Headers = Api.Core.ClaimEmail.SendPaymentRequestEmail.IHeaders;
const url = '/api/Claims/ClaimEmail/SendPaymentRequestEmail';
export default async function claimEmailSendPaymentRequestEmailApi(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url),
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
