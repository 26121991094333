import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import { addLog } from 'assets/components/feedback/Feedback';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { HttpStatus } from 'config/Api.Config';
import claimTypeSaveApi from 'models/claims/claimType/save/Api.ClaimType.Save';
import { ClaimTypeAction, useClaimTypeProvider } from '../ClaimType.Hooks';
import { cloneDeep } from 'lodash';

export default function ClaimTypeList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, permissions, canDeleteClaimType } = useClaimTypeProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.claimTypes}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList} isLoading={listApi.isExecuting}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: claimType }) => (
          <Row
            key={claimType.id}
            children={
              <>
                <RowImage render={<Icon class="fas-clipboard-list" />} />
                <RowData {...claimType.displayInfo} />
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: claimType.id.toString(),
                      action: ClaimTypeAction.INFO,
                    }}
                    class={ThemeButtonCircle}
                    htmlElementProps={{
                      title: lang.info,
                    }}
                    disabled={claimType.isDeleted}
                    media={<Icon class="fas-external-link-alt" />}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: claimType.id.toString(),
                      action: ClaimTypeAction.EDIT,
                    }}
                    disabled={claimType.isDeleted || !permissions.EDIT_CLAIMTYPE}
                    class={ThemeButtonCircle}
                    htmlElementProps={{
                      title: lang.edit,
                    }}
                    media={<Icon class="fas-edit" />}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: claimType.id.toString(),
                      action: ClaimTypeAction.COPY,
                    }}
                    disabled={claimType.isDeleted || !permissions.CREATE_CLAIMTYPE}
                    class={ThemeButtonCircle}
                    htmlElementProps={{
                      title: lang.copy,
                    }}
                    media={<Icon class="fas-copy" />}
                  />
                  {canDeleteClaimType(claimType) ? (
                    <Button
                      onClick={pageRouter.updateParams}
                      data={{
                        id: claimType.id.toString(),
                        action: ClaimTypeAction.DELETE,
                      }}
                      disabled={!permissions.DELETE_CLAIMTYPE}
                      class={ThemeButtonCircle}
                      htmlElementProps={{
                        title: lang.delete,
                      }}
                      media={<Icon class="fas-trash" />}
                    />
                  ) : claimType.isDeleted ? (
                    <Button
                      onClick={async (data) => {
                        const finalData = cloneDeep(data);
                        finalData.claimTypeAggregates?.forEach((it) => {
                          delete it.id;
                          delete it.version;
                        });
                        finalData.claimTypeDocuments?.forEach((it) => {
                          delete it.id;
                          delete it.version;
                        });
                        finalData.claimTypeReportSettings?.forEach((it) => {
                          delete it.id;
                          delete it.version;
                          it.infoInputs = JSON.stringify(it.infoInputs) as any;
                          it.reportRows = JSON.stringify(it.reportRows) as any;
                        });
                        finalData.isDeleted = false;
                        const res = await claimTypeSaveApi(finalData);

                        if (res.status === HttpStatus.ok) {
                          addLog({ success: 'Claim type is successfully unarchived.' });
                        }

                        listApi.execute((body) => body);
                      }}
                      disabled={!permissions.DELETE_CLAIMTYPE}
                      class={ThemeButtonCircle}
                      data={claimType}
                      htmlElementProps={{
                        title: lang.unarchive,
                      }}
                      media={<Icon class="fas-recycle" />}
                    />
                  ) : (
                    <Button
                      onClick={pageRouter.updateParams}
                      data={{
                        id: claimType.id.toString(),
                        action: ClaimTypeAction.ARCHIVE,
                      }}
                      disabled={!permissions.DELETE_CLAIMTYPE}
                      class={ThemeButtonCircle}
                      htmlElementProps={{
                        title: lang.archive,
                      }}
                      media={<Icon class="fas-archive" />}
                    />
                  )}
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
