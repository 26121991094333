import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales, Language } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { managementHomeRoute } from 'config/Api.Config';
import accountUpdateUserApi from 'models/account/updateUser/Api.Account.UpdateUser';
import accountUploadProfilePhotoApi from 'models/account/uploadProfilePhoto/Api.Account.UploadProfilePhoto';
import React from 'react';
import { useHistory } from 'react-router';
import { accountUserWelcomeRoute } from '../userWelcome/Account.UserWelcome.Index';

type UserSettingsForm = Api.Core.Account.UpdateUser.IRequest & Api.Core.Account.UploadProfilePhoto.IRequest;

export const accountEditUserSettingsRoute = managementHomeRoute + '/account/edit-user-settings';
export default function AccountEditUserSettingsIndex() {
  const { lang, currentLanguage } = getLocales();
  const authInfo = useAuthStore();
  const _history = useHistory();
  const updateUserSettings = async ({ formFile, ...form }: UserSettingsForm) => {
    if (formFile) await accountUploadProfilePhotoApi({ formFile });
    await accountUpdateUserApi(form);
    authInfo.fetchUser();
    _history.push(accountUserWelcomeRoute);
  };

  return (
    authInfo.userId && (
      <>
        <ModalForm<UserSettingsForm>
          subcomponents={ThemeModalFormDefault}
          title={lang.user}
          default={{
            id: authInfo.userId,
            email: authInfo.email,
            firstName: authInfo.givenName,
            lastName: authInfo.familyName,
            languageIsoCode: currentLanguage,
          }}
          onCancel={() => {
            _history.goBack();
          }}
          onSubmit={(data) => updateUserSettings(data)}
          validation={(data, errors) => {
            if (!data.firstName) errors.firstName = lang.mustNotBeEmpty;
            if (!data.lastName) errors.lastName = lang.mustNotBeEmpty;
            if (!data.email) errors.email = lang.mustNotBeEmpty;
            if (!data.languageIsoCode) errors.languageIsoCode = lang.mustNotBeEmpty;
          }}
          render={({ form }) => (
            <>
              <FormWrapper>
                <InputWrapper>
                  <TextInput
                    name="firstName"
                    label={lang.firstName}
                    value={form.data.firstName}
                    error={form.errors.firstName}
                    onChange={form.update}
                    icon={<Icon class="if-quill-pen" />}
                  />
                  <TextInput
                    name="lastName"
                    label={lang.lastName}
                    value={form.data.lastName}
                    error={form.errors.lastName}
                    onChange={form.update}
                    icon={<Icon class="if-quill-pen" />}
                  />
                </InputWrapper>
                <TextInput
                  name="inpute"
                  label={lang.email}
                  value={form.data.email}
                  error={form.errors.email}
                  onChange={({ inpute: email }) => form.update({ email })}
                  icon={<Icon class="if-envelope" />}
                />
                <SelectInput
                  name="languageIsoCode"
                  label={lang.language}
                  data={Object.values(Language).map((id) => ({ id, title: lang[id] }))}
                  value={form.data.languageIsoCode}
                  error={form.errors.languageIsoCode}
                  onChange={form.update}
                  icon={<Icon class="fas-globe" />}
                />
                <FileInput
                  name="file"
                  label={lang.profileImage}
                  fileLimit={1}
                  value={
                    form.data.formFile
                      ? [
                          {
                            original: form.data.formFile,
                            type: form.data.formFile.type as Utils.File.MimeType,
                            dataUrl: window.URL.createObjectURL(form.data.formFile),
                          },
                        ]
                      : []
                  }
                  onChange={({ file }) => {
                    const formFile = file && file[0]?.original;
                    form.update({ formFile });
                  }}
                  class={ThemeFileInputDropzone}
                />
              </FormWrapper>
            </>
          )}
        />
      </>
    )
  );
}
