import Model from 'models/Model';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Product from 'models/productManagement/product/Model.Product';
import DistributorProductPricingDistribution from '../distributorProductPricingDistribution/Model.DistributorProductPricingDistribution';
import distributorProductListApi from './list/Api.ProductDistributor.List';
import distributorProductSaveApi from './save/Api.ProductDistributor.Save';
import distributorProductDeleteApi from './delete/Api.ProductDistributor.Delete';

export default class DistributorProduct
  extends Model<Model.IDistributorProduct>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributorProduct>
{
  id: number;
  productId: number;
  product: Product;
  distributorId: number;
  pricingDistributionTemplateId: number;
  pricingDistributionTemplate?: PricingDistributionTemplate;
  pricingDistributions?: Array<DistributorProductPricingDistribution>;

  constructor(
    data: Partial<Model.IDistributorProduct> | Utils.FormData<Model.IDistributorProduct>,
    language?: Locale.Languages
  ) {
    super(
      {
        pricingDistributionTemplate: PricingDistributionTemplate,
        product: Product,
        pricingDistributions: [DistributorProductPricingDistribution],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.productId,
      title: this.product.name || this.productId.toString(),
      info: this.pricingDistributionTemplate?.name,
    };
  }

  static async list(body?: Api.Core.DistributorProduct.List.IParams & Api.Core.DistributorProduct.List.IRequest) {
    const { payload, ...rest } = await distributorProductListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DistributorProduct(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await distributorProductSaveApi(this);
  }
  async delete() {
    return await distributorProductDeleteApi(this.id);
  }
}
