enum MimeType {
  allFiles = '*',
  allAudioFiles = 'audio/*',
  allApplicationFiles = 'application/*',
  allVideoFiles = 'video/*',
  allImageFiles = 'image/*',
  allTextFiles = 'text/*',
  allFontFiles = 'font/*',
  audioAAC = 'audio/aac',
  audioMPEG = 'audio/mpeg',
  audioOOG = 'audio/ogg',
  audioWAV = 'audio/wav',
  appXML = 'application/xml',
  appOPEN_XML_SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  appMS_EXCEL = 'application/vnd.ms-excel',
  appRTF = 'application/rtf',
  appOPEN_XML_PRESENTATION = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  appMS_POWERPOINT = 'application/vnd.ms-powerpoint',
  appPDF = 'application/pdf',
  appZIP = 'application/zip',
  appJSON = 'application/json',
  appMS_WORD = 'application/msword',
  appOPEN_XML_WORD_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  videoMPEG = 'video/mpeg',
  videoOGG = 'video/ogg',
  imageBMP = 'image/bmp',
  imageGIF = 'image/gif',
  imageJPEG = 'image/jpeg',
  imageJPG = 'image/jpg',
  imagePNG = 'image/png',
  imageSVG = 'image/svg+xml',
  imageTIFF = 'image/tiff',
  textCSS = 'text/css',
  textCSV = 'text/csv',
  textHTML = 'text/html',
  textJS = 'text/javascript',
  textPLAIN = 'text/plain',
  textXML = 'text/xml',
  fontTTF = 'font/ttf',
  fontWOFF = 'font/woff',
  fontWOFF2 = 'font/woff2',
}
export default MimeType;
export type Verify = Utils.VerifyExtends<Utils.File.MimeType, Utils.ValueOf<typeof MimeType>>;
