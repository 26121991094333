import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import payeeTypeDeleteApi from 'models/claims/payeeType/delete/Api.PayeeType.Delete';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import payeeTypeSaveApi from 'models/claims/payeeType/save/Api.PayeeType.Save';
import { useMemo } from 'react';
import { payeeTypeIndexRoute } from './PayeeType.Index';

export enum PayeeTypeAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyPayeeTypeAction = Utils.VerifyExtends<
  Module.Claims.PayeeType.Actions,
  Utils.ValueOf<typeof PayeeTypeAction>
>;
export default function usePayeeType() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.PayeeType.Params, Module.Claims.PayeeType.Query>({
    route: payeeTypeIndexRoute,
  });

  const canView = permissions.CREATE_PAYEE || permissions.EDIT_PAYEE || permissions.DELETE_PAYEE;
  const listApi = useApi({
    action: PayeeType.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: payeeTypeDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: payeeTypeSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    permissions,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    views: {
      [PayeeTypeAction.DELETE]:
        pageRouter.params.action === PayeeTypeAction.DELETE && selected && permissions.DELETE_PAYEE,
      [PayeeTypeAction.EDIT]:
        pageRouter.params.action === PayeeTypeAction.EDIT &&
        ((permissions.CREATE_PAYEE && !selected) || (permissions.EDIT_PAYEE && selected)),
    },
  };
}

export function usePayeeTypeProvider() {
  return useDataProvider<ReturnType<typeof usePayeeType>>();
}
