import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import dealerSaveApi from 'models/dealerManagement/dealer/save/Api.Dealer.Save';
import dealerGroupDeleteUiFileApi from 'models/dealerManagement/dealerGroup/deleteUiFile/Api.DealerGroup.DeleteUiFile';
import dealerGroupSaveApi from 'models/dealerManagement/dealerGroup/save/Api.DealerGroup.Save';
import dealerGroupUploadUiFileApi from 'models/dealerManagement/dealerGroup/uploadUiFile/Api.DealerGroup.UploadUiFile';
import { useDealerGroupProvider } from '../DealerGroup.Hooks';
import DealerGroupGeneralForm from './edit/DealerGroup.General';
import DealerGroupUiSettingsForm from './edit/DealerGroup.UiSettingsForm';
import { HttpStatus } from 'config/Api.Config';
import { addLog } from 'assets/components/feedback/Feedback';

export type DealerGroupForm = Api.Core.DealerGroup.Save.IRequest & {
  logo: Hooks.Input.File.Info;
};
export default function DealerGroupEdit() {
  const { lang } = getLocales();
  const { user, isDistributor, isDealerOrDistributor } = useAuthStore();
  const { selected, pageRouter, listApi } = useDealerGroupProvider();
  const { payload: dealersPayload } = useApi({ action: Dealer.list });

  return (
    <ModalForm<DealerGroupForm>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.group}
      default={
        selected
          ? {
              ...selected.toJSON(),
              logo: selected.uiSettingsFile
                ? {
                    id: selected.uiSettingsFile.id,
                    dataUrl: selected.uiSettingsFile.url,
                    name: selected.uiSettingsFile.name,
                    type: selected.uiSettingsFile.contentType as Utils.File.MimeType,
                  }
                : null,
            }
          : {
              distributorId: user?.distributorId,
            }
      }
      onCancel={pageRouter.updateParams}
      onSubmit={async ({ dealers, logo, ...data }) => {
        const response = await dealerGroupSaveApi(data);

        if (response.status === HttpStatus.ok) {
          for (const dealer of dealers ?? []) {
            const selected = dealersPayload?.data?.find((d) => d.id === dealer.id);
            await dealerSaveApi(selected);
          }
          if (logo?.id !== selected?.uiSettingsFile?.id) {
            await dealerGroupDeleteUiFileApi(response.payload || data.id);
          }
          if (logo && !logo?.id) {
            await dealerGroupUploadUiFileApi(response.payload || data.id, { formFile: logo.original });
          }
        } else {
          addLog({ error: response?.message });
        }

        listApi.execute((body) => body);
      }}
      validation={(data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
        if (!data.distributorId) errors.distributorId = lang.mustNotBeEmpty;
      }}
      tabs={[
        ...(isDistributor || !isDealerOrDistributor ? [{ id: 'info', icon: 'fas-info-circle', text: lang.info }] : []),
        ...(isDistributor || !isDealerOrDistributor
          ? [{ id: 'uiSettings', icon: 'fas-paint-roller', text: lang.uiSettings }]
          : []),
      ]}
      render={({ activeTab }) =>
        activeTab?.id === 'info' ? (
          <DealerGroupGeneralForm />
        ) : activeTab?.id === 'uiSettings' ? (
          <DealerGroupUiSettingsForm />
        ) : activeTab?.id === 'group' ? (
          <DealerGroupGeneralForm />
        ) : null
      }
    />
  );
}
