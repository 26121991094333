import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeDropdownButtonDrawer from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Drawer';
import Theme from 'assets/themes/Theme.Common';

const ThemeFilterFormDrawer: Template.FilterForm.Import<any>['subcomponents'] = {
  dropdownButton: ThemeDropdownButtonDrawer,
  cancelButton: { class: ThemeButtonCircle },
  submitButton: { class: ThemeButtonCircle },
  resetButton: { class: ThemeButtonCircle },
  buttonWrapperBox: {
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: Theme.Size.L,
      marginTop: 'auto',
    },
  },
};

export default ThemeFilterFormDrawer;
