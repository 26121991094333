import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import { CanadaProvinces } from 'models/enums/CanadaProvinces';
import { useProductProvider } from '../../Product.Hooks';

const sectionColumn: React.CSSProperties = { fontWeight: 'bold', textAlign: 'left', color: Theme.Color.primaryAlt };
const sectionRow: React.CSSProperties = { backgroundColor: Theme.Color.primaryLight };
const input: React.CSSProperties = { textAlign: 'right' };

export default function ProductEditTaxes() {
  const { form } = useProductProvider();
  const { lang } = getLocales();
  function updateTax(tax: Partial<Model.IProductTax>) {
    const otherTaxes = form.data.productTaxes?.filter((it) => it.provinceCode !== tax.provinceCode) || [];
    if (!tax.claimsGstHst) tax.claimsGstHst = 0;
    if (!tax.claimsProvincialSalesTax) tax.claimsProvincialSalesTax = 0;
    if (!tax.contractGstHst) tax.contractGstHst = 0;
    if (!tax.contractProvincialSalesTax) tax.contractProvincialSalesTax = 0;
    form.update({ productTaxes: [...otherTaxes, tax] });
  }

  return (
    <FormWrapper class={ThemeWrapperPaper}>
      <div style={{ fontSize: Theme.FontSize.L, fontWeight: 'bold' }}>{lang.taxes}</div>
      <br />
      <table className={ThemeTableSimple2}>
        <thead>
          <tr>
            <th children={lang.province} />
            <th children="PST" style={{ textAlign: 'right' }} />
            <th children="GST/HST" style={{ textAlign: 'right' }} />
          </tr>
        </thead>
        <tbody>
          <tr style={sectionRow}>
            <td colSpan={3} style={sectionColumn} children={lang.claim} />
          </tr>
          <DataObjectMap
            data={CanadaProvinces}
            render={({ data: provinceName, key: provinceCode }) => {
              const tax = form.data.productTaxes?.find((t) => t.provinceCode === provinceCode);
              return (
                <tr key={provinceCode}>
                  <td>
                    {provinceName} ({provinceCode})
                  </td>
                  <td>
                    <NumberInput<'tax'>
                      name="tax"
                      value={Number(((tax?.claimsProvincialSalesTax || 0) * 100).toFixed(3))}
                      onChange={(d) =>
                        updateTax({
                          ...tax,
                          claimsProvincialSalesTax: d.tax * 0.01,
                          provinceCode,
                        })
                      }
                      step={0.001}
                      icon={<Icon class="fas-percent" />}
                      style={{ input: input }}
                    />
                  </td>
                  <td>
                    <NumberInput<'tax'>
                      name="tax"
                      value={Number(((tax?.claimsGstHst || 0) * 100).toFixed(3))}
                      onChange={(d) =>
                        updateTax({
                          ...tax,
                          claimsGstHst: d.tax * 0.01,
                          provinceCode,
                        })
                      }
                      step={0.001}
                      icon={<Icon class="fas-percent" />}
                      style={{ input: input }}
                    />
                  </td>
                </tr>
              );
            }}
          />
          <tr style={sectionRow}>
            <td colSpan={3} style={sectionColumn} children={lang.contract} />
          </tr>
          <DataObjectMap
            data={CanadaProvinces}
            render={({ data: provinceName, key: provinceCode }) => {
              const tax = form.data.productTaxes?.find((t) => t.provinceCode === provinceCode);
              return (
                <tr key={provinceCode}>
                  <td>
                    {provinceName} ({provinceCode})
                  </td>
                  <td>
                    <NumberInput<'tax'>
                      name="tax"
                      value={Number(((tax?.contractProvincialSalesTax || 0) * 100).toFixed(3))}
                      onChange={(d) =>
                        updateTax({
                          ...tax,
                          contractProvincialSalesTax: d.tax * 0.01,
                          provinceCode,
                        })
                      }
                      step={0.001}
                      icon={<Icon class="fas-percent" />}
                      style={{ input: input }}
                    />
                  </td>
                  <td>
                    <NumberInput<'tax'>
                      name="tax"
                      value={Number(((tax?.contractGstHst || 0) * 100).toFixed(3))}
                      onChange={(d) =>
                        updateTax({
                          ...tax,
                          contractGstHst: d.tax * 0.01,
                          provinceCode,
                        })
                      }
                      step={0.001}
                      icon={<Icon class="fas-percent" />}
                      style={{ input: input }}
                    />
                  </td>
                </tr>
              );
            }}
          />
        </tbody>
      </table>
    </FormWrapper>
  );
}
