import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { omit, pick } from 'lodash';

type Params = Api.Core.DistributorProduct.List.IParams;
type Request = Api.Core.DistributorProduct.List.IRequest;
type Response = Api.Core.DistributorProduct.List.IResponse;
type Headers = Api.Core.DistributorProduct.List.IHeaders;
const url = '/api/ProductManagement/DistributorProduct/:distributorId/:productId';
export default async function distributorProductListApi(body?: Params & Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url: createPathFromRoute(url, pick(body, 'distributorId', 'productId')),
    body: omit(body, 'distributorId', 'productId'),
    headers: getDefaultHeaders(),
  });
}
