import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import statementDeleteApi from 'models/sales/statement/delete/Api.Statement.Delete';
import Statement from 'models/sales/statement/Model.Statement';
import { useMemo } from 'react';
import { statementIndexRoute } from './Statement.Index';
import statementCreateForDealerApi from 'models/sales/statement/createForDealer/Api.Statement.CreateForDealer';
import statementSetStatusApi from 'models/sales/statement/setStatus/Api.Statement.SetStatus';
import StatementStatusCode from 'models/enums/StatementStatusCode';

export enum StatementAction {
  CREATE = 'create',
  UPLOAD_DOCUMENT = 'uploadDocument',
  PRINT = 'print',
  CHANGE_STATUS = 'changeStatus',
  DELETE = 'delete',
}
export type VerifyStatementAction = Utils.VerifyExtends<
  Module.Claims.Statement.Actions,
  Utils.ValueOf<typeof StatementAction>
>;
export default function useStatement() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Statement.Params, Module.Claims.Statement.Query>({
    route: statementIndexRoute,
  });

  const canView = permissions.CREATE_STATEMENT || permissions.EDIT_STATEMENT || permissions.DELETE_STATEMENT;
  const listApi = useApi({
    action: Statement.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: statementDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const createApi = useApi({
    action: statementCreateForDealerApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const setStatusApi = useApi({
    action: statementSetStatusApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    createApi,
    selected,
    permissions,
    setStatusApi,
    views: {
      [StatementAction.DELETE]:
        pageRouter.params.action === StatementAction.DELETE &&
        selected &&
        permissions.DELETE_STATEMENT &&
        (selected.status === StatementStatusCode.OPEN || selected.status === StatementStatusCode.CANCELED),
      [StatementAction.CREATE]:
        pageRouter.params.action === StatementAction.CREATE &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.UPLOAD_DOCUMENT]:
        pageRouter.params.action === StatementAction.UPLOAD_DOCUMENT &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.PRINT]:
        pageRouter.params.action === StatementAction.PRINT &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.CHANGE_STATUS]:
        pageRouter.params.action === StatementAction.CHANGE_STATUS &&
        permissions.UPDATE_STATEMENT_STATUS &&
        !!selected &&
        selected.status !== StatementStatusCode.CANCELED,
    },
  };
}

export function useStatementProvider() {
  return useDataProvider<ReturnType<typeof useStatement>>();
}
