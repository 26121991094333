import Box from 'assets/components/box/Box';
import { hasKey } from 'assets/utils/parsersAndValidation/Validators';
import React from 'react';
import useAnimation from '../../components/animation/hooks/Animation';
import Dropdown from '../../components/dropdown/Dropdown';
import RenderProps from '../../components/helpers/RenderProps';
import Button from '../../components/button/Button';

export default function DropdownButton(props: Template.DropdownButton.Import) {
  const animation = useAnimation({
    onAnimateIn: props.onAnimateIn,
    onAnimateOut: props.onAnimateOut,
  });
  const tabIndex = hasKey(props, 'tabIndex') ? props.tabIndex : 0;

  return (
    <Button
      animateIn={animation.animateIn}
      animateOut={animation.animateOut}
      tabIndex={tabIndex}
      htmlElementProps={props.htmlElementProps}
      disabled={props.disabled}
      nonClickable={
        animation.isVisible &&
        !props.disabled && (
          <Dropdown
            title={props.title}
            horizontalPosition={props.horizontalPosition}
            verticalPosition={props.verticalPosition}
            animation={{ animate: animation.isIn }}
            disableWidthConstraint={props.disableWidthConstraint}
            constrainment={props.constrainment}
            render={RenderProps(props, 'dropdownOptions', animation)}
            actions={RenderProps(props, 'dropdownActions', animation)}
            {...(props.subcomponents && props.subcomponents.dropdown)}
            htmlElementProps={{ title: '' }}
          />
        )
      }
      media={props.buttonMedia}
      render={
        !(props.buttonBody || props.notification) ? undefined : (
          <>
            {RenderProps(props, 'buttonBody')}
            <Box render={props.notification} disableOnEmpty={true} {...props.subcomponents?.badge} />
          </>
        )
      }
      {...props.subcomponents?.button}
    />
  );
}
