import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import Draft from 'models/core/draft/Model.Draft';
import { salesDraftIndexRoute } from './Sales.Draft.Index';
import draftDeleteApi from 'models/core/draft/delete/Api.Draft.Delete';
import { useMemo } from 'react';

export enum SalesDraftAction {
  DELETE = 'delete',
}
export default function useSalesDraft() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Sales.Draft.Params, Module.Sales.Draft.Query>({
    route: salesDraftIndexRoute,
  });

  const canView = permissions.CREATE_CONTRACT;
  const listApi = useApi({
    action: Draft.list,
    default: { limit: defaultLimit, offset: defaultOffset, entity: 'Contract' },
    body: pageRouter.query.list,
    callback: (req) =>
      pageRouter.redirect(
        { ...pageRouter.params, action: pageRouter.params.action === 'delete' ? undefined : pageRouter.params.action },
        { ...pageRouter.query, list: req }
      ),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: draftDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    permissions,
    selected,
    views: {
      [SalesDraftAction.DELETE]:
        pageRouter.params.action === SalesDraftAction.DELETE && selected && permissions.CREATE_CONTRACT,
    },
  };
}

export function useSalesDraftProvider() {
  return useDataProvider<ReturnType<typeof useSalesDraft>>();
}
