import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Theme from 'assets/themes/Theme.Common';
import { useState } from 'react';

type Props = {
  templateKeys: string[];
  onUpdate(firstKey: string, secondKey: string): void;
};
export default function DistributionPriceSwitch({ templateKeys, onUpdate }: Props) {
  const [first, setFirst] = useState<string>();
  const [second, setSecond] = useState<string>();
  const { email } = useAuthStore();

  if (email !== 'super.admin@autoshield.com') return null;
  return (
    <>
      <br />
      <br />
      <InputWrapper>
        <SelectInput
          name="key"
          value={first}
          label="Replace key"
          data={templateKeys.map((it) => ({ id: it, title: it }))}
          onChange={({ key }) => setFirst(key)}
          style={{ width: '200px' }}
        />
        <SelectInput
          name="key"
          value={second}
          label="With key"
          data={templateKeys.map((it) => ({ id: it, title: it }))}
          onChange={({ key }) => setSecond(key)}
          style={{ width: '200px' }}
        />
        <Button
          class={ThemeButtonCircle}
          media="fas-save"
          disabled={!first || !second || first === second}
          onClick={() => onUpdate(first, second)}
          style={{ border: `${Theme.Size.XXS} solid ${Theme.Color.shadowDark}` }}
        />
      </InputWrapper>
    </>
  );
}
