import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';

type PreviewDocument = {
  id: number;
  url: string;
  name: string;
  extension?: string;
  description?: string;
  info?: string;
  approved?: boolean;
  onDelete?(): void;
};
type Props = {
  documents: PreviewDocument[];
  children?: any;
  isDisabled?: boolean;
};
export default function ClaimDocumentPreview(props: Props) {
  const { lang } = getLocales();

  return (
    <Collection class={combineStrings(' ', ThemeCollectionTable)} style={{ padding: 0 }}>
      {props.children}
      {!props.documents?.length ? (
        <ErrorWrapper children={lang.noFiles} />
      ) : (
        <DataMap
          data={props.documents}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              subtitle={doc.description}
              info={doc.info}
              style={{
                cardMedia: {
                  backgroundColor:
                    !doc.url || !isValidValue(doc.approved)
                      ? !doc.id
                        ? Theme.Color.warning
                        : Theme.Color.success
                      : doc.approved
                      ? Theme.Color.success
                      : Theme.Color.error,
                },
              }}
              media={
                <Icon
                  class={
                    !doc.url || !isValidValue(doc.approved)
                      ? !doc.id
                        ? 'fas-upload'
                        : 'fas-file'
                      : doc.approved
                      ? 'fas-check'
                      : 'fas-times'
                  }
                />
              }
              actions={
                <>
                  <LinkButton
                    class={ThemeButtonCircle}
                    url={doc.url}
                    media={<Icon class="fas-download" />}
                    target="_blank"
                    disabled={!doc.id}
                    htmlElementProps={{ title: lang.download }}
                  />
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    disabled={!doc.onDelete || props.isDisabled}
                    onClick={doc.onDelete}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </>
              }
            />
          )}
        />
      )}
    </Collection>
  );
}
