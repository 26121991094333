enum InputType {
  date = 'DATE',
  dateTime = 'DATE_TIME',
  year = 'YEAR',
  month = 'MONTH',
  day = 'DAY',
  text = 'TEXT',
  longText = 'LONG_TEXT',
  boolean = 'BOOLEAN',
  number = 'NUMBER',
  json = 'JSON',
  decimal = 'DECIMAL',
  price = 'PRICE',
  formattedNumber = 'FORMATTED_NUMBER',
  formattedDecimal = 'FORMATTED_DECIMAL',
}
export default InputType;
export type VerifyProductType = Utils.VerifyExtends<Model.Enum.InputType, Utils.ValueOf<typeof InputType>>;
