import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useVehicleModel, { VehicleModelAction } from './VehicleModel.Hooks';
import VehicleModelDelete from './sections/VehicleModel.Delete';
import VehicleModelEdit from './sections/VehicleModel.Edit';
import VehicleModelFilters from './sections/VehicleModel.Filters';
import VehicleModelList from './sections/VehicleModel.List';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { vehicleMakeIndexRoute } from '../vehicleMake/VehicleMake.Index';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type Verify = Utils.VerifyExtends<Module.ProductManagement.VehicleModel.Actions, Utils.ValueOf<typeof Actions>>;
export const vehicleModelIndexRoute = productManagementHomeRoute + '/vehicleModel/:action?/:id?';
export default function VehicleModelIndex() {
  const { lang } = getLocales();
  const vehicleModelHook = useVehicleModel();

  const { redirect: redirectToMakes } = usePageRouter<
    Module.ProductManagement.VehicleMake.Params,
    Module.ProductManagement.VehicleMake.Query
  >({
    route: vehicleMakeIndexRoute,
  });
  if (!vehicleModelHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={vehicleModelHook}
        render={({ listApi, views, pageRouter, permissions, vehicleMake }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <Button class={ThemeButtonCircle} media="fas-chevron-left" onClick={redirectToMakes} />
                <b style={{ marginRight: 'auto' }}>
                  {vehicleMake ? (
                    <>
                      {vehicleMake.makeCode} {lang.models.toLowerCase()}
                    </>
                  ) : (
                    lang.vehicleModels
                  )}
                </b>
                <VehicleModelFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_VEHICLEMODEL}
                  data={{ action: VehicleModelAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <VehicleModelEdit />}
                {views.delete && <VehicleModelDelete />}
                <VehicleModelList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
