import { useTheme } from 'assets/hooks/theme/useTheme';
import { preventDefaultBehavior } from 'assets/utils/dom/Events';
import { isIcon, isImageUrl, isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import React, { useRef } from 'react';
import RenderProps from '../helpers/RenderProps';
import Icon from '../icon/Icon';

export enum ButtonStructure {
  button = 'button',
  clickableArea = 'clickableArea',
  buttonMedia = 'buttonMedia',
  buttonText = 'buttonText',
  buttonBadge = 'buttonBadge',
}
export type Verify = Utils.VerifyExtends<Component.Button.Structure, typeof ButtonStructure>;
export default function Button<T>(props: Component.Button.Import<T>) {
  const extend = useTheme(ButtonStructure, props);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const _props: any = props;
    if (_props.onClick && event.button == 0) {
      _props.onClick(_props.data);
    } else if (_props.url) {
      const doc: any = document;
      if (_props.url[0] === '#' && doc) {
        doc.querySelector(_props.url).scrollIntoView({
          behavior: 'smooth',
        });
      } else if (event.button === 1) window.open(_props.url, '_blank');
      else window.location.href = _props.url;
    }
  };
  const isMouseDown = useRef(false);
  return (
    <div {...extend(ButtonStructure.button, true)}>
      {props.badge && <div {...extend(ButtonStructure.buttonBadge)} children={RenderProps(props, 'badge')} />}

      <div
        {...extend(ButtonStructure.clickableArea)}
        onMouseUp={(e) => {
          if (isMouseDown.current) {
            isMouseDown.current = false;
            if (handleClick) handleClick(e);
          }
        }}
        onMouseDown={() => (isMouseDown.current = true)}
        onClick={props.animation ? undefined : (event) => preventDefaultBehavior(event)}
        tabIndex={isValidValue(props.tabIndex) ? props.tabIndex : undefined}
        children={
          <>
            {props.media && (
              <div
                {...extend(ButtonStructure.buttonMedia)}
                children={
                  typeof props.media === 'string' ? (
                    isImageUrl(props.media) ? (
                      <img src={props.media} />
                    ) : isIcon(props.media) ? (
                      <Icon class={props.media} />
                    ) : (
                      props.media
                    )
                  ) : (
                    RenderProps(props, 'media')
                  )
                }
              />
            )}
            {(props.render || props.children) && (
              <div {...extend(ButtonStructure.buttonText)} children={RenderProps(props)} />
            )}
          </>
        }
      />
      {RenderProps(props, 'nonClickable')}
    </div>
  );
}
