import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { compact, first } from 'lodash';
import React from 'react';
import { useVehicleMakeProvider } from '../VehicleMake.Hooks';
import MimeType from 'assets/utils/file/mimeType/MimeType';
import vehicleMakeImportExcelApi from 'models/productManagement/vehicleMake/importExcel/Api.VehicleMake.ImportExcel';
import { HttpStatus } from 'config/Api.Config';
import { addLog } from 'assets/components/feedback/Feedback';

type ImportExcelFile = Api.Core.VehicleMake.ImportExcel.IRequest & {
  file: Hooks.Input.File.Info;
};

const VehicleMakeImportExcel: React.FC = () => {
  const { lang } = getLocales();
  const { pageRouter, listApi } = useVehicleMakeProvider();

  return (
    <ModalForm<ImportExcelFile>
      subcomponents={ThemeModalFormDefault}
      title={lang.vehicleMakeImportExcel}
      onCancel={pageRouter.updateParams}
      onSubmit={async ({ file }) => {
        const res = await vehicleMakeImportExcelApi({ file: file.original });

        if (res.status !== HttpStatus.ok) addLog({ error: lang.importError });
        else addLog({ success: lang.importSuccess  });

        listApi.execute((body) => body);
      }}
      render={({ form }) => (
        <FormWrapper>
          <FileInput
            name="file"
            allowedMimeTypes={[MimeType.appMS_EXCEL, MimeType.appOPEN_XML_SHEET]}
            label={lang.vehicleMakeImportExcel}
            fileLimit={1}
            value={compact([form.data?.file])}
            onChange={({ file }) => {
              form.update({ file: first(file) });
            }}
            class={ThemeFileInputDropzone}
          />
        </FormWrapper>
      )}
    />
  );
};

export default VehicleMakeImportExcel;
