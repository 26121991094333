import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ContractConsent.Find.IRequest;
type Response = Api.Core.ContractConsent.Find.IResponse;
type Headers = Api.Core.ContractConsent.Find.IHeaders;
const url = '/api/Sales/ContractConsent/:id';
export default async function contractConsentFindApi(
  params: { id: number },
  access_token?: string
): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url: createPathFromRoute(url, params),
    headers: access_token ? { ...getDefaultHeaders(), Authorization: `Bearer ${access_token}` } : getDefaultHeaders(),
  });
}
