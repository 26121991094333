import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { combineStrings } from 'assets/utils/data/String';
import { find } from 'lodash';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import pricingDistributionTemplateSaveApi from 'models/productManagement/pricingDistributionTemplate/save/Api.PricingDistributionTemplate.Save';
import { useMemo } from 'react';
import { pricingDistributionTemplateIndexRoute } from './PricingDistributionTemplate.Index';
import Distributor from 'models/sales/distributor/Model.Distributor';

export const PRICING_DISTRIBUTION_TEMPLATE_SEPARATOR = '.';

type Form = Hooks.Form.Export<Api.Core.PricingDistributionTemplate.Save.IRequest>;

export enum PricingDistributionTemplateAction {
  EDIT = 'edit',
}
export type VerifyPricingDistributionTemplateAction = Utils.VerifyExtends<
  Module.ProductManagement.PricingDistributionTemplate.Actions,
  Utils.ValueOf<typeof PricingDistributionTemplateAction>
>;
export default function usePricingDistributionTemplate() {
  const { permissions, user, isDealerOrDistributor } = useAuthStore();
  const { lang } = getLocales();
  const pageRouter = usePageRouter<
    Module.ProductManagement.PricingDistributionTemplate.Params,
    Module.ProductManagement.PricingDistributionTemplate.Query
  >({ route: pricingDistributionTemplateIndexRoute });

  const canView =
    permissions.CREATE_PRICINGDISTRIBUTIONTEMPLATE ||
    permissions.EDIT_PRICINGDISTRIBUTIONTEMPLATE ||
    permissions.DELETE_PRICINGDISTRIBUTIONTEMPLATE;
  const listApi = useApi({
    action: PricingDistributionTemplate.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
    body: !user?.distributorId
      ? null
      : {
          distributorId: user?.distributorId,
        },
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const saveApi = useApi({
    action: pricingDistributionTemplateSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const onAdd = (path: string, form: Form) => {
    const distributions = form.data.keys || [];
    const newPath = combineStrings(PRICING_DISTRIBUTION_TEMPLATE_SEPARATOR, path, 'TEMP KEY');
    const lastTempKey = find(distributions, (key) => new RegExp(`^${path || newPath}$`, 'g').test(key));

    if (lastTempKey) {
      form.update({
        keys: [...distributions.filter((d) => d !== lastTempKey), newPath],
      });
    } else {
      form.update({
        keys: [...distributions, newPath],
      });
    }
  };
  const form = useForm<Api.Core.PricingDistributionTemplate.Save.IRequest>(
    {
      default:
        selected?.toJSON() ??
        (!user?.distributorId
          ? {}
          : {
              distributorId: user?.distributorId,
            }),
      onSubmit: (data) => saveApi.execute(data),
      validation: (data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
      },
    },
    [selected]
  );
  const distributorsApi = useApi({
    action: Distributor.list,
    wait: isDealerOrDistributor,
  });
  return {
    canView,
    form,
    onAdd,
    saveApi,
    selected,
    pageRouter,
    listApi,
    distributorsApi,
    permissions,
    views: {
      [PricingDistributionTemplateAction.EDIT]:
        pageRouter.params.action === PricingDistributionTemplateAction.EDIT &&
        ((permissions.CREATE_PRICINGDISTRIBUTIONTEMPLATE && !selected) ||
          (permissions.EDIT_PRICINGDISTRIBUTIONTEMPLATE && selected)),
    },
  };
}

export function usePricingDistributionTemplateProvider() {
  return useDataProvider<ReturnType<typeof usePricingDistributionTemplate>>();
}
