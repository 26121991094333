enum ClaimCreationType {
  PUBLIC = 'Public link',
  ASC = 'ASC app',
}

enum ClaimCreatorOptions {
  DEALERSHIP = 'DEALERSHIP',
  REPAIR_FACILITY = 'REPAIR_FACILITY',
  CUSTOMER = 'CUSTOMER',
  OTHER = 'OTHER',
}

export { ClaimCreationType, ClaimCreatorOptions };
