import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useProductProvider } from '../../Product.Hooks';

export default function ProductEditDocumentTemplates() {
  const { lang } = getLocales();
  const { form } = useProductProvider();
  const [currentTemplate, setTemplate] = useState<Partial<Model.IProductDocumentTemplate>>();
  function updatePartialDocumentTemplate(template: Partial<Model.IProductDocumentTemplate>) {
    setTemplate((old) => ({ ...old, ...template }));
  }
  function updateForm() {
    const currentTemplates = cloneDeep(form.data.productDocumentTemplates) ?? [];
    const index = currentTemplates.findIndex((it) => it.id === currentTemplate.id);
    if (index < 0) {
      form.update({ productDocumentTemplates: [...currentTemplates, currentTemplate] });
    } else {
      currentTemplates.splice(index, 1, currentTemplate);
      form.update({ productDocumentTemplates: currentTemplates });
    }
    setTemplate(null);
  }
  function deleteTemplate(templateId: number) {
    form.update({ productDocumentTemplates: form.data.productDocumentTemplates?.filter((it) => it.id !== templateId) });
  }
  return (
    <>
      <Collection class={ThemeCollectionTable}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              fontSize: Theme.FontSize.L,
              fontWeight: 'bold',
            }}
          >
            {lang.documentTemplates}
          </span>
          <Button
            class={ThemeButtonCircle}
            render={lang.addNew}
            active
            data={{
              id: -1 * (form.data.productDocumentTemplates?.length || 0),
              allowMultiple: false,
              required: false,
              allowNotes: false,
            }}
            onClick={setTemplate}
            htmlElementProps={{ title: lang.addNew }}
          />
        </div>
        <DataMap
          data={form.data?.productDocumentTemplates}
          render={({ data: document }) => (
            <Card
              class={ThemeCardRow}
              media={<Icon class="far-file-alt" />}
              key={document.id}
              info={document.source}
              title={document.name}
              subtitle={document.description}
              actions={
                <>
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class={document.id === currentTemplate?.id ? 'fas-ban' : 'fas-edit'} />}
                    onClick={setTemplate}
                    data={document.id === currentTemplate?.id ? null : document}
                    htmlElementProps={{ title: document.id === currentTemplate?.id ? lang.ban : lang.edit }}
                  />
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    disabled={document.id === currentTemplate?.id}
                    onClick={deleteTemplate}
                    data={document.id}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </>
              }
            />
          )}
        />
        {currentTemplate && (
          <FormWrapper>
            <TextInput
              name="name"
              label={lang.name}
              value={currentTemplate.name}
              onChange={updatePartialDocumentTemplate}
              icon={<Icon class="if-quill-pen" />}
              error={!currentTemplate.name ? lang.mustNotBeEmpty : undefined}
            />
            <MultilineTextInput
              name="description"
              label={lang.description}
              value={currentTemplate.description}
              onChange={updatePartialDocumentTemplate}
              icon={<Icon class="if-quill-pen" />}
            />
            <InputWrapper>
              <TextInput
                name="source"
                label={lang.source}
                value={currentTemplate.source}
                onChange={updatePartialDocumentTemplate}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="example"
                label={lang.example}
                value={currentTemplate.example}
                onChange={updatePartialDocumentTemplate}
                icon={<Icon class="if-quill-pen" />}
              />
            </InputWrapper>
            <CheckWrapper>
              <CheckInput
                name="allowMultiple"
                label={lang.allowMultiple}
                class={inputStyles.check.switch}
                value={currentTemplate.allowMultiple}
                onChange={updatePartialDocumentTemplate}
              />
              <CheckInput
                name="required"
                label={lang.required}
                class={inputStyles.check.switch}
                value={currentTemplate.required}
                onChange={updatePartialDocumentTemplate}
              />
              <CheckInput
                name="allowNotes"
                label={lang.allowNotes}
                class={inputStyles.check.switch}
                value={currentTemplate.allowNotes}
                onChange={updatePartialDocumentTemplate}
              />
            </CheckWrapper>
            <br />
            <Button
              class={ThemeButtonCircle}
              render={lang.save}
              active
              disabled={!currentTemplate.name}
              onClick={updateForm}
              htmlElementProps={{ title: lang.save }}
            />
          </FormWrapper>
        )}
      </Collection>
    </>
  );
}
