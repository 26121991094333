import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import { isImageUrl, isIcon } from 'assets/utils/parsersAndValidation/Validators';
import Icon from '../../icon/Icon';
import './RowImage.scss';

export enum RowImageStructure {
  rowImage = 'rowImage',
  subImage = 'subImage',
}
export type Verify = Utils.VerifyExtends<Component.RowImage.Structure, typeof RowImageStructure>;
export default function RowImage(props: Component.RowImage.Import) {
  const extend = useTheme(RowImageStructure, props, {
    style: {
      backgroundImage: props.url && isImageUrl(props.url) ? `url(${props.url})` : undefined,
    },
  });

  return (
    <div {...extend(RowImageStructure.rowImage, true)}>
      {props.subImage && <div {...extend(RowImageStructure.subImage, false)}>{RenderProps(props, 'subImage')}</div>}
      {props.url && isIcon(props.url) ? <Icon class={props.url} /> : !props.url && RenderProps(props, 'render')}
    </div>
  );
}
