import { inputStyles } from 'assets/components/inputs/Input';
import { getLocales } from 'assets/locales/Locale';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';
import Icon, { iconStyles } from '../../icon/Icon';
import CheckInput from '../../inputs/check/CheckInput';
import TextInput from '../../inputs/text/TextInput';
import { textBoxStyles } from '../../textBox/TextBox';
import Modal from './Modal';

export enum ConfirmationType {
  NEXT_BUTTON = 'nextButton',
  CHECKBOX = 'checkbox',
  FOUR_DIGITS = 'fourDigits',
}
export default function Confirm<T>(props: Template.Confirm.Import<T>) {
  const { lang } = getLocales();
  const [isValid, setIsValid] = useState(props.confirmationType == ConfirmationType.NEXT_BUTTON);
  const [val, setVal] = useState<any>();
  const [validityCheck] = useState(Math.floor(1000 + Math.random() * 9000).toString());

  useEffect(() => {
    switch (props.confirmationType) {
      case ConfirmationType.CHECKBOX:
        setIsValid(val ? true : false);
        break;
      case ConfirmationType.FOUR_DIGITS:
        setIsValid(validityCheck === val);
        break;
    }
  }, [val]);
  return (
    <Modal
      subcomponents={props.subcomponents && props.subcomponents.modal}
      onClose={props.onClose}
      header={lang.userConfirmationRequired}
      render={
        <>
          <span
            children={
              <>
                <b>{typeof props.actionName === 'string' ? _.startCase(props.actionName) : props.actionName}</b>{' '}
                {props.itemName && <b>{props.itemName}</b>}?&nbsp;
                {props.info && (
                  <>
                    <br />
                    <span
                      className={textBoxStyles.layout.infoLabel}
                      children={
                        <>
                          <Icon class={iconStyles.common.info} />
                          &nbsp;
                          {props.info}
                        </>
                      }
                    />
                  </>
                )}
                {props.confirmationType == ConfirmationType.CHECKBOX && lang.confirmByCheckbox}
                {props.confirmationType == ConfirmationType.FOUR_DIGITS && lang.confirmByFourDigits}
              </>
            }
          />
          {props.confirmationType == ConfirmationType.FOUR_DIGITS && <b children={validityCheck} />}
          {props.confirmationType == ConfirmationType.FOUR_DIGITS && (
            <TextInput
              placeholder={lang.writeHere}
              name="confirm"
              onChange={({ confirm }) => setVal(confirm)}
              value={val}
            />
          )}
          {props.confirmationType == ConfirmationType.CHECKBOX && (
            <CheckInput
              name="confirm"
              class={inputStyles.check.checkbox}
              onChange={({ confirm }) => setVal(confirm)}
              value={val}
            />
          )}
        </>
      }
      footer={
        <>
          <Button
            {...(props.subcomponents && props.subcomponents.button)}
            onClick={props.onConfirm}
            data={{ ...(props.data || ({} as T)), confirmation: val }}
            disabled={!isValid || null}
            render={lang.confirm}
          />
        </>
      }
    />
  );
}
