import { getLocales } from 'assets/locales/Locale';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { useVehicleClassProvider } from '../VehicleClass.Hooks';
import { orderBy } from 'lodash';
import { combineStrings } from 'assets/utils/data/String';
import DataMap from 'assets/components/dataMap/DataMap';
import '../style/print.scss';

export default function VehicleClassPrint() {
  const { lang } = getLocales();
  const { listApi } = useVehicleClassProvider();
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    documentTitle: lang.vehicleClass,
  });

  useEffect(() => {
    handlePrint();
    history.goBack();
  }),
    [];

  return (
    listApi.payload && (
      <div id="printVehicleClass" ref={componentRef}>
        <style>{`@page {size: a4 portret; margin: 1cm;}`}</style>
        <h1>{lang.vehicleClass}</h1>
        <table>
          <DataMap
            data={listApi.payload?.data}
            render={({ data: vehicleClass }) => (
              <>
                <tr className="print_bacground_darker print_never_break">
                  <td className="print_row_data_title">{`${vehicleClass.primaryCode} - ${vehicleClass.secondaryCode}`}</td>
                </tr>
                <tr>
                  <td className="print_row_data_info">
                    {vehicleClass.vehicleModels.length > 0
                      ? orderBy(vehicleClass.vehicleModels, ['vehicleMake.makeCode', 'name'], ['asc', 'asc'])
                          .map((it) => combineStrings(' ', it.vehicleMake?.makeCode, it.name))
                          .join(', ')
                      : 'n/a'}
                  </td>
                </tr>
              </>
            )}
          />
        </table>
      </div>
    )
  );
}
