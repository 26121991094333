import { addLog } from 'assets/components/feedback/Feedback';
import freshworksIdentifyUser from 'assets/integrations/freshworks/Freshworks.IdentifyUser';
import { defaultLanguage, getLocales, setLanguage } from 'assets/locales/Locale';
import { date } from 'assets/utils/data/Date';
import { isEmpty } from 'assets/utils/parsersAndValidation/Validators';
import { setAuthToken } from 'config/Auth.Config';
import dayjs from 'dayjs';
import { first, get } from 'lodash';
import accountInfoApi from 'models/account/info/Api.Account.Info';
import User from 'models/core/user/Model.User';
import { useEffect, useMemo, useState } from 'react';

export enum Roles {
  administrator = 1,
}
const authInfoSessionKey = 'auth_session';
export default function useAuthInfo(props: Hooks.AuthInfo.Import) {
  const { lang } = getLocales();
  const [state, setState] = useState<Hooks.AuthInfo.IState>(() => {
    try {
      const sessionAuthInfo: Hooks.AuthInfo.IState = JSON.parse(localStorage.getItem(authInfoSessionKey));
      const expirationDate = sessionAuthInfo.expirationTimestamp && dayjs.unix(sessionAuthInfo.expirationTimestamp);

      if (expirationDate && date().isAfter(expirationDate)) return {};
      else return sessionAuthInfo;
    } catch {
      return {};
    }
  });
  async function fetchUser() {
    const { payload: user } = await accountInfoApi();
    const dealer = user?.dealers?.find((d) => d.id === state.dealerId) ?? first(user?.dealers);
    if (dealer) {
      setState((old) => ({ ...old, dealerId: dealer?.id ?? null }));
    }
    setUser(user && new User(user));
  }
  const [user, setUser] = useState<User>(null);
  useEffect(() => {
    if (state.userId) fetchUser();
    else setUser(null);
  }, [state.userId]);

  function updateDealer(dealerId: number) {
    const dealer = user?.dealers?.find((d) => d.id === dealerId) ?? first(user?.dealers);
    if (dealer) {
      localStorage.setItem(authInfoSessionKey, JSON.stringify({ ...state, dealerId }));
      window.location.reload();
    }
  }

  function updateAuthInfo(tokenUnpacked: Hooks.AuthInfo.TokenUnpacked) {
    const { token, language, ...authInfo } = tokenUnpacked;
    if (token && authInfo) {
      setAuthToken(token);
      setLanguage(language || defaultLanguage);
      setState((oldState) => authInfo || oldState);
    }
  }
  async function refreshToken() {
    if (props.refreshToken) updateAuthInfo(await props.refreshToken(state));
  }
  useEffect(() => {
    localStorage.setItem(authInfoSessionKey, JSON.stringify(state));
    if (state.expirationTimestamp) {
      const currentTimestamp = date().unix();
      const timeout = setTimeout(() => {
        setState({});
        addLog({ warning: lang.sessionHasTimedOut });
      }, (state.expirationTimestamp - currentTimestamp) * 1000);
      return () => clearTimeout(timeout);
    } else if (isEmpty(state) && props.refreshToken) {
      refreshToken();
    }
  }, [state]);

  async function login(userLoginInfo: Hooks.AuthInfo.LoginInfo) {
    if (props.login) {
      const tokenUnpacked = await props.login(userLoginInfo);
      if (tokenUnpacked.displayname && tokenUnpacked.email) {
        freshworksIdentifyUser(tokenUnpacked.displayname, tokenUnpacked.email);
      }
      updateAuthInfo(tokenUnpacked);
      if (!tokenUnpacked?.userId) addLog({ error: lang.invalidUsernameOrPassword });
      return tokenUnpacked?.userId ? true : false;
    }
    return false;
  }
  function logout() {
    localStorage.removeItem(authInfoSessionKey);
    setAuthToken();
    window.location.reload();
  }
  function hasAccess(...permission: Auth.Permission[]) {
    if (Array.isArray(permission)) return permission.find((it) => get(state.permissions, it)) ? true : false;
    else return state.permissions[permission];
  }

  const currentPermissions = useMemo(
    () => Object.keys(state.permissions || {}).filter((p) => state.permissions[p]) as Auth.Permission[],
    [state.permissions]
  );
  const { appName, uiSettings, logo } = useMemo(() => {
    const currentDealer = user?.dealers?.find((d) => d.id === state.dealerId);
    return currentDealer
      ? {
          appName: currentDealer.name,
          uiSettings: currentDealer?.uiSettings ?? currentDealer?.dealerGroup?.uiSettings,
          logo: currentDealer?.uiSettingsFile?.url ?? currentDealer?.dealerGroup?.uiSettingsFile?.url,
        }
      : { appName: null, uiSettings: null, logo: null };
  }, [user]);
  const isDealer = !!user?.dealers?.length;
  const isDistributor = (!!user?.distributor || !!user?.distributorId) && !user?.dealers?.length;
  const isDealerOrDistributor = isDealer || isDistributor;
  const isAdministrator = user?.roles?.find((r) => r.id === Roles.administrator);

  return {
    ...state,
    isDealer,
    isDistributor,
    isDealerOrDistributor,
    isAdministrator,
    appName,
    uiSettings,
    logo,
    user,
    login,
    logout,
    hasAccess,
    refreshToken,
    fetchUser,
    updateDealer,
    currentPermissions,
  };
}
