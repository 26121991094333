import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import productPricingPlanSaveApi from 'models/productManagement/productPricingPlan/save/Api.ProductPricingPlan.Save';
import { useWarrantyClassPricingProvider } from '../WarrantyClassPricing.Hooks';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import WarrantyOptions from 'models/enums/WarrantyOptions';

export default function WarrantyClassPricingEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, listApi, productApi, setEditableId, provinceGroup } = useWarrantyClassPricingProvider();

  return (
    <>
      <ModalForm<Api.Core.ProductPricingPlan.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.product}
        default={selected?.toJSON() || { productId: productApi.payload?.id, productProvinceGroupId: provinceGroup?.id }}
        onCancel={() => setEditableId(null)}
        onSubmit={async (data) => {
          if (await productPricingPlanSaveApi(data)) {
            listApi.execute((body) => body);
            setEditableId(null);
            pageRouter.updateParams({
              productId: pageRouter.params.productId,
              productProvinceGroupId: pageRouter.params.productProvinceGroupId,
            });
          }
        }}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="primaryDescription"
                label={lang.description}
                value={form.data.primaryDescription}
                error={form.errors.primaryDescription}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="secondaryDescription"
                label={lang.secondaryDescription}
                value={form.data.secondaryDescription}
                error={form.errors.secondaryDescription}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <CheckInput
                class={inputStyles.check.switch}
                name="isForNewVehicle"
                label={lang.isForNewVehicle}
                value={form.data.isForNewVehicle}
                error={form.errors.isForNewVehicle}
                onChange={form.update}
              />
              <InputWrapper>
                <NumberInput
                  name="minOdometerKm"
                  label={lang.minOdometer}
                  value={form.data.minOdometerKm}
                  error={form.errors.minOdometerKm}
                  onChange={form.update}
                />
                <NumberInput
                  name="maxOdometerKm"
                  label={lang.maxOdometer}
                  value={form.data.maxOdometerKm}
                  error={form.errors.maxOdometerKm}
                  onChange={form.update}
                />
              </InputWrapper>
              <InputWrapper>
                <NumberInput
                  name="minPriceCAD"
                  label={lang.minPrice}
                  value={form.data.minPriceCAD}
                  error={form.errors.minPriceCAD}
                  onChange={form.update}
                />
                <NumberInput
                  name="maxPriceCAD"
                  label={lang.maxPrice}
                  value={form.data.maxPriceCAD}
                  error={form.errors.maxPriceCAD}
                  onChange={form.update}
                />
              </InputWrapper>
              <SelectInput
                name="warrantyOption"
                label={lang.warrantyOptions}
                error={form.errors.warrantyOption}
                data={Object.values(WarrantyOptions).map((it) => ({
                  id: it,
                  title: it,
                }))}
                value={form.data.warrantyOption}
                onChange={form.update}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
