import { addLog } from 'assets/components/feedback/Feedback';
import { getLocales } from 'assets/locales/Locale';
import { cleanValues, flattenKeys, hasEmptyFields } from 'assets/utils/data/Object';
import { mapValues, merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export default function useForm<T>(props: Hooks.Form.Import<T>, dependencyArray: any[] = []): Hooks.Form.Export<T> {
  const { lang } = getLocales();
  const [state, setState] = useState<Hooks.Form.IState<T>>({
    data: props.default,
    isUpdated: props.isUpdatedByDefault || false,
    isSubmitted: false,
  });
  const [errors, hasErrors] = useMemo(() => {
    const err: Hooks.Form.Errors<T> = {};
    if (props.validation) props.validation(state.data, err);
    const hasErr = !hasEmptyFields(flattenKeys(err));
    return [err, hasErr];
  }, [props.validation, state.data]);

  useEffect(() => {
    if (dependencyArray.length > 0) reload();
  }, dependencyArray);

  useEffect(() => {
    if (state.isSubmitted && props.onSubmit && !hasErrors) props.onSubmit(state.data as T);
  }, [state.isSubmitted, hasErrors]);

  useEffect(() => {
    if (state.isUpdated && props.onUpdate) props.onUpdate(state.data);
  }, [state]);

  function submit() {
    if (!hasErrors && state.isUpdated) {
      setState((currState) => ({ ...currState, isSubmitted: true }));
      return state.data;
    } else if (hasErrors) {
      addLog({ error: lang.invalidForm });
      return undefined;
    } else {
      addLog({ warning: lang.noChangesWereMade });
      return undefined;
    }
  }
  function reload() {
    setState({
      data: props.default,
      isUpdated: false,
      isSubmitted: false,
    });
  }

  function update(newValues: Partial<T>) {
    setState((currState) => {
      const dataToUpdate = { ...currState.data, ...newValues };
      const finalValues = props.beforeUpdate
        ? props.beforeUpdate(dataToUpdate, mapValues(newValues, () => true) as any)
        : dataToUpdate;
      if (!finalValues) return currState;
      else
        return {
          data: cleanValues<Partial<T>>(finalValues),
          isUpdated: true,
          isSubmitted: false,
        };
    });
  }

  function set(newValues: Partial<T>) {
    setState((currState) => {
      const dataToUpdate = newValues;
      const finalValues = props.beforeUpdate
        ? props.beforeUpdate(dataToUpdate, mapValues(newValues, () => true) as any)
        : dataToUpdate;
      if (!finalValues) return currState;
      else
        return {
          data: cleanValues<Partial<T>>(finalValues),
          isUpdated: true,
          isSubmitted: false,
        };
    });
  }
  function _merge(newValues: Utils.FormData<T>) {
    update(merge(state.data, newValues));
  }
  return {
    ...state,
    submit,
    reload,
    update,
    set,
    merge: _merge,
    errors,
    hasErrors,
  };
}
