import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Account.Login.IRequest;
type Response = Api.Core.Account.Login.IResponse;
type Headers = Api.Core.Account.Login.IHeaders;
const apiAccountLoginPath = '/api/Account/Login';
export default async function accountLoginApi(request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: apiAccountLoginPath,
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
