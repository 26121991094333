import Collection from 'assets/components/data/Collection';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';
import ClaimTypeEditInfoInputsList from './reportSettings/ClaimType.Edit.InfoInputList';
import ClaimTypeEditReportRowList from './reportSettings/ClaimType.Edit.ReportRowList';

const wrapperStyle: React.CSSProperties = { paddingLeft: Theme.Size.L, paddingRight: Theme.Size.L };
const wrapperStyle2: React.CSSProperties = { padding: Theme.Size.L };
export default function ClaimTypeEditReportSettings() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const claimTypeReportSettings = form.data.claimTypeReportSettings?.length
    ? form.data.claimTypeReportSettings
    : [
        {
          reportRows: [],
          infoInputs: [],
        },
      ];
  const report = claimTypeReportSettings && claimTypeReportSettings[0];

  return (
    <>
      <Collection>
        <div style={wrapperStyle2}>
          <TextInput
            name="name"
            label={lang.report}
            value={report?.name}
            onChange={({ name }) => {
              report.name = name;
              form.update({ claimTypeReportSettings });
            }}
            error={form.errors?.claimTypeReportSettings?.name}
          />
        </div>
        <hr style={{ borderColor: Theme.Color.shadow }} />
        <h2 style={wrapperStyle}>{lang.info}</h2>
        <ClaimTypeEditInfoInputsList />
        <hr style={{ borderColor: Theme.Color.shadow }} />
        <h2 style={wrapperStyle}>{lang.calculation}</h2>
        <ClaimTypeEditReportRowList />
      </Collection>
    </>
  );
}
