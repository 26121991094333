import ValidationRegex from '../data/Utils.Data.ValidationRegex';
import MimeType from '../file/mimeType/MimeType';

export function isEmail(email?: string): boolean {
  if (!email) return false;
  return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g.test(
    email
  );
}
export function isEmailDomain(email: string, domain?: string): boolean {
  if (!domain) return false;
  return new RegExp(`^[^@\\s]+@${domain.replace(/\./g, '\\.')}$`, 'i').test(email);
}

export function isEmpty(data?: string | object): boolean {
  if (typeof data == 'string') return !data || !data.trim();
  else if (typeof data == 'object' && data)
    return Object.keys(data).length == 0 || Object.values(data).reduce((final, curr) => final + (curr ? 1 : 0), 0) == 0;
  else return false;
}
export function isImageUrl(url?: string): boolean {
  if (!url) return false;
  else return /([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/g.test(url);
}
export function isUrl(url?: string) {
  if (!url) return false;
  else
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g.test(
      url
    );
}
export function isDomain(url?: string): boolean {
  if (!url) return false;
  else return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/g.test(url);
}
export function isRelativePath(url?: string): boolean {
  if (!url) return false;
  else return /^\/\w+/g.test(url);
}
export function isIcon(code?: string): boolean {
  if (!code) return false;
  else if (/^(fa|if|ti)/g.test(code)) return true;
  else return /^(&#x)?\w+;\w+$/g.test(code);
}
export function isNumberWithMeasurementUnit(code?: string): boolean {
  if (!code) return false;
  else return /^\d+\.\d+(\w+|\$)$/g.test(code);
}

export function isValidValue(val: any): boolean {
  if (val === 0 || (typeof val == 'object' && val)) return true;
  else
    return (
      val !== undefined && val !== null && val.toString().trim() !== '' && !(val.toString() == 'NaN' && isNaN(val))
    );
}

export function isValidPhoneNumber(phoneNumber?: string): boolean {
  if (!phoneNumber) return false;
  else return ValidationRegex.phoneNumber.test(phoneNumber);
}

export function hasErrors(val: any): boolean {
  return typeof val == 'object' && val && (val as any).error;
}
export function isMimeType(requiredMimeTypes?: Utils.File.MimeType[], mimeType?: Utils.File.MimeType) {
  if (!requiredMimeTypes || !mimeType) return false;
  return requiredMimeTypes
    ? mimeType &&
      requiredMimeTypes.find((mt) =>
        mimeType && (mt === mimeType || (/\/\*$/g.test(mt) && mt.split('/')[0] === mimeType.split('/')[0]))
          ? true
          : false
      )
      ? true
      : false
    : true;
}
export function getFileExtensionFromFileName(fileName: string) {
  const parts = fileName?.split('.') ?? [];
  return parts.length > 1 ? parts[parts.length - 1] : fileName;
}
export function getMimeTypeFromFileName(fileName: string) {
  const extension = getFileExtensionFromFileName(fileName);
  switch (extension) {
    case 'txt':
      return MimeType.textPLAIN;
    case 'js':
      return MimeType.textJS;
    case 'svg':
      return MimeType.imageSVG;
    case 'doc':
      return MimeType.appMS_WORD;
    case 'docx':
      return MimeType.appMS_WORD;
    case 'xls':
      return MimeType.appMS_EXCEL;
    case 'xlsx':
      return MimeType.appMS_EXCEL;
    case 'ppt':
      return MimeType.appMS_POWERPOINT;
    case 'pptx':
      return MimeType.appMS_POWERPOINT;
    default:
      return extension ? Object.values(MimeType).find((it) => it.indexOf(extension) > 0) : null;
  }
}

export function hasKey<T>(obj: T, key: Utils.KeyOf<T>) {
  return Object.keys(obj).find((it) => it == key) ? true : false;
}

export function isStringNumber(text: string): boolean {
  if (text === null || text === undefined) return false;
  if (text === '') return true;
  else return /^[1-9]\d*$/.test(text);
}
