import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Product from '../product/Model.Product';
import productPricingPlanDeleteApi from './delete/Api.ProductPricingPlan.Delete';
import productPricingPlanListApi from './list/Api.ProductPricingPlan.List';
import productPricingPlanSaveApi from './save/Api.ProductPricingPlan.Save';

export default class ProductPricingPlan
  extends Model<Model.IProductPricingPlan>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductPricingPlan>
{
  id: number;
  name: string;
  primaryDescription?: string;
  secondaryDescription?: string;
  maxOdometerKm: number;
  minOdometerKm: number;
  maxPriceCAD: number;
  minPriceCAD: number;
  productId?: number;
  product?: Product;
  isForNewVehicle?: boolean;
  productProvinceGroupId?: number;
  warrantyOption?: string;

  constructor(
    data: Partial<Model.IProductPricingPlan> | Utils.FormData<Model.IProductPricingPlan>,
    language?: Locale.Languages
  ) {
    super({ product: Product }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      info: combineStrings(' - ', this.minOdometerKm.toFixed(2), this.maxOdometerKm.toFixed(2)),
      title: this.name || this.id.toString(),
      subtitle: this.primaryDescription,
    };
  }

  static async list(body?: Api.Core.ProductPricingPlan.List.IRequest) {
    const { payload, ...rest } = await productPricingPlanListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductPricingPlan(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await productPricingPlanSaveApi(this);
  }
  async delete() {
    return await productPricingPlanDeleteApi(this.id);
  }
}
