export type Verify = Utils.VerifyExtends<Effect.Animation, Utils.ValueOf<typeof AnimationVariable>>;
enum AnimationVariable {
  bounce = 'bounce',
  flash = 'flash',
  pulse = 'pulse',
  rubberBand = 'rubberBand',
  shake = 'shake',
  headShake = 'headShake',
  swing = 'swing',
  tada = 'tada',
  wobble = 'wobble',
  jello = 'jello',
  heartBeat = 'heartBeat',
  bounceIn = 'bounceIn',
  bounceInDown = 'bounceInDown',
  bounceInLeft = 'bounceInLeft',
  bounceInRight = 'bounceInRight',
  bounceInUp = 'bounceInUp',
  bounceOut = 'bounceOut',
  bounceOutDown = 'bounceOutDown',
  bounceOutLeft = 'bounceOutLeft',
  bounceOutRight = 'bounceOutRight',
  bounceOutUp = 'bounceOutUp',
  fadeInDown = 'fadeInDown',
  fadeInDownBig = 'fadeInDownBig',
  fadeInLeft = 'fadeInLeft',
  fadeInLeftBig = 'fadeInLeftBig',
  fadeInRight = 'fadeInRight',
  fadeInRightBig = 'fadeInRightBig',
  fadeInUp = 'fadeInUp',
  fadeInUpBig = 'fadeInUpBig',
  fadeOutDown = 'fadeOutDown',
  fadeOutDownBig = 'fadeOutDownBig',
  fadeOutLeft = 'fadeOutLeft',
  fadeOutLeftBig = 'fadeOutLeftBig',
  fadeOutRight = 'fadeOutRight',
  fadeOutRightBig = 'fadeOutRightBig',
  fadeOutUp = 'fadeOutUp',
  fadeOutUpBig = 'fadeOutUpBig',
  flip = 'flip',
  flipInX = 'flipInX',
  flipInY = 'flipInY',
  flipOutX = 'flipOutX',
  flipOutY = 'flipOutY',
  lightSpeedIn = 'lightSpeedIn',
  lightSpeedOut = 'lightSpeedOut',
  rotateIn = 'rotateIn',
  rotateInDownLeft = 'rotateInDownLeft',
  rotateInDownRight = 'rotateInDownRight',
  rotateInUpLeft = 'rotateInUpLeft',
  rotateInUpRight = 'rotateInUpRight',
  rotateOut = 'rotateOut',
  rotateOutDownLeft = 'rotateOutDownLeft',
  rotateOutDownRight = 'rotateOutDownRight',
  rotateOutUpLeft = 'rotateOutUpLeft',
  rotateOutUpRight = 'rotateOutUpRight',
  hinge = 'hinge',
  jackInTheBox = 'jackInTheBox',
  rollIn = 'rollIn',
  rollOut = 'rollOut',
  zoomInDown = 'zoomInDown',
  zoomInLeft = 'zoomInLeft',
  zoomInRight = 'zoomInRight',
  zoomInUp = 'zoomInUp',
  zoomOutDown = 'zoomOutDown',
  zoomOutLeft = 'zoomOutLeft',
  zoomOutRight = 'zoomOutRight',
  zoomOutUp = 'zoomOutUp',
  slideInDown = 'slideInDown',
  slideInUp = 'slideInUp',
  slideOutDown = 'slideOutDown',
  slideOutUp = 'slideOutUp',
  fadeIn = 'fadeIn',
  fadeOut = 'fadeOut',
  slideInLeft = 'slideInLeft',
  slideInRight = 'slideInRight',
  slideOutLeft = 'slideOutLeft',
  slideOutRight = 'slideOutRight',
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
}
export default AnimationVariable;
