import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.ClaimJobDocument.Save.IRequest;
type Response = Api.Core.ClaimJobDocument.Save.IResponse;
type Headers = Api.Core.ClaimJobDocument.Save.IHeaders;
const url = '/api/Claims/ClaimJob/:claimJobId/Documents';
export default async function claimJobDocumentSaveApi(body?: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { claimJobId: body?.claimJobId }),
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
