import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ClaimTypeReportSettings, {
  ClaimTypeReportRow,
} from 'models/claims/claimTypeReportSettings/Model.ClaimTypeReportSettings';
import React, { Fragment, useState } from 'react';
import ClaimTypeEditReportRowForm from './ClaimType.Edit.ReportRowForm';

export default function ClaimTypeEditReportRowList() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const claimTypeReportSettings = form.data.claimTypeReportSettings?.length
    ? form.data.claimTypeReportSettings
    : [
        {
          reportRows: [],
          infoInputs: [],
        },
      ];
  const report = claimTypeReportSettings && claimTypeReportSettings[0];
  const rowsForMapping = ClaimTypeReportSettings.reportRowsForMapping(report?.reportRows);

  const [activeIndex, setActiveIndex] = useState<number>();
  function addNew(data: Utils.FormData<Model.IClaimTypeReportRow>) {
    report.reportRows.push(data);
    form.update({ claimTypeReportSettings });
    setActiveIndex(null);
  }
  function deleteAt(index: number) {
    report.reportRows.splice(index, 1);
    form.update({ claimTypeReportSettings });
  }
  function updateAt(data: Utils.FormData<Model.IClaimTypeReportRow>, index: number) {
    report.reportRows[index] = data;
    form.update({ claimTypeReportSettings });
    setActiveIndex(null);
  }
  return (
    <>
      <Collection>
        <DataMap
          data={report?.reportRows}
          render={({ data: row, index }) => (
            <Fragment key={`${index}-${row.key || ''}`}>
              <Card
                class={ThemeCardRow}
                media={<Icon class={ClaimTypeReportRow.getIcon(row.summationOperation)} />}
                info={row.key}
                title={`#${index + 1} ${row.name?.en}`}
                subtitle={
                  <>
                    {Array.isArray(row.primaryValue)
                      ? `${lang.sumValues} (${row.primaryValue
                          .map((pv) => rowsForMapping.find((it) => it.id === pv)?.title)
                          .join(', ')})`
                      : typeof row.primaryValue == 'string'
                      ? rowsForMapping.find((it) => it.id === row.primaryValue)?.title
                      : lang.askForInput}
                    {row.totalAmountOperation && (
                      <>
                        &nbsp;
                        <Icon class={ClaimTypeReportRow.getIcon(row.totalAmountOperation)} />
                        &nbsp;
                        {lang.askForInput}
                      </>
                    )}
                  </>
                }
                actions={
                  <>
                    <Button
                      class={{ button: ThemeButtonCircle }}
                      media="fas-chevron-up"
                      disabled={index === 0 || activeIndex === index}
                      onClick={() => {
                        const temp = report?.reportRows[index];
                        report.reportRows[index] = report.reportRows[index - 1];
                        report.reportRows[index - 1] = temp;
                        form.update({ claimTypeReportSettings });
                      }}
                      htmlElementProps={{ title: lang.moveUp }}
                    />
                    <Button
                      class={{ button: ThemeButtonCircle }}
                      media="fas-chevron-down"
                      disabled={index === report.reportRows.length || activeIndex === index}
                      onClick={() => {
                        const temp = report.reportRows[index];
                        report.reportRows[index] = report.reportRows[index + 1];
                        report.reportRows[index + 1] = temp;
                        form.update({ claimTypeReportSettings });
                      }}
                      htmlElementProps={{ title: lang.moveDown }}
                    />
                    <Button
                      class={{ button: ThemeButtonCircle }}
                      media={activeIndex === index ? 'fas-ban' : 'fas-edit'}
                      onClick={setActiveIndex}
                      data={index === activeIndex ? null : index}
                      htmlElementProps={{ title: activeIndex === index ? lang.ban : lang.edit }}
                    />
                    <Button
                      class={{ button: ThemeButtonCircle }}
                      media="fas-trash"
                      onClick={deleteAt}
                      data={index}
                      disabled={activeIndex === index}
                      htmlElementProps={{ title: lang.delete }}
                    />
                  </>
                }
              />
              {activeIndex === index && (
                <ClaimTypeEditReportRowForm
                  row={row}
                  rowsForMapping={rowsForMapping}
                  onSave={(data) => {
                    updateAt(data, index);
                  }}
                />
              )}
            </Fragment>
          )}
        />
        {activeIndex === -1 && <ClaimTypeEditReportRowForm row={{}} rowsForMapping={rowsForMapping} onSave={addNew} />}
        <Button
          class={{ button: ThemeButtonCircle }}
          media={activeIndex === -1 ? 'fas-ban' : 'fas-plus'}
          children={activeIndex === -1 ? lang.remove : lang.addNew}
          onClick={setActiveIndex}
          data={activeIndex === -1 ? null : -1}
          style={{
            margin: `${Theme.Size.M} ${Theme.Size.L}  ${Theme.Size.M} auto`,
          }}
        />
      </Collection>
    </>
  );
}
