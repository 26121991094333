import { intersection } from 'lodash';
import EmailTemplate from 'models/core/emailTemplate/Model.EmailTemplate';

export function getNextStatus(
  emailTemplates: EmailTemplate[],
  statusInfo: Model.ClaimStatusInfo,
  currentPermissions: Auth.Permission[],
  currentStatus?: Model.Enum.ClaimStatusCode
) {
  let nextStatusList: Model.NextClaimStatus[] = [];
  if (!statusInfo && !emailTemplates) return { nextStatusList };
  const currentStatusInfo = statusInfo && currentStatus && statusInfo[currentStatus];
  if (currentStatusInfo && intersection(currentStatusInfo.permissions, currentPermissions).length) {
    nextStatusList = currentStatusInfo.nextStatusList?.map((nextStatus) => {
      const { permissions } = statusInfo[nextStatus] || {};
      const [primary, secondary, reason] = nextStatus.split('_');
      return {
        nextStatus,
        statusInfo: { primary, secondary, reason },
        ownershipAllowed: intersection(permissions, currentPermissions).length > 0,
        emailTemplate: emailTemplates?.find((it) => it.claimStatus === nextStatus)?.toJSON(),
      };
    });
    return { currentStatusInfo, nextStatusList };
  }

  return { nextStatusList };
}
