import { useEffect } from 'react';

type Props<T> = {
  callback?: (data: T) => void;
  eventName: string;
  onNoListenersDispatch?: (data: T) => void;
};
type DispatchProps<T> = {
  callback?: (data: T) => void;
  data: T;
  onNoListenersDispatch?: (data: T) => void;
};
type CallbackProps<T> = {
  callback: (data: T) => void;
};
const CustomEventBuilder = <T>(props: Props<T>) => {
  let numOfListeners = 0;
  const dispatch = (dispatchProps: DispatchProps<T>) => {
    if (numOfListeners > 0) {
      document.dispatchEvent(new CustomEvent<T>(props.eventName, { detail: dispatchProps.data }));
      if (dispatchProps.callback) dispatchProps.callback(dispatchProps.data);
      if (props.callback) props.callback(dispatchProps.data);
    } else {
      if (dispatchProps.onNoListenersDispatch) dispatchProps.onNoListenersDispatch(dispatchProps.data);
      if (props.onNoListenersDispatch) props.onNoListenersDispatch(dispatchProps.data);
    }
  };
  const useEvent = (callbackProps: CallbackProps<T>, dependencyArray: unknown[] = []) => {
    useEffect(() => {
      const customEventCall = (customEvent: CustomEvent<T>) => {
        customEvent.stopPropagation();
        customEvent.preventDefault();
        callbackProps.callback(customEvent.detail);
      };
      numOfListeners += 1;
      // eslint-disable-next-line
      document.addEventListener(props.eventName as any, customEventCall as any, false);
      return () => {
        numOfListeners -= 1;
        // eslint-disable-next-line
        document.removeEventListener(props.eventName as any, customEventCall as any);
      };
    }, dependencyArray);
  };
  return {
    dispatch,
    eventName: props.eventName,
    useEvent,
  };
};
export default CustomEventBuilder;
