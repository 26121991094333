import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import { difference, set, values } from 'lodash';
import dealerDeleteFileApi from 'models/dealerManagement/dealer/deleteFile/Api.Dealer.DeleteFile';
import dealerDeleteUiFileApi from 'models/dealerManagement/dealer/deleteUiFile/Api.Dealer.DeleteUiFile';
import dealerSaveApi from 'models/dealerManagement/dealer/save/Api.Dealer.Save';
import dealerUploadFileApi from 'models/dealerManagement/dealer/uploadFile/Api.Dealer.UploadFile';
import dealerUploadProductDocumentApi from 'models/dealerManagement/dealer/uploadProductDocument/Api.Dealer.UploadProductDocument';
import dealerUploadUiFileApi from 'models/dealerManagement/dealer/uploadUiFile/Api.Dealer.UploadUiFile';
import {
  dealerDistributionDiscountDeleteApi,
  dealerDistributionDiscountSaveApi,
} from 'models/dealerManagement/dealerDistributionDiscount/Model.DealerDistributionDiscount';
import { useState } from 'react';
import { useDealerProvider } from '../Dealer.Hooks';
import DealerDistributionDiscountForm from './edit/Dealer.DistributionDiscountForm';
import DealerGeneralForm from './edit/Dealer.General';
import DealerProductDocumentForm from './edit/Dealer.ProductDocumentForm';
import DealerUiSettingsForm from './edit/Dealer.UiSettingsForm';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import dealerDeleteProductDocumentApi from 'models/dealerManagement/dealer/deleteProductDocument/Api.Dealer.DeleteProductDocument';

export type DealerForm = Omit<Api.Core.Dealer.Save.IRequest, 'productDealerDocuments'> & {
  files: Hooks.Input.File.Info[];
  logo: Hooks.Input.File.Info;
  distributionDiscounts: Array<Model.IDealerDistributionDiscount>;
  productDealerDocuments: Array<
    Utils.Unpacked<Api.Core.Dealer.Save.IRequest['productDealerDocuments']> & {
      formFile?: Hooks.Input.File.Info;
      isDeleted?: boolean;
    }
  >;
};
export default function DealerEdit() {
  const { lang } = getLocales();
  const { user, isDistributor, isDealerOrDistributor } = useAuthStore();
  const { selected, pageRouter, listApi, discountsApi } = useDealerProvider();
  const [additionalProductIds, setAdditionalProductIds] = useState<number[]>([]);
  return (
    <>
      <ModalForm<DealerForm>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected, discountsApi.payload?.data]}
        title={lang.dealer}
        default={{
          ...selected?.toJSON(),
          representativeId: selected?.representativeId ?? user?.distributorId,
          logo: selected?.uiSettingsFile
            ? {
                id: selected.uiSettingsFile.id,
                dataUrl: selected.uiSettingsFile.url,
                name: selected.uiSettingsFile.name,
                type: selected.uiSettingsFile.contentType as Utils.File.MimeType,
              }
            : null,
          files: selected?.mediaFiles?.map((it) => ({
            id: it.id,
            dataUrl: it.url,
            name: it.name,
            type: it.contentType as Utils.File.MimeType,
          })),
          distributionDiscounts: discountsApi.payload?.data,
        }}
        onCancel={pageRouter.updateParams}
        onSubmit={async ({ files, logo, productDealerDocuments, distributionDiscounts, ...data }) => {
          const response = await dealerSaveApi(data);

          if (response.status === HttpStatus.ok) {
            let totalFiles = 0;
            let uploadedFiles = 0;
            let deletedFiles = 0;
            let totalDeletedFiles = 0;
            const deletedFileIds = difference(
              selected?.mediaFiles?.map((it) => it.id),
              files?.map((it) => it.id)
            );
            for (const fileId of deletedFileIds || []) {
              const fileUploadResponse = await dealerDeleteFileApi(response.payload || data.id, {
                fileId,
              });
              if (fileUploadResponse.status === HttpStatus.ok) {
                deletedFiles += 1;
              }
              totalDeletedFiles += 1;
            }
            for (const formFile of files?.filter((it) => !it.id && it.original) || []) {
              const fileUploadResponse = await dealerUploadFileApi(response.payload || data.id, {
                formFile: formFile.original,
              });
              if (fileUploadResponse.status === HttpStatus.ok) {
                uploadedFiles += 1;
              }
              totalFiles += 1;
            }
            for (const discount of distributionDiscounts || []) {
              if (!discount.distributionDiscount && discount.id) {
                await dealerDistributionDiscountDeleteApi(discount.id);
              } else {
                await dealerDistributionDiscountSaveApi({ dealerId: data.id ?? response.payload, ...discount });
              }
            }
            for (const productDealerDoc of productDealerDocuments || []) {
              console.log(productDealerDoc);
              if (productDealerDoc.isDeleted && productDealerDoc.id) {
                const docDeleteReponse = await dealerDeleteProductDocumentApi({
                  id: response.payload || data.id,
                  productDealerDocumentId: productDealerDoc.id,
                });
                if (docDeleteReponse.status === HttpStatus.ok) {
                  deletedFiles += 1;
                }
                totalDeletedFiles += 1;
              } else if (productDealerDoc.formFile) {
                const docUploadResponse = await dealerUploadProductDocumentApi(
                  {
                    id: response.payload || data.id,
                    productId: productDealerDoc.productDocument.productId,
                    productDocumentId: productDealerDoc.productDocumentId,
                  },
                  { formFile: productDealerDoc.formFile.original }
                );
                if (docUploadResponse.status === HttpStatus.ok) {
                  uploadedFiles += 1;
                }
                totalFiles += 1;
              }
            }
            if (logo?.id !== selected?.uiSettingsFile?.id) {
              const logoUploadResponse = await dealerDeleteUiFileApi(response.payload || data.id);
              if (logoUploadResponse.status === HttpStatus.ok) {
                deletedFiles += 1;
              }
              totalDeletedFiles += 1;
            }
            if (logo && !logo?.id) {
              const logoUploadResponse = await dealerUploadUiFileApi(response.payload || data.id, {
                formFile: logo.original,
              });
              if (logoUploadResponse.status === HttpStatus.ok) {
                uploadedFiles += 1;
              }
              totalFiles += 1;
            }
            listApi.execute((body) => body);
            if (uploadedFiles !== totalFiles || deletedFiles !== totalDeletedFiles) {
              addLog({
                warning: combineStrings(
                  ' ',
                  `Dealer was successfully saved but some files did not get deleted or uploaded!`,
                  totalFiles > 0 ? `${uploadedFiles}/${totalFiles} files uploaded` : null,
                  totalDeletedFiles > 0 ? `${deletedFiles}/${totalDeletedFiles} files got deleted!` : null
                ),
              });
            } else {
              addLog({ success: lang.saveSuccess });
            }
          } else {
            addLog({ error: response.message });
          }
        }}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.streetAddress) errors.streetAddress = lang.mustNotBeEmpty;
          if (!data.provinceCode) errors.provinceCode = lang.mustNotBeEmpty;
          if (!data.city) errors.city = lang.mustNotBeEmpty;
          if (!data.countryCode) errors.countryCode = lang.mustNotBeEmpty;
          if (!data.postalCode) errors.postalCode = lang.mustNotBeEmpty;
          if (!data.representativeId) errors.representativeId = lang.mustNotBeEmpty;
          if (
            data.distributionDiscounts?.some((dd) =>
              values(dd.distributionDiscount).some((it) => !it?.amount || !it?.unit || it.amount <= 0)
            )
          ) {
            errors.distributionDiscounts = {};
            errors.distributionDiscounts._objectError = lang.invalidForm;
          }
          if (data.primaryPhoneNumber && !isValidPhoneNumber(data.primaryPhoneNumber))
            set(errors, 'primaryPhoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
        }}
        tabs={[
          ...(isDistributor || !isDealerOrDistributor
            ? [{ id: 'info', icon: 'fas-info-circle', text: lang.info }]
            : []),
          ...(!isDealerOrDistributor ? [{ id: 'discounts', icon: 'fas-percent', text: lang.discounts }] : []),
          ...(!isDealerOrDistributor
            ? [{ id: 'productDocuments', icon: 'fas-file', text: lang.productDocuments }]
            : []),
          ...(isDistributor || !isDealerOrDistributor
            ? [{ id: 'uiSettings', icon: 'fas-paint-roller', text: lang.uiSettings }]
            : []),
        ]}
        render={({ activeTab }) =>
          activeTab?.id === 'productDocuments' ? (
            <DealerProductDocumentForm
              additionalProductIds={additionalProductIds}
              setAdditionalProductIds={setAdditionalProductIds}
            />
          ) : activeTab?.id === 'uiSettings' ? (
            <DealerUiSettingsForm />
          ) : activeTab?.id === 'info' ? (
            <DealerGeneralForm
              additionalProductIds={additionalProductIds}
              setAdditionalProductIds={setAdditionalProductIds}
            />
          ) : activeTab?.id === 'discounts' ? (
            <DealerDistributionDiscountForm
              additionalProductIds={additionalProductIds}
              setAdditionalProductIds={setAdditionalProductIds}
            />
          ) : null
        }
      />
    </>
  );
}
