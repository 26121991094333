import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import contractProductDocumentDeleteApi from './delete/Api.ContractProductDocument.Delete';
import contractProductDocumentSaveApi from './save/Api.ContractProductDocument.Save';

export default class ContractProductDocument
  extends Model<Model.IContractProductDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IContractProductDocument>
{
  id: number;
  name: string;
  notes?: string;
  contractId: number;

  mediaFileId: number;
  mediaFile: MediaFile;
  formFile?: File;

  constructor(
    data: Partial<Model.IContractProductDocument> | Utils.FormData<Model.IContractProductDocument>,
    language?: Locale.Languages
  ) {
    super({ mediaFile: MediaFile }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
  async save() {
    return await contractProductDocumentSaveApi(this);
  }
  async delete() {
    return await contractProductDocumentDeleteApi({ id: this.contractId, documentId: this.id });
  }
}
