import React from 'react';
import RenderProps from '../helpers/RenderProps';
import { useTheme } from '../../hooks/theme/useTheme';

export enum PageStructure {
  page = 'page',
  pageWrapper = 'pageWrapper',
  pageContent = 'pageContent',
  pageHeader = 'pageHeader',
  pageSubHeader = 'pageSubHeader',
  pageFooter = 'pageFooter',
  pageSidebar = 'pageSidebar',
}
export type Verify = Utils.VerifyExtends<Component.Page.Structure, typeof PageStructure>;
export default function Page(props: Component.Page.Import) {
  const extend = useTheme(PageStructure, props);

  return (
    <div {...extend(PageStructure.page, true)}>
      <div
        {...extend(PageStructure.pageWrapper)}
        children={
          <>
            {props.header && <div {...extend(PageStructure.pageHeader)} children={RenderProps(props, 'header')} />}
            {props.subHeader && (
              <div {...extend(PageStructure.pageSubHeader)} children={RenderProps(props, 'subHeader')} />
            )}
            {props.sidebar && <div {...extend(PageStructure.pageSidebar)} children={RenderProps(props, 'sidebar')} />}
            <div {...extend(PageStructure.pageContent)} children={RenderProps(props)} />
            {props.footer && <div {...extend(PageStructure.pageFooter)} children={RenderProps(props, 'footer')} />}
            {props.extraFooter}
          </>
        }
      />
    </div>
  );
}
