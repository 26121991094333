import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import claimInvoiceSaveApi from './save/Api.ClaimInvoice.Save';
import claimInvoiceDeleteApi from './delete/Api.ClaimInvoice.Delete';

export default class ClaimInvoice
  extends Model<Model.IClaimInvoice>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimInvoice>
{
  id: number;
  name: string;
  notes?: string;
  claimId: number;

  mediaFile: MediaFile;
  formFile?: File;

  constructor(data: Partial<Model.IClaimInvoice> | Utils.FormData<Model.IClaimInvoice>, language?: Locale.Languages) {
    super({ mediaFile: MediaFile }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
  async save() {
    return await claimInvoiceSaveApi(this);
  }
  async delete() {
    return await claimInvoiceDeleteApi({ id: this.claimId, invoiceId: this.id });
  }
}
