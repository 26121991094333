import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { CanadaProvinces } from 'models/enums/CanadaProvinces';
import React, { Fragment } from 'react';
import { ProductEditTab, useProductProvider } from '../../Product.Hooks';

const titleStyle: React.CSSProperties = { fontSize: Theme.FontSize.L, fontWeight: 'bold' };
export default function ProductEditNavigation() {
  const { lang, translate } = getLocales();
  const { pageRouter, product, uploadedDocuments } = useProductProvider();

  return (
    <>
      <div
        data-active={pageRouter.query.editTab === ProductEditTab.INFO ? true : undefined}
        onClick={() => pageRouter.updateQuery({ editTab: ProductEditTab.INFO })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.generalInformation}</div>
        {!product?.id ? (
          <p style={{ lineHeight: Theme.Size.em(1.6) }}>{lang.noInfo}</p>
        ) : (
          <p style={{ lineHeight: Theme.Size.em(1.6) }}>
            <b>{lang.product}</b>:&nbsp;{product.name}
            <br />
            <b>{lang.category}</b>: {product.category?.name}
            <br />
            <b>{lang.type}</b>: {translate(product.productType)}
            <br />
            <b>{lang.code}</b>: {product.code}
            <br />
            <b>{lang.claimTypes}</b>: {combineStrings(', ', ...(product.claimTypes?.map((c) => c.name) || []))}
            <br />
            <b>{lang.claimTypes}</b>: {combineStrings(', ', ...(product.insurers?.map((c) => c.name) || []))}
            <br />
          </p>
        )}
      </div>
      {!!product?.id && (
        <div
          data-active={pageRouter.query.editTab === ProductEditTab.DOCUMENTS ? true : undefined}
          onClick={() => pageRouter.updateQuery({ editTab: ProductEditTab.DOCUMENTS })}
          title={lang.showMore}
        >
          <span style={titleStyle}>{lang.documents}</span>
          <br />
          {!uploadedDocuments.length && lang.noInfo}
          <DataMap
            data={uploadedDocuments}
            render={({ data: doc }) => (
              <Fragment key={doc.id}>
                <span
                  style={{
                    color: Theme.Color.success,
                  }}
                  title={lang.uploaded}
                >
                  <Icon class="fas-check" />
                  &nbsp;
                  {doc.mediaFile?.name || lang.unknownDocument}
                </span>
                &nbsp;&nbsp;
              </Fragment>
            )}
          />
        </div>
      )}

      <div
        data-active={pageRouter.query.editTab === ProductEditTab.DOC_TEMPLATES ? true : undefined}
        onClick={() => pageRouter.updateQuery({ editTab: ProductEditTab.DOC_TEMPLATES })}
        title={lang.showMore}
      >
        <span style={titleStyle}>{lang.documentTemplates}</span>
        <br />
        {product?.productDocumentTemplates?.map((it) => it.name).join(', ') ?? lang.noInfo}
      </div>
      <div
        data-active={pageRouter.query.editTab === ProductEditTab.TAXES ? true : undefined}
        onClick={() => pageRouter.updateQuery({ editTab: ProductEditTab.TAXES })}
        title={lang.showMore}
      >
        <span style={titleStyle}>{lang.taxes}</span>
        <br />
        {product?.productTaxes?.length
          ? product.productTaxes
              .filter(
                (it) =>
                  it.claimsGstHst || it.claimsProvincialSalesTax || it.contractGstHst || it.contractProvincialSalesTax
              )
              .map((it) => CanadaProvinces[it.provinceCode] || it.provinceCode)
              .join(', ')
          : lang.noItemsAdded}
      </div>
      <div
        data-active={pageRouter.query.editTab === ProductEditTab.PROVINCE_GROUPS ? true : undefined}
        onClick={() => pageRouter.updateQuery({ editTab: ProductEditTab.PROVINCE_GROUPS })}
        title={lang.showMore}
      >
        <span style={titleStyle}>
          {lang.province} {lang.groups.toLowerCase()}
        </span>
        <br />
        {!product?.productProvinceGroups?.length
          ? lang.noInfo
          : product.productProvinceGroups.map((g) => g.name).join(', ')}
      </div>
    </>
  );
}
