import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.SetStatus.IRequest;
type Response = Api.Core.Contract.SetStatus.IResponse;
type Headers = Api.Core.Contract.SetStatus.IHeaders;
const url = '/api/Sales/Contract/SetStatus/:contractId';
export default async function contractSetStatusApi(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<string, Response, Headers>({
    method: HttpMethod.put,
    url: createPathFromRoute(url, { contractId: body.contractId }),
    body: body.status,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
