import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import './RowActions.scss';

export enum RowActionsStructure {
  dataActions = 'dataActions',
}
export type Verify = Utils.VerifyExtends<Component.RowActions.Structure, typeof RowActionsStructure>;
export default function RowActions(props: Component.RowActions.Import) {
  const extend = useTheme(RowActionsStructure, props);

  return (
    <div
      {...extend(RowActionsStructure.dataActions, true)}
      children={RenderProps(props, 'render')}
      data-vertical={props.isVertical || undefined}
    />
  );
}
