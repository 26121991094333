import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { combineStrings } from 'assets/utils/data/String';
import { UICommand } from 'assets/utils/dom/Commands';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { toNumber } from 'lodash';
import { useEffect, useState } from 'react';

function useUIEditorCore(props: Hooks.UIEditor.Import): Hooks.UIEditor.Export<UICommand> {
  const editorSelector = props.editorSelector || '.htmlViewerEditor';
  const doc = props.doc || document;
  const [{ selectionInfo }, setSelection] = useState(() => ({ selectionInfo: getSelectionInfo() }));

  useEffect(() => {
    function changeSelection() {
      setSelection({ selectionInfo: getSelectionInfo() });
    }
    if (doc) {
      doc.addEventListener('selectionchange', changeSelection);
      return () => doc.removeEventListener('selectionchange', changeSelection);
    }
  }, [doc]);

  function getEditorElement() {
    return doc?.querySelector(editorSelector) as HTMLElement;
  }
  function executeUpdateCallback(command?: string, value?: any) {
    if (props.onUpdate) props.onUpdate(getEditorElement()?.innerHTML, { command, value });
  }

  //Modes
  const [useCssMode /*, switchCssMode*/] = useState(false);
  useEffect(() => {
    if (useCssMode) doc.execCommand('styleWithCSS', false, useCssMode as any);
  }, [useCssMode, doc]);

  //Selection
  function getCurrentSelection() {
    return doc?.getSelection ? doc.getSelection() : null;
  }
  function getSelectionInfo() {
    const selection = getCurrentSelection();
    let focusNode = selection?.focusNode || null;
    const originalFocus = focusNode as HTMLElement;
    const rootNode = doc?.querySelector(editorSelector);
    const isNestedNode = originalFocus?.closest && originalFocus?.closest(editorSelector)?.isEqualNode(rootNode);
    if (!isNestedNode) focusNode = rootNode;

    const isTextNode = focusNode?.nodeName === '#text';
    const isRootNode = rootNode?.isEqualNode(focusNode);
    const hasOneChild = focusNode?.childNodes?.length === 1;

    const block: HTMLElement | null =
      ((isTextNode
        ? focusNode?.parentElement
        : isRootNode && hasOneChild
        ? focusNode?.firstChild
        : isNestedNode
        ? focusNode
        : rootNode) as HTMLElement) || null;

    const selectedText = selection?.toString();
    const isBlockSelected = !selectedText || block?.innerText === selectedText;
    const isCmsStyle = block?.nodeName?.toLowerCase() === 'cms-style';
    const isEditable = block?.contentEditable === 'true';
    const originalBlock = originalFocus?.nodeName === '#text' ? originalFocus?.parentElement : originalFocus;
    return { selection, block, originalBlock, isBlockSelected, selectedText, isCmsStyle, isEditable };
  }
  function getBlockName(block?: HTMLElement | null) {
    if (!block) return null;
    return combineStrings(
      '',
      block.tagName?.toLowerCase(),
      block.id && `#${block.id}`,
      block.className && `.${block.className.split(/\s+/g).join('.')}`
    );
  }

  //Set functions
  function applyUiCommand(command: UICommand, value: string = '') {
    doc.execCommand(command, false, value);
    executeUpdateCallback(command, value);
  }
  function applyCss(style: React.CSSProperties) {
    const { selection, block, isBlockSelected, selectedText, isCmsStyle, isEditable } = getSelectionInfo();
    if (block && selection) {
      if (isBlockSelected) {
        if (!isCmsStyle && isEditable) {
          const wrapper = doc.createElement('cms-style');
          for (const [key, val] of Object.entries(style)) wrapper.style[key as any] = val;
          wrapper.innerHTML = block.innerHTML;
          block.innerHTML = '';
          block.append(wrapper);
        } else {
          for (const [key, val] of Object.entries(style)) {
            block.style[key as any] = block.style[key as any] != val ? val || '' : '';
          }
          if (!block.style.cssText && isCmsStyle) {
            if (selectedText) {
              const range = selection.getRangeAt(0);
              const fragment = range.extractContents();
              if (fragment && range) {
                block.remove();
                range.insertNode(fragment);
                if (fragment.parentNode) range.selectNode(fragment);
              }
            } else {
              block.outerHTML = block.innerHTML;
            }
          }
        }
      } else {
        if (selection.rangeCount) {
          const wrapper = doc.createElement('cms-style');
          for (const [key, val] of Object.entries(style)) wrapper.style[key as any] = val;
          const range = selection.getRangeAt(0);
          const fragment = range.extractContents();
          range.insertNode(wrapper as any);
          wrapper.append(fragment);
          range.selectNodeContents(wrapper);
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      }
    }
    setSelection({ selectionInfo });
    executeUpdateCallback('css', style);
  }
  function applyAttribute(attributes: { [key: string]: string | null }) {
    const { block } = getSelectionInfo();
    for (const [name, value] of Object.entries(attributes)) {
      if (isValidValue(value)) block?.setAttribute(name, value!);
      else block?.removeAttribute(name);
    }
    setSelection({ selectionInfo });
    executeUpdateCallback('attribute', attributes);
  }

  //Get functions
  function getUiCommandValue(command: UICommand) {
    if (doc.queryCommandValue) return doc.queryCommandValue(command);
    else if (doc.queryCommandState) return doc.queryCommandState(command);
  }
  function getCssValue<T extends keyof Hooks.UIEditor.CSS>(block: HTMLElement, cssAttribute: T): Hooks.UIEditor.CSS[T] {
    return block?.style && (block.style[cssAttribute as any] as unknown as Hooks.UIEditor.CSS[T]);
  }
  function getAttribute(block: HTMLElement, name: string) {
    return block?.getAttribute(name);
  }
  function getCssValueInfo<T extends keyof Hooks.UIEditor.CSS>(
    block: HTMLElement,
    cssAttribute: T
  ): Hooks.UIEditor.CssValueInfo<T> {
    const value = getCssValue(block, cssAttribute);
    const isVariable = typeof value === 'string' ? value?.indexOf('var') === 0 : false;
    const [tempSize, tempUnit] = !isVariable
      ? typeof value === 'number'
        ? [value, undefined]
        : typeof value === 'string'
        ? value?.match(/\d+|\w+/g) || []
        : []
      : [];
    return {
      value,
      isVariable,
      size: toNumber(tempSize) || undefined,
      unit: tempUnit,
    };
  }
  return {
    doc,
    selectionInfo,
    getBlockName,
    applyUiCommand,
    getUiCommandValue,
    applyCss,
    getCssValue,
    applyAttribute,
    getAttribute,
    getCssValueInfo,
    getEditorElement,
  };
}
const { Provider: UIEditorProvider, useProvider: useUIEditor } = ProviderBuilder(useUIEditorCore);
export default useUIEditor;
export { UIEditorProvider, useUIEditorCore };
