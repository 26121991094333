import { calculateIndex, calculateOffset, calculatePages } from 'assets/utils/data/Number';
import { useState } from 'react';
import _ from 'lodash';

export function usePagination(props: Hooks.Pagination.Import) {
  const calcFunc = props.pageCalculation || 'startAndEnd';
  const limit = props.limit || 0;
  const total = props.count || 0;
  const offset = props.offset || 0;
  const pages = calculatePages(limit, total);

  const [_index, setIndex] = useState(offset);
  const index = calcFunc === 'startAndEnd' ? offset : _index;

  function nextIndex() {
    setIndex(calculateIndex(index, total, 1));
  }
  function prevIndex() {
    setIndex(calculateIndex(index, total, -1));
  }
  function nextSection() {
    const _total = total > pages ? Math.round(total / pages) * pages : total;
    const _index = calculateIndex(Math.floor(index / limit) * limit, _total, limit);
    setIndex(_index);
  }
  function prevSection() {
    const _total = total > pages ? Math.round(total / pages) * pages : total;
    const _index = calculateIndex(Math.floor(index / limit) * limit, _total, -limit);
    setIndex(_index);
  }

  const _offset = calculateOffset(index, limit);
  let pageList: Hooks.Pagination.Page[] = [];

  switch (calcFunc) {
    case 'startAndEnd':
      pageList = startAndEndSections(pages, index);
      break;
    case 'sections':
      pageList = oneSection(_offset, limit, total, index);
      break;
  }
  return {
    index,
    offset: _offset,
    limit,
    pageList,
    total,
    pages,
    setIndex,
    nextIndex,
    nextSection,
    prevIndex,
    prevSection,
  };
}

function startAndEndSections(sections: number, index: number) {
  const pages: Hooks.Pagination.Page[] = [];

  if (index - 2 >= 0 && index + 1 === sections)
    pages.push({
      index: index - 2,
      title: index - 1,
    });
  if (index - 1 >= 0)
    pages.push({
      index: index - 1,
      title: index,
    });
  if (sections > 1)
    pages.push({
      index: index,
      title: index + 1,
      isActive: true,
    });
  if (index + 1 < sections)
    pages.push({
      index: index + 1,
      title: index + 2,
    });
  if (index + 2 < sections && index === 0)
    pages.push({
      index: index + 2,
      title: index + 3,
    });

  if (pages.length > 0 && pages[0].index > 0)
    pages.unshift({
      index: 0,
      title: `1${pages[0].index === 1 ? '' : '...'}`,
    });
  if (pages.length > 0 && pages[pages.length - 1].index < sections - 1)
    pages.push({
      index: sections - 1,
      title: `${pages[pages.length - 1].index === sections - 2 ? '' : '...'}${sections}`,
    });

  return pages;
}
function oneSection(offset: number, limit: number, total: number, index: number) {
  const startIndex = offset * limit;
  const endIndex = startIndex + limit;
  const fixedEndIndex = endIndex > total ? total : endIndex;
  return _.range(startIndex, fixedEndIndex).map((it) => ({
    index: it,
    title: it + 1,
    isActive: index === it,
  }));
}
