import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { combineStrings } from 'assets/utils/data/String';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { keys } from 'lodash';
import Claim from 'models/claims/claim/Model.Claim';
import claimDeleteApi from 'models/claims/claim/delete/Api.Claim.Delete';
import EmailTemplate from 'models/core/emailTemplate/Model.EmailTemplate';
import User from 'models/core/user/Model.User';
import Product from 'models/productManagement/product/Model.Product';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import { useMemo } from 'react';
import { claimIndexRoute } from './Claim.Index';
import ContractStatusCode from 'models/enums/ContractStatusCode';

export enum ClaimAction {
  ADD = 'add',
  EDIT_SIMPLE = 'editSimple',
  DELETE = 'delete',
}
export type VerifyClaimAction = Utils.VerifyExtends<Module.Claims.Claim.Actions, Utils.ValueOf<typeof ClaimAction>>;
export const allowedContractStatusesForClaimCreation = [
  ContractStatusCode.OPEN_SUBMITTED,
  ContractStatusCode.OPEN_UNDER_REVIEW,
  ContractStatusCode.OPEN_UNPAID,
  ContractStatusCode.OPEN_WAITING_CONSENT,
  ContractStatusCode.PAID_ACTIVE,
  ContractStatusCode.PAID_EXPIRED,
];
export function useClaimRouter() {
  return usePageRouter<Module.Claims.Claim.Params, Module.Claims.Claim.Query>({ route: claimIndexRoute });
}
export default function useClaim() {
  const pageRouter = useClaimRouter();

  const emailTemplatesApi = useApi({ action: EmailTemplate.list });
  const emailTemplates = emailTemplatesApi.payload?.data;

  const statusInfoApi = useApi({ action: Claim.getStatusFlowAndRequirements });
  const statusInfo = statusInfoApi.payload;

  const { permissions, userId } = useAuthStore();

  const claimId = pageRouter?.params?.id ? parseInt(pageRouter.params.id) : undefined;

  const isClaimAdmin = permissions?.CLAIM_ADMIN;
  const isEditable = isClaimAdmin || permissions?.EDIT_CLAIM;
  const canCreateClaim = isClaimAdmin || permissions?.CREATE_CLAIM;
  const canCreateSimpleClaim = isClaimAdmin || permissions?.CREATE_SIMPLE_CLAIM;
  const hasAnyPermission =
    isClaimAdmin || permissions?.EDIT_CLAIM || permissions?.CREATE_CLAIM || permissions?.DELETE_CLAIM;
  const canAddClaim = canCreateClaim || canCreateSimpleClaim;

  //#region Api calls
  const listApi = useApi({
    action: Claim.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.updateQuery({ ...pageRouter.query, list: req }),
    wait: !hasAnyPermission,
  });

  const deleteApi = useApi({
    action: claimDeleteApi,
    callback: () => {
      pageRouter.clear();
      listApi.execute((body) => body);
    },
    wait: true,
  });

  //#endregion

  //#region Variables
  const claim = listApi.payload?.data?.find((it) => it.id === claimId);
  const isDeletable =
    !!(isEditable && claim && !claim.claimJobCount && !claim.claimDocuments?.length) &&
    (isClaimAdmin || permissions?.DELETE_CLAIM);
  const isClaimOwner = claim?.ownerId === userId;
  //#endregion

  const productsApi = useApi({ action: Product.list, wait: true });
  const vehicleMakeApi = useApi({ action: VehicleMake.list, wait: true });
  const userApi = useApi({ action: User.list, wait: true });

  const productApi = useApi({
    action: Product.find,
    wait: !claim?.contract?.productId,
    body: { id: claim?.contract?.productId },
  });
  const product = productApi.payload;

  const statuses = useMemo(() => {
    return keys(statusInfo).map((status) => {
      const [primary, secondary, reason] = status.split('_');
      const mainStatus = combineStrings(' - ', primary, secondary);
      return {
        id: status,
        subtitle: mainStatus,
        title: reason ?? mainStatus,
      };
    });
  }, [statusInfo]);

  return {
    hasAnyPermission,
    listApi,
    pageRouter,
    isEditable,
    canCreateClaim,
    canCreateSimpleClaim,
    canAddClaim,
    isClaimAdmin,
    isClaimOwner,
    statusInfo,
    emailTemplates,
    productsApi,
    userApi,
    vehicleMakeApi,
    statuses,
    deleteApi,
    claim,
    product,
    views: {
      [ClaimAction.DELETE]: pageRouter.params.action === ClaimAction.DELETE && isDeletable,
      [ClaimAction.EDIT_SIMPLE]: pageRouter.params.action === ClaimAction.EDIT_SIMPLE && isEditable,
      [ClaimAction.ADD]: pageRouter.params.action === ClaimAction.ADD && isEditable,
    },
  };
}

export function useClaimProvider() {
  return useDataProvider<ReturnType<typeof useClaim>>();
}
