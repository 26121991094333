import Model from 'models/Model';

export default class RoleTranslation
  extends Model<Model.IRoleTranslation>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IRoleTranslation>
{
  id: number;
  languageIsoCode?: Locale.Languages;
  name?: string;
  description?: string;

  constructor(
    data: Partial<Model.IRoleTranslation> | Utils.FormData<Model.IRoleTranslation>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      subtitle: this.description,
    };
  }
}
