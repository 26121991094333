import { useTheme } from 'assets/hooks/theme/useTheme';
import { getUrlInfo } from 'assets/utils/dom/UrlParsing';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import React from 'react';
import { Link } from 'react-router-dom';
import RenderProps from '../helpers/RenderProps';

export enum ButtonStructure {
  button = 'button',
  clickableArea = 'clickableArea',
  buttonMedia = 'buttonMedia',
  buttonText = 'buttonText',
}
export type Verify = Utils.VerifyExtends<Component.Button.Structure, typeof ButtonStructure>;
export default function LinkButton<T>(props: Component.Button.LinkImport<T>) {
  const extend = useTheme(ButtonStructure, props);

  const fileInfo = getUrlInfo(props.url);
  const tabIndex = isValidValue(props.tabIndex) ? props.tabIndex : undefined;
  const children = (
    <>
      {props.media && <div {...extend(ButtonStructure.buttonMedia)} children={RenderProps(props, 'media')} />}
      {(props.render || props.children) && (
        <div {...extend(ButtonStructure.buttonText)} children={RenderProps(props)} />
      )}
    </>
  );
  return (
    <div {...extend(ButtonStructure.button, true)}>
      {!fileInfo.is_nav_path || props.download ? (
        <a
          {...extend(ButtonStructure.clickableArea)}
          href={fileInfo.url}
          target={props.target}
          download={props.download}
          tabIndex={tabIndex}
          children={children}
        />
      ) : (
        <Link
          {...extend(ButtonStructure.clickableArea)}
          to={props.url}
          target={props.useTargetOnlyForOtherSites ? undefined : props.target}
          tabIndex={tabIndex}
          children={children}
        />
      )}
      {RenderProps(props, 'nonClickable')}
    </div>
  );
}
