import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Dealer.Delete.IRequest;
type Response = Api.Core.Dealer.Delete.IResponse;
type Headers = Api.Core.Dealer.Delete.IHeaders;
const url = '/api/DealerManagement/Dealer/:id';
export default async function dealerDeleteApi({ id }: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.delete,
    url: createPathFromRoute(url, { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
