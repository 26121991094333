import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import User from 'models/core/user/Model.User';
import userResetPasswordApi from 'models/core/user/resetPassword/Api.User.ResetPassword';
import React from 'react';

export default function UserResetPassword(
  props: Template.ModalForm.MinimalImport<
    Model.IUser,
    Api.Core.User.ResetPassword.IRequest,
    Api.Res<Api.Core.User.ResetPassword.IResponse>
  >
) {
  const { lang } = getLocales();
  const save = useApi({
    action: (req) => userResetPasswordApi(props.data?.id, req),
    callback: props.onSave,
    wait: true,
  });

  return (
    <>
      <ModalForm<Api.Core.User.ResetPassword.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[props.data]}
        title="Reset password"
        default={{}}
        onCancel={props.onCancel}
        onSubmit={(data) => save.execute(data)}
        validation={(data, errors) => {
          if (!data.newPassword) errors.newPassword = lang.mustNotBeEmpty;
          if (!data.confirmPassword) errors.confirmPassword = lang.mustNotBeEmpty;
          if (data.newPassword !== data.confirmPassword) errors.confirmPassword = lang.passwordsDoNotMatch;
          if (!User.checkPasswordValidity(data.newPassword)) errors.newPassword = lang.passwordValidityMessage;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="newPassword"
                type="password"
                autoComplete="new-password"
                label={lang.newPassword}
                onChange={form.update}
                value={form.data.newPassword}
                error={form.errors.newPassword}
                icon={<Icon class={iconStyles.common.password} />}
              />
              <TextInput
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                label={lang.confirmPassword}
                onChange={form.update}
                value={form.data.confirmPassword}
                error={form.errors.confirmPassword}
                icon={<Icon class={iconStyles.common.password} />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
