import React from 'react';
import { useTheme } from '../../hooks/theme/useTheme';
import RenderProps from '../helpers/RenderProps';
import './Icon.scss';

export enum IconStructure {
  icon = 'icon',
}
export type Verify = Utils.VerifyExtends<Component.Icon.Structure, typeof IconStructure>;
export default function Icon(props: Component.Icon.Import) {
  const extend = useTheme(IconStructure, props);

  return <i {...extend(IconStructure.icon, true)} children={RenderProps(props, 'render')} />;
}

export const iconStyles = {
  common: {
    document: 'fo-book-open-1',
    table: 'fas-table',
    menu: 'fas-bars',
    exchange: 'fas-exchange-alt',
    filters: 'fas-sliders-h',
    reload: 'if-refresh',
    image: 'fas-image',
    spinner: 'fas-circle-notch',
    minimize: 'fas-compress',
    maximize: 'fas-expand',
    notFound: 'fas-search-minus',
    unauthorized: 'fas-ban',
    settings: 'fas-cogs',
    appMenu: 'fas-th',
    notifications: 'fas-bell',
    signOut: 'fas-sign-out-alt',
    signIn: 'fas-sign-in-alt',
    error: 'fas-unlink',
    times: 'fas-times',

    legal: 'fas-gavel',
    company: 'fas-building',
    skype: 'fab-skype',
    facebook: 'fab-facebook-square',
    twitter: 'fab-twitter-square',
    instagram: 'fab-instagram',
    link: 'fas-link',
    cms: 'fas-globe',
    email: 'far-envelope',
    user: 'fas-user',
    userGroup: 'fas-users',
    mobile: 'fas-mobile-alt',
    id: 'fas-id-card-alt',
    contact: 'fas-phone',
    username: 'fas-user-tag',
    password: 'fas-lock',
    edit: 'fas-pencil-alt',
    delete: 'fas-trash-alt',
    externalLink: 'fas-external-link-alt',
    close: 'ti-close',
    add: 'fas-plus',
    remove: 'fas-minus',
    check: 'fas-check',
    crossed: 'fas-times',
    yes: 'fas-check',
    no: 'fas-times',
    checkBox: 'far-square',
    checkBoxChecked: 'fas-check-square',
    selectArrow: 'fas-caret-down',
    search: 'fas-search',
    calendar: 'fas-calendar',
    left: 'ti-angle-left',
    right: 'ti-angle-right',
    play: 'fas-play',
    pause: 'fas-pause',
    up: 'fas-chevron-up',
    down: 'fas-chevron-down',
    warehouse: 'fas-warehouse',
    building: 'fas-building',
    ban: 'fas-ban',
    restore: 'fas-recycle',
    certificate: 'fas-certificate',
    info: 'fas-info-circle',
    warning: 'fas-exclamation-triangle',
    cashRegister: 'fas-cash-register',
    category: 'fas-sitemap',
    infinity: 'fas-infinity',
    attribute: 'fas-clipboard-list',
    list: 'if-listing-box',
    grid: 'fas-th',
    normative: 'fas-clipboard-list',
    name: 'fas-signature',
    measurementUnit: 'fas-balance-scale',
    approve: 'fas-check',
    warehouseReceipt: 'fas-sign-in-alt',
    transferNote: 'fas-random',
    ship: 'fas-shipping-fast',
    stocktaking: 'fas-clipboard-check',
    arrowRight: 'fas-arrow-right',
    log: 'fas-clipboard-list',
    currency: 'fas-dollar-sign',
    accessRule: 'fas-user-lock',
    partner: 'fas-handshake',
    phone: 'fas-phone',
    mobilePhone: 'fas-mobile',
    website: 'fas-globe',
    address: 'fas-map-signs',
    crmContact: 'fas-address-book',
    fiscalCertificate: 'fas-certificate',
    save: 'fas-save',
    order: 'fas-receipt',
    cart: 'fas-shopping-cart',
    return: 'fas-undo',
    birthday: 'fas-birthday-cake',
    shipping: 'fas-shipping-fast',
    payment: 'fas-credit-card',
    webshop: 'fas-store',
    integration: 'fas-plug',
    print: 'fas-print',
    time: 'fas-clock',
    text: 'fas-paragraph',
    emailTemplate: 'fas-envelope-open-text',
    minus: 'if-minus',
    plus: 'if-plus',
    account: 'fas-user',
    seo: 'fas-search',
    policy: 'fas-gavel',
    history: 'fas-history',
    view: 'fas-eye',
    sitemap: 'fas-sitemap',
    gallery: 'fas-images',
    pin: 'fas-thumbtack',
    login: 'fas-sign-in-alt',
    file: 'fas-file-alt',
    folder: 'fas-folder-open',
    locked: 'fas-lock',
    unlocked: 'fas-lock-open',
    deposit: 'fas-coins',
    sum: 'fas-plus',
    css: 'fas-css3-alt',
    page: 'fas-file',
    html: 'fas-html5',
    locale: 'fas-language',
    coupon: 'fas-tags',
    copy: 'fas-copy',
    sittingTable: 'fas-utensils',
    calculator: 'fas-calculator',
    display: 'fas-tv',
  },
};
