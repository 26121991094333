enum WarrantyOptions {
  optionA = 'Option A',
  optionB = 'Option B',
  optionC = 'Option C',
  optionD = 'Option D',
}

export default WarrantyOptions;
export type VerifyWarrantyOptions = Utils.VerifyExtends<
  Model.Enum.WarrantyOptions,
  Utils.ValueOf<typeof WarrantyOptions>
>;
