import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { useAudtiLogProvider } from '../AuditLog.Hooks';

export default function AuditLogList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useAudtiLogProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.changeLogs}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: auditLog }) => (
          <Row
            key={auditLog.id}
            children={
              <>
                <RowImage render={<Icon class="if-database" />} />
                <RowData
                  title={auditLog.application?.name}
                  subtitle={
                    <>
                      {auditLog.user?.firstName} {auditLog.user?.lastName}
                    </>
                  }
                />
                <RowData {...auditLog.displayInfo} />
                <RowData children={auditLog?.changedAtUtcParsed} />
                <RowActions isVertical>
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-info-circle" />}
                    onClick={pageRouter.updateParams}
                    data={{ id: auditLog.id.toString() }}
                    htmlElementProps={{ title: lang.info }}
                  />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
