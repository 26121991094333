import DataMap from 'assets/components/dataMap/DataMap';
import { amexCard, ascLogo, masterCard, visaCard } from 'assets/themes/assets/Images';
import { date, defaultDateFormat, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import Contract from 'models/sales/contract/Model.Contract';
import { Fragment, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { useStatementProvider } from '../Statement.Hooks';
import { combineStrings } from 'assets/utils/data/String';
import ProductType from 'models/enums/ProductType';
import '../style/print.scss';

export default function StatementPrint() {
  const { selected } = useStatementProvider();
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement | null>(null);

  const dateNow = new Date();

  const _OLDER_THEN_90_DAYS = 'Older than 90 days';
  const _61_TO_90_DAYS = '61 to 90 days';
  const _31_TO_60_DAYS = '31 to 60 days';
  const _0_TO_30_DAYS = '0 to 30 days';

  const contractsTimeRanges = [
    { label: _OLDER_THEN_90_DAYS, minDays: 90, maxDays: undefined },
    { label: _61_TO_90_DAYS, minDays: 61, maxDays: 90 },
    { label: _31_TO_60_DAYS, minDays: 31, maxDays: 60 },
    { label: _0_TO_30_DAYS, minDays: 0, maxDays: 30 },
  ];

  // Calculate difference in days beetwen contact creation day and current time
  const differenceInDays = (contract: Contract) => {
    const contractDate = new Date(
      date(contract?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)
    );
    return Math.floor((dateNow.getTime() - contractDate.getTime()) / (1000 * 60 * 60 * 24));
  };

  // Filter contracts by time ranges defined in contractsTimeRanges
  const filterContractsByDays = (contracts: Contract[], minDays: number, maxDays: number) => {
    return contracts?.filter((contract) => {
      const difference = differenceInDays(contract);
      return difference >= minDays && (maxDays === undefined || difference < maxDays);
    });
  };

  // Sort and group contracts by product into arrays inside object allContractsSorted
  function sortAndGroupContracts(contracts: Contract[] = []) {
    return contracts
      .sort((a, b) => a.product.name.localeCompare(b.product.name))
      .reduce((groupedContracts: Contract[][], contract) => {
        const currentGroup = groupedContracts[groupedContracts.length - 1];
        if (!currentGroup || currentGroup[0].product.name !== contract.product.name) {
          groupedContracts.push([contract]);
        } else {
          currentGroup.push(contract);
        }
        return groupedContracts;
      }, []);
  }

  const selectedStatementContracts = selected?.contracts.map(
    (x) =>
      ({
        ...x,
        paymentTaxCAD: x.client?.taxExempt ? 0 : x.paymentTaxCAD,
        paymentAmountWithTaxCAD: x.client?.taxExempt ? x.paymentAmountCAD : x.paymentAmountWithTaxCAD,
      } as Contract)
  );

  let filteredContracts = selectedStatementContracts;

  // Store already sorted and grouped contracts into array of time ranges
  const filteredRanges = contractsTimeRanges.map((range) => {
    const { label, minDays, maxDays } = range;
    const filtered = filterContractsByDays(filteredContracts, minDays, maxDays);
    filteredContracts = filteredContracts?.filter((contract) => !filtered.includes(contract));
    return {
      label,
      allContractsSorted: sortAndGroupContracts(filtered),
      length: filtered?.length,
      paymentAmountCAD: filtered?.reduce((acc, current) => acc + current.paymentAmountCAD, 0).toFixed(2),
      paymentTaxCAD: filtered?.reduce((acc, current) => acc + current.paymentTaxCAD, 0).toFixed(2),
      paymentAmountWithTaxCAD: filtered?.reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0).toFixed(2),
    };
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    documentTitle: `Statment#${selected?.id}`,
  });

  useEffect(() => {
    handlePrint();
    history.goBack();
  }),
    [];

  return !selected ? null : (
    <div id="printStatement" ref={componentRef}>
      <style>{`@page {size: a4 portret; margin: 1cm;}`}</style>
      <h1 style={{ fontSize: '4rem', textAlign: 'end' }}>STATEMENT #{selected.statementNumber}</h1>
      <div className="print_details">
        <div className="print_details_company">
          <img src={ascLogo} />
          <p>
            <b>Auto Shield Canada,</b>
            <br />
            6601 Goreway Dr.,
            <br />
            Mississauga, ON, L4V 1V6
          </p>
        </div>
        <div className="print_details_dealer">
          <p>
            <b>Date:</b> {date(selected.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateFormat)}
            <br />
            <b>Dealer:</b> {selected.dealer.name}
            <br />
            <b>Address:</b>
            <br /> {selected.dealer?.streetAddress}, {selected.dealer?.postalCode}
            <br /> {selected.dealer?.city}, {selected.dealer?.provinceCode}, {selected.dealer?.countryCode}
          </p>
          <p>
            If issuing a cheque, please
            <br />
            ensure that it is made payable to:
            <br />
            <b>Auto Shield Canada</b>
          </p>
          <div className="print_details_dealer_cards">
            <img src={visaCard} />
            <img src={masterCard} />
            <img src={amexCard} />
          </div>
        </div>
      </div>
      <div className="print_table_header">
        <table className="print_table_header_title">
          <tr>
            <th>Balance Due is Payable Upon Receipt</th>
          </tr>
        </table>
        <table className="print_table_header_payments">
          <tr>
            <th>Balance Due:</th>
            <th>Over 90 Days:</th>
            <th>61 - 90 Days</th>
            <th>31 - 60 Days</th>
            <th>0 - 30 Days</th>
          </tr>
          <tr>
            <td>
              $
              {selectedStatementContracts.reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0).toFixed(2)}
            </td>
            <td>${filteredRanges.find((x) => x.label.includes(_OLDER_THEN_90_DAYS)).paymentAmountWithTaxCAD}</td>
            <td>${filteredRanges.find((x) => x.label.includes(_61_TO_90_DAYS)).paymentAmountWithTaxCAD}</td>
            <td>${filteredRanges.find((x) => x.label.includes(_31_TO_60_DAYS)).paymentAmountWithTaxCAD}</td>
            <td>${filteredRanges.find((x) => x.label.includes(_0_TO_30_DAYS)).paymentAmountWithTaxCAD}</td>
          </tr>
        </table>
      </div>
      <div className="print_table_body">
        <table>
          <DataMap
            data={filteredRanges}
            render={({ data: ranges }) => (
              <>
                {ranges.allContractsSorted.length > 0 && (
                  <>
                    <tr className="print_title_row">
                      <td colSpan={10}>{ranges.label.toUpperCase()}</td>
                    </tr>
                    <DataMap
                      data={ranges.allContractsSorted}
                      render={({ data: contracts }) => (
                        <>
                          <tr className="print_background_color">
                            <th style={{ width: '8%' }}>App#</th>
                            <th>Product</th>
                            <th style={{ width: '8%' }}>Stock#</th>
                            <th style={{ width: '20%' }}>Coverage</th>
                            <th>Client</th>
                            <th style={{ width: '10%' }}>Created</th>
                            <th style={{ width: '10%' }}>Effective on</th>
                            <th className="th_payment">Amount</th>
                            <th className="th_payment">Tax</th>
                            <th className="th_payment">Total</th>
                          </tr>
                          <DataMap
                            data={contracts}
                            render={({ data: contract }) => (
                              <Fragment key={contract.id}>
                                <tr>
                                  <td>
                                    {contract.appNumber +
                                      (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')}
                                  </td>
                                  <td>{combineStrings(' ', contract.product?.name)}</td>
                                  <td>{contract.vehicle?.stockNumber || 'n/a'}</td>
                                  <td>
                                    {contract.product.productType === ProductType.warrantyTierBased && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Tier:</b> {contract.tier || 'n/a'}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.nonWarranty && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Price:</b>
                                        {`$${contract.minVehiclePriceCAD.toFixed(2) || 'n/a'} - $${
                                          contract.maxVehiclePriceCAD.toFixed(0) || 'n/a'
                                        }`}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.warrantiClassBased && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m / {contract.maxKm || 'n/a'}km
                                        <br />
                                        <b>Coverage:</b> {contract.customInfo || 'n/a'}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.custom && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Coverage:</b> {contract.customInfo || 'n/a'}
                                        <br />
                                        <b>Coverage Type:</b> {contract.customInfoGroup || 'n/a'}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {combineStrings(' ', contract.client?.firstName, contract.client?.lastName)}
                                    <br />
                                    {`(...${contract.vehicle?.vin.toString().slice(-8)})`}
                                  </td>
                                  <td>
                                    {date(contract?.createdAtUtc, defaultServerDateTimeFormat).format(
                                      defaultDateFormat
                                    )}
                                  </td>
                                  <td>
                                    {date(contract?.effectiveDate, defaultServerDateTimeFormat).format(
                                      defaultDateFormat
                                    )}
                                  </td>
                                  <td className="td_payment">{`$${contract.paymentAmountCAD.toFixed(2)}`}</td>
                                  <td className="td_payment">{`$${contract.paymentTaxCAD.toFixed(2)}`}</td>
                                  <td className="td_payment">{`$${contract.paymentAmountWithTaxCAD.toFixed(2)}`}</td>
                                </tr>
                              </Fragment>
                            )}
                          />
                          {ranges.allContractsSorted.length > 1 && (
                            <>
                              <tr className="print_background_color print_never_break">
                                <td colSpan={10}></td>
                              </tr>
                              <tr className="print_never_break">
                                <td colSpan={6}></td>
                                <td>
                                  <b>SUBTOTAL</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${contracts
                                    .reduce((acc, current) => acc + current.paymentAmountCAD, 0)
                                    .toFixed(2)}`}</b>
                                </td>
                                <td colSpan={2}></td>
                              </tr>
                              <tr className="print_never_break">
                                <td>
                                  <b># OF APPS</b>
                                </td>
                                <td className="td_payment">
                                  <b>{contracts.length}</b>
                                </td>
                                <td colSpan={5}></td>
                                <td>
                                  <b>SALES TAX</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${contracts
                                    .reduce((acc, current) => acc + current.paymentTaxCAD, 0)
                                    .toFixed(2)}`}</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr className="print_never_break">
                                <td colSpan={8}></td>
                                <td>
                                  <b>TOTAL</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${contracts
                                    .reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0)
                                    .toFixed(2)}`}</b>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    />
                    <tr className="print_background_color print_never_break">
                      <td colSpan={10}></td>
                    </tr>
                    <tr className="print_never_break">
                      <td colSpan={6}></td>
                      <td>
                        <b>SUBTOTAL</b>
                      </td>
                      <td className="td_payment">
                        <b>${ranges.paymentAmountCAD}</b>
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr className="print_never_break">
                      <td>
                        <b># OF APPS {ranges.label}</b>
                      </td>
                      <td className="td_payment">
                        <b>{ranges.length}</b>
                      </td>
                      <td colSpan={5}></td>
                      <td>
                        <b>SALES TAX</b>
                      </td>
                      <td className="td_payment">
                        <b>${ranges.paymentTaxCAD}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr className="print_never_break">
                      <td colSpan={8}></td>
                      <td>
                        <b>TOTAL</b>
                      </td>
                      <td className="td_payment">
                        <b>${ranges.paymentAmountWithTaxCAD}</b>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          />
          <tr className="print_title_row">
            <td colSpan={10}>TOTAL SUM</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <b>SUBTOTAL</b>
            </td>
            <td className="td_payment">
              <b>{`$${selectedStatementContracts
                .reduce((acc, current) => acc + current.paymentAmountCAD, 0)
                .toFixed(2)}`}</b>
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td>
              <b># OF APPS</b>
            </td>
            <td className="td_payment">
              <b>{selectedStatementContracts.length}</b>
            </td>
            <td colSpan={5}></td>
            <td>
              <b>SALES TAX</b>
            </td>
            <td className="td_payment">
              <b>{`$${selectedStatementContracts
                .reduce((acc, current) => acc + current.paymentTaxCAD, 0)
                .toFixed(2)}`}</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={8}></td>
            <td>
              <b>TOTAL</b>
            </td>
            <td className="td_payment">
              <b>{`$${selectedStatementContracts
                .reduce((acc, current) => acc + current.paymentAmountWithTaxCAD, 0)
                .toFixed(2)}`}</b>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
