import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Claim.TakeOwnership.IRequest;
type Response = Api.Core.Claim.TakeOwnership.IResponse;
type Headers = Api.Core.Claim.TakeOwnership.IHeaders;
const url = '/api/Claims/Claim/TakeOwnership';
export default async function claimTakeOwnershipApi(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.put,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
