import { getLocales } from 'assets/locales/Locale';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { useTheme } from '../../../hooks/theme/useTheme';
import RenderProps from '../../helpers/RenderProps';
import useInput from '../Input';

export enum CheckInputStructure {
  checkInput = 'checkInput',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.Check.Structure, typeof CheckInputStructure>;
export default function CheckInput<Name>(props: Component.Input.Check.Import<Name>) {
  const { lang } = getLocales();
  const extend = useTheme(CheckInputStructure, props);
  const { value: currentValue, setValue } = useInput(props);

  const isTwoState = isValidValue(props.primaryState) || isValidValue(props.secondaryState) ? true : false;
  const pState = isTwoState
    ? props.primaryState || (props.strictRadioBehavior ? props.secondaryState : undefined)
    : true;
  const sState = isTwoState
    ? props.secondaryState || (props.strictRadioBehavior ? props.primaryState : undefined)
    : props.strictRadioBehavior
    ? true
    : false;

  const handleChange = () => {
    setValue(
      isTwoState ? (currentValue === pState ? sState : pState) : typeof pState == 'boolean' ? !currentValue : pState
    );
  };
  const isChecked = currentValue === pState;
  const label =
    props.label === 'state'
      ? currentValue === pState
        ? typeof pState !== 'boolean' && isValidValue(pState)
          ? pState
          : lang.on
        : typeof sState !== 'boolean' && isValidValue(sState)
        ? sState
        : lang.off
      : props.label;

  return (
    <div {...extend(CheckInputStructure.checkInput, true)} data-checked={isChecked ? true : null}>
      <div {...extend(CheckInputStructure.input)} onClick={handleChange}>
        {label && typeof label == 'string' && <div className="checkText">{label}</div>}
        {props.icon && props.iconAlt ? (
          isChecked ? (
            RenderProps(props, 'icon')
          ) : (
            RenderProps(props, 'iconAlt')
          )
        ) : (
          <div className="checkIcon"></div>
        )}
      </div>
    </div>
  );
}
