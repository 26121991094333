import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import useApi from 'assets/hooks/api/useApi';
import { compact, first, uniq } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import React, { Fragment, useMemo } from 'react';
import { useDealerProvider } from '../../Dealer.Hooks';
import { DealerForm } from '../Dealer.Edit';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';

const DealerProductDocumentForm: React.FC<{
  additionalProductIds: number[];
  setAdditionalProductIds: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ additionalProductIds }) => {
  const { form } = useModalForm<DealerForm>();
  const { selected } = useDealerProvider();

  const productIds = useMemo(() => {
    const ids = uniq([...compact(selected?.products?.map((p) => p.id)), ...additionalProductIds]);
    return ids.length ? ids : undefined;
  }, [selected?.products, additionalProductIds]);
  const { payload: productsPayload } = useApi(
    {
      action: Product.list,
      wait: !productIds,
      body: {
        productId: productIds,
      },
    },
    [productIds]
  );
  const products = productsPayload?.data;
  return (
    <FormWrapper>
      <DataMap
        data={products}
        render={({ data: product }) =>
          !product.productDocuments.length ? null : (
            <Fragment key={product.id}>
              <h3>{product.name}</h3>
              <DataMap
                data={product.productDocuments}
                render={({ data: productDocument }) => {
                  let currentDocs = form.data.productDealerDocuments ?? [];
                  const foundDocs = currentDocs.filter(
                    (d) => d.productDocumentId === productDocument.id && !d.isDeleted
                  );

                  return (
                    <Fragment key={productDocument.id}>
                      <FileInput
                        name="files"
                        fileLimit={1}
                        value={foundDocs?.map((it) => it.formFile ?? { id: it.id })}
                        onChange={({ files }) => {
                          currentDocs = currentDocs.map((d) => ({
                            ...d,
                            isDeleted: d.productDocumentId === productDocument.id,
                          }));
                          if (files.length) {
                            currentDocs.push({
                              productDocumentId: productDocument.id,
                              productDocument,
                              formFile: first(files),
                            });
                          }
                          form.update({ productDealerDocuments: currentDocs });
                        }}
                        class={ThemeFileInputDropzone}
                        label={<b>{productDocument.displayInfo.title}</b>}
                      />
                    </Fragment>
                  );
                }}
              />
            </Fragment>
          )
        }
      />
    </FormWrapper>
  );
};
export default DealerProductDocumentForm;
