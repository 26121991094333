import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import payeeDeleteApi from 'models/claims/payee/delete/Api.Payee.Delete';
import Payee from 'models/claims/payee/Model.Payee';
import payeeSaveApi from 'models/claims/payee/save/Api.Payee.Save';
import { useMemo } from 'react';
import { payeeIndexRoute } from './Payee.Index';

export enum PayeeAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyPayeeAction = Utils.VerifyExtends<Module.Claims.Payee.Actions, Utils.ValueOf<typeof PayeeAction>>;
export default function usePayee() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Payee.Params, Module.Claims.Payee.Query>({ route: payeeIndexRoute });

  const canView = permissions.CREATE_PAYEE || permissions.EDIT_PAYEE || permissions.DELETE_PAYEE;
  const listApi = useApi({
    action: Payee.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: payeeDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: payeeSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [PayeeAction.DELETE]: pageRouter.params.action === PayeeAction.DELETE && selected && permissions.DELETE_PAYEE,
      [PayeeAction.EDIT]:
        pageRouter.params.action === PayeeAction.EDIT &&
        ((permissions.CREATE_PAYEE && !selected) || (permissions.EDIT_PAYEE && selected)),
    },
  };
}

export function usePayeeProvider() {
  return useDataProvider<ReturnType<typeof usePayee>>();
}
