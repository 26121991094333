import Model from 'models/Model';
import subfeeFindApi from './find/Api.Subfee.Find';
import subfeeListApi from './list/Api.Subfee.List';
import subfeeSaveApi from './save/Api.Subfee.Save';
import subfeeDeleteApi from './delete/Api.Subfee.Delete';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';

export default class Subfee
  extends Model<Model.ISubfee>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ISubfee>
{
  id: number;
  code: string;
  name: string;
  amount: number;
  currency: string;
  documents?: MediaFile[];

  constructor(data: Partial<Model.ISubfee> | Utils.FormData<Model.ISubfee>, language?: Locale.Languages) {
    super({ documents: [MediaFile] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id?.toString(),
      subtitle: this.documents?.length > 0 ? `${this.documents.length} document(s)` : 'No documents uploaded',
    };
  }

  static async list(body?: Api.Core.Subfee.List.IRequest) {
    const { payload, ...rest } = await subfeeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Subfee(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: { id: number }) {
    const { payload, ...rest } = await subfeeFindApi(params);
    return {
      ...rest,
      payload: payload ? new Subfee(payload) : null,
    };
  }
  async save() {
    return await subfeeSaveApi(this);
  }
  async delete() {
    return await subfeeDeleteApi(this.id);
  }
}
