import ThemeBoxBadge from 'assets/components/box/themes/Theme.Box.Badge';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeDropdownPopup from 'assets/components/dropdown/themes/Theme.Dropdown.Popup';

const ThemeDropdownButtonPopup: Template.DropdownButton.Import['subcomponents'] = {
  button: { class: ThemeButtonCircle, animation: { trigger: 'click' } },
  dropdown: { class: ThemeDropdownPopup },
  badge: { class: ThemeBoxBadge },
};
export default ThemeDropdownButtonPopup;
