import Model from 'models/Model';
import Permission from '../permission/Model.Permission';

export default class AdditionalPermission
  extends Model<Model.IAdditionalPermission>({ icon: 'if-login' })
  implements Model.Instance<Model.IAdditionalPermission>
{
  id: number;
  expiresAtUtc?: string;
  permission: Permission;

  constructor(
    data: Partial<Model.IAdditionalPermission> | Utils.FormData<Model.IAdditionalPermission>,
    language?: Locale.Languages
  ) {
    super({ permission: Permission }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.permission?.name || this.id.toString(),
    };
  }
}
