import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import { omit, pick } from 'lodash';
import Model from 'models/Model';

export async function dealerDistributionLimitationSaveApi(body?: Api.Core.DealerDistributionLimitation.Save.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerDistributionLimitation.Save.IRequest,
    Api.Core.DealerDistributionLimitation.Save.IResponse,
    Api.Core.DealerDistributionLimitation.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/DealerManagement/DealerDistributionLimitation',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function dealerDistributionLimitationListApi(
  body?: Api.Core.DealerDistributionLimitation.List.IParams & Api.Core.DealerDistributionLimitation.List.IRequest
) {
  return await executeServerApi<
    Api.Core.DealerDistributionLimitation.List.IRequest,
    Api.Core.DealerDistributionLimitation.List.IResponse,
    Api.Core.DealerDistributionLimitation.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute(
      '/api/DealerManagement/DealerDistributionLimitation/:dealerId/:productId',
      pick(body, 'dealerId', 'productId'),
      omit(body, 'dealerId', 'productId')
    ),
    body: omit(body, 'dealerId', 'productId'),
    headers: getDefaultHeaders(),
  });
}
export async function dealerDistributionLimitationDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerDistributionLimitation.Delete.IRequest,
    Api.Core.DealerDistributionLimitation.Delete.IResponse,
    Api.Core.DealerDistributionLimitation.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/DealerManagement/DealerDistributionLimitation/:id', { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DealerDistributionLimitation
  extends Model<Model.IDealerDistributionLimitation>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IDealerDistributionLimitation>
{
  id: number;
  productId: number;
  dealerId: number;
  pricePointId: number;
  pricePointType: Utils.PricePointType;
  limitations: Utils.DistributionLimitation;

  constructor(
    data: Partial<Model.IDealerDistributionLimitation> | Utils.FormData<Model.IDealerDistributionLimitation>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.id.toString(),
    };
  }

  static async list(
    body?: Api.Core.DealerDistributionLimitation.List.IParams & Api.Core.DealerDistributionLimitation.List.IRequest
  ) {
    const { payload, ...rest } = await dealerDistributionLimitationListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DealerDistributionLimitation(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await dealerDistributionLimitationSaveApi(this);
  }
  async delete() {
    return await dealerDistributionLimitationDeleteApi(this.id);
  }
}
