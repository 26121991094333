import { ClaimCreationType } from 'models/enums/ClaimCreation';
import { combineStrings } from '../data/String';

export function getClaimContact(claim: Model.IClaim) {
  return getClaimCreatedByApp(claim) === ClaimCreationType.PUBLIC
    ? claim?.publicContactName
      ? combineStrings(' ', claim.publicContactName)
      : 'N/A'
    : combineStrings(' ', claim.owner?.firstName, claim.owner?.lastName);
}

export function getClaimCreatedByApp(claim: Model.IClaim) {
  return claim?.publicContactEmail && claim?.publicContactName && claim?.publicContactPhone
    ? ClaimCreationType.PUBLIC
    : ClaimCreationType.ASC;
}
