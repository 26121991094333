import React from 'react';
import RenderProps from '../helpers/RenderProps';
import Icon, { iconStyles } from '../icon/Icon';
import TextBox from '../textBox/TextBox';
import { useTheme } from '../../hooks/theme/useTheme';
import { getLocales } from 'assets/locales/Locale';

export enum TagStructure {
  tag = 'tag',
  tagAction = 'tagAction',
  tagActionAlt = 'tagActionAlt',
  tagIconAlt = 'tagIconAlt',
}
export type Verify = Utils.VerifyExtends<Component.Tag.Structure, typeof TagStructure>;
export default function Tag<T>(props: Component.Tag.Import<T>) {
  const extend = useTheme(TagStructure, props);
  const { lang } = getLocales();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.data);
    }
  };
  const handleClickAlt = () => {
    if (props.onClickAlt) {
      props.onClickAlt(props.data);
    }
  };
  return (
    <div {...extend(TagStructure.tag, true)}>
      <div onClick={handleClick} {...extend(TagStructure.tagAction)}>
        {RenderProps(props, 'media')}
        <TextBox>{RenderProps(props, 'text')}</TextBox>
      </div>
      {props.onClickAlt && (
        <div onClick={handleClickAlt} {...extend(TagStructure.tagActionAlt)}>
          {props.mediaAlt ? (
            RenderProps(props, 'mediaAlt')
          ) : (
            <Icon htmlElementProps={{ title: lang.remove }} class={iconStyles.common.times} />
          )}
        </div>
      )}
    </div>
  );
}
