import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import vehicleMakeDeleteApi from 'models/productManagement/vehicleMake/delete/Api.VehicleMake.Delete';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import vehicleMakeSaveApi from 'models/productManagement/vehicleMake/save/Api.VehicleMake.Save';
import { useMemo } from 'react';
import { vehicleMakeIndexRoute } from './VehicleMake.Index';
import vehicleMakeImportExcelApi from 'models/productManagement/vehicleMake/importExcel/Api.VehicleMake.ImportExcel';

export enum VehicleMakeAction {
  EDIT = 'edit',
  DELETE = 'delete',
  IMPORT_EXCEL = 'importExcel',
}
export type VerifyVehicleMakeAction = Utils.VerifyExtends<
  Module.ProductManagement.VehicleMake.Actions,
  Utils.ValueOf<typeof VehicleMakeAction>
>;
export default function useVehicleMake() {
  const { permissions } = useAuthStore();

  const pageRouter = usePageRouter<
    Module.ProductManagement.VehicleMake.Params,
    Module.ProductManagement.VehicleMake.Query
  >({
    route: vehicleMakeIndexRoute,
  });

  const canView =
    permissions.CREATE_VEHICLEMAKE ||
    permissions.EDIT_VEHICLEMAKE ||
    permissions.DELETE_VEHICLEMAKE ||
    permissions.IMPORT_VEHICLEMAKE_FROM_EXCEL;

  const listApi = useApi({
    action: VehicleMake.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: vehicleMakeDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: vehicleMakeSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const importExcelApi = useApi({
    action: vehicleMakeImportExcelApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    importExcelApi,
    selected,
    permissions,
    views: {
      [VehicleMakeAction.DELETE]:
        pageRouter.params.action === VehicleMakeAction.DELETE && selected && permissions.DELETE_VEHICLEMAKE,
      [VehicleMakeAction.EDIT]:
        pageRouter.params.action === VehicleMakeAction.EDIT &&
        ((permissions.CREATE_VEHICLEMAKE && !selected) || (permissions.EDIT_VEHICLEMAKE && selected)),
      [VehicleMakeAction.IMPORT_EXCEL]:
        pageRouter.params.action === VehicleMakeAction.IMPORT_EXCEL && permissions.IMPORT_VEHICLEMAKE_FROM_EXCEL,
    },
  };
}

export function useVehicleMakeProvider() {
  return useDataProvider<ReturnType<typeof useVehicleMake>>();
}
