import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.PayeeType.List.IRequest;
type Response = Api.Core.PayeeType.List.IResponse;
type Headers = Api.Core.PayeeType.List.IHeaders;
const url = '/api/Claims/PayeeType';
export default async function payeeTypeListApi(body?: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url,
    body,
    headers: getDefaultHeaders(),
  });
}
