import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { compact, first } from 'lodash';
import React from 'react';
import { useLossCodeProvider } from '../LossCode.Hooks';
import MimeType from 'assets/utils/file/mimeType/MimeType';
import lossCodeImportApi from 'models/claims/lossCode/import/Api.LossCode.Import';
import { HttpStatus } from 'config/Api.Config';
import { addLog } from 'assets/components/feedback/Feedback';

type ImportFile = Api.Core.LossCode.Import.IRequest & {
  file: Hooks.Input.File.Info;
};

const LossCodeImport: React.FC = () => {
  const { lang } = getLocales();
  const { pageRouter, listApi } = useLossCodeProvider();

  return (
    <ModalForm<ImportFile>
      subcomponents={ThemeModalFormDefault}
      title={lang.lossCodesImportExcel}
      onCancel={pageRouter.updateParams}
      onSubmit={async ({ file }) => {
        const response = await lossCodeImportApi({ file: file.original });

        if (response.status !== HttpStatus.ok) {
          addLog({ error: response.message });
        }

        addLog({ success: `LossCodes successfully imported from Excel.` });
        listApi.execute((body) => body);
      }}
      render={({ form }) => (
        <FormWrapper>
          <FileInput
            name="file"
            allowedMimeTypes={[MimeType.appMS_EXCEL, MimeType.appOPEN_XML_SHEET]}
            label={lang.lossCodesImportExcel}
            fileLimit={1}
            value={compact([form.data?.file])}
            onChange={({ file }) => {
              form.update({ file: first(file) });
            }}
            class={ThemeFileInputDropzone}
          />
        </FormWrapper>
      )}
    />
  );
};

export default LossCodeImport;
