import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { orderBy, sortBy, uniqBy } from 'lodash';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import { useEffect, useState } from 'react';
import { useVehicleClassProvider } from '../VehicleClass.Hooks';
import { combineStrings } from 'assets/utils/data/String';

export default function VehicleClassEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useVehicleClassProvider();
  const { payload: vehicleMakesPayload } = useApi({ action: VehicleMake.list });
  const {
    payload: vehicleModelsPayload,
    execute: fetchModels,
    reset: resetModels,
  } = useApi({ action: VehicleModel.list });

  const [selectedMake, setMake] = useState<Model.IVehicleMake>();
  useEffect(() => {
    if (selectedMake) fetchModels({ makeCode: selectedMake.makeCode });
    else if (vehicleModelsPayload?.count) resetModels();
  }, [selectedMake]);
  return (
    <ModalForm<Api.Core.VehicleClass.Save.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.vehicleClass}
      default={selected?.toJSON() || {}}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) => saveApi.execute(data)}
      validation={(data, errors) => {
        if (!data.primaryCode) errors.primaryCode = lang.mustNotBeEmpty;
        if (!data.secondaryCode) errors.secondaryCode = lang.mustNotBeEmpty;
      }}
      render={({ form }) => {
        const finalModels = uniqBy(
          [
            ...(vehicleModelsPayload?.data?.map((it) => it.toJSON()) || []),
            ...(selected?.vehicleModels || []),
            ...(form.data?.vehicleModels || []),
          ],
          (it) => it.id
        );

        return (
          <FormWrapper>
            <InputWrapper>
              <TextInput
                name="primaryCode"
                label={lang.primaryCode}
                value={form.data.primaryCode}
                error={form.errors.primaryCode}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="secondaryCode"
                label={lang.secondaryCode}
                value={form.data.secondaryCode}
                error={form.errors.secondaryCode}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
            </InputWrapper>
            <MultilineTextInput
              name="description"
              label={lang.description}
              value={form.data.description}
              error={form.errors.description}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <SelectInput
              name="vehicleMakeId"
              label={lang.vehicleMake}
              icon={<Icon class="fas-search" />}
              data={sortBy(
                vehicleMakesPayload?.data?.map((it) => it.displayInfo),
                'title'
              )}
              value={selectedMake?.id}
              onChange={({ vehicleMakeId }) => setMake(vehicleMakesPayload?.data?.find((d) => d.id === vehicleMakeId))}
            />
            <SelectInput
              name="vehicleModelId"
              label={lang.vehicleModel}
              data={sortBy(
                finalModels.map((it) => ({
                  id: it.id,
                  subtitle: it.name,
                  title: combineStrings(' - ', it.vehicleMake?.makeCode, it.code),
                })),
                'title'
              )}
              value={orderBy(form.data.vehicleModels, ['vehicleMake.makeCode', 'name'], ['asc', 'asc'])?.map(
                (it) => it.id
              )}
              error={form.errors.vehicleModels?._objectError}
              disabled={!finalModels}
              onChange={(selectData) =>
                form.update({
                  vehicleModels: selectData.vehicleModelId
                    .map((it) => finalModels?.find((d) => d.id === it))
                    ?.filter((it) => (it ? true : false)),
                })
              }
              isMultiselect
              icon={<Icon class="fas-car" />}
            />
          </FormWrapper>
        );
      }}
    />
  );
}
