import React from 'react';
import RenderProps from '../helpers/RenderProps';

export default function DataObjectMap<T>(props: Component.DataObjectMap.Import<T>) {
  let finalMap: Component.DataObjectMap.Export<T>[] = [];
  if (props && props.data) {
    const keys: Utils.KeyOf<T>[] = Object.keys(props.data) as any;
    finalMap = keys.map((key, i) => ({
      key: key,
      index: i,
      length: keys.length,
      isFirst: i === 0,
      isLast: i === keys.length - 1,
      data: props.data![key],
      originalData: props.data!,
    }));
  }
  return <>{finalMap.map((dom) => RenderProps(props, 'render', dom))}</>;
}
