enum ProductDocumentPropertyPath {
  'contract.appNumber' = 'contract.appNumber',
  'contract.creator' = 'contract.creator',
  'contract.effectiveDate' = 'contract.effectiveDate',
  'contract.expiryDate' = 'contract.expiryDate',
  'contract.termInMonths' = 'contract.termInMonths',
  'contract.maxKm' = 'contract.maxKm',
  'contract.salePriceCAD' = 'contract.salePriceCAD',
  'contract.priceCAD' = 'contract.priceCAD',
  'contract.totalTaxCAD' = 'contract.totalTaxCAD',
  'contract.salePriceWithTaxCAD' = 'contract.salePriceWithTaxCAD',
  'contract.dealerCost' = 'contract.dealerCost',
  'contract.dealerCostTaxAmt' = 'contract.dealerCostTaxAmt',
  'contract.dealerCostTotal' = 'contract.dealerCostTotal',
  'contract.dealerCost75Percent' = 'contract.dealerCost75Percent',
  'contract.dealerProfit' = 'contract.dealerProfit',
  'contract.dealerProfitTaxAmt' = 'contract.dealerProfitTaxAmt',
  'contract.coverageKm' = 'contract.coverageKm',
  'contract.customInfo' = 'contract.customInfo',
  'contract.customInfoGroup' = 'contract.customInfoGroup',
  'contract.coApplicantFirstName' = 'contract.coApplicantFirstName',
  'contract.coApplicantLastName' = 'contract.coApplicantLastName',
  'contract.warrantyOption' = 'contract.warrantyOption',
  'contract.postSale' = 'contract.postSale',
  'contract.deductible' = 'contract.deductible',
  'contract.subfees' = 'contract.subfees',
  'contract.vehicle.vin' = 'contract.vehicle.vin',
  'contract.vehicle.year' = 'contract.vehicle.year',
  'contract.vehicle.makeCode' = 'contract.vehicle.makeCode',
  'contract.vehicle.model' = 'contract.vehicle.model',
  'contract.vehicle.bodyType' = 'contract.vehicle.bodyType',
  'contract.vehicle.bodyColor' = 'contract.vehicle.bodyColor',
  'contract.vehicle.posOdometerReading' = 'contract.vehicle.posOdometerReading',
  'contract.vehicle.stockNumber' = 'contract.vehicle.stockNumber',
  'contract.vehicle.engineSize' = 'contract.vehicle.engineSize',
  'contract.vehicle.purchaseDate' = 'contract.vehicle.purchaseDate',
  'contract.vehicle.deliveryDate' = 'contract.vehicle.deliveryDate',
  'contract.vehicle.classDate' = 'contract.vehicle.classDate',
  'contract.vehicle.purchasePrice' = 'contract.vehicle.purchasePrice',
  'contract.vehicle.inServiceDate' = 'contract.vehicle.inServiceDate',
  'contract.vehicle.mfgPowertrainWarrantyMonths' = 'contract.vehicle.mfgPowertrainWarrantyMonths',
  'contract.vehicle.mfgPowertrainWarrantyKm' = 'contract.vehicle.mfgPowertrainWarrantyKm',
  'contract.vehicle.mfgWholeVehicleWarrantyMonths' = 'contract.vehicle.mfgWholeVehicleWarrantyMonths',
  'contract.vehicle.mfgWholeVehicleWarrantyKm' = 'contract.vehicle.mfgWholeVehicleWarrantyKm',
  'contract.vehicle.trimLevel' = 'contract.vehicle.trimLevel',
  'contract.vehicle.engineType' = 'contract.vehicle.engineType',
  'contract.vehicle.driveLine' = 'contract.vehicle.driveLine',
  'contract.vehicle.purchaseState' = 'contract.vehicle.purchaseState',
  'contract.vehicle.purchaseType' = 'contract.vehicle.purchaseType',
  'contract.vehicle.financedAmount' = 'contract.vehicle.financedAmount',
  'contract.vehicle.interestRate' = 'contract.vehicle.interestRate',
  'contract.vehicle.lender.id' = 'contract.vehicle.lender.id',
  'contract.vehicle.lender.phoneNumber' = 'contract.vehicle.lender.phoneNumber',
  'contract.vehicle.lender.email' = 'contract.vehicle.lender.email',
  'contract.vehicle.lender.name' = 'contract.vehicle.lender.name',
  'contract.vehicle.lender.streetAddress' = 'contract.vehicle.lender.streetAddress',
  'contract.vehicle.lender.postalCode' = 'contract.vehicle.lender.postalCode',
  'contract.vehicle.lender.muncipality' = 'contract.vehicle.lender.muncipality',
  'contract.vehicle.lender.city' = 'contract.vehicle.lender.city',
  'contract.vehicle.lender.provinceCode' = 'contract.vehicle.lender.provinceCode',
  'contract.vehicle.lender.countryCode' = 'contract.vehicle.lender.countryCode',
  'contract.dealer.id' = 'contract.dealer.id',
  'contract.dealer.name' = 'contract.dealer.name',
  'contract.dealer.primaryPhoneNumber' = 'contract.dealer.primaryPhoneNumber',
  'contract.dealer.primaryEmailAddress' = 'contract.dealer.primaryEmailAddress',
  'contract.dealer.streetAddress' = 'contract.dealer.streetAddress',
  'contract.dealer.postalCode' = 'contract.dealer.postalCode',
  'contract.dealer.muncipality' = 'contract.dealer.muncipality',
  'contract.dealer.city' = 'contract.dealer.city',
  'contract.dealer.provinceCode' = 'contract.dealer.provinceCode',
  'contract.dealer.countryCode' = 'contract.dealer.countryCode',
  'contract.client.email' = 'contract.client.email',
  'contract.client.firstName' = 'contract.client.firstName',
  'contract.client.lastName' = 'contract.client.lastName',
  'contract.client.phone' = 'contract.client.phone',
  'contract.client.streetAddress' = 'contract.client.streetAddress',
  'contract.client.postalCode' = 'contract.client.postalCode',
  'contract.client.muncipality' = 'contract.client.muncipality',
  'contract.client.city' = 'contract.client.city',
  'contract.client.provinceCode' = 'contract.client.provinceCode',
  'contract.client.countryCode' = 'contract.client.countryCode',
  'contract.client.taxExempt' = 'contract.client.taxExempt',
  'contract.client.taxExemptionBand' = 'contract.client.taxExemptionBand',
  'contract.product.code' = 'contract.product.code',
  'contract.product.name' = 'contract.product.name',
  'contract.product.productType' = 'contract.product.productType',
  'contract.product.canSellPostSale' = 'contract.product.canSellPostSale',
  'contract.product.category.code' = 'contract.product.category.code',
  'contract.product.category.name' = 'contract.product.category.name',
}
export default ProductDocumentPropertyPath;
