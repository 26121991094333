import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { compact, get, uniq } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { DealerForm } from '../Dealer.Edit';
import { useDealerProvider } from '../../Dealer.Hooks';
import Product from 'models/productManagement/product/Model.Product';
import useApi from 'assets/hooks/api/useApi';

const DealerDistributionDiscountForm: React.FC<{
  additionalProductIds: number[];
  setAdditionalProductIds: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ additionalProductIds }) => {
  const { lang } = getLocales();
  const { form } = useModalForm<DealerForm>();
  const { selected } = useDealerProvider();

  const productIds = useMemo(() => {
    const ids = uniq([...compact(selected?.products?.map((p) => p.id)), ...additionalProductIds]);
    return ids.length ? ids : undefined;
  }, [selected?.products, additionalProductIds]);
  const { payload: productsPayload } = useApi(
    {
      action: Product.list,
      wait: !productIds,
      body: {
        productId: productIds,
      },
    },
    [productIds]
  );
  const products = productsPayload?.data;
  const distributionDiscounts = form.data.distributionDiscounts;

  return (
    <table className={ThemeTableSimple2}>
      <thead>
        <tr>
          <th children={lang.pricingDistributionTemplate} style={{ paddingLeft: Theme.Size.L }} />
          <th children={lang.amount} />
          <th children={lang.discountType} />
        </tr>
      </thead>
      <tbody>
        <DataMap
          data={products}
          render={({ data: product }) => (
            <Fragment key={product.id}>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    paddingLeft: Theme.Size.L,
                    backgroundColor: Theme.Color.primary,
                    color: Theme.Color.primaryAlt,
                    fontWeight: 'bold',
                  }}
                >
                  {product.name}
                </td>
              </tr>
              <DataMap
                data={product?.pricingDistributionTemplate?.keys}
                render={({ data: templateKey }) => {
                  const discountsForProduct = distributionDiscounts?.find((it) => it.productId === product.id);
                  const otherDiscounts = distributionDiscounts?.filter((it) => it.productId !== product.id);
                  const distributionDiscount = get(discountsForProduct?.distributionDiscount, templateKey);
                  return (
                    <tr key={templateKey}>
                      <td style={{ paddingLeft: Theme.Size.L }}>
                        {templateKey.replace(/^\d+\./g, '').replace(/\./g, ' - ')}
                      </td>
                      <td>
                        <NumberInput
                          name="amount"
                          value={distributionDiscount?.amount}
                          error={
                            distributionDiscount
                              ? !distributionDiscount.amount
                                ? lang.mustNotBeEmpty
                                : distributionDiscount.amount <= 0
                                ? lang.mustBeMoreThanAmount.replace('{amount}', '0')
                                : undefined
                              : undefined
                          }
                          onChange={({ amount }) =>
                            form.merge({
                              distributionDiscounts: [
                                ...(otherDiscounts ?? []),
                                {
                                  productId: product.id,
                                  distributionDiscount: {
                                    ...discountsForProduct?.distributionDiscount,
                                    [templateKey]: { ...distributionDiscount, amount },
                                  },
                                },
                              ],
                            })
                          }
                        />
                      </td>
                      <td style={{ paddingRight: Theme.Size.L }}>
                        <SelectInput
                          name="unit"
                          data={[
                            { id: 'percent', title: 'Percent (%)' },
                            { id: 'addition', title: 'Addition (+- $)' },
                          ]}
                          value={distributionDiscount?.unit}
                          error={distributionDiscount && !distributionDiscount.unit ? lang.mustNotBeEmpty : undefined}
                          onChange={({ unit }) =>
                            form.merge({
                              distributionDiscounts: [
                                ...(otherDiscounts ?? []),
                                {
                                  productId: product.id,
                                  distributionDiscount: {
                                    ...discountsForProduct?.distributionDiscount,
                                    [templateKey]: { ...distributionDiscount, unit },
                                  },
                                },
                              ],
                            })
                          }
                        />
                      </td>
                    </tr>
                  );
                }}
              />
            </Fragment>
          )}
        />
      </tbody>
    </table>
  );
};
export default DealerDistributionDiscountForm;
