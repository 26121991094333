import Theme from 'assets/themes/Theme.Common';
import { useClaimEdit } from '../ClaimEdit.Hooks';
import DataMap from 'assets/components/dataMap/DataMap';
import Button from 'assets/components/button/Button';
import ThemeButtonLink from 'assets/components/button/themes/Theme.Button.Link';
export default function ClaimEditTabs() {
  const { tabs, navigateToTab } = useClaimEdit();

  return (
    <div style={{ display: 'flex', gap: Theme.Size.M }}>
      <DataMap
        data={tabs}
        render={({ data: tab }) => (
          <Button key={tab.value} class={ThemeButtonLink} onClick={() => navigateToTab(tab.value)}>
            {tab.label} {tab.count ? `(${tab.count})` : null}
          </Button>
        )}
      />
    </div>
  );
}
