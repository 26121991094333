import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { ContractDraft } from '../../newContract/Sales.NewContract.Hooks';
import { useSalesDraftProvider } from '../Sales.Draft.Hooks';

export default function DraftDelete() {
  const { lang } = getLocales();
  const { deleteApi, selected, pageRouter } = useSalesDraftProvider();
  const contractDraft = { form: selected?.data, dateUpdated: '2023-01-01 00:00:00' } as ContractDraft;
  const vehicleInfo = combineStrings(
    '/',
    contractDraft?.form.vehicle?.year?.toString(),
    contractDraft?.form.vehicle?.makeCode,
    contractDraft?.form.vehicle?.model
  );
  return (
    <Confirm
      data={selected}
      onConfirm={({ id }) => deleteApi.execute(id)}
      onClose={pageRouter.updateParams}
      actionName={lang.delete}
      itemName={vehicleInfo}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
