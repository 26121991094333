import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import { useClaimTypeProvider } from '../ClaimType.Hooks';

export default function ClaimTypeFilters() {
  const { lang } = getLocales();
  const { listApi, productsApi } = useClaimTypeProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onOpen={() => {
          if (!productsApi.payload) productsApi.execute((body) => body);
        }}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        render={({ tempFilters, setFilters }) => (
          <>
            <SelectInput
              name="productId"
              label={lang.products}
              data={productsApi.payload?.data?.map((item) => ({
                ...item.displayInfo,
                icon: <Icon class="fas-box" />,
              }))}
              isMultiselect
              value={tempFilters?.productId}
              onChange={({ productId }) => setFilters({ ...tempFilters, productId })}
              icon={<Icon class="fas-box" />}
            />
            <CheckInput
              name="withDeleted"
              label={lang.withArchived}
              class={inputStyles.check.switch}
              value={tempFilters?.withDeleted}
              onChange={({ withDeleted }) => setFilters({ ...tempFilters, withDeleted: Boolean(withDeleted) })}
              htmlElementProps={{ title: tempFilters?.withDeleted ? lang.disable : lang.enable }}
            />
          </>
        )}
      />
    </>
  );
}
