import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import { cloneDeep, compact, difference, merge, min, uniq, xor } from 'lodash';
import { CanadaProvinces } from 'models/enums/CanadaProvinces';
import { useState } from 'react';
import { useProductProvider } from '../../Product.Hooks';

const input: React.CSSProperties = { textAlign: 'right' };

export default function ProductEditProvinceGroups() {
  const { form } = useProductProvider();
  const { lang } = getLocales();
  function updateProvince(group: Utils.FormData<Model.IProductProvinceGroup>) {
    form.update({
      productProvinceGroups: compact([
        ...(form.data.productProvinceGroups?.map((g) => {
          if (g.id === group.id) return group;
          else {
            g.provinceCodes = difference(g.provinceCodes || [], group.provinceCodes || []);
            return g;
          }
        }) || []),
        !form.data.productProvinceGroups?.some((g) => g.id === group.id) ? group : undefined,
      ]),
    });
    setTempGroupName(null);
  }

  const lowestId = min(form.data.productProvinceGroups?.map((g) => g.id) || []) || 0;
  const [tempGroupName, setTempGroupName] = useState<string | null>(null);
  return (
    <FormWrapper class={ThemeWrapperPaper}>
      <table className={ThemeTableSimple2}>
        <thead>
          <tr>
            <th children={lang.province} />
            <th children={lang.group} />
          </tr>
        </thead>
        <tbody>
          <DataObjectMap
            data={CanadaProvinces}
            render={({ data: provinceName, key: provinceCode }) => {
              const selectedGroup = form.data.productProvinceGroups?.find((g) =>
                g.provinceCodes?.some((p) => p === provinceCode)
              );
              return (
                <tr key={provinceCode}>
                  <td>
                    {provinceName} ({provinceCode})
                  </td>

                  <td>
                    <InputWrapper>
                      <TextInput
                        name="name"
                        value={selectedGroup?.name || lang.default}
                        disabled={!selectedGroup}
                        onChange={({ name }) => updateProvince({ ...selectedGroup, name })}
                        style={{ input: input }}
                      />
                      <DropdownButton
                        disabled={!form.data.productProvinceGroups?.length}
                        subcomponents={merge(cloneDeep(ThemeDropdownButtonPopup), {
                          button: { style: { border: `${Theme.Size.XXS} solid ${Theme.Color.shadowDark}` } },
                        })}
                        buttonMedia="fas-chevron-down"
                        horizontalPosition="auto_fixed"
                        verticalPosition="auto_fixed"
                        dropdownOptions={
                          <>
                            <DataMap
                              data={form.data.productProvinceGroups}
                              render={({ data: group }) => (
                                <Button
                                  class={ThemeButtonMenuItem}
                                  onClick={() =>
                                    updateProvince({
                                      ...group,
                                      provinceCodes: uniq(xor(group.provinceCodes || [], [provinceCode])),
                                    })
                                  }
                                  active={group.provinceCodes?.some((code) => code === provinceCode)}
                                  render={group.name}
                                />
                              )}
                            />
                          </>
                        }
                      />
                    </InputWrapper>
                  </td>
                </tr>
              );
            }}
          />

          <tr>
            <td>
              <Button
                onClick={updateProvince}
                data={{ id: lowestId - 1, name: tempGroupName }}
                media="fas-plus"
                class={ThemeButtonCircle}
                disabled={!tempGroupName}
              >
                {lang.addNew}
              </Button>
            </td>
            <td>
              <TextInput
                name="name"
                value={tempGroupName}
                onChange={({ name }) => setTempGroupName(name)}
                style={{ input: input }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FormWrapper>
  );
}
