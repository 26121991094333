import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { date, defaultServerDateFormat } from 'assets/utils/data/Date';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import AuditLog from 'models/audit/auditLog/Model.AuditLog';
import Application from 'models/core/application/Model.Application';
import Permission from 'models/core/permission/Model.Permission';
import User from 'models/core/user/Model.User';
import { useMemo } from 'react';
import { auditLogIndexRoute } from './AuditLog.Index';

export default function useAuditLog() {
  const { hasAccess } = useAuthStore();
  const canView = hasAccess(AuthPermission.viewAuditLogs);
  const pageRouter = usePageRouter<Module.Management.AuditLog.Params, Module.Management.AuditLog.Query>({
    route: auditLogIndexRoute,
  });

  //#region APIS
  const listApi = useApi({
    action: AuditLog.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
      startDateUtc: date().subtract(5, 'days').format(defaultServerDateFormat),
      endDateUtc: date().format(defaultServerDateFormat),
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.updateQuery({ ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const userListApi = useApi({ action: User.list });
  const users = userListApi.payload?.data;

  const permissionListApi = useApi({ action: Permission.list });
  const permissions = permissionListApi.payload?.data;

  const applicationListApi = useApi({ action: Application.list });
  const applications = applicationListApi.payload?.data;
  //#endregion

  const selected = useMemo(
    () => listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id),
    [pageRouter.params.id, listApi.payload]
  );
  const isSelectedEdited = useMemo(() => selected?.isChange, [selected]);

  return {
    canView,
    pageRouter,
    listApi,
    selected,
    isSelectedEdited,
    permissions,
    applications,
    users,
  };
}

export function useAudtiLogProvider() {
  return useDataProvider<ReturnType<typeof useAuditLog>>();
}
