import { template } from 'lodash';
import ValidationRegex from './Utils.Data.ValidationRegex';

export function combineStrings(separator: string, ...text: (string | undefined)[]) {
  return text
    ? text
        .filter((it) => (it ? true : false))
        .map((it) => (it ? it.replace(/\s+/g, ' ').trim() : ''))
        .join(separator)
    : '';
}
export function shortenText(text: string, threshold = 10) {
  return text.length > threshold ? `${text.slice(0, threshold)}...` : text;
}

export function replaceStringTemplateWithValues(stringToReplace: string, values: Record<string, string>) {
  return template(stringToReplace, { interpolate: ValidationRegex.templatePlaceholderRegex })(values);
}
