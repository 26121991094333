import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Dealer.UploadFile.IRequest;
type Response = Api.Core.Dealer.UploadFile.IResponse;
type Headers = Api.Core.Dealer.UploadFile.IHeaders;
const apiDealerUploadFilePath = '/api/DealerManagement/Dealer/:id/UploadFile';
export default async function dealerUploadFileApi(id: number, request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiDealerUploadFilePath, { id }),
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
