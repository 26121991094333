import { combineStrings } from 'assets/utils/data/String';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import InputType from 'models/enums/InputType';
import Model from 'models/Model';

export default class ProductAdditionalField
  extends Model<Model.IProductAdditionalField>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductAdditionalField>
{
  id: number;
  key: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  group?: string;
  label?: string;
  type?: InputType;
  propertyPath?: string;
  productDocumentId?: number;
  defaultValue?: string | number | boolean;

  constructor(
    data: Partial<Model.IProductAdditionalField> | Utils.FormData<Model.IProductAdditionalField>,
    language?: Locale.Languages
  ) {
    super({ claimTypes: [ClaimType] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' | ', this.label, this.key),
      info: this.group,
      subtitle: this.type,
    };
  }
}
