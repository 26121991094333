import React from 'react'
import { useTheme } from 'assets/hooks/theme/useTheme';
import DOMpurify from 'dompurify';
import RenderProps from '../helpers/RenderProps';
import { TextBoxStructure } from './TextBox';

export default function Sanitizer(props: Component.TextBox.Import) {
  const extend = useTheme(TextBoxStructure, props);
  const finalText = props.text || RenderProps(props, 'render');
  const sanitizedText = finalText && DOMpurify.sanitize(finalText.toString());
  return !sanitizedText ? null : (
    <div
      {...extend(TextBoxStructure.textBox, true)}
      data-sanitized={true}
      dangerouslySetInnerHTML={finalText && { __html: sanitizedText }}
    />
  );
}
