import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import Model from 'models/Model';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Product from 'models/productManagement/product/Model.Product';

export async function distributorProductPricingDistributionSaveApi(
  body?: Api.Core.DistributorProductPricingDistribution.Save.IRequest
) {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductPricingDistribution.Save.IRequest,
    Api.Core.DistributorProductPricingDistribution.Save.IResponse,
    Api.Core.DistributorProductPricingDistribution.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/DistributorProduct/DistributorProductPricingDistribution',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function distributorProductPricingDistributionListApi(
  body?: Api.Core.DistributorProductPricingDistribution.List.IRequest
) {
  return await executeServerApi<
    Api.Core.DistributorProductPricingDistribution.List.IRequest,
    Api.Core.DistributorProductPricingDistribution.List.IResponse,
    Api.Core.DistributorProductPricingDistribution.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute('/api/ProductManagement/DistributorProduct/DistributorProductPricingDistribution'),
    body,
    headers: getDefaultHeaders(),
  });
}
export async function distributorProductPricingDistributionDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductPricingDistribution.Delete.IRequest,
    Api.Core.DistributorProductPricingDistribution.Delete.IResponse,
    Api.Core.DistributorProductPricingDistribution.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/ProductManagement/DistributorProduct/DistributorProductPricingDistribution/:id', {
      id,
    }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DistributorProductPricingDistribution
  extends Model<Model.IDistributorProductPricingDistribution>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributorProductPricingDistribution>
{
  id: number;
  pricePointId: number;
  pricePointType: Utils.PricePointType;
  distributions: Utils.PricingDistribution;
  distributorProductId: number;

  constructor(
    data: Partial<Model.IDistributorProduct> | Utils.FormData<Model.IDistributorProduct>,
    language?: Locale.Languages
  ) {
    super({ pricingDistributionTemplate: PricingDistributionTemplate, product: Product }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.pricePointType || this.id.toString(),
    };
  }
}
