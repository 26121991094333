import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Params = Api.Core.ClaimDocument.SavePublic.IParams;
type Request = Api.Core.ClaimDocument.SavePublic.IRequest;
type Response = Api.Core.ClaimDocument.SavePublic.IResponse;
type Headers = Api.Core.ClaimDocument.SavePublic.IHeaders;
const url = '/api/Public/UploadDocument/:contractNumber/:vin/:clientName/:id';
export default async function claimDocumentSavePublicApi(props: {
  body: Request;
  params: Params;
}): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, props.params),
    body: props.body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
