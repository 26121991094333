import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Product from '../product/Model.Product';
import insurerDeleteApi from './delete/Api.Insurer.Delete';
import insurerListApi from './list/Api.Insurer.List';
import insurerSaveApi from './save/Api.Insurer.Save';

export default class Insurer
  extends Model<Model.IInsurer>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IInsurer>
{
  id: number;
  name: string;
  code: string;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  provinceCode?: string;
  countryCode?: string;
  isActive?: boolean;
  products?: Product[];

  constructor(data: Partial<Model.IInsurer> | Utils.FormData<Model.IInsurer>, language?: Locale.Languages) {
    super({ products: [Product] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      info: combineStrings(', ', this.streetAddress, this.postalCode, this.city, this.provinceCode, this.countryCode),
      subtitle: this.products?.map((p) => p.name).join(' | '),
    };
  }

  static async list(body?: Api.Core.Insurer.List.IRequest) {
    const { payload, ...rest } = await insurerListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Insurer(it)),
        count: payload.count,
      },
    };
  }

  async save() {
    return await insurerSaveApi(this);
  }
  async delete() {
    return await insurerDeleteApi(this.id);
  }
}
