import { addLog } from 'assets/components/feedback/Feedback';
import { preventDefaultBehavior } from 'assets/utils/dom/Events';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import DataSize from 'assets/utils/units/enums/dataSize/DataSize';
import convertDataSize from 'assets/utils/units/Utils.Units.ConvertDataSize';
import { useEffect, useRef, useState } from 'react';

export default function useFileInput(props: Hooks.Input.File.Import) {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    preventDefaultBehavior(event);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) handleChange(event.dataTransfer.files);
  };
  const handleEnter = (event: React.DragEvent<HTMLElement>) => {
    preventDefaultBehavior(event);
    setIsActive(true);
  };
  const handleLeave = (event: React.DragEvent<HTMLElement>) => {
    preventDefaultBehavior(event);
    setIsActive(false);
  };
  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    preventDefaultBehavior(event);
    if (fileInput && fileInput.current) fileInput.current.click();
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    preventDefaultBehavior(event);
    handleChange(event.currentTarget.files);
    event.currentTarget.value = '';
  };
  useEffect(() => {
    document.body.addEventListener('dragover', preventDefaultBehavior, false);
    return () => document.body.removeEventListener('dragover', preventDefaultBehavior, false);
  });

  const values = props.value || [];
  const totalFiles = values.length;

  let limit = isValidValue(props.fileLimit) ? props.fileLimit! - totalFiles : Infinity;
  if (limit < 0) limit = 0;
  const isDropAllowed = limit > 0;

  function removeFile(index: number) {
    values.splice(index, 1);
    props.onChange({ [props.name]: values });
  }
  function handleChange(fileList?: FileList | null) {
    let uploadedFiles = (fileList ? Array.from(fileList as any) : []) as File[];

    const currentFileCount = totalFiles;
    let matchedFileCount = currentFileCount;
    uploadedFiles = uploadedFiles.filter((f) => {
      let isMatch;
      if (props.allowedMimeTypes) {
        const [fileCategory, fileType] = f.type.split('/');
        props.allowedMimeTypes.forEach((allowedMimeType) => {
          const [allowedCategory, allowedType] = allowedMimeType.split('/');
          if (allowedCategory === '*') isMatch = true;
          else if (allowedCategory === fileCategory && (allowedType === '*' || allowedType === fileType))
            isMatch = true;
        });
      } else isMatch = true;

      if (isMatch === true) {
        if (
          props.maxFileUploadSizeMB &&
          props.maxFileUploadSizeMB > 0 &&
          convertDataSize(props.maxFileUploadSizeMB, DataSize.megaByte, DataSize.byte) < f.size
        ) {
          isMatch = false;
        }
      }

      if (isMatch) matchedFileCount += 1;
      if (isMatch && (!props.fileLimit || matchedFileCount - 1 < props.fileLimit)) return true;
      else return false;
    });

    if (props.fileLimit && matchedFileCount > props.fileLimit) addLog({ error: 'file_limit_reached' });
    else {
      const finalValues = values;
      for (const uploadedFile of uploadedFiles) {
        finalValues.push({
          type: uploadedFile.type as Utils.File.MimeType,
          dataUrl: window.URL.createObjectURL(uploadedFile),
          original: uploadedFile,
        });
      }
      props.onChange({ [props.name]: finalValues });
      setIsActive(false);
    }
  }

  return {
    fileInput,
    isActive,
    isDropAllowed,
    values,
    removeFile,
    limit,
    handleDrop,
    handleEnter,
    handleLeave,
    handleClick,
    handleFileSelect,
  };
}
