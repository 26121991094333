import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import PricingPlan from '../productPricingPlan/Model.ProductPricingPlan';
import productPricingPlanClassDeleteApi from './delete/Api.ProductPricingPlanClass.Delete';
import productPricingPlanClassListApi from './list/Api.ProductPricingPlanClass.List';
import productPricingPlanClassSaveApi from './save/Api.ProductPricingPlanClass.Save';

export default class ProductPricingPlanClass
  extends Model<Model.IProductPricingPlanClass>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductPricingPlanClass>
{
  id: number;
  productPricingPlanId: number;
  vehicleClassId: number;
  term: number;
  maxKm: number;
  priceCAD: number;
  deductible?: number;
  customInfo: string;
  pricingDistributions?: Utils.PricingDistribution;
  subfees?: Array<{
    subfeeId: number;
    currency?: string;
    amount: number;
    quantity?: number;
  }>;

  get type() {
    return combineStrings('/', this.term.toString(), this.maxKm.toString());
  }

  constructor(
    data: Partial<Model.IProductPricingPlanClass> | Utils.FormData<Model.IProductPricingPlanClass>,
    language?: Locale.Languages
  ) {
    super({ productPricingPlan: PricingPlan }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.type,
      subtitle: `${this.priceCAD}$`,
    };
  }

  static async list(body?: Api.Core.ProductPricingPlanClass.List.IRequest) {
    const { payload, ...rest } = await productPricingPlanClassListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductPricingPlanClass(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await productPricingPlanClassSaveApi(this);
  }
  async delete() {
    return await productPricingPlanClassDeleteApi(this.id);
  }
}
