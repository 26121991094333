import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { useClaimProvider } from '../Claim.Hooks';

export default function ClaimDelete() {
  const { lang } = getLocales();
  const { deleteApi, claim, pageRouter, product } = useClaimProvider();

  return (
    <Confirm
      data={claim}
      onConfirm={deleteApi.execute}
      onClose={() => pageRouter.updateParams({ action: null })}
      actionName={lang.delete}
      itemName={combineStrings('-', product?.category?.code, claim?.id?.toString())}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
