import Model from 'models/Model';
import CategoryTranslation from 'models/translations/categoryTranslation/Model.CategoryTranslation';
import categoryListApi from './list/Api.Category.List';
import categorySaveApi from './save/Api.Category.Save';

export default class Category
  extends Model<Model.ICategory>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ICategory>
{
  id: number;
  translations: CategoryTranslation[];

  get name(): string {
    return this.translations.find((it) => it.languageIsoCode == this._language)?.name || this.id.toString();
  }
  constructor(data: Partial<Model.ICategory> | Utils.FormData<Model.ICategory>, language?: Locale.Languages) {
    super({ translations: [CategoryTranslation] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }

  static async list(body?: Api.Core.Category.List.IRequest) {
    const { payload, ...rest } = await categoryListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Category(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await categorySaveApi(this);
  }
}
