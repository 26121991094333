import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { HttpStatus, managementHomeRoute } from 'config/Api.Config';
import accountChangePasswordApi from 'models/account/changePassword/Api.Account.ChangePassword';
import User from 'models/core/user/Model.User';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const accountChangePasswordRoute = managementHomeRoute + '/account/change-password';
export default function AccountChangePasswordIndex() {
  const { lang } = getLocales();
  const { logout } = useAuthStore();
  const _history = useHistory();
  const changePasswordApi = useApi({
    action: accountChangePasswordApi,
    wait: true,
  });
  useEffect(() => {
    if (changePasswordApi.status == HttpStatus.ok) {
      addLog({ success: lang.passwordWasSuccessfullyChanged });
      logout();
    } else if (changePasswordApi.status) {
      addLog({ error: lang.weWereUnableToChangeYourPassword });
      changePasswordApi.reset();
    }
  }, [changePasswordApi.status, changePasswordApi.message]);

  return (
    <>
      <ModalForm<Api.Core.Account.ChangePassword.IRequest>
        subcomponents={ThemeModalFormDefault}
        title={lang.changePassword}
        default={{}}
        onCancel={() => {
          _history.goBack();
        }}
        onSubmit={(data) => changePasswordApi.execute(data)}
        validation={(data, errors) => {
          if (!data.newPassword) errors.newPassword = lang.mustNotBeEmpty;
          if (!data.confirmPassword) errors.confirmPassword = lang.mustNotBeEmpty;
          if (!data.oldPassword) errors.oldPassword = lang.mustNotBeEmpty;
          if (data.newPassword !== data.confirmPassword) errors.confirmPassword = lang.passwordsDoNotMatch;
          if (!User.checkPasswordValidity(data.newPassword)) errors.newPassword = lang.passwordValidityMessage;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="newPassword"
                label={lang.newPassword}
                onChange={form.update}
                value={form.data.newPassword}
                error={form.errors.newPassword}
                type="password"
                autoComplete="new-password"
                icon={<Icon class={iconStyles.common.password} />}
              />
              <TextInput
                name="confirmPassword"
                label={lang.confirmPassword}
                onChange={form.update}
                value={form.data.confirmPassword}
                error={form.errors.confirmPassword}
                type="password"
                autoComplete="new-password"
                icon={<Icon class={iconStyles.common.password} />}
              />
              <TextInput
                name="oldPassword"
                label={lang.oldPassword}
                onChange={form.update}
                value={form.data.oldPassword}
                error={form.errors.oldPassword}
                type="password"
                autoComplete="new-password"
                icon={<Icon class={iconStyles.common.password} />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
