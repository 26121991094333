import { optimalOffset, optimalPosition } from 'assets/utils/dom/Positioning';
import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '../../hooks/theme/useTheme';
import RenderProps from '../helpers/RenderProps';
import { PageStructure } from '../page/Page';

export enum DropdownStructure {
  dropdown = 'dropdown',
  dropdownTitle = 'dropdownTitle',
  dropdownTitleText = 'dropdownTitleText',
  dropdownOptions = 'dropdownOptions',
  dropdownActions = 'dropdownActions',
}
export type Verify = Utils.VerifyExtends<Component.Dropdown.Structure, typeof DropdownStructure>;
export default function Dropdown(props: Component.Dropdown.Import) {
  const [activityState, setactivityState] = useState<number>(props.initialState || 0);
  const handleClick = () => {
    setactivityState(activityState !== 2 ? 2 : 1);
  };

  const extend = useTheme(DropdownStructure, props, {
    htmlElementProps: {
      dropdownTitle: {
        onClick: props.isToggleable ? handleClick : undefined,
      },
    },
  });
  const [scroll, setScroll] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const isFixed = props.horizontalPosition == 'auto_fixed' || props.verticalPosition == 'auto_fixed';
  const isAbsolute = props.horizontalPosition == 'auto_absolute' || props.verticalPosition == 'auto_absolute';
  const isPopup = isFixed || isAbsolute;

  useEffect(() => {
    if (dropdownRef.current && isPopup) {
      const dropdown = dropdownRef.current;
      const body = dropdown.closest('body');
      const container =
        dropdown.closest('.scrollableBox') ||
        (dropdown.parentElement && dropdown.parentElement.closest(`.${DropdownStructure.dropdown}`)) ||
        dropdown.closest(`.ThemePageModal > .${PageStructure.pageWrapper}`) ||
        body;
      if (dropdown && container && body) {
        const { left, top, maxWidth } = optimalOffset(dropdown, container);
        const { horizontal, vertical } = optimalPosition(dropdown, container);

        if (isFixed) {
          dropdown.style.left = `${left}px`;
          dropdown.style.position = 'fixed';
          if (!props.disableWidthConstraint) dropdown.style.maxWidth = `${maxWidth}px`;

          dropdown.style.top = `${top}px`;
          dropdown.style.position = 'fixed';
        } else if (isAbsolute) {
          if (horizontal) dropdown.setAttribute('data-horizontal', horizontal);
          if (vertical) dropdown.setAttribute('data-vertical', vertical);
        }
      }
    } else if (dropdownRef.current && !isPopup) {
      const dropdown = dropdownRef.current;
      if (typeof props.horizontalPosition == 'number') dropdown.style.left = `${props.horizontalPosition}px`;
      else dropdown.setAttribute('data-horizontal', props.horizontalPosition!);

      if (typeof props.verticalPosition == 'number') dropdown.style.top = `${props.verticalPosition}px`;
      else dropdown.setAttribute('data-vertical', props.verticalPosition!);
    }
    const globalScroll = (event: Event) => {
      if (event && event.target) setScroll((event.target as Element).scrollTop);
    };
    window.addEventListener('scroll', globalScroll, true);
    return () => window.removeEventListener('scroll', globalScroll, true);
  }, [dropdownRef, scroll, isPopup, props.render]);
  return (
    <div
      ref={dropdownRef}
      {...extend(DropdownStructure.dropdown, true)}
      data-constrainment={props.constrainment || undefined}
      children={
        <>
          {(props.isToggleable || props.title || props.actions) && (
            <div {...extend(DropdownStructure.dropdownTitle)}>
              {RenderProps(props, 'icon')}
              {props.icon && <>&nbsp;</>}
              {props.title && (
                <span {...extend(DropdownStructure.dropdownTitleText)} children={RenderProps(props, 'title')} />
              )}
              {props.actions && (
                <div {...extend(DropdownStructure.dropdownActions)} children={RenderProps(props, 'actions')} />
              )}
            </div>
          )}
          {activityState !== 2 && (
            <div {...extend(DropdownStructure.dropdownOptions)} children={RenderProps(props, 'render')} />
          )}
        </>
      }
    />
  );
}
