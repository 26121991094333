import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import clientListApi from './list/Api.Client.List';
import clientSyncWithVertaforeApi from './syncWithVertafore/Api.Client.SyncWithVertafore';

export default class Client
  extends Model<Model.IClient>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClient>
{
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  primaryLangugaeIsoCode?: string;
  streetAddress?: string;
  postalCode?: string;
  muncipality?: string;
  provinceCode?: string;
  countryCode?: string;
  city?: string;
  taxExempt: boolean;
  taxExemptionBand?: string;
  veraForteCustomerNumber?: string;

  constructor(data: Partial<Model.IClient> | Utils.FormData<Model.IClient>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.firstName, this.lastName) || '',
      subtitle: this.fullAddress,
    };
  }

  get fullAddress() {
    return combineStrings(', ', this.streetAddress, combineStrings(' ', this.postalCode, this.provinceCode));
  }
  static async list(body?: Api.Core.Client.List.IRequest) {
    const { payload, ...rest } = await clientListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Client(it)),
        count: payload.count,
      },
    };
  }
  static async syncWithVertafore(params: Api.Core.Client.SyncWithVertafore.IParams) {
    return await clientSyncWithVertaforeApi(params);
  }
}
