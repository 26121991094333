import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import Theme from 'assets/themes/Theme.Common';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import {
  PricingDistributionTemplateAction,
  usePricingDistributionTemplateProvider,
} from '../PricingDistributionTemplate.Hooks';

const DistributionRow = ({ distribution }: { distribution?: PricingDistributionTemplate }) => {
  const { lang } = getLocales();
  const { pageRouter, permissions, views, selected, form, onAdd, distributorsApi } =
    usePricingDistributionTemplateProvider();
  const isSelected = views.edit && ((!selected && !distribution) || selected?.id == distribution?.id);
  return (
    <Row
      active={isSelected}
      children={
        <>
          <RowImage render={<Icon class="fas-user-shield" />} />
          <RowData
            style={{
              rowSubtitle: { overflow: 'auto', width: '100%' },
              rowData: { overflow: 'hidden', width: '100%' },
            }}
            info={isSelected ? null : distribution?.distributor?.name}
            title={
              isSelected ? (
                <>
                  <TextInput
                    name="name"
                    label={lang.name}
                    value={form.data.name}
                    error={form.errors.name}
                    onChange={form.update}
                    icon={<Icon class="if-quill-pen" />}
                  />
                  {!!distributorsApi?.payload?.data?.length && (
                    <>
                      <br />
                      <SelectInput
                        name="distributorId"
                        label={lang.distributor}
                        data={distributorsApi?.payload?.data?.map((it) => it.displayInfo)}
                        value={form.data.distributorId}
                        error={form.errors.distributorId}
                        onChange={({ distributorId }) => {
                          const distributor = distributorsApi?.payload?.data?.find((d) => d.id === distributorId);
                          form.update({
                            distributor: distributor?.toJSON(),
                            distributorId: distributor?.id,
                          });
                        }}
                        icon={<Icon class="fas-globe" />}
                      />
                    </>
                  )}
                  <br />
                </>
              ) : (
                distribution?.name
              )
            }
            subtitle={
              <>
                <ObjectTable
                  className={isSelected ? 'AAA' : undefined}
                  paths={(isSelected ? form.data.keys : distribution.keys) || []}
                  setPaths={isSelected ? (newPaths) => form.update({ keys: newPaths }) : undefined}
                />
                {isSelected && (
                  <>
                    <Box
                      style={{
                        margin: `${Theme.Size.M} 0 0 auto`,
                        display: 'flex',
                        gap: Theme.Size.M,
                      }}
                    >
                      <Button
                        class={ThemeButtonCircle}
                        media="fas-plus"
                        onClick={() => onAdd('', form)}
                        render={lang.add}
                        htmlElementProps={{ title: lang.add }}
                      />
                      <Button
                        onClick={pageRouter.updateParams}
                        class={ThemeButtonCircle}
                        media="fas-ban"
                        render={lang.cancel}
                      />
                      <Button
                        class={ThemeButtonCircle}
                        onClick={form.submit}
                        render={lang.submit}
                        disabled={form.hasErrors || form.isSubmitted}
                        active={!form.hasErrors}
                        isLoading={form.isSubmitted}
                        media="fas-save"
                        htmlElementProps={{ title: lang.save }}
                      />
                    </Box>
                  </>
                )}
              </>
            }
          />
          <RowActions isVertical style={{ height: 'fit-content' }}>
            <Button
              onClick={pageRouter.updateParams}
              data={{
                id: distribution?.id.toString(),
                action: PricingDistributionTemplateAction.EDIT,
              }}
              disabled={!permissions.EDIT_PRICINGDISTRIBUTIONTEMPLATE || isSelected}
              class={ThemeButtonCircle}
              media={<Icon class="fas-edit" />}
              htmlElementProps={{ title: lang.edit }}
            />
          </RowActions>
        </>
      }
    />
  );
};
export default function PricingDistributionTemplateList() {
  const { lang } = getLocales();
  const { listApi, views, selected } = usePricingDistributionTemplateProvider();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.vehicles}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      {!selected && views.edit && <DistributionRow />}
      <DataMap
        data={listApi.payload?.data}
        render={({ data: distribution }) => <DistributionRow key={distribution?.id} distribution={distribution} />}
      />
    </Collection>
  );
}
