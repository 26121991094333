import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Response = Api.Core.Client.SyncWithVertafore.IResponse;
type Headers = Api.Core.Client.SyncWithVertafore.IHeaders;
type Params = Api.Core.Client.SyncWithVertafore.IParams;
const url = '/api/Claims/Client/:clientId/SyncWithVerforte';
export default async function clientSyncWithVertaforeApi(params: Params): Promise<Api.Res<Response>> {
  return await executeServerApi<Params, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { ...params }),
    headers: getDefaultHeaders(),
  });
}
