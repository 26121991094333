import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useUIEditor from 'assets/hooks/uiEditor/UIEditor';
import React, { useMemo } from 'react';

export default function UICssButton(
  props: Component.Button.DefaultImport & Component.Button.OnlyData<React.CSSProperties> & { title?: string }
) {
  const uiEditor = useUIEditor();
  const style = uiEditor.selectionInfo?.block?.style || {};

  const isSelected = useMemo(() => {
    for (const key of Object.keys(props.data)) {
      if (style[key as keyof CSSStyleDeclaration] !== props.data[key as keyof React.CSSProperties]) return false;
    }
    return true;
  }, [props.data, style]);

  return (
    uiEditor && (
      <Button
        class={ThemeButtonCircle}
        onClick={uiEditor.applyCss}
        active={isSelected}
        htmlElementProps={{ title: props.title }}
        {...props}
      />
    )
  );
}
