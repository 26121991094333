import { useTheme } from 'assets/hooks/theme/useTheme';
import React, { Fragment, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import useAnimation from '../animation/hooks/Animation';
import Box from '../box/Box';
import ThemeBoxBlank from '../box/themes/Theme.Box.Blank';
import DataMap from '../dataMap/DataMap';
import RenderProps from '../helpers/RenderProps';
import Icon, { iconStyles } from '../icon/Icon';
import TextBox from '../textBox/TextBox';
import { getLocales } from 'assets/locales/Locale';

export enum NavigationStructure {
  navigation = 'navigation',
  navLink = 'navLink',
  navGroup = 'navGroup',
  navGroupTitle = 'navGroupTitle',
  navSubmenu = 'navSubmenu',
  navButton = 'navButton',
  navLogo = 'navLogo',
  navWrapper = 'navWrapper',
  navExternalLinks = 'navExternalLinks',
}
export type Verify = Utils.VerifyExtends<Component.Navigation.Structure, typeof NavigationStructure>;
export default function Navigation(props: Component.Navigation.Import) {
  const extend = useTheme(NavigationStructure, props);
  const { lang } = getLocales();
  const location = useLocation();
  const animation = useAnimation({
    onAnimateIn: () => {
      if (typeof props.toggle == 'function') props.toggle(true);
    },
    onAnimateOut: () => {
      if (typeof props.toggle == 'function') props.toggle(false);
    },
  });
  function Logo() {
    return (
      <div
        {...extend(NavigationStructure.navLogo)}
        children={
          typeof props.logo == 'string' ? (
            <>
              <img src={props.logo} />
            </>
          ) : (
            <>
              {props.logo.icon && <Icon class={props.logo.icon} />}
              <TextBox title={props.logo.title} subtitle={props.logo.subtitle} />
            </>
          )
        }
      />
    );
  }
  const [activeGroup, activateGroup] = useState<number>();
  const routes = useMemo(() => {
    return props.routes?.map((it, index) => ({
      ...it,
      isActive: it.groups
        ? activeGroup === index
          ? true
          : undefined
        : it.path && doesNavRouteMatches(it.path, location.pathname)
        ? true
        : undefined,
      groups: it.groups?.map((git) => ({
        ...git,
        isActive: git.path && doesNavRouteMatches(git.path, location.pathname) ? true : undefined,
      })),
    }));
  }, [props.routes, location.pathname, activeGroup]);

  return (
    <>
      <div
        data-active={animation.isIn ? true : undefined}
        {...extend(NavigationStructure.navigation, true)}
        children={
          <>
            <div
              {...extend(NavigationStructure.navButton)}
              onClick={() => animation.toggle()}
              children={<Icon class={animation.isIn ? iconStyles.common.left : iconStyles.common.menu} />}
              title={animation.isIn ? lang.closeMenu : lang.openMenu}
            />
            <Logo />
            {!animation.isVisible ? null : (
              <Box
                class={ThemeBoxBlank}
                htmlElementProps={{ onClick: () => animation.animateOut() }}
                active={animation.isIn}
              />
            )}
            <div
              {...extend(NavigationStructure.navWrapper)}
              children={
                <>
                  {!props.disableExtraLogo && <Logo />}
                  <DataMap
                    data={routes}
                    render={({ data: route, index }) =>
                      !route.hidden && (
                        <Fragment key={index}>
                          {!route.path && route.groups ? (
                            <>
                              <div
                                {...extend(NavigationStructure.navGroup)}
                                children={
                                  <>
                                    <div
                                      {...extend(NavigationStructure.navGroupTitle)}
                                      data-active={route.isActive}
                                      onClick={() => {
                                        if (animation.isOut) animation.animateIn();
                                        activateGroup(index);
                                      }}
                                      title={route.title}
                                      children={
                                        <>
                                          {route.icon && <Icon class={route.icon} />}
                                          <TextBox>{route.title}</TextBox>
                                        </>
                                      }
                                    />
                                    {route.isActive && animation.isVisible && (
                                      <>
                                        <div
                                          {...extend(NavigationStructure.navSubmenu)}
                                          children={
                                            <DataMap
                                              data={route.groups}
                                              render={({ data: subRoute, index: subIndex }) =>
                                                !subRoute.hidden && (
                                                  <NavLink
                                                    {...extend(NavigationStructure.navLink)}
                                                    to={subRoute.path}
                                                    key={subIndex}
                                                    onClick={() => {
                                                      animation.animateOut();
                                                      activateGroup(index);
                                                    }}
                                                    data-active={subRoute.isActive}
                                                    title={subRoute.title}
                                                    children={
                                                      <>
                                                        {subRoute.icon && <Icon class={subRoute.icon} />}
                                                        <TextBox>{subRoute.title}</TextBox>
                                                      </>
                                                    }
                                                  />
                                                )
                                              }
                                            />
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                }
                              />
                            </>
                          ) : (
                            <>
                              <NavLink
                                {...extend(NavigationStructure.navLink)}
                                to={route.path}
                                onClick={() => {
                                  animation.animateOut();
                                  activateGroup(undefined);
                                }}
                                title={route.title}
                                data-active={route.isActive}
                                children={
                                  <>
                                    {route.icon && <Icon class={route.icon} />}
                                    <TextBox>{route.title}</TextBox>
                                  </>
                                }
                              />
                            </>
                          )}
                        </Fragment>
                      )
                    }
                  />
                </>
              }
            />
            {props.renderAfter && (
              <div
                {...extend(NavigationStructure.navExternalLinks)}
                children={RenderProps(props, 'renderAfter', animation)}
              />
            )}
          </>
        }
      />
    </>
  );
}

function doesNavRouteMatches(route: string, currentPath: string) {
  return new RegExp(
    `^(${route
      .replace(/\//g, '\\/')
      .replace(/\\\/:(\w+((-|_|\w)+)\?)+/g, '((\\/\\w+((_|-|\\w)+)?)?)')
      .replace(/\\\/:(\w+((-|_|\w)+))+/g, '(\\/\\w+((_|-|\\w)+)?)')})$`,
    'g'
  ).test(currentPath)
    ? true
    : undefined;
}
