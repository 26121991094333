import Model from 'models/Model';
import statementListApi from './list/Api.Statement.List';
import statementSaveApi from './save/Api.Statement.Save';
import statementDeleteApi from './delete/Api.Statement.Delete';
import User from 'models/core/user/Model.User';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Contract from '../contract/Model.Contract';
import { combineStrings } from 'assets/utils/data/String';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';

export default class Statement
  extends Model<Model.IStatement>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IStatement>
{
  id: number;
  status: Model.Enum.StatementStatusCode;
  statementNumber: number;
  totalAmountDueCAD: number;
  invoicingDate: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  representativeId: number;
  representative: User;
  dealerId: number;
  dealer: Dealer;
  isPayed?: boolean;
  contracts: Contract[];

  constructor(data: Partial<Model.IStatement> | Utils.FormData<Model.IStatement>, language?: Locale.Languages) {
    super({ representative: User, dealer: Dealer, contracts: [Contract] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.dealer?.name || this.id.toString(),
      subtitle: this.contracts
        .map((it) => `${it.appNumber}${it.revisionNumber ? `-R${it.revisionNumber}` : ''}`)
        .join(', '),
      info: combineStrings(
        ' | ',
        this.status,
        date(this.invoicingDate, defaultServerDateTimeFormat).format('MMMM YYYY')
      ),
    };
  }

  static async list(body?: Api.Core.Statement.List.IRequest) {
    const { payload, ...rest } = await statementListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Statement(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await statementSaveApi(this);
  }
  async delete() {
    return await statementDeleteApi(this.id);
  }
}
