import Model from 'models/Model';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import claimEmailSendEmailApi from './sendEmail/Api.ClaimEmail.SendEmail';
import claimEmailSendPaymentRequestEmailApi from './sendPaymentRequestEmail/Api.ClaimEmail.SendPaymentRequestEmail';

export default class ClaimEmail
  extends Model<Model.IClaimEmail>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimEmail>
{
  id: number;
  claimId: number;
  sendDateUtc: string;
  from?: string;
  tos?: Array<string>;
  subject?: string;
  body?: string;

  constructor(data: Partial<Model.IClaimEmail> | Utils.FormData<Model.IClaimEmail>, language?: Locale.Languages) {
    super({ mediaFile: MediaFile }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.subject || this.id.toString(),
      subtitle: this.from,
    };
  }

  static async sendEmail(props: { emailTemplate: Utils.FormData<Model.IEmailTemplate>; claimId: number }) {
    return await claimEmailSendEmailApi({ params: { claimId: props.claimId }, body: props.emailTemplate });
  }

  static async sendPaymentRequestEmail(body: Api.Core.ClaimEmail.SendPaymentRequestEmail.IRequest) {
    return await claimEmailSendPaymentRequestEmailApi(body);
  }
}
