import TextBox, { textBoxStyles } from 'assets/components/textBox/TextBox';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import { defaultLanguage, getLanguage, getLocales } from 'assets/locales/Locale';
import DataMap from 'assets/components/dataMap/DataMap';
import { paragraphStyles } from '../claimEditInfo/ClaimEdit.Info';

export default function ClaimEditJobInfo() {
  const { lang } = getLocales();
  const currentLanguage = getLanguage();
  const { claimTypeReport, report, claimJob, claimTypes } = useClaimJobEdit();
  const onlyClaimType = claimTypes && claimTypes.length === 1;

  return (
    <div style={paragraphStyles}>
      {!onlyClaimType && (
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title={`${lang.claimType}:`}
          subtitle={claimJob.claimType?.displayInfo?.title ?? 'N/A'}
        />
      )}
      <TextBox
        class={textBoxStyles.block.flexBlock}
        title={`${lang.owner}:`}
        subtitle={claimJob.owner?.displayInfo?.title ?? 'N/A'}
      />
      {!claimTypeReport && (
        <>
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={`${lang.correctionDetails}:`}
            subtitle={claimJob.correction ?? 'N/A'}
          />
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={`${lang.customerComplaint}:`}
            subtitle={claimJob.customerComplaint ?? 'N/A'}
          />
        </>
      )}
      {!!claimTypeReport && (
        <DataMap
          data={claimTypeReport?.infoInputs}
          render={({ data: input }) => (
            <TextBox
              key={input.key}
              class={textBoxStyles.block.flexBlock}
              title={`${input.name[currentLanguage] || input.name[defaultLanguage]}:`}
              subtitle={report[input.key] ?? 'N/A'}
            />
          )}
        />
      )}
    </div>
  );
}
