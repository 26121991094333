import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { SalesContractAction } from '../../contract/Sales.Contract.Hooks';
import { salesContractIndexRoute } from '../../contract/Sales.Contract.Index';
import { ContractSaveStatus, useSalesNewContractProvider } from '../Sales.NewContract.Hooks';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import ContractConsentRequestType from 'models/enums/ContractConsentRequestType';

export default function SalesNewContractFinish() {
  const { lang } = getLocales();
  const { contractStatuses, sendContractConsent } = useSalesNewContractProvider();

  const contractPageRouter = usePageRouter<Module.Sales.Contract.Params, Module.Sales.Contract.Query>({
    route: salesContractIndexRoute,
  });
  return (
    <Collection class={ThemeCollectionList}>
      <HeaderRow>
        <HeaderData children={lang.contract} />
        <HeaderData children={lang.client} />
        <HeaderData children={lang.status} />
        <HeaderData numOfActions={1} />
      </HeaderRow>
      <DataMap
        data={contractStatuses}
        render={({
          data: {
            contract,
            areDocumentsGenerated,
            areWeGeneratingDocuments,
            areDocumentsAutomaticallySentForSigning,
            isPaymentRequired,
            isSignRequired,
            isCustomerConsentRequired,
            documentCount,
            uploadedDocuments,
            status,
          },
        }) => {
          const isDone = status === ContractSaveStatus.done || status === ContractSaveStatus.failed;
          return (
            <Row
              key={contract.id}
              children={
                <>
                  <RowImage render={<Icon class={isDone ? 'fas-check' : 'fas-spinner'} />} />
                  <RowData
                    info={
                      contract.appNumber
                        ? contract.appNumber + (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')
                        : null
                    }
                    title={combineStrings(
                      '/',
                      contract.vehicle.year?.toString(),
                      contract.vehicle.makeCode,
                      contract.vehicle.model
                    )}
                    subtitle={contract.vehicle?.vin}
                  />
                  <RowData>
                    {contract.client?.firstName} {contract.client?.lastName}
                  </RowData>
                  <RowData>
                    <b>{`${lang[status]} (${contract?.statusCode ?? ContractStatusCode.OPEN_SUBMITTED})`}</b>
                    {!!documentCount && (
                      <span>
                        {lang.uploaded}: {uploadedDocuments}/{documentCount}
                      </span>
                    )}
                    {!!areWeGeneratingDocuments && isDone && (
                      <span>
                        {lang.documentsGenerated}: <Icon class={areDocumentsGenerated ? 'fas-check' : 'fas-times'} />
                      </span>
                    )}
                    {!!areDocumentsAutomaticallySentForSigning && isSignRequired && isDone && (
                      <span>
                        {lang.documentsSentForSigning}:{' '}
                        <Icon class={areDocumentsAutomaticallySentForSigning ? 'fas-check' : 'fas-times'} />
                      </span>
                    )}
                    {isDone && (
                      <span>
                        {isPaymentRequired ? 'Payment required!' : isSignRequired ? 'Signature required' : null}
                      </span>
                    )}
                    {!!isCustomerConsentRequired && isDone && (
                      <span>
                        {lang.isCustomerConsentRequired}:{' '}
                        <Icon class={isCustomerConsentRequired ? 'fas-check' : 'fas-times'} />
                      </span>
                    )}
                  </RowData>
                  <RowActions isVertical>
                    <DropdownButton
                      title={lang.sendConsent}
                      buttonMedia={<Icon class="fas-envelope" />}
                      horizontalPosition="auto_fixed"
                      verticalPosition="auto_fixed"
                      constrainment="unconstrained"
                      disableWidthConstraint
                      subcomponents={ThemeDropdownButtonPopup}
                      htmlElementProps={{ title: lang.sendConsent }}
                      disabled={!isCustomerConsentRequired}
                      dropdownOptions={
                        <>
                          <Button
                            onClick={sendContractConsent}
                            render={lang.email}
                            class={ThemeButtonMenuItem}
                            data={{ contractId: contract?.id, consentRequestType: ContractConsentRequestType.EMAIL }}
                          />
                          <Button
                            onClick={sendContractConsent}
                            render={lang.TEXT}
                            class={ThemeButtonMenuItem}
                            disabled={true}
                            data={{ contractId: contract?.id, consentRequestType: ContractConsentRequestType.TEXT }}
                          />
                        </>
                      }
                    />
                    <LinkButton
                      class={ThemeButtonCircle}
                      url={contract?.documentationAggregate?.url}
                      media={<Icon class="fas-download" />}
                      disabled={!contract?.documentationAggregate?.url || !isDone}
                      target="_blank"
                      htmlElementProps={{ title: lang.download }}
                    />
                    <Button
                      class={ThemeButtonCircle}
                      media={<Icon class="fas-info-circle" />}
                      onClick={contractPageRouter.updateParams}
                      disabled={!contract?.id || !isDone}
                      data={{ id: contract?.id?.toString(), action: SalesContractAction.INFO }}
                      htmlElementProps={{ title: lang.info }}
                    />
                  </RowActions>
                </>
              }
            />
          );
        }}
      />
    </Collection>
  );
}
