import RenderProps from 'assets/components/helpers/RenderProps';
import useAuthInfo from 'assets/hooks/authInfo/useAuthInfo';
import React, { useContext } from 'react';

let AppStoreContext: React.Context<ReturnType<typeof useAuthInfo>>;

export function AppStoreProvider(props: Provider.AuthStore.Import<ReturnType<typeof useAuthInfo>>) {
  const data = useAuthInfo(props);

  if (!AppStoreContext) AppStoreContext = React.createContext<ReturnType<typeof useAuthInfo>>(data);
  return (
    <>
      <AppStoreContext.Provider value={data}>{RenderProps(props, 'render', data)}</AppStoreContext.Provider>
    </>
  );
}

export function useAuthStore() {
  return useContext(AppStoreContext);
}
