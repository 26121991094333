import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import useSalesDraft from './Sales.Draft.Hooks';
import DraftList from './sections/Sales.Draft.List';
import DraftDelete from './sections/Sales.Draft.Delete';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const salesDraftIndexRoute = salesHomeRoute + '/contract-draft/:action?/:id?';
export default function SalesDraftIndex() {
  const draftHook = useSalesDraft();

  if (!draftHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <DataProvider
      data={draftHook}
      render={({ views }) => (
        <>
          <DraftList />
          {views.delete && <DraftDelete />}
        </>
      )}
    />
  );
}
