import Model from 'models/Model';
import Tier from '../tier/Model.Tier';
import VehicleModel from '../vehicleModel/Model.VehicleModel';
import vehicleMakeDeleteApi from './delete/Api.VehicleMake.Delete';
import vehicleMakeFindApi from './find/Api.VehicleMake.Find';
import vehicleMakeListApi from './list/Api.VehicleMake.List';
import vehicleMakeSaveApi from './save/Api.VehicleMake.Save';

export default class VehicleMake
  extends Model<Model.IVehicleMake>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVehicleMake>
{
  id: number;
  makeCode: string;
  alternativeMakeCode?: string;
  vehicleModels: VehicleModel[];
  tiers?: Tier[];
  mfgPowertrainWarrantyMonths?: string;
  mfgPowertrainWarrantyKm?: string;
  mfgWholeVehicleWarrantyMonths?: string;
  mfgWholeVehicleWarrantyKm?: string;

  constructor(data: Partial<Model.IVehicleMake> | Utils.FormData<Model.IVehicleMake>, language?: Locale.Languages) {
    super({ vehicleModels: [VehicleModel], tiers: [Tier] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.makeCode || this.id.toString(),
    };
  }

  static async list(body?: Api.Core.VehicleMake.List.IRequest) {
    const { payload, ...rest } = await vehicleMakeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new VehicleMake(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await vehicleMakeSaveApi(this);
  }
  async delete() {
    return await vehicleMakeDeleteApi(this.id);
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await vehicleMakeFindApi(params);
    return {
      ...rest,
      payload: new VehicleMake(payload),
    };
  }
}
