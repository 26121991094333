import Model from 'models/Model';
import Client from '../client/Model.Client';
import Dealer from '../../dealerManagement/dealer/Model.Dealer';
import Payee from '../payee/Model.Payee';
import PayeeType from '../payeeType/Model.PayeeType';

export default class ClaimJobItemPayee
  extends Model<Model.IClaimJobItemPayee>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimJobItemPayee>
{
  id: number;
  payeeTypeId?: number;
  payeeType?: PayeeType;
  payeeId?: number;
  payee?: Payee;
  dealerId?: number;
  dealer?: Dealer;
  clientId?: number;
  client?: Client;

  get typeAndId(): string {
    return this.dealerId
      ? `${PayeeType.DEALER_ID}:${this.dealerId}`
      : this.clientId
      ? `${PayeeType.CLIENT_ID}:${this.clientId}`
      : this.payeeId
      ? `${this?.payeeTypeId || this?.payeeType?.id}:${this.payeeId}`
      : null;
  }
  constructor(
    data: Partial<Model.IClaimJobItemPayee> | Utils.FormData<Model.IClaimJobItemPayee>,
    language?: Locale.Languages
  ) {
    super({ payeeType: PayeeType, payee: Payee, dealer: Dealer, client: Client }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.dealer?.displayInfo?.id ?? this.payee?.displayInfo?.id ?? this.client?.displayInfo?.id,
      title:
        this.dealer?.displayInfo?.title ||
        this.client?.displayInfo?.title ||
        this.payee?.displayInfo?.title ||
        this.id?.toString(),
      subtitle: this.dealer?.fullAddress || this.client?.fullAddress || this.payee?.fullAddress,
      info: this.payeeType?.name,
    };
  }
}
