import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon from 'assets/components/icon/Icon';
import { useTheme } from 'assets/hooks/theme/useTheme';
import { UIEditorProvider } from 'assets/hooks/uiEditor/UIEditor';
import { UICommand, UITag } from 'assets/utils/dom/Commands';
import React, { useEffect, useRef, useState } from 'react';
import * as UIActions from './inputs/UIEditor.Actions';

export enum RichTextInputStructure {
  formInput = 'formInput',
  input = 'input',
  actions = 'actions',
}
export type Verify = Utils.VerifyExtends<Component.Input.RichText.Structure, typeof RichTextInputStructure>;
export default function RichTextInput<Name>(props: Component.Input.RichText.Import<Name>) {
  const extend = useTheme(RichTextInputStructure, props);
  const id = `richText_${props.name}`;

  const [useHtmlMode, switchMode] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const tempValue = useRef('');
  function setValue(val: string) {
    if (props.onChange) props.onChange({ [props.name as any]: val } as any);
    tempValue.current = val;
  }
  useEffect(() => {
    if (ref.current && tempValue.current !== props.value) {
      if (useHtmlMode) ref.current.innerHTML = props.value || '';
      else ref.current.innerText = props.value || '';
    }
  }, [ref.current, tempValue.current, props.value, useHtmlMode]);
  return (
    <>
      <div
        {...extend(RichTextInputStructure.formInput, true)}
        data-label={props.label}
        data-error={props.error || undefined}
        data-read-only={props.readOnly || undefined}
        children={
          <>
            <UIEditorProvider
              editorSelector={`#${id}`}
              onUpdate={(code) => {
                props.onChange({ [props.name.toString()]: code } as any);
              }}
              render={(uiEditor) =>
                uiEditor && (
                  <div
                    {...extend(RichTextInputStructure.actions)}
                    children={
                      <>
                        <Button
                          media={<Icon class="if-code-alt" />}
                          class={ThemeButtonCircle}
                          onClick={switchMode}
                          data={true}
                          active={useHtmlMode}
                        />
                        <Button
                          media={<Icon class="if-ui-keyboard" />}
                          class={ThemeButtonCircle}
                          onClick={switchMode}
                          data={false}
                          active={!useHtmlMode}
                        />
                        {useHtmlMode && (
                          <>
                            <UIActions.Undo />
                            <UIActions.Redo />
                            <UIActions.Cut />
                            <UIActions.Copy />
                            <UIActions.Paste />
                            <UIActions.SelectAll />
                            <UIActions.Delete />
                            <UIActions.RemoveFormat />
                            <UIActions.Bold />
                            <UIActions.Italic />
                            <UIActions.Superscript />
                            <UIActions.Subscript />
                            <UIActions.JustifyLeft />
                            <UIActions.JustifyCenter />
                            <UIActions.JustifyRight />
                            <UIActions.JustifyFull />
                            <UIActions.OrderedList />
                            <UIActions.UnorderedList />
                            <UIActions.HorizontalRule />
                            <DataObjectMap
                              data={UITag}
                              render={({ data }) => (
                                <Button
                                  key={data}
                                  class={ThemeButtonCircle}
                                  onClick={() => uiEditor.applyUiCommand(UICommand.formatBlock, data)}
                                  media={
                                    data === UITag.paragraph ? (
                                      <Icon class="if-paragraph" />
                                    ) : (
                                      <span
                                        style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                                        children={data}
                                      />
                                    )
                                  }
                                />
                              )}
                            />
                            {RenderProps(props, 'customActions')}
                          </>
                        )}
                      </>
                    }
                  />
                )
              }
            />
            <div
              {...extend(RichTextInputStructure.input)}
              tabIndex={0}
              id={id}
              ref={ref}
              contentEditable
              title={typeof props.label == 'string' ? props.label : undefined}
              placeholder={props.placeholder || ' '}
              onInput={(event) => {
                if (event.currentTarget)
                  setValue(useHtmlMode ? event.currentTarget.innerHTML : event.currentTarget.innerText);
              }}
            />
          </>
        }
      />
    </>
  );
}
