import ThemeBoxBadge from 'assets/components/box/themes/Theme.Box.Badge';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import ThemeDropdownPopup from 'assets/components/dropdown/themes/Theme.Dropdown.Popup';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { xorBy } from 'lodash';

const dropdownStyles: Template.DropdownButton.Import['subcomponents'] = {
  button: {
    class: ThemeButtonCircle,
    animation: { trigger: 'click' },
    style: { button: { display: 'inline-block' }, buttonText: { textTransform: 'unset' } },
  },
  dropdown: { class: ThemeDropdownPopup },
  badge: {
    class: ThemeBoxBadge,
    style: { paddingLeft: Theme.Size.S, paddingRight: Theme.Size.S, backgroundColor: Theme.Color.secondary },
  },
};
export default function TableFilter<T>(props: {
  label(d: T): Utils.RenderPropsEmpty;
  selection: T[];
  data: T[];
  onChange(data: T[]): void;
  identifier(d: T): string | number;
  buttonBody?: Utils.RenderPropsEmpty;
  isMultiselect?: boolean;
}) {
  return (
    <DropdownButton
      buttonMedia="fas-sliders-h"
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      tabIndex={null}
      buttonBody={props.buttonBody}
      notification={
        !props.isMultiselect
          ? null
          : combineStrings('/', props.selection.length.toString(), props.data.length.toString())
      }
      subcomponents={dropdownStyles}
      dropdownOptions={({ animateIn }) => (
        <DataMap
          data={props.data}
          render={({ data }) => (
            <Button
              class={ThemeButtonMenuItem}
              data={data}
              active={props.selection.some((it) => props.identifier(it) === props.identifier(data))}
              onClick={(d) => {
                if (props.isMultiselect) {
                  props.onChange(xorBy(props.selection, [d], (it) => props.identifier(it)));
                } else {
                  props.onChange([d]);
                }
                animateIn();
              }}
            >
              {props.label(data)}
            </Button>
          )}
        />
      )}
    />
  );
}
