import Model from 'models/Model';

export default class ProductTax
  extends Model<Model.IProductTax>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductTax>
{
  id: number;
  productId: number;
  provinceCode: string;
  claimsProvincialSalesTax: number;
  claimsGstHst: number;
  contractProvincialSalesTax: number;
  contractGstHst: number;

  constructor(data: Partial<Model.IProductTax> | Utils.FormData<Model.IProductTax>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.provinceCode || this.id.toString(),
    };
  }
}
