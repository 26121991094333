import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import { isMobile } from 'react-device-detect';
import usePricingDistributionTemplate, { PricingDistributionTemplateAction } from './PricingDistributionTemplate.Hooks';
import PricingDistributionTemplateList from './sections/PricingDistributionTemplate.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import PricingDistributionTemplateFilters from './sections/PricingDistributionTemplate.Filters';

export const pricingDistributionTemplateIndexRoute =
  productManagementHomeRoute + '/pricingDistributionTemplate/:action?/:id?';
export default function PricingDistributionTemplateIndex() {
  const { lang } = getLocales();
  const pricingDistributionTemplateHook = usePricingDistributionTemplate();

  if (!pricingDistributionTemplateHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={pricingDistributionTemplateHook}
        render={({ listApi, pageRouter, permissions }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            style={{ pageContent: { overflow: 'auto' } }}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.pricingDistributionTemplates} />
                <PricingDistributionTemplateFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_PRICINGDISTRIBUTIONTEMPLATE}
                  data={{ action: PricingDistributionTemplateAction.EDIT }}
                  htmlElementProps={{ title: lang.addNew }}
                />
              </>
            }
            render={<PricingDistributionTemplateList />}
          />
        )}
      />
    </>
  );
}
