import { getLocales } from 'assets/locales/Locale';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { shortenText } from 'assets/utils/data/String';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import Icon from '../icon/Icon';

export default function ToString(props: { value: any; textTreshold?: number }) {
  const { lang } = getLocales();

  if (typeof props.value == 'object' && props.value) return null;

  const d = date(props.value, defaultServerDateTimeFormat, true);
  if (d.isValid() && isNaN(parseInt(props.value))) return d.format(defaultDateTimeFormat);
  else if (isValidValue(props.value)) {
    if (typeof props.value == 'boolean') return <Icon class={props.value ? 'fas-check' : 'fas-times'} />;
    else if (typeof props.value == 'string')
      return props.textTreshold ? shortenText(props.value, props.textTreshold) : props.value;
    else return props.value;
  } else return lang.noInfo;
}
