import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import React from 'react';
import { DealerGroupAction, useDealerGroupProvider } from '../DealerGroup.Hooks';

export default function DealerGroupList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useDealerGroupProvider();
  const { permissions } = useAuthStore();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page class={ThemePageError} sidebar={<Icon class="fas-users" />} header={lang.groups} render={lang.noInfo} />
  ) : (
    <Collection class={ThemeCollectionList}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: dealerGroup }) => (
          <Row
            key={dealerGroup.id}
            children={
              <>
                <RowImage
                  render={
                    dealerGroup?.uiSettingsFile ? (
                      <img src={dealerGroup?.uiSettingsFile?.url} />
                    ) : (
                      <Icon class="fas-users" />
                    )
                  }
                />
                <RowData {...dealerGroup.displayInfo} />
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: dealerGroup.id.toString(),
                      action: DealerGroupAction.EDIT,
                    }}
                    disabled={!permissions.EDIT_DEALERGROUP}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-edit" />}
                    htmlElementProps={{
                      title: lang.edit,
                    }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: dealerGroup.id.toString(),
                      action: DealerGroupAction.DELETE,
                    }}
                    disabled={!permissions.DELETE_DEALERGROUP}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{
                      title: lang.delete,
                    }}
                  />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
