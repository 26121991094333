import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { isEmpty } from 'assets/utils/parsersAndValidation/Validators';
import VehicleClass from 'models/productManagement/vehicleClass/Model.VehicleClass';
import { useVehicleClassProvider } from '../VehicleClass.Hooks';

export default function VehicleClassCopy() {
  const { lang } = getLocales();
  const { selected, pageRouter, copyApi } = useVehicleClassProvider();

  const { payload: vehicleClassPayload, isExecuting } = useApi({
    action: VehicleClass.list,
  });

  return (
    <ModalForm<Api.Core.VehicleClass.Copy.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.vehicleClassCopy}
      default={selected}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) => copyApi.execute({ ...data, fromClassId: selected.id })}
      validation={(data, errors) => {
        if (!data.fromClassId && isEmpty(data.fromClassId?.toString())) errors.fromClassId = lang.mustNotBeEmpty;
        if (!data.toClassId) errors.toClassId = lang.mustNotBeEmpty;
      }}
      render={({ form }) => {
        return (
          <FormWrapper>
            <SelectInput
              name="fromClassId"
              label={lang.vehicleClassCopyFrom}
              icon={<Icon class="fas-search" />}
              data={vehicleClassPayload?.data.map((it) => it.displayInfo)}
              value={selected.id}
              error={form.errors.fromClassId}
              disabled={true}
              onChange={form.update}
            />
            <SelectInput
              name="toClassId"
              label={lang.vehicleClassCopyTo}
              data={vehicleClassPayload?.data.map((it) => it.displayInfo)}
              value={form.data.toClassId}
              error={form.errors.toClassId}
              disabled={isExecuting}
              onChange={form.update}
              icon={<Icon class="fas-car" />}
            />
          </FormWrapper>
        );
      }}
    />
  );
}
