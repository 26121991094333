import Icon from 'assets/components/icon/Icon';
import Sidebar from 'assets/templates/sidebar/Sidebar';
import ContractInfoDetails from 'module/sales/views/contract/sections/info/Contract.Info.Details';
import { useContractProvider } from '../Contract.Hooks';

export default function ContractInfo() {
  const { selected, pageRouter } = useContractProvider();

  return !selected ? null : (
    <Sidebar
      onClose={() => pageRouter.updateParams({})}
      icon={<Icon class="if-database" />}
      title={selected?.appNumber}
      subtitle={selected?.vehicle?.vin}
      content={<ContractInfoDetails contract={selected} />}
    />
  );
}
