import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import Tier from 'models/productManagement/tier/Model.Tier';
import { useVehicleMakeProvider } from '../VehicleMake.Hooks';

export default function VehicleMakeFilters() {
  const { lang } = getLocales();
  const { listApi } = useVehicleMakeProvider();
  const tierApi = useApi({ action: Tier.list });

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByName}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'MakeCode',
                orderType: OrderType.asc,
                thenType: [OrderType.asc, OrderType.asc, OrderType.asc],
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByName}
              media={<Icon class="fas-caret-down" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'MakeCode',
                orderType: OrderType.desc,
                thenType: [OrderType.desc, OrderType.desc, OrderType.desc],
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        render={({ tempFilters, setFilters }) => (
          <>
            <SelectInput
              name="tierId"
              label={lang.tier}
              data={tierApi?.payload?.data?.map((item) => ({
                id: item.id,
                title: item.name,
                icon: <Icon class="fas-layer-group" />,
              }))}
              isMultiselect
              value={tempFilters?.tierId}
              onChange={({ tierId }) => setFilters({ ...tempFilters, tierId })}
              icon={<Icon class="fas-layer-group" />}
            />
          </>
        )}
      />
    </>
  );
}
