import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Response = Api.Core.Dealer.DeleteProductDocument.IResponse;
type Headers = Api.Core.Dealer.DeleteProductDocument.IHeaders;
const apiDealerDeleteProductDocumentPath =
  '/api/DealerManagement/Dealer/:id/DeleteProductDealerDocument/:productDealerDocumnetId';
export default async function dealerDeleteProductDocumentApi(params: {
  id: number;
  productDealerDocumentId: number;
}): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    url: createPathFromRoute(apiDealerDeleteProductDocumentPath, {
      id: params.id,
      productDealerDocumnetId: params.productDealerDocumentId,
    }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
