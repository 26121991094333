import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { OrderType } from 'config/Api.Config';
import { useClaimProvider } from '../Claim.Hooks';

export default function ClaimFilters() {
  const { lang } = getLocales();
  const { listApi, productsApi, userApi, vehicleMakeApi, statuses } = useClaimProvider();

  return (
    <>
      <SearchInput
        value={listApi?.body?.search}
        onSearch={(search) => listApi?.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        subcomponents={ThemeDropdownButtonPopup}
        htmlElementProps={{ title: lang.sort }}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi?.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi?.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByUserName}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi?.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'firstName',
                orderType: OrderType.asc,
                thenBy: 'lastName',
                thenType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByUserName}
              media={<Icon class="fas-caret-down" />}
              onClick={listApi?.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'firstName',
                orderType: OrderType.desc,
                thenBy: 'lastName',
                thenType: OrderType.desc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi?.body}
        onFilter={(newFilers) => listApi?.execute(newFilers)}
        onReset={listApi?.reset}
        onOpen={() => {
          if (!productsApi?.payload) productsApi?.execute();
          if (!vehicleMakeApi?.payload) vehicleMakeApi?.execute();
          if (!userApi?.payload) userApi?.execute();
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <SelectInput
              name="statusCode"
              label={lang.status}
              data={statuses}
              isMultiselect
              value={tempFilters?.statusCode}
              onChange={({ statusCode }) => setFilters({ ...tempFilters, statusCode })}
              icon={<Icon class="fas-check" />}
            />
            <SelectInput
              name="makeId"
              label={lang.vehicleMakes}
              data={vehicleMakeApi?.payload?.data?.map((item) => ({
                ...item.displayInfo,
                icon: <Icon class="fas-car" />,
              }))}
              isMultiselect
              value={tempFilters?.makeId}
              onChange={({ makeId }) => setFilters({ ...tempFilters, makeId })}
              icon={<Icon class="fas-car" />}
            />
            <SelectInput
              name="productId"
              label={lang.products}
              data={productsApi?.payload?.data?.map((item) => ({
                ...item.displayInfo,
                icon: <Icon class="fas-box" />,
              }))}
              isMultiselect
              value={tempFilters?.productId}
              onChange={({ productId }) => setFilters({ ...tempFilters, productId })}
              icon={<Icon class="fas-box" />}
            />
            <SelectInput
              name="ownerId"
              label={lang.owner}
              data={userApi?.payload?.data?.map((item) => ({
                ...item.displayInfo,
                icon: <Icon class="fas-user" />,
              }))}
              isMultiselect
              value={tempFilters?.ownerId}
              onChange={({ ownerId }) => setFilters({ ...tempFilters, ownerId })}
              icon={<Icon class="fas-user" />}
            />
            <InputWrapper>
              <DateInput
                name="startDateUtc"
                label={lang.startDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.startDateUtc}
                onChange={({ startDateUtc }) => setFilters({ ...tempFilters, startDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                name="endDateUtc"
                label={lang.endDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.endDateUtc}
                onChange={({ endDateUtc }) => setFilters({ ...tempFilters, endDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </InputWrapper>
          </>
        )}
      />
    </>
  );
}
