import Model from 'models/Model';

export default class ProductProvinceGroup
  extends Model<Model.IProductProvinceGroup>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductProvinceGroup>
{
  id: number;
  productId: number;
  name: string;
  provinceCodes: string[];

  constructor(
    data: Partial<Model.IProductProvinceGroup> | Utils.FormData<Model.IProductProvinceGroup>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.provinceCodes.join(', ') || this.id.toString(),
    };
  }
}
