import React from 'react';
import ReactDOM from 'react-dom';
import RenderProps from './RenderProps';

export default function Portal(props: Component.Portal.Import) {
  if (props.dontPort) return <>{RenderProps(props, 'render')}</>;
  try {
    const target =
      props && props.domElement && props.domElement.tagName
        ? props.domElement
        : document.querySelector(props.querySelector || '');
    if (target) return ReactDOM.createPortal(RenderProps(props, 'render'), target);
    else return props.renderIfPortalFails ? <>{RenderProps(props, 'render')}</> : null;
  } catch (e) {
    return props.renderIfPortalFails ? <>{RenderProps(props, 'render')}</> : null;
  }
}
