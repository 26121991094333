import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { uniq } from 'lodash';
import Model from 'models/Model';
import ClaimType from '../../claims/claimType/Model.ClaimType';
import Insurer from '../insurer/Model.Insurer';
import PricingDistributionTemplate from '../pricingDistributionTemplate/Model.PricingDistributionTemplate';
import ProductCategory from '../productCategory/Model.ProductCategory';
import ProductDocument from '../productDocument/ProductDocument';
import ProductDocumentTemplate from '../productDocumentTemplate/Model.ProductDocumentTemplate';
import ProductProvinceGroup from '../productProvinceGroup/Model.ProductProvinceGroup';
import ProductTax from '../productTax/Model.ProductTax';
import Subfee from '../subfee/Model.Subfee';
import productDeleteApi from './delete/Api.Product.Delete';
import productDetailsApi from './details/Api.Product.Details';
import productFindApi from './find/Api.Product.Find';
import productListApi from './list/Api.Product.List';
import productSaveApi from './save/Api.Product.Save';

export default class Product
  extends Model<Model.IProduct>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProduct>
{
  id: number;
  legacyId?: number;
  code?: string;
  name?: string;
  limitOfLiabilityAggregateCAD?: number;
  productType?: Model.Enum.ProductType;
  categoryId?: number;
  category?: ProductCategory;
  canSellPostSale?: boolean;
  isPartOfPackage?: boolean;
  isWarrantyDetailsVisible?: boolean;
  isPurchaseDetailsVisible?: boolean;
  isCustomerConsentRequired?: boolean;
  isActive?: boolean;
  claimTypes?: ClaimType[];
  insurers?: Insurer[];
  subfee?: Subfee[];
  pricingDistributionTemplateId?: number;
  pricingDistributionTemplate?: PricingDistributionTemplate;
  productDocuments?: ProductDocument[];
  productTaxes?: ProductTax[];
  productProvinceGroups?: ProductProvinceGroup[];
  productDocumentTemplates?: ProductDocumentTemplate[];

  constructor(data: Partial<Model.IProduct> | Utils.FormData<Model.IProduct>, language?: Locale.Languages) {
    super(
      {
        claimTypes: [ClaimType],
        insurers: [Insurer],
        subfee: [Subfee],
        category: ProductCategory,
        pricingDistributionTemplate: PricingDistributionTemplate,
        productDocuments: [ProductDocument],
        productTaxes: [ProductTax],
        productProvinceGroups: [ProductProvinceGroup],
        productDocumentTemplates: [ProductDocumentTemplate],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: combineStrings(' - ', this.category?.name, this.name) || this.id?.toString(),
      subtitle: this.pricingDistributionTemplate?.name,
      info: combineStrings(' | ', this.code, this.productType ? lang[this.productType] : null),
    };
  }

  get templateKeys() {
    return uniq(
      [
        ...(this.insurers?.map((i) => `Premium.${i.name}`) || []),
        ...(this.pricingDistributionTemplate?.keys || []),
      ].filter((it) => !!it)
    );
  }

  get pricingDistribution() {
    return this.templateKeys.reduce(
      (prev, curr) => ({ ...prev, [curr]: { currency: 'CAD', amount: 0.0 } }),
      {} as Utils.PricingDistribution
    );
  }

  static async list(body?: Api.Core.Product.List.IRequest) {
    const { payload, ...rest } = await productListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Product(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: { id: number }) {
    const { payload, ...rest } = await productFindApi(params);
    return {
      ...rest,
      payload: payload ? new Product(payload) : null,
    };
  }
  async save() {
    return await productSaveApi(this);
  }
  async delete() {
    return await productDeleteApi(this.id);
  }

  static async details(body: Api.Core.Product.Details.IRequest) {
    const { payload, ...rest } = await productDetailsApi(body);
    return {
      ...rest,
      payload: payload?.map((it) => new Product(it)),
    };
  }
}
