import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import ProductType from 'models/enums/ProductType';
import Product from 'models/productManagement/product/Model.Product';
import { buildCustomPricingPath } from 'module/productManagement/views/customPricing/CustomPricing.Index';
import { buildNonWarrantyPricingPath } from 'module/productManagement/views/nonWarrantyPricing/NonWarrantyPricing.Index';
import { buildWarrantyClassPricingPath } from 'module/productManagement/views/warrantyClassPricing/WarrantyClassPricing.Index';
import { buildWarrantyTierPricingPath } from 'module/productManagement/views/warrantyTierPricing/WarrantyTierPricing.Index';
import { useHistory } from 'react-router';

type Props = {
  product: Product;
  label?: Utils.RenderPropsEmpty;
  class?: string;
};
export default function ProductPricingButton({ product, label, class: className }: Props) {
  const { permissions } = useAuthStore();
  const { lang } = getLocales();
  const history = useHistory();
  if (!product) return null;
  return (
    <Button
      onClick={() => {
        if (product.productType)
          history.push(
            product.productType === ProductType.custom
              ? buildCustomPricingPath({ productId: product.id.toString() })
              : product.productType === ProductType.nonWarranty
              ? buildNonWarrantyPricingPath({ productId: product.id.toString() })
              : product.productType === ProductType.warrantyTierBased
              ? buildWarrantyTierPricingPath({ productId: product.id.toString() })
              : product.productType === ProductType.warrantiClassBased
              ? buildWarrantyClassPricingPath({ productId: product.id.toString() })
              : null
          );
      }}
      class={className ?? ThemeButtonCircle}
      disabled={
        !product.productType ||
        (product.productType === ProductType.custom
          ? !(
              permissions.CREATE_CUSTOMPRICEPOINT ||
              permissions.EDIT_CUSTOMPRICEPOINT ||
              permissions.DELETE_CUSTOMPRICEPOINT
            )
          : product.productType === ProductType.nonWarranty
          ? !(
              permissions.CREATE_NONWARRANTYPRICEPOINT ||
              permissions.EDIT_NONWARRANTYPRICEPOINT ||
              permissions.DELETE_NONWARRANTYPRICEPOINT
            )
          : product.productType === ProductType.warrantyTierBased
          ? !(
              permissions.CREATE_PRODUCTTIERPRICEPOINT ||
              permissions.EDIT_PRODUCTTIERPRICEPOINT ||
              permissions.DELETE_PRODUCTTIERPRICEPOINT
            )
          : product.productType === ProductType.warrantiClassBased
          ? !(
              permissions.CREATE_PRODUCTPRICINGPLAN ||
              permissions.EDIT_PRODUCTPRICINGPLAN ||
              permissions.DELETE_PRODUCTPRICINGPLAN
            )
          : true)
      }
      media={<Icon class="fas-coins" />}
      render={label}
      htmlElementProps={{ title: lang.pricingPlan }}
    />
  );
}
