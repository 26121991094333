import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { accountChangePasswordRoute } from 'module/account/views/changePassword/Account.ChangePassword.Index';
import { accountUserWelcomeRoute } from 'module/account/views/userWelcome/Account.UserWelcome.Index';
import React from 'react';
import DropdownButton from '../dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from '../dropdownButton/themes/Theme.DropdownButton.Popup';

type Props = {
  isUsernameVisible?: boolean;
};
export default function UserActionMenu(props: Props) {
  const { lang } = getLocales();
  const authInfo = useAuthStore();

  return (
    <DropdownButton
      title={authInfo.displayname || 'User account'}
      buttonMedia={<Icon class="fas-user" />}
      buttonBody={props.isUsernameVisible && authInfo.displayname}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      subcomponents={ThemeDropdownButtonPopup}
      htmlElementProps={{ title: lang.userMenu }}
      dropdownOptions={
        <>
          <LinkButton
            class={ThemeButtonMenuItem}
            media={<Icon class="if-user" />}
            render={lang.mySettings}
            url={accountUserWelcomeRoute}
            htmlElementProps={{ title: lang.mySettings }}
          />
          <LinkButton
            class={ThemeButtonMenuItem}
            media={<Icon class="if-lock" />}
            render={lang.changePassword}
            url={accountChangePasswordRoute}
            htmlElementProps={{ title: lang.changePassword }}
          />
          <Button
            class={ThemeButtonMenuItem}
            media={<Icon class="if-logout" />}
            render={lang.logout}
            onClick={authInfo.logout}
            htmlElementProps={{ title: lang.logout }}
          />
        </>
      }
    />
  );
}
