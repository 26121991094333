export const ascLogoOld = `${process.env.PUBLIC_URL}/images/logo.jpeg`;
export const ascLogo = `${process.env.PUBLIC_URL}/images/logo-asc.svg`;
export const ascLogoWhite = `${process.env.PUBLIC_URL}/images/logo-asc-white.svg`;
export const ascLogoPng = `${process.env.PUBLIC_URL}/images/logo-asc.png`;
export const ascInsuranceLogo = `${process.env.PUBLIC_URL}/images/logo-insurance-asc.png`;

export const visaCard = `${process.env.PUBLIC_URL}/images/visa.svg`;
export const masterCard = `${process.env.PUBLIC_URL}/images/mastercard.svg`;
export const amexCard = `${process.env.PUBLIC_URL}/images/amex.svg`;
export const discoverCard = `${process.env.PUBLIC_URL}/images/discover.svg`;
