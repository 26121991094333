import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import ThemeWrapperBreadcrumbs from 'assets/themes/wrappers/Theme.Wrapper.Breadcrumbs';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import useSalesQuote, { SalesQuoteAction } from './Sales.Quote.Hooks';
import SalesQuoteQuote from './sections/Sales.Quote.Quote';
import SalesQuoteProducts from './sections/Sales.Quote.Products';
import SalesQuoteVehicle from './sections/Sales.Quote.Vehicle';

export const salesQuoteIndexRoute = salesHomeRoute + '/quote/:action/:id?';
export default function SalesQuoteIndex() {
  const { lang } = getLocales();
  const newQuoteHook = useSalesQuote();

  if (!newQuoteHook.isAllowed) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={newQuoteHook}
        render={({ isAllowed, activeTab, nextTab, next, prev, reset, openTab, prevTab, permissions, form, tabs }) => (
          <Page
            class={ThemePageStatic2}
            style={{
              page: {
                overflow: 'auto',
                height: '100vh',
              },
            }}
            header={<Card class={ThemeCardRow} media={<Icon class="fa-clipboard-question" />} title={lang.quote} />}
            subHeader={
              <div className={ThemeWrapperBreadcrumbs}>
                <DataMap
                  data={tabs}
                  render={({ data: tab }) => (
                    <div
                      id={tab.id}
                      data-active={tab.id === activeTab.id ? true : undefined}
                      style={{ cursor: form.data.id ? 'pointer' : undefined }}
                      onClick={
                        form.data.id && !tab.hasErrors && tab.id !== SalesQuoteAction.QUOTE
                          ? () => openTab(tab.id)
                          : null
                      }
                    >
                      {tab.label}
                    </div>
                  )}
                />
              </div>
            }
            render={
              isAllowed ? (
                <>
                  {activeTab.id === SalesQuoteAction.VEHICLE && <SalesQuoteVehicle />}
                  {activeTab.id === SalesQuoteAction.PRODUCTS && <SalesQuoteProducts />}
                  {activeTab.id === SalesQuoteAction.QUOTE && <SalesQuoteQuote />}
                </>
              ) : (
                <ErrorPage status={HttpStatus.unauthorized} />
              )
            }
            footer={
              activeTab.id === SalesQuoteAction.QUOTE ? (
                <Button
                  class={ThemeButtonCircle}
                  active
                  render={lang.reset}
                  onClick={reset}
                  disabled={!permissions.CREATE_CONTRACT}
                />
              ) : (
                <>
                  <Button
                    onClick={prev}
                    render={lang.prev}
                    class={ThemeButtonCircle}
                    active={activeTab.id !== prevTab.id}
                    disabled={activeTab.id === prevTab.id}
                  />
                  <Button
                    onClick={next}
                    render={lang.next}
                    class={ThemeButtonCircle}
                    active={activeTab.id !== nextTab.id}
                    disabled={activeTab.id === nextTab.id || activeTab.hasErrors}
                    isLoading={form.isSubmitted}
                  />
                </>
              )
            }
          />
        )}
      />
    </>
  );
}
