import Button from 'assets/components/button/Button';
import ThemeButtonFileItem from 'assets/components/button/themes/Theme.Button.FileItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { ApplicationSettings } from 'config/Api.Config';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import DropdownButton from '../dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from '../dropdownButton/themes/Theme.DropdownButton.Popup';

export default function AppMenu({ routes }: { routes: Component.Navigation.Route[] }) {
  const { lang } = getLocales();
  const apps = useMemo(
    () =>
      uniq(routes.map((r) => r.applicationId)).map((appId) => ({
        name: ApplicationSettings[appId].name,
        icon: ApplicationSettings[appId].icon,
        id: appId,
        path: routes.find((r) => r.applicationId === appId)?.path,
      })),
    [routes]
  );
  const history = useHistory();

  return !apps?.length ? null : (
    <DropdownButton
      title={lang.applications}
      buttonMedia={<Icon class="fas-th" />}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      constrainment="unconstrained"
      disableWidthConstraint
      subcomponents={ThemeDropdownButtonPopup}
      htmlElementProps={{ title: lang.applicationMenu }}
      dropdownOptions={
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridAutoRows: `calc(2 * ${Theme.Size.XL})`,
              gridGap: Theme.Size.S,
              padding: Theme.Size.S,
            }}
          >
            <DataMap
              data={apps}
              render={({ data: app }) => (
                <Button
                  key={app.id}
                  class={ThemeButtonFileItem}
                  style={{
                    button: {
                      backgroundColor: Theme.Color.primary,
                      color: Theme.Color.primaryAlt,
                    },
                    buttonText: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      fontSize: Theme.FontSize.S,
                    },
                  }}
                  media={<Icon class={app.icon} />}
                  render={app.name}
                  onClick={() => history.push(app.path)}
                  htmlElementProps={{ title: app.name }}
                />
              )}
            />
          </div>
        </>
      }
    />
  );
}
