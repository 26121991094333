import {
  ApiHeaderApplication,
  claimsHomeRoute,
  dealerManagementHomeRoute,
  homeRoute,
  managementHomeRoute,
  productManagementHomeRoute,
  salesHomeRoute,
} from 'config/Api.Config';
import Model from 'models/Model';
import Permission from '../permission/Model.Permission';
import applicationListApi from './list/Api.Application.List';
import applicationSaveApi from './save/Api.Application.Save';

export default class Application
  extends Model<Model.IApplication>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IApplication>
{
  id: number;
  name?: string;
  url?: string;
  permissions: Permission[];

  constructor(data: Partial<Model.IApplication> | Utils.FormData<Model.IApplication>, language?: Locale.Languages) {
    super({ permissions: [Permission] }, language);
    this.update(data);
    this.url =
      data?.id === ApiHeaderApplication.management
        ? managementHomeRoute
        : data?.id === ApiHeaderApplication.claims
        ? claimsHomeRoute
        : data?.id === ApiHeaderApplication.sales
        ? salesHomeRoute
        : data?.id === ApiHeaderApplication.productManagement
        ? productManagementHomeRoute
        : data?.id === ApiHeaderApplication.dealerManagement
        ? dealerManagementHomeRoute
        : homeRoute;
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
  static async list(body?: Api.Core.Application.List.IRequest) {
    const { payload, ...rest } = await applicationListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Application(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await applicationSaveApi(this);
  }
}
