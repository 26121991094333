import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, dealerManagementHomeRoute } from 'config/Api.Config';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useDealer, { DealerAction } from './Dealer.Hooks';
import DealerDelete from './sections/Dealer.Delete';
import DealerEdit from './sections/Dealer.Edit';
import DealerFilters from './sections/Dealer.Filters';
import DealerList from './sections/Dealer.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const dealerIndexRoute = dealerManagementHomeRoute + '/dealer/:action?/:id?';
export default function DealerIndex() {
  const { lang } = getLocales();
  const dealerHook = useDealer();
  const { permissions } = useAuthStore();

  if (!dealerHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={dealerHook}
        render={({ listApi, views, pageRouter }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.dealers} />
                <DealerFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions.CREATE_DEALER}
                  data={{ action: DealerAction.EDIT }}
                />
              </>
            }
            render={
              <>
                {views.edit && <DealerEdit />}
                {views.delete && <DealerDelete />}
                <DealerList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
