import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import useInput from '../Input';

export enum TextInputStructure {
  formInput = 'formInput',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.Text.Structure, typeof TextInputStructure>;
export default function TextInput<Name>(props: Component.Input.Text.Import<Name>) {
  const extend = useTheme(TextInputStructure, props);
  const { value, setValue } = useInput(props);
  return (
    <div
      {...extend(TextInputStructure.formInput, true)}
      data-label={props.label}
      data-error={props.error || undefined}
      data-read-only={props.readOnly || undefined}
    >
      {RenderProps(props, 'iconBefore')}
      <input
        {...extend(TextInputStructure.input)}
        type={props.type || (/password/g.test(props.name?.toString()) ? 'password' : 'text')}
        autoComplete={
          typeof props.autoComplete === 'boolean' ? (props.autoComplete ? 'on' : 'off') : props.autoComplete
        }
        readOnly={props.readOnly}
        minLength={props.minLength}
        maxLength={props.maxLength}
        pattern={props.pattern}
        value={value || ''}
        placeholder={props.placeholder || ' '}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.currentTarget.value)}
      />
      {RenderProps(props, 'icon')}
    </div>
  );
}
