import Model from 'models/Model';
import statementSummaryListApi from './list/Api.StatementSummary.List';
import statementSummarySaveApi from './save/Api.StatementSummary.Save';
import statementSummaryDeleteApi from './delete/Api.StatementSummary.Delete';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';
import Contract from '../contract/Model.Contract';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';

export default class StatementSummary
  extends Model<Model.IStatementSummary>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IStatementSummary>
{
  id: number;
  statementNumber: number;
  invoicingDate: string;
  createdAtUtc: string;
  fromDate: string;
  toDate: string;
  modifiedAtUtc: string;
  dealerGroupId: number;
  dealerGroup: DealerGroup;
  contracts: Contract[];

  constructor(
    data: Partial<Model.IStatementSummary> | Utils.FormData<Model.IStatementSummary>,
    language?: Locale.Languages
  ) {
    super({ dealerGroup: DealerGroup, contracts: [Contract] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.dealerGroup?.name || this.id.toString(),
      subtitle: this.dealerGroup?.dealers.map((dealer) => dealer.name).join(', '),
      info: date(this.invoicingDate, defaultServerDateTimeFormat).format('MMMM YYYY'),
    };
  }

  static async list(body?: Api.Core.StatementSummary.List.IRequest) {
    const { payload, ...rest } = await statementSummaryListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new StatementSummary(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await statementSummarySaveApi(this);
  }
  async delete() {
    return await statementSummaryDeleteApi(this.id);
  }
}
