import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import useProduct from './Product.Hooks';
import ProductDelete from './sections/Product.Delete';
import ProductEdit from './sections/Product.Edit';
import ProductList from './sections/Product.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

enum Actions {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type Verify = Utils.VerifyExtends<Module.ProductManagement.Product.Actions, Utils.ValueOf<typeof Actions>>;
export const productIndexRoute = productManagementHomeRoute + '/product/:action?/:id?';
export default function ProductIndex() {
  const productHook = useProduct();

  if (!productHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={productHook}
        render={({ views }) => (
          <>
            {views.edit ? (
              <ProductEdit />
            ) : (
              <>
                {views.delete && <ProductDelete />}
                <ProductList />
              </>
            )}
          </>
        )}
      />
    </>
  );
}
