import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.SendDocumentForSigning.IRequest;
type Response = Api.Core.Contract.SendDocumentForSigning.IResponse;
type Headers = Api.Core.Contract.SendDocumentForSigning.IHeaders;
const url = '/api/Sales/Contract/:contractId/SendDocumentForSigning';
export default async function contractSendDocumentForSigningApi({ contractId }: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<undefined, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { contractId }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
