import React from 'react';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import HeaderData from 'assets/components/data/rows/HeaderData';
import FormatValue from 'assets/components/formatValue/FormatValue';
import { StatementSummaryAction, useStatementSummaryProvider } from '../StatementSummary.Hooks';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';

export default function StatementSummaryList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useStatementSummaryProvider();
  const { permissions } = useAuthStore();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.statements}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <HeaderRow>
        <HeaderData>{lang.dealerGroup}</HeaderData>
        <HeaderData>{lang.fromDate}</HeaderData>
        <HeaderData>{lang.toDate}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.totalAmount}</HeaderData>
        <HeaderData numOfActions={1} />
      </HeaderRow>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: statementSummary }) => (
          <Row
            key={statementSummary.id}
            children={
              <>
                <RowImage render={<Icon class="fas-user-shield" />} />
                <RowData {...statementSummary.displayInfo} />
                <RowData>
                  {date(statementSummary.fromDate, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                </RowData>
                <RowData>
                  {date(statementSummary.toDate, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                </RowData>
                <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                  <b>
                    <FormatValue
                      value={`${(
                        statementSummary.contracts.reduce(
                          (sum, obj) =>
                            sum + (obj.client?.taxExempt ? obj.paymentAmountCAD : obj.paymentAmountWithTaxCAD),
                          0
                        ) || 0
                      ).toFixed(2)}$`}
                    />
                  </b>
                </RowData>
                <RowActions isVertical>
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statementSummary.id.toString(),
                      action: StatementSummaryAction.PRINT,
                      router: pageRouter,
                    }}
                    disabled={!permissions.CREATE_STATEMENTSUMMARY}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-print" />}
                    htmlElementProps={{ title: lang.print }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: statementSummary.id.toString(),
                      action: StatementSummaryAction.DELETE,
                    }}
                    disabled={!permissions.DELETE_STATEMENTSUMMARY}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{ title: lang.delete }}
                  />
                  <Button />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
