import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import { combineStrings } from 'assets/utils/data/String';
import { Fragment } from 'react';
import { useSalesContractProvider } from '../../Sales.Contract.Hooks';
import ContractInfoDetails from './Contract.Info.Details';
import LinkButton from 'assets/components/button/LinkButton';

export default function ContractInfoRevisions() {
  const { lang } = getLocales();
  const { revisions, selectedRevision, selectRevision } = useSalesContractProvider();

  return (
    <>
      <Collection class={combineStrings(' ', ThemeCollectionTable, ThemeWrapperPaper)} style={{ padding: 0 }}>
        <div
          style={{
            fontSize: Theme.FontSize.L,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {lang.revisions}
        </div>
        {!revisions?.length ? (
          <ErrorWrapper children={lang.noInfo} />
        ) : (
          <DataMap
            data={revisions}
            render={({ data }) =>
              data.contractJson && (
                <Fragment key={data.id}>
                  <Card
                    class={ThemeCardRow}
                    title={
                      data.contractJson.appNumber + (data.contractJson.revisionNumber ? `-R${data.revisionNumber}` : '')
                    }
                    info={data.user ? combineStrings(' ', data.user?.firstName, data.user?.lastName) : lang.unknownUser}
                    media={<Icon class="fas-clock" />}
                    actions={
                      <>
                        <LinkButton
                          class={ThemeButtonCircle}
                          url={data.contractJson?.documentationAggregate?.url}
                          disabled={!data.contractJson?.documentationAggregate}
                          media={<Icon class="fas-download" />}
                          target="_blank"
                          htmlElementProps={{ title: lang.download }}
                        />
                        <Button
                          class={ThemeButtonCircle}
                          media={
                            <Icon class={selectedRevision?.id === data?.id ? 'fas-chevron-down' : 'fas-chevron-left'} />
                          }
                          onClick={selectRevision}
                          data={selectedRevision?.id === data?.id ? null : data}
                        />
                      </>
                    }
                  />
                  {selectedRevision?.id === data?.id && (
                    <div>
                      <ContractInfoDetails contract={selectedRevision.contractJson} />
                    </div>
                  )}
                </Fragment>
              )
            }
          />
        )}
      </Collection>
    </>
  );
}
