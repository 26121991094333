export type Verify = Utils.VerifyExtends<Effect.FontNestedSize, Utils.ValueOf<typeof FontNestedSizeVariable>>;
enum FontNestedSizeVariable {
  XXS = 'var(--fontNestedXXS)',
  XS = 'var(--fontNestedXS)',
  S = 'var(--fontNestedS)',
  M = 'var(--fontNestedM)',
  L = 'var(--fontNestedL)',
  XL = 'var(--fontNestedXL)',
  XXL = 'var(--fontNestedXXL)',
}
export default FontNestedSizeVariable;
