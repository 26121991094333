import { addLog } from 'assets/components/feedback/Feedback';
import { HttpStatus } from 'config/Api.Config';
import { useEffect, useState } from 'react';

export default function useApi<Req, Res>(
  props: Hooks.ApiConnection.Import<Req, Res>,
  dependencies: any[] = []
): Hooks.ApiConnection.Export<Req, Res> {
  const [state, setState] = useState<Hooks.ApiConnection.IState<Req, Res>>({
    body: { ...props.default, ...props.body },
    isExecuting: false,
    apiCounter: 0,
  });

  useEffect(() => {
    async function callApi() {
      const actionResponse = await props.action(state.body);
      setState((curr) => ({
        ...actionResponse,
        isExecuting: false,
        body: state.body,
        apiCounter: curr.apiCounter + 1,
      }));
      if (props.callback) props.callback(state.body, actionResponse);
    }

    if (state.status && state.message && !state.isExecuting) {
      addLog(state.status === HttpStatus.ok ? { success: state.message } : { error: state.message });
    } else if (state.isExecuting) callApi();
  }, [state]);

  async function execute(body?: Utils.ValueFunction<Req>) {
    if (!state.isExecuting) {
      const finalBody = typeof body == 'function' ? ((body as any)(state.body) as Req) : body;
      setState((curr) => ({
        ...(props.keepResponse ? curr : {}),
        isExecuting: true,
        body: finalBody,
        apiCounter: curr.apiCounter + 1,
      }));
    }
  }
  function reset(disableApiExec?: boolean) {
    setState((curr) => ({
      body: props.default,
      isExecuting: !disableApiExec,
      apiCounter: curr.apiCounter + 1,
    }));
  }

  useEffect(() => {
    if (!props.wait) execute({ ...props.default, ...props.body });
  }, dependencies);

  return {
    ...state,
    execute,
    reset,
  };
}
