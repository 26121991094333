import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { getLocales } from 'assets/locales/Locale';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import { combineStrings } from 'assets/utils/data/String';

type PreviewDocument = {
  id: number;
  url: string;
  name: string;
  description?: string;
  info?: string;
  isEditing?: boolean;
  onDelete?(): void;
  onClick?(): void;
};
type Props = {
  documents: PreviewDocument[];
  children?: any;
};
export default function ProductDocumentPreview(props: Props) {
  const { lang } = getLocales();

  return (
    <Collection class={combineStrings(' ', ThemeCollectionTable, ThemeWrapperPaper)} style={{ padding: 0 }}>
      {props.children}
      {!props.documents?.length ? (
        <ErrorWrapper children={lang.noFiles} />
      ) : (
        <DataMap
          data={props.documents}
          render={({ data: doc, index }) => (
            <Card
              key={index}
              class={ThemeCardRow}
              title={doc.name}
              subtitle={doc.description}
              info={doc.info}
              media={<Icon class="fas-file" />}
              actions={
                <>
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class={doc.isEditing ? 'fas-ban' : 'fas-edit'} />}
                    onClick={doc.onClick}
                    disabled={!doc.onClick}
                    htmlElementProps={{ title: doc.isEditing ? lang.ban : lang.edit }}
                  />
                  <LinkButton
                    class={ThemeButtonCircle}
                    url={doc.url}
                    media={<Icon class="fas-download" />}
                    target="_blank"
                    disabled={!doc.id || doc.isEditing}
                    htmlElementProps={{ title: lang.download }}
                  />
                  <Button
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    disabled={!doc.onDelete || doc.isEditing}
                    onClick={doc.onDelete}
                    htmlElementProps={{ title: lang.delete }}
                  />
                </>
              }
            />
          )}
        />
      )}
    </Collection>
  );
}
