import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { isEmpty } from 'assets/utils/parsersAndValidation/Validators';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import vehicleModelDeleteApi from 'models/productManagement/vehicleModel/delete/Api.VehicleModel.Delete';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import vehicleModelSaveApi from 'models/productManagement/vehicleModel/save/Api.VehicleModel.Save';
import { useMemo } from 'react';
import { vehicleModelIndexRoute } from './VehicleModel.Index';

export enum VehicleModelAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyVehicleModelAction = Utils.VerifyExtends<
  Module.ProductManagement.VehicleModel.Actions,
  Utils.ValueOf<typeof VehicleModelAction>
>;
export default function useVehicleModel() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.VehicleModel.Params,
    Module.ProductManagement.VehicleModel.Query
  >({
    route: vehicleModelIndexRoute,
  });

  const canView = permissions.CREATE_VEHICLEMODEL || permissions.EDIT_VEHICLEMODEL || permissions.DELETE_VEHICLEMODEL;
  const listApi = useApi({
    action: VehicleModel.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: vehicleModelDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: vehicleModelSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const vehicleMake: Model.IVehicleMake = {
    id: pageRouter.query.makeId,
    makeCode: pageRouter.query.list?.makeCode,
  };

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    vehicleMake: !isEmpty(vehicleMake) ? vehicleMake : null,
    views: {
      [VehicleModelAction.DELETE]:
        pageRouter.params.action === VehicleModelAction.DELETE && selected && permissions.DELETE_VEHICLEMODEL,
      [VehicleModelAction.EDIT]:
        pageRouter.params.action === VehicleModelAction.EDIT &&
        ((permissions.CREATE_VEHICLEMODEL && !selected) || (permissions.EDIT_VEHICLEMODEL && selected)),
    },
  };
}

export function useVehicleModelProvider() {
  return useDataProvider<ReturnType<typeof useVehicleModel>>();
}
