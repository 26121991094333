import Model from 'models/Model';
import lossCodeDeleteApi from './delete/Api.LossCode.Delete';
import lossCodeListApi from './list/Api.LossCode.List';
import lossCodeSaveApi from './save/Api.LossCode.Save';

export default class LossCode
  extends Model<Model.ILossCode>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.ILossCode>
{
  id: number;
  code: string;
  category: string;
  descnEn: string;
  notes: string;

  constructor(data: Partial<Model.ILossCode> | Utils.FormData<Model.ILossCode>, language?: Locale.Languages) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.descnEn || this.id.toString(),
      info: `${this.code} - ${this.category}`,
      subtitle: this.notes,
    };
  }
  static async list(body?: Api.Core.LossCode.List.IRequest) {
    const { payload, ...rest } = await lossCodeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new LossCode(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await lossCodeSaveApi(this);
  }
  async delete() {
    return await lossCodeDeleteApi(this.id);
  }
}
