import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageSplit from 'assets/components/page/themes/Theme.Page.Split';
import { getLocales } from 'assets/locales/Locale';
import ProductPricingButton from 'module/productManagement/components/productPricingButton/ProductPricingButton';
import { ProductAction, ProductEditTab, useProductProvider } from '../Product.Hooks';
import ProductEditDocuments from './edit/Product.Edit.Documents';
import ProductEditInfo from './edit/Product.Edit.Info';
import ProductEditNavigation from './edit/Product.Edit.Navigation';
import ProductEditProvinceGroups from './edit/Product.Edit.ProvinceGroups';
import ProductEditTaxes from './edit/Product.Edit.Taxes';
import ProductEditDocumentTemplates from './edit/Product.Edit.DocumentTemplates';

export default function ProductEdit() {
  const { lang } = getLocales();
  const { pageRouter, form, product, isEditLoading, newDocuments } = useProductProvider();

  return (
    <Page
      class={ThemePageSplit}
      header={
        product || form.data ? (
          <Card
            class={ThemeCardRow}
            {...(product?.displayInfo || { title: lang.addNew, subtitle: lang.product, media: 'fas-plus' })}
          />
        ) : (
          lang.noInfo
        )
      }
      isLoading={isEditLoading}
      sidebar={
        <>
          {(pageRouter.query.editTab === ProductEditTab.INFO || !pageRouter.query.editTab) && <ProductEditInfo />}
          {pageRouter.query.editTab === ProductEditTab.DOCUMENTS && <ProductEditDocuments />}
          {pageRouter.query.editTab === ProductEditTab.DOC_TEMPLATES && <ProductEditDocumentTemplates />}
          {pageRouter.query.editTab === ProductEditTab.TAXES && <ProductEditTaxes />}
          {pageRouter.query.editTab === ProductEditTab.PROVINCE_GROUPS && <ProductEditProvinceGroups />}
        </>
      }
      render={<ProductEditNavigation />}
      footer={
        <>
          <ProductPricingButton product={product} label={lang.goToPrices} />
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={
              <>
                {lang.save}
                {newDocuments.length > 0 && (
                  <>
                    &nbsp;+&nbsp;{newDocuments.length}
                    &nbsp;
                    <Icon class="fas-paperclip" />
                  </>
                )}
              </>
            }
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
          />
          {product && (
            <Button
              onClick={pageRouter.updateParams}
              data={{
                id: product?.id?.toString(),
                action: ProductAction.DELETE,
              }}
              class={ThemeButtonCircle}
              disabled={!product.id}
              render={lang.delete}
              media={<Icon class="fas-trash" />}
            />
          )}
        </>
      }
    />
  );
}
