import { combineStrings } from 'assets/utils/data/String';
import React from 'react';
import { inputStyles } from '../Input';

export default function FormWrapper(
  props: Component.DefaultProps & { style?: React.CSSProperties; class?: string; isLoading?: boolean }
) {
  return (
    <div
      data-loading={props.isLoading || undefined}
      className={combineStrings(' ', inputStyles.wrapper.formWrapper, props.class)}
      children={props.children}
      style={props.style}
    />
  );
}
