import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import { useLenderProvider } from '../Lender.Hooks';
import React from 'react';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import useApi from 'assets/hooks/api/useApi';
import Distributor from 'models/sales/distributor/Model.Distributor';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';

export default function LenderFilters() {
  const { isDistributor, isDealer } = useAuthStore();
  const { lang } = getLocales();
  const { listApi } = useLenderProvider();
  const distributorsApi = useApi({ action: Distributor.list, wait: isDistributor && !isDealer });

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
      &nbsp;
      {distributorsApi.payload?.data && (
        <FilterForm
          subcomponents={ThemeFilterFormDrawer}
          filters={listApi.body}
          onFilter={(newFilers) => listApi.execute(newFilers)}
          onReset={listApi.reset}
          onOpen={() => {
            if (!distributorsApi.payload && !isDistributor && !isDealer) distributorsApi.execute();
          }}
          render={({ tempFilters, setFilters }) => (
            <>
              <SelectInput
                name="distributorId"
                label={lang.distributors}
                data={distributorsApi.payload?.data?.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-box" />,
                }))}
                isMultiselect
                value={tempFilters?.distributorId}
                onChange={({ distributorId }) => setFilters({ ...tempFilters, distributorId })}
                icon={<Icon class="fas-box" />}
              />
            </>
          )}
        />
      )}
    </>
  );
}
