import { combineStrings } from 'assets/utils/data/String';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import User from 'models/core/user/Model.User';
import Model from 'models/Model';
import Product from 'models/productManagement/product/Model.Product';
import Distributor from 'models/sales/distributor/Model.Distributor';
import DealerGroup from '../dealerGroup/Model.DealerGroup';
import dealerDeleteApi from './delete/Api.Dealer.Delete';
import dealerFindApi from './find/Api.Dealer.Find';
import dealerListApi from './list/Api.Dealer.List';
import dealerSaveApi from './save/Api.Dealer.Save';
import ProductDealerDocument from '../productDealerDocument/Model.ProductDealerDocument';
import dealerListPublicApi from './listPublic/Api.Dealer.ListPublic';

export default class Dealer
  extends Model<Model.IDealer>({ icon: 'if-login' })
  implements Model.Instance<Model.IDealer>
{
  id: number;
  name?: string;
  isInactive: boolean = false;
  isPaymentRequired: boolean = false;
  primaryPhoneNumber?: string;
  primaryEmailAddress?: string;
  accountingEmails?: string[];
  representativeId: number;
  representative: Distributor;
  dealerGroupId?: number;
  dealerGroup: DealerGroup;
  mediaFiles?: MediaFile[];
  streetAddress?: string;
  city?: string;
  provinceCode?: string;
  countryCode?: string;
  postalCode?: string;
  users?: User[];
  products?: Product[];
  uiSettingsFile?: MediaFile;
  uiSettings?: Utils.UiSettings;
  productDealerDocuments?: Array<ProductDealerDocument>;
  labourRate?: number;
  paymentMethodCode?: string;

  mainDistributorId?: number;
  mainDistributor?: User;

  constructor(data: Partial<Model.IDealer> | Utils.FormData<Model.IDealer>, language?: Locale.Languages) {
    super(
      {
        representative: Distributor,
        dealerGroup: DealerGroup,
        uiSettingsFile: MediaFile,
        mediaFiles: [MediaFile],
        users: [User],
        products: [Product],
        productDealerDocuments: [ProductDealerDocument],
        mainDistributor: User,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id?.toString(),
      info: combineStrings(' | ', this.representative?.name, this.dealerGroup?.name),
      subtitle: this.products
        ?.filter((p) => p.isActive)
        ?.map((p) => p.name)
        ?.join(', '),
    };
  }

  get fullAddress() {
    return combineStrings(', ', this.streetAddress, combineStrings(' ', this.postalCode, this.provinceCode));
  }

  static async list(body?: Api.Core.Dealer.List.IRequest) {
    const { payload, ...rest } = await dealerListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Dealer(it)),
        count: payload.count,
      },
    };
  }

  static async listPublic(req: Api.Core.Dealer.ListPublic.IRequest) {
    const { payload, ...rest } = await dealerListPublicApi(req);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Dealer(it)),
        count: payload.count,
      },
    };
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await dealerFindApi(params);
    return {
      ...rest,
      payload: new Dealer(payload),
    };
  }
  async save() {
    return await dealerSaveApi(this);
  }
  async delete() {
    return await dealerDeleteApi({ id: this.id });
  }
}
