import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Account.ResetPassword.IRequest;
type Response = Api.Core.Account.ResetPassword.IResponse;
type Headers = Api.Core.Account.ResetPassword.IHeaders;
const apiAccountResetPasswordPath = '/api/Account/resetPassword';
export default async function accountSetPasswordApi(request: Request, token: string): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: apiAccountResetPasswordPath,
    method: HttpMethod.post,
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  });
}
