export enum ContractConsentStatus {
  GIVEN = 'GIVEN',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
}

export default ContractConsentStatus;
export type VerifyContractConsentStatus = Utils.VerifyExtends<
  Model.Enum.ContractConsentStatus,
  Utils.ValueOf<typeof ContractConsentStatus>
>;
