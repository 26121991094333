import React from 'react';
import RenderProps from '../../helpers/RenderProps';
import { useTheme } from '../../../hooks/theme/useTheme';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import './RowData.scss';

export enum RowDataStructure {
  rowData = 'rowData',
  rowInfotitle = 'rowInfotitle',
  rowTitle = 'rowTitle',
  rowSubtitle = 'rowSubtitle',
}
export type Verify = Utils.VerifyExtends<Component.RowData.Structure, typeof RowDataStructure>;
export default function RowData(props: Component.RowData.Import) {
  const renderedValue = isValidValue(props.render) || (props.children && RenderProps(props, 'render'));
  let alignment: string;
  if (!props.disableAutoAlignment) {
    if (renderedValue && typeof renderedValue == 'object' && renderedValue?.type?.name == 'Icon')
      alignment = 'textCenter';
    else if (
      isValidValue(renderedValue) &&
      Number.isFinite(typeof renderedValue == 'number' ? renderedValue : Number.parseFloat(renderedValue.toString()))
    )
      alignment = 'textRight';
    else if (typeof renderedValue == 'string' && /^\d+\w+$/g.test(renderedValue)) alignment = 'textRight';
    else alignment = 'textLeft';
  }

  const extend = useTheme(RowDataStructure, props, {
    class: { rowData: alignment },
  });
  return (
    <div {...extend(RowDataStructure.rowData, true)}>
      {props.info && <div {...extend(RowDataStructure.rowInfotitle)}>{props.info}</div>}
      {props.title && <div {...extend(RowDataStructure.rowTitle)}>{props.title}</div>}
      {props.subtitle && <div {...extend(RowDataStructure.rowSubtitle)}>{props.subtitle}</div>}
      {renderedValue}
    </div>
  );
}
