import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Headers = Api.Core.User.SendResetPasswordEmail.IHeaders;
const url = '/api/Management/User/:id/SendResetPasswordEmail';
export default async function userSendResetPasswordEmailApi(id: number): Promise<Api.Res<never>> {
  const serverResponse = await executeServerApi<never, never, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
